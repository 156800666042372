import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CurrentPage,
  FilterName,
  List,
  Loading,
  Params,
  TotalItems,
} from "redux/voucher/selectors";
import MainTable from "components/Table/MainTable";
import {
  changeFilterName,
  changeLimit,
  changePage,
  changeFilter,
  deleteFilter,
  addFilter,
} from "redux/voucher/slice";

import MainCard from "components/Paper/MainCard";
import MainCardHeader from "components/Paper/MainCardHeader";
import MainPagination from "components/Pagination";
import Providers from "components/Providers";
import { status_voucher, tipe_voucher } from "./variables";
import { getListVoucher, getVoucher } from "redux/voucher/action";
import Dialog from "./Dialog";
import useFetchData from "hook/useFetchData";
import ExportHelper from "helper/ExportHelper";
import Import from "./Import";

const Voucher = () => {
  const list = useSelector(List);
  const loading = useSelector(Loading);
  const params = useSelector(Params);
  const totalItems = useSelector(TotalItems);
  const currentPage = useSelector(CurrentPage);
  const filterName = useSelector(FilterName);
  const [open, setOpen] = useState({
    open: false,
    import: false,
  });

  const headers = [
    {
      name: "Nama Penerbit",
      key: "IssuerName",
      width: 20,
    },
    {
      name: "Nama Voucer",
      key: "VoucherName",
      width: 60,
    },
    {
      name: "Kode Prefiks",
      key: "PrefixCode",
      width: 20,
    },
  ];

  const filter = [
    {
      label: "Nama Penerbit",
      value: "IssuerName",
    },
    {
      label: "Nama Voucer",
      value: "VoucherName",
    },
    {
      label: "Kode Prefiks",
      value: "PrefixCode",
    },
  ];

  const filterData = [
    {
      label: "Tipe Voucer",
      key: "VoucherType",
      placeholder: "Pilih tipe voucer",
      type: "select",
      options: tipe_voucher,
    },
    {
      label: "Status Voucer",
      key: "Status",
      placeholder: "Pilih status voucer",
      type: "select",
      options: status_voucher,
    },
  ];

  const dispatch = useDispatch();

  const fetchData = async () => {
    await dispatch(getListVoucher(params));
  };

  const exportData = useFetchData({
    action: getVoucher,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "voucer",
        opt: {
          header: data.keys,
        },
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(null, null, data);
  };

  return (
    <>
      <Providers
        title="Master Voucer"
        currentPage={currentPage}
        params={params}
        get={fetchData}
      >
        <MainCard
          title="Daftar Voucer"
          onClick={() =>
            setOpen({
              ...open,
              open: true,
            })
          }
        >
          <MainCardHeader
            filter={filter}
            filterName={filterName}
            changeFilterName={changeFilterName}
            deleteFilter={deleteFilter}
            changeFilter={changeFilter}
            params={params}
            filterData={filterData}
            addFilter={addFilter}
            onChangeExport={(type) => handleExport(type)}
            title="Filter Voucer"
            onClickImport={() =>
              setOpen({
                ...open,
                import: true,
              })
            }
          />
          <MainTable
            headers={headers}
            loading={loading}
            data={list}
            summaryKey="IssuerName"
            detail={[
              {
                type: "link",
                url_key: "master-data/master-bantuan/voucer:id",
                tooltip: "Lihat Detail Penerbit",
                variant: "icon:eye",
              },
            ]}
            increment={params?.offset + 1}
          />
          <MainPagination
            params={params}
            changeLimit={changeLimit}
            changePage={changePage}
            totalItems={totalItems}
            currentPage={currentPage}
          />
        </MainCard>
      </Providers>
      <Dialog
        open={open.open}
        onClose={() =>
          setOpen({
            ...open,
            open: false,
          })
        }
        refresh={fetchData}
        dokumen_info
        kode_voucher
        ikon
      />

      <Import
        open={open.import}
        onClose={() =>
          setOpen({
            ...open,
            import: false,
          })
        }
        refresh={fetchData}
        isEdit={false}
      />
    </>
  );
};

export default Voucher;
