import { createAsyncThunk } from "@reduxjs/toolkit";
import { getParams, post, get } from "api";
import { SubsidiBansos } from "api/endpoints";

export const getListSubsidiBansos = createAsyncThunk(
  "subsidiBansos/getSubsidiBansos",
  async (params) => {
    const response = await getParams(SubsidiBansos.list, params);
    return response.data.data;
  }
);

export const getSubsidiBansos = async (params) => {
  const response = await getParams(SubsidiBansos.list, params);
  return response;
};

export const getPenerimaBansos = createAsyncThunk(
  "subsidiBansos/getPenerimaBansos",
  async ({ params, id }) => {
    const withId = `${SubsidiBansos.listPenerima}/${id}`;

    const response = await getParams(withId, params);
    return response.data.data;
  }
);

export const newSubsidiBansos = async (data) => {
  const response = await post(SubsidiBansos.action, data);

  return response;
};

export const getVoucherSubsidi = async (data, id) => {
  const withId = `${SubsidiBansos.voucherPenerbit}/${id}`;

  const response = await getParams(withId, data);

  return response;
};

export const getDetailSubsidi = async (id) => {
  const withId = `${SubsidiBansos.detail}/${id}`;

  const response = await get(withId);

  return response;
};

export const exportUserSubsidi = async (id) => {
  const withId = `${SubsidiBansos.exportUser}/${id}`;

  const response = await get(withId);

  return response;
};
