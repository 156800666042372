import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import React, { useRef } from "react";

const ImportDialog = ({
  open,
  onClose,
  title,
  children,
  loading,
  width,
  onClickTemplate,
  onChange,
  onClear,
  onSubmit,
  ...props
}) => {
  const br = width == "lg" ? "1108px" : width == "xs" ? "400px" : "570px";
  const iref = useRef(null);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      fullWidth
      sx={{
        "& .MuiDialog-container": {
          "& .MuiDialog-paper": {
            maxWidth: br,
            width: "calc(100% - 10%)",
            margin: "0px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
        variant="h2"
      >
        <span>Import Data</span>
        <Box
          sx={{
            display: "flex",
            gap: 4,
          }}
        >
          <Button variant="primary-dotted" onClick={() => onClickTemplate()}>
            Template
          </Button>
          <input
            type="file"
            hidden
            value=""
            ref={iref}
            accept=".xlsx, .xls, .csv"
            onChange={onChange}
          />
          <Button variant="primary-dotted" onClick={() => iref.current.click()}>
            Choose Files
          </Button>
        </Box>
      </DialogTitle>
      <DialogContent dividers {...props}>
        {children}
      </DialogContent>
      <DialogActions>
        <Stack direction={"row"} spacing={2} mx="auto">
          <Button variant="secondary-outlined" onClick={onClose}>
            Batal
          </Button>
          <Button variant="secondary-outlined" onClick={onClear}>
            Bersihkan
          </Button>
          <Button variant="primary" onClick={onSubmit} disabled={loading}>
            Simpan
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

ImportDialog.propTypes = {
  children: PropTypes.any,
  width: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.string,
  loading: PropTypes.bool,
  onClickTemplate: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onSubmit: PropTypes.func,
};

ImportDialog.defaultProps = {
  onClose: () => {},
  open: false,
  title: "",
  children: null,
  loading: false,
  width: "lg",
  onClickTemplate: () => {},
  onChange: () => {},
  onClear: () => {},
  onSubmit: () => {},
};
export default ImportDialog;
