import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import HeroImage from "assets/dasbor/hero.png";
import InisaWhite from "assets/dasbor/InisaWhite";
import { useNavigate } from "react-router-dom";
import { removeCookie } from "helper/cokkie";

const BussinesRegistration = () => {
  const navigate = useNavigate();

  const Logout = () => {
    removeCookie("token-ntt-webadmin");
    localStorage.clear();
    // navigate("/auth/login");

    navigate(`/auth/login`);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#F2F2F2",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "135vh",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "602px",
            background: `linear-gradient(#0009, #0009), url(${HeroImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundAttachment: "fixed",
            display: "flex",
            margin: "auto",
            alignItems: "center",
            flexDirection: "column",
            pt: 14,
            gap: 4,
          }}
        >
          <InisaWhite />
          <Typography
            sx={{
              color: "#fff",
              fontSize: "40px",
              fontWeight: "700",
            }}
          >
            Selamat Bergabung Dengan INISA!
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: 400,
          left: 0,
          right: 0,
        }}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "760px",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
            padding: 5,
            boxShadow: "0px 4px 11px -10px #c8c8c8",
            gap: 4,
            borderRadius: "10px",
          }}
        >
          <Typography
            variant="cardTitle"
            sx={{
              fontSize: "22px",
            }}
          >
            Terima kasih telah melakukan pendaftaran
          </Typography>
          <Typography
            variant="cardText2"
            sx={{
              fontSize: "14px",
              // width: "450px",
              textAlign: "center",
            }}
          >
            Bisnis Anda sudah dapat menerima pembayaran online. Pastikan bisnis
            Anda sudah terintegrasi sesuai dengan dokumentasi teknis kami.
          </Typography>
          <Button
            variant="primary"
            sx={{
              mt: 3,
            }}
            onClick={() => Logout()}
          >
            Menuju Dasbor
          </Button>
        </Paper>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            mt: 3,
            paddingTop: "20px",
            textAlign: "center",
          }}
        >
          <Typography variant="cardText2">
            {`Selanjutnya, Anda akan menerima email terkait status pendaftaran ini. \n Kami akan menghubungi Anda kembali apabila kami membutuhkan tambahan dokumen dan informasi dari Anda.`}
          </Typography>
          <Typography
            variant="cardText2"
            sx={{
              mt: 3,
            }}
          >
            Untuk aktivasi <b>metode pembayaran lainnya</b>, silahkan hubungi{" "}
            <b>info@inisa.id</b>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BussinesRegistration;
