import { Box, Button, Typography } from "@mui/material";
import MainDialog from "components/Dialog/MainDialog";
import React from "react";
import PropTypes from "prop-types";
import { QRCodeSVG } from "qrcode.react";

const QRCode = ({ open, onClose, dataQr }) => {
  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
      }}
      title="  "
      width="xs"
      action={false}
      sx={{
        borderTop: "none",
      }}
      closeButton={false}
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h2">Scan Kode</Typography>
        <Typography variant="h2" mb={3}>
          Untuk redeem voucer
        </Typography>
        {dataQr ? (
          <QRCodeSVG
            value={dataQr}
            size={300}
            level={"L"}
            bgColor={"#ffffff"}
            fgColor={"#000000"}
          />
        ) : (
          ""
        )}
      </Box>
    </MainDialog>
  );
};

QRCode.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  dataQr: PropTypes.any,
};

QRCode.defaultProps = {
  onClose: () => {},
  open: false,
  dataQr: null,
};

export default QRCode;
