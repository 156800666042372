import React from "react";

const MerchantIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.309 9.81802C29.309 9.81802 29.309 9.73317 29.309 9.69681L28.0969 3.6362C27.9878 3.0665 27.4909 2.6665 26.909 2.6665H5.09087C4.50905 2.6665 4.01208 3.07863 3.90299 3.6362L2.69087 9.69681C2.69087 9.69681 2.69087 9.78166 2.69087 9.81802C2.69087 9.85438 2.66663 9.89075 2.66663 9.93923V11.7574C2.66663 13.4423 3.66057 14.8847 5.09087 15.5756V28.121C5.09087 28.7877 5.63632 29.3332 6.30299 29.3332H25.6969C26.3636 29.3332 26.909 28.7877 26.909 28.121V15.5756C28.3394 14.8968 29.3333 13.4423 29.3333 11.7574V9.93923C29.3333 9.93923 29.309 9.8665 29.309 9.81802ZM17.2121 26.9089V20.8483H19.6363V26.9089H17.2121ZM22.0606 26.9089V19.6362C22.0606 18.9695 21.5151 18.4241 20.8484 18.4241H16C15.3333 18.4241 14.7878 18.9695 14.7878 19.6362V26.9089H7.51511V15.9392C8.46057 15.8059 9.30905 15.3695 9.93935 14.715C10.7151 15.5029 11.7818 15.9998 12.9697 15.9998C14.1575 15.9998 15.2242 15.5029 16 14.715C16.7757 15.5029 17.8424 15.9998 19.0303 15.9998C20.2181 15.9998 21.2848 15.5029 22.0606 14.715C22.6909 15.3695 23.5394 15.8059 24.4848 15.9392V26.9089H22.0606ZM25.0909 13.5756C24.0848 13.5756 23.2727 12.7635 23.2727 11.7574C23.2727 11.0907 22.7272 10.5453 22.0606 10.5453C21.3939 10.5453 20.8484 11.0907 20.8484 11.7574C20.8484 12.7635 20.0363 13.5756 19.0303 13.5756C18.0242 13.5756 17.2121 12.7635 17.2121 11.7574C17.2121 11.0907 16.6666 10.5453 16 10.5453C15.3333 10.5453 14.7878 11.0907 14.7878 11.7574C14.7878 12.7635 13.9757 13.5756 12.9697 13.5756C11.9636 13.5756 11.1515 12.7635 11.1515 11.7574C11.1515 11.0907 10.606 10.5453 9.93935 10.5453C9.27269 10.5453 8.72723 11.0907 8.72723 11.7574C8.72723 12.7635 7.91511 13.5756 6.90905 13.5756C5.90299 13.5756 5.09087 12.7635 5.09087 11.7574V10.0604L6.08481 5.09075H25.9151L26.909 10.0604V11.7574C26.909 12.7635 26.0969 13.5756 25.0909 13.5756Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MerchantIcon;
