import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useEffect } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import useFetchData from "hook/useFetchData";
import { Box, Collapse, Grid, IconButton, Typography } from "@mui/material";
import VerticalGrid from "components/Grid/VerticalGrid";
import TextFieldv3 from "components/TextField/TextFieldv3";
import MainController from "components/Controller/MainController";
import FormatNumber from "helper/FormatNumber";
import Autocompletev3 from "components/AutoComplete/Autocompletev3";
import {
  createMasterDistribusi,
  getMasterProduk,
  getMerchantProduk,
  updateMasterDistribusi,
} from "redux/inventory/action";
import DatePicker2 from "components/Picker/DatePicker2";
import { getMerchantList } from "redux/merchant/action";
import PlusCircleIcon from "assets/paper/PlusCircleIcon";
import XCircleIcon from "assets/paper/XCircleIcon";
import { TransitionGroup } from "react-transition-group";
import { getPenerbit } from "redux/penerbit/action";
import { API } from "api/axios";

const defaultValues = {
  mdCode: "",
  mdDate: null,
  mdIssuerId: null,
  mdMerchantId: null,
  stocks: [],
};

export const statusList = [
  {
    label: "Aktif",
    value: 0,
  },
  {
    label: "Ada",
    value: 1,
  },
  {
    label: "Habis",
    value: 2,
  },
  {
    label: "Tidak Aktif",
    value: 3,
  },
];

const Dialog = ({ open, onClose, refresh, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset: resetForm,
    setValue,
    watch,
    clearErrors,
    setError,
  } = useForm({
    defaultValues: { ...defaultValues },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "stocks",
    shouldUnregister: true,
    rules: {
      required: "Item Wajib Diisi",
    },
  });

  const action = useFetchData({
    action: !data?.id ? createMasterDistribusi : updateMasterDistribusi,
    reset: resetForm,
    message: !data?.id
      ? "Berhasil menambah distribusi baru"
      : "berhasil update data distribusi",
    refresh: refresh,
    onSuccess: () => {
      refresh();
      onClose();
    },
  });

  const penerbit = useFetchData({
    action: getMerchantList,
    snackbar: false,
  });
  const merchant = useFetchData({
    action: getMerchantList,
    snackbar: false,
  });

  const produk = useFetchData({
    action: getMerchantProduk,
    snackbar: false,
  });

  const onSubmit = async (submitData) => {
    if (!submitData.stocks.length) {
      return;
    }
    await action.fetch(submitData, data?.id);
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  useEffect(() => {
    if (data != null) {
      const copy = { ...data };
      delete copy.id;

      if (copy.mdIssuerId) {
        penerbit.fetch({
          limit: 10,
          offset: 0,
          id: copy.mdIssuerId || "",
        });
      }

      if (copy.mdMerchantId) {
        merchant.fetch({
          limit: 10,
          offset: 0,
          mcId: copy.mdMerchantId || "",
        });
      }

      produk.fetch({
        limit: 10,
        offset: 0,
      });

      resetForm(copy);
    } else {
      remove();
      resetForm({
        mdCode: "",
        mdDate: null,
        mdIssuerId: null,
        mdMerchantId: null,
      });
    }
  }, [data]);

  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
        resetForm();
      }}
      title={!data?.id ? "Tambah Distribusi Produk" : "Edit Distribusi Produk"}
      loading={action.loading}
      valid={true}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      submitText={!data?.id ? "Tambah" : "Simpan Perubahan"}
      onReset={() => {
        resetForm();
      }}
      type={1}
      customWidth="700px"
    >
      <Grid container spacing={2}>
        <VerticalGrid label={"Kode"}>
          <MainController
            {...getProps("mdCode")}
            rules={{ required: "Kode wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <TextFieldv3
                {...field}
                error={invalid}
                // disabled
                placeholder="Masukkan Kode"
              />
            )}
          />
        </VerticalGrid>
        <VerticalGrid label={"Tanggal"}>
          <MainController
            {...getProps("mdDate")}
            rules={{ required: "Tanggal Distribusi wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <DatePicker2
                {...field}
                past
                error={invalid}
                placeholder="DD/MM/YYYY"
              />
            )}
          />
        </VerticalGrid>
        <VerticalGrid label={"Penerbit"} md={12}>
          <MainController
            {...getProps("mdIssuerId")}
            rules={{ required: "Penerbit wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <Autocompletev3
                {...field}
                options={penerbit?.response?.list || []}
                error={invalid}
                value={
                  penerbit?.response?.list.find(
                    (item) => item.mcId === field.value
                  ) || null
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.mcId}>
                      {option.mcName}
                    </li>
                  );
                }}
                loading={penerbit.loading}
                onOpen={() => {
                  penerbit.fetch({
                    limit: 10,
                    offset: 0,
                    type: 1,
                  });
                }}
                placeholder="Silahkan pilih penerbit"
                isOptionEqualToValue={(option, value) =>
                  option.mcId === value.mcId
                }
                onInputChange={(e) => {
                  if (e != null) {
                    penerbit.fetch({
                      limit: 10,
                      offset: 0,
                      type: 1,
                      mpName: e?.target?.value || "",
                    });

                    if (watch("stocks")?.length) {
                      setValue("stocks", [], {
                        shouldValidate: true,
                      });
                    }
                    clearErrors("stocks.root");
                  }
                }}
                getOptionLabel={(option) => option.mcName}
                onChange={(_, nv) => {
                  if (nv == null) {
                    return field.onChange(null);
                  }
                  if (watch("stocks")?.length) {
                    setValue("stocks", [], {
                      shouldValidate: true,
                    });
                  }
                  clearErrors("stocks.root");
                  field.onChange(nv.mcId);
                }}
              />
            )}
          />
        </VerticalGrid>
        <VerticalGrid label={"Merchant Tujuan"} md={12}>
          <MainController
            {...getProps("mdMerchantId")}
            rules={{ required: "Pilih Produk wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <Autocompletev3
                {...field}
                options={merchant?.response?.list || []}
                error={invalid}
                value={
                  merchant?.response?.list.find(
                    (item) => item.mcId === field.value
                  ) || null
                }
                loading={merchant.loading}
                onOpen={() => {
                  merchant.fetch({
                    limit: 10,
                    offset: 0,
                    type: 2,
                  });
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.mcId}>
                      {option.mcName}
                    </li>
                  );
                }}
                placeholder="Silahkan pilih merchant"
                isOptionEqualToValue={(option, value) =>
                  option.mcId === value.mcId
                }
                onInputChange={(e) => {
                  if (e != null) {
                    merchant.fetch({
                      limit: 10,
                      offset: 0,
                      type: 2,
                      mpName: e?.target?.value || "",
                    });
                  }
                }}
                getOptionLabel={(option) => option.mcName}
                onChange={(_, nv) => {
                  if (nv == null) {
                    field.onChange(null);
                  }
                  field.onChange(nv.mcId);
                }}
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Pilih Item "} md={12}>
          <Box
            sx={{
              display: "inline-flex",
              gap: 1,
              width: "100%",
              alignItems: "center",
              backgroundColor: "#E2E3E3",
            }}
          >
            <Typography variant="label2" width="10%" align="center">
              No
            </Typography>
            <Typography variant="label2" width="50%">
              Item
            </Typography>
            <Typography variant="label2" width="30%">
              Jumlah
            </Typography>
            <Typography width="10%" align="center">
              <IconButton
                onClick={() => {
                  if (watch("mdIssuerId") == null) {
                    return setError("stocks.root", {
                      type: "custom",
                      message: "Silahkan Pilih Penerbit",
                    });
                  }
                  append({ mpId: null, mdsStock: "" });
                }}
              >
                <PlusCircleIcon />
              </IconButton>
            </Typography>
          </Box>

          <TransitionGroup
            component={Box}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {fields.map((item, index) => (
              <Collapse key={item.id}>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography width="10%" align="center" variant="label2">
                    {index + 1}
                  </Typography>
                  <Box width="50%">
                    <MainController
                      {...getProps(`stocks.${index}.mpId`)}
                      customError={errors?.stocks?.[index]?.mpId?.message}
                      rules={{ required: "Item wajib diisi" }}
                      render={({ field, fieldState: { invalid } }) => (
                        <Autocompletev3
                          {...field}
                          options={produk?.response?.list || []}
                          error={invalid}
                          value={
                            produk?.response?.list.find(
                              (item) => item.mpId === field.value
                            ) || null
                          }
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.mpId}>
                                {option.mpName}
                              </li>
                            );
                          }}
                          loading={produk.loading}
                          onOpen={() => {
                            produk.fetch(
                              {
                                limit: 10,
                                offset: 0,
                              },
                              watch("mdIssuerId")
                            );
                          }}
                          placeholder="Pilih Item"
                          isOptionEqualToValue={(option, value) =>
                            option.mpId === value.mpId
                          }
                          onInputChange={(e) => {
                            if (e != null) {
                              produk.fetch(
                                {
                                  limit: 10,
                                  offset: 0,
                                  mpName: e?.target?.value || "",
                                },
                                watch("mdIssuerId")
                              );
                            }
                          }}
                          getOptionLabel={(option) => option.mpName}
                          onChange={(_, nv) => {
                            if (nv == null) {
                              field.onChange(null);
                            }
                            field.onChange(nv.mpId);
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Box width="30%">
                    <MainController
                      {...getProps(`stocks.${index}.mdsStock`)}
                      customError={errors?.stocks?.[index]?.mdsStock?.message}
                      rules={{ required: "Jumlah item Wajib diisi" }}
                      render={({ field, fieldState: { invalid } }) => (
                        <TextFieldv3
                          {...field}
                          error={invalid}
                          onChange={(e) => {
                            e.target.value = FormatNumber(e.target.value);
                            field.onChange(Number(e.target.value));
                          }}
                          placeholder="0"
                        />
                      )}
                    />
                  </Box>
                  <Typography width="10%" align="center" variant="label2">
                    <IconButton
                      onClick={() => {
                        remove(index);
                      }}
                    >
                      <XCircleIcon />
                    </IconButton>
                  </Typography>
                </Box>
              </Collapse>
            ))}
          </TransitionGroup>

          {errors?.stocks?.root?.message && (
            <Typography variant="inputDesc" sx={{ color: "#cc1d15" }}>
              * {errors?.stocks?.root?.message}
            </Typography>
          )}
        </VerticalGrid>
      </Grid>
    </MainDialog>
  );
};

Dialog.propTypes = {
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  data: PropTypes.object,
  id: PropTypes.any,
  setRes: PropTypes.func,
};

Dialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  data: null,
  id: null,
  setRes: null,
};
export default Dialog;
