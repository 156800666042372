import React from "react";

const AlertIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.07933 16.7574C1.89418 17.0907 2.13516 17.5002 2.5164 17.5002H17.4836C17.8648 17.5002 18.1058 17.0907 17.9206 16.7574L10.4371 3.28696C10.2466 2.94406 9.75341 2.94406 9.5629 3.28696L2.07933 16.7574ZM4.14635 16.316C3.91757 16.316 3.77299 16.0702 3.88415 15.8702L9.73777 5.34032C9.85209 5.13467 10.1479 5.13467 10.2622 5.34032L16.1158 15.8702C16.227 16.0702 16.0824 16.316 15.8536 16.316H4.14635ZM10.0757 14.6783C10.2399 14.6783 10.3756 14.6246 10.4829 14.5173C10.5903 14.4099 10.6439 14.2742 10.6439 14.1101C10.6439 13.9459 10.5903 13.8102 10.4829 13.7029C10.3756 13.5955 10.2399 13.5419 10.0757 13.5419C9.9116 13.5419 9.77587 13.5955 9.66854 13.7029C9.56122 13.8102 9.50756 13.9459 9.50756 14.1101C9.50756 14.2742 9.56122 14.4099 9.66854 14.5173C9.77587 14.6246 9.9116 14.6783 10.0757 14.6783ZM10.0757 12.576C9.76194 12.576 9.50756 12.3216 9.50756 12.0078V8.90174C9.50756 8.58794 9.76194 8.33356 10.0757 8.33356C10.3895 8.33356 10.6439 8.58794 10.6439 8.90174V12.0078C10.6439 12.3216 10.3895 12.576 10.0757 12.576Z"
        fill="black"
      />
      <mask
        id="mask0_2120_6409"
        maskUnits="userSpaceOnUse"
        x="2"
        y="3"
        width="16"
        height="15"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.07933 16.7574C1.89418 17.0907 2.13516 17.5002 2.5164 17.5002H17.4836C17.8648 17.5002 18.1058 17.0907 17.9206 16.7574L10.4371 3.28696C10.2466 2.94406 9.75341 2.94406 9.5629 3.28696L2.07933 16.7574ZM4.14635 16.316C3.91757 16.316 3.77299 16.0702 3.88415 15.8702L9.73777 5.34032C9.85209 5.13467 10.1479 5.13467 10.2622 5.34032L16.1158 15.8702C16.227 16.0702 16.0824 16.316 15.8536 16.316H4.14635ZM10.0757 14.6783C10.2399 14.6783 10.3756 14.6246 10.4829 14.5173C10.5903 14.4099 10.6439 14.2742 10.6439 14.1101C10.6439 13.9459 10.5903 13.8102 10.4829 13.7029C10.3756 13.5955 10.2399 13.5419 10.0757 13.5419C9.9116 13.5419 9.77587 13.5955 9.66854 13.7029C9.56122 13.8102 9.50756 13.9459 9.50756 14.1101C9.50756 14.2742 9.56122 14.4099 9.66854 14.5173C9.77587 14.6246 9.9116 14.6783 10.0757 14.6783ZM10.0757 12.576C9.76194 12.576 9.50756 12.3216 9.50756 12.0078V8.90174C9.50756 8.58794 9.76194 8.33356 10.0757 8.33356C10.3895 8.33356 10.6439 8.58794 10.6439 8.90174V12.0078C10.6439 12.3216 10.3895 12.576 10.0757 12.576Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2120_6409)">
        <rect width="20" height="20" fill="currentColor" />
      </g>
    </svg>
  );
};

export default AlertIcon;
