import { Box, Button, Typography } from "@mui/material";
import MainDialog from "components/Dialog/MainDialog";
import React from "react";
import QrCodeIcon from "assets/penerimaBantuan/voucher/QRCode.png";
import PropTypes from "prop-types";

const CetakQR = ({ open, onClose, cetakQR, goBack }) => {
  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
      }}
      title="  "
      width="sm"
      action={false}
      sx={{
        borderTop: "none",
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" flexDirection="column">
          <Typography variant="h2">
            Pindai kode <br></br>
            untuk menukarkan voucer
          </Typography>
          <Typography
            component="ol"
            variant="label"
            sx={{
              padding: "0px",
              mt: 3,
              paddingLeft: "14px",
            }}
          >
            <Box
              component="li"
              sx={{
                padding: "3px 0px",
              }}
            >
              Buka Aplikasi INISA-Officer.
            </Box>
            <Box
              component="li"
              sx={{
                padding: "3px 0px",
              }}
            >
              {" "}
              Tab menu voucer Bansos.
            </Box>
            <Box
              component="li"
              sx={{
                padding: "3px 0px",
              }}
            >
              Pindai QR Code yang tampil di layar untuk penukaran.
            </Box>
          </Typography>
        </Box>
        <Box
          component="img"
          src={QrCodeIcon}
          sx={{
            width: "160px",
            height: "160px",
          }}
        />
      </Box>
      <Button
        variant="secondary-outlined"
        fullWidth
        sx={{ mt: 2 }}
        onClick={cetakQR}
      >
        Cetak QR Code
      </Button>

      <Button variant="primary" fullWidth sx={{ mt: 2 }} onClick={goBack}>
        Kembali ke Detail Voucer
      </Button>
    </MainDialog>
  );
};

CetakQR.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  cetakQR: PropTypes.func,
  goBack: PropTypes.func,
};

CetakQR.defaultProps = {
  onClose: () => {},
  open: false,
  cetakQR: () => {},
  goBack: () => {},

};

export default CetakQR;
