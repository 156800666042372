import MasterPengguna from "assets/layout/sidebar/MasterPengguna";
const permissionList = ["Web:Pengguna:Get", "Web:Peran:Get"];

const ManagementPengguna = {
  id: "management-pengguna",
  type: "collapse",
  title: "Management Pengguna",
  icon: MasterPengguna,
  permission: permissionList,
  children: [
    {
      id: "user",
      title: "Pengguna",
      type: "item",
      url: "/management-pengguna/pengguna",
      permission: permissionList[0],
    },
    {
      id: "role",
      title: "Peran",
      type: "item",
      url: "/management-pengguna/peran",
      permission: permissionList[1],
    },
    {
      id: "log-aktivitas",
      title: "Log Aktivitas",
      type: "item",
      url: "/management-pengguna/log-aktivitas",
      permission: permissionList[1],
    },
  ],
};

export default ManagementPengguna;
