import PropTypes from "prop-types";
import { ExpandMore } from "@mui/icons-material";
import { Box, ClickAwayListener, Grow, Popper } from "@mui/material";
import MainLoading from "components/Loading/MainLoading";
import TextFieldv3 from "components/TextField/TextFieldv3";
import React, { useState } from "react";

const PopOut = ({ loading, onClick, children, ...props }) => {
  const [open, setOpen] = useState(null);

  return (
    <>
      <TextFieldv3
        onClick={(e) => {
          e.stopPropagation();
          if (!open) {
            onClick();
            setOpen(e.currentTarget);
          }
        }}
        // value={value}
        {...props}
        InputProps={{
          endAdornment: (
            <>
              <ExpandMore
                sx={{
                  position: "absolute",
                  right: "13px",
                  color: "#4E4751",
                }}
              />
            </>
          ),
          readOnly: true,
        }}
      />

      <ClickAwayListener
        onClickAway={(e) => {
          e.stopPropagation();
          setOpen(null);
        }}
      >
        <Popper
          open={Boolean(open)}
          anchorEl={open}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 10],
              },
            },
            {
              name: "preventOverflow",
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                rootBoundary: "document",
              },
            },
          ]}
          transition
          sx={{
            zIndex: 9999,
            boxShadow: "0px 1px 8px 1px rgba(0, 0, 0, 0.06)",
            width: open?.offsetWidth,
            background: "#fff",
            borderRadius: "6px",
            border: "1px solid #f7f7f7",
          }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={300}>
              <Box
                sx={{
                  maxHeight: "300px",
                  height: "100%",
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    boxShadow: "inset 0 0 0px rgba(0, 0, 0, 0)",
                    backgroundColor: "#fff",
                    width: "5px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "10px",
                    backgroundColor: "#c6defd",
                  },
                }}
              >
                <MainLoading loading={loading}>{children}</MainLoading>
              </Box>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
    </>
  );
};

PopOut.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.any,
  onClick: PropTypes.func,
};

PopOut.defaultProps = {
  children: null,
  loading: false,
  onClick: () => {},
};

export default PopOut;
