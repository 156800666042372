import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useState, useEffect } from "react";
import { defaultValues, tipe_voucher, status_voucher } from "./variables";
import { Controller, useForm } from "react-hook-form";
import MainController from "components/Controller/MainController";
import MainAutocomplete from "components/AutoComplete/MainAutocomplete";
import useFetchData from "hook/useFetchData";
import { getPenerbit } from "redux/penerbit/action";
import VerticalGrid from "components/Grid/VerticalGrid";
import { Box, Grid } from "@mui/material";
import MainTextField from "components/TextField/MainTextField";
import MainContentEditable from "components/ContentEditable/MainContentEditable";
import MainDateRange from "components/Picker/MainDateRange";
import StartAdorment from "components/StartAdorment/StartAdorment";
import FormatRupiah from "helper/FormatRupiah";
import FormatNumber from "helper/FormatNumber";
import FileInput from "components/TextField/FileInput";
import FileValidator from "helper/FileValidator";
import MainTemplate from "components/Template/MainTemplate";
import VoucherTemplateMerah from "assets/voucher/bg_merah.png";
import VoucherTemplateHijau from "assets/voucher/bg_hijau.png";
import VoucherTemplateBiru from "assets/voucher/bg_biru.png";
import VoucherTemplateKuning from "assets/voucher/bg_kuning.png";
import { voucherNew, voucherUpdate } from "redux/voucher/action";
import moment from "moment";

const circleList = [
  {
    color: "#f21208",
    image: VoucherTemplateMerah,
    value: 1,
  },
  {
    color: "#62c7ff",
    image: VoucherTemplateBiru,
    value: 2,
  },
  {
    color: "#86cf4a",
    image: VoucherTemplateHijau,
    value: 3,
  },
  {
    color: "#f7b500",
    image: VoucherTemplateKuning,
    value: 4,
  },
];

const Dialog = ({
  open,
  onClose,
  refresh,
  isEdit,
  gambar,
  ikon,
  dokumen_info,
  kode_voucher,
  data,
  id,
  setRes,
}) => {
  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    getValues,
    reset: resetForm,
    clearErrors,
    setValue,
    watch,
    setError,
  } = useForm({
    defaultValues: defaultValues,
    mode: "onChange",
  });

  const [chooseTemplate, setChooseTemplate] = useState(false);
  const [selectedCircle, setSelectedCircle] = useState({});

  const action = useFetchData({
    action: !id ? voucherNew : voucherUpdate,
    reset: resetForm,
    message: !id ? "Berhasil menambah voucer baru" : "Berhasil mengubah voucer",
    refresh: refresh,
    onSuccess: () => {
      onClose();
    },
  });

  useEffect(() => {
    if (data) {
      console.log(data);
      resetForm(data);
    }
    if (open) {
      penerbit.setResponse([]);
      penerbit.fetch({
        filter: {
          IssuerStatus: 1,
        },
      });
    }
  }, [data]);

  useEffect(() => {
    if (setRes) {
      setRes(action.response);
    }
  }, [action.response]);

  const penerbit = useFetchData({
    action: getPenerbit,
    snackbar: false,
  });

  const onSubmit = async (data) => {
    const copyData = { ...data };
    copyData.StartDate = moment(copyData.StartDate).format("YYYY-MM-DD");
    copyData.EndDate = moment(copyData.EndDate).format("YYYY-MM-DD");

    let postObj = {};
    if (copyData.VoucherType === 1) {
      const { PercentageValue, MinTrx, MaxTrx, RewardAmount, ...newObj } =
        copyData;
      postObj = newObj;
    } else if (copyData.VoucherType === 2) {
      const { PercentageValue, MaxTrx, RewardAmount, ...newObj } = copyData;
      postObj = newObj;
    } else if (copyData.VoucherType === 3) {
      const { AmountValue, MinTrx, MaxTrx, RewardAmount, ...newObj } = copyData;
      postObj = newObj;
    } else if (copyData.VoucherType === 4) {
      const { AmountValue, MaxTrx, RewardAmount, ...newObj } = copyData;
      postObj = newObj;
    } else if (copyData.VoucherType === 5) {
      const { AmountValue, MinTrx, RewardAmount, ...newObj } = copyData;
      postObj = newObj;
    } else if (copyData.VoucherType === 6) {
      const { AmountValue, RewardAmount, ...newObj } = copyData;
      postObj = newObj;
    } else if (copyData.VoucherType === 7) {
      const { PercentageValue, MinTrx, MaxTrx, ...newObj } = copyData;
      postObj = newObj;
    }

    if (!copyData.hasOwnProperty("VoucherType")) {
      const { Budget, StartDate, EndDate, Distributed, ...newObj } = copyData;
      postObj = newObj;
    }

    if (ikon && id) {
      postObj = {
        Image1: postObj.Image1,
      };
    }

    await convertToBase64(postObj.Image1, async (base64) => {
      if(base64 != null){
        postObj.Image1 = base64
      }else{
        postObj.Image1 = ""
      }
      await convertToBase64(postObj.Image2, async (base64_2) => {
        if (base64_2 != null){
          postObj.Image2 = base64_2
        }else{
          postObj.Image2 = ""
        }

        const formData = new URLSearchParams({})
        for (let key in postObj) {
          formData.append(key, postObj[key]);
        }
        if (!id) {
          await action.fetch(postObj);
        } else {
          await action.fetch(formData, id);
        }

      })
    })
  };

  const convertToBase64 = async (file, handler = null) => {
    if(file == null || typeof file == "string"){
      return handler(null)
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const arr = reader.result.split(",");
      if (handler != null) {
        handler(arr[1])
      }
    }
  }
  const SvgToFile = async (field, item) => {
    await fetch(item.image)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
          const arr = reader.result.split(",");
          const mime = arr[0].match(/:(.*?);/)[1];
          let bstr = atob(arr[1]);
          let n = bstr.length;
          let u8arr = new Uint8Array(n);
          while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
          }
          const file = new File([u8arr], "kartu_subsidi.png", {
            type: mime,
          });

          setSelectedCircle({
            ...item,
          });

          field.onChange(file);
        };
      });
  };

  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
        resetForm();
        setChooseTemplate(false);
      }}
      title={!id ? "Tambah Voucer " : "Edit Voucer"}
      loading={action?.loading}
      valid={isValid}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
    >
      <Grid container spacing={2}>
        {dokumen_info && (
          <>
            <VerticalGrid label={"Penerbit Voucer"}>
              <MainController
                controller={Controller}
                name="IssuerId"
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                rules={{
                  required: "Nama penerbit wajib diisi",
                }}
                render={({ field }) => (
                  <MainAutocomplete
                    {...field}
                    options={penerbit.response?.list || []}
                    value={
                      penerbit.response?.list?.find(
                        (item) => item.id === getValues("IssuerId")
                      ) || null
                    }
                    onOpen={() => {
                      penerbit.setResponse([]);
                      penerbit.fetch({
                        filter: {
                          IssuerStatus: 1,
                        },
                      });
                    }}
                    onChange={(_, nv) => {
                      if (nv === null) {
                        field.onChange(null);
                        setValue("MerchantCode", "", {
                          shouldValidate: true,
                        });
                      } else {
                        field.onChange(nv.id);
                        setValue("MerchantCode", nv.IssuerCode, {
                          shouldValidate: true,
                        });
                      }
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.IssuerName}
                        </li>
                      );
                    }}
                    placeholder="Pilih penerbit"
                    loading={penerbit.loading}
                    getOptionLabel={(option) => option.IssuerName || null}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                )}
              />
            </VerticalGrid>

            <VerticalGrid label={"Kode Penerbit"}>
              <MainTextField
                controller={Controller}
                name="MerchantCode"
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                rules={{ required: "Kode Penerbit wajib diisi" }}
                placeholder="Kode akan otomatis ditampilkan"
                disabled
              />
            </VerticalGrid>

            <VerticalGrid label={"Nama Voucer"}>
              <MainTextField
                controller={Controller}
                name="VoucherName"
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                rules={{
                  required: "Nama voucer wajib diisi",
                  maxLength: {
                    message: "Nama voucer tidak boleh melebihi 40 karakter",
                    value: 40,
                  },
                }}
                placeholder="Beri nama voucer"
                desc="Batas maksimum 40 karakter"
              />
            </VerticalGrid>

            <VerticalGrid label={"Tipe Voucer"}>
              <MainController
                controller={Controller}
                name="VoucherType"
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                rules={{ required: "Tipe kartu subsidi wajib diisi" }}
                render={({ field }) => (
                  <MainAutocomplete
                    {...field}
                    options={tipe_voucher}
                    value={
                      tipe_voucher.find(
                        (option) => option.value == getValues("VoucherType")
                      ) || null
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(_, nv) => {
                      if (nv === null) {
                        field.onChange(null);
                      } else {
                        field.onChange(nv.value);
                      }
                    }}
                    placeholder="Pilih tipe voucer"
                  />
                )}
              />
            </VerticalGrid>

            <VerticalGrid label={"Deskripsi Voucer"}>
              <MainTextField
                controller={Controller}
                name="VoucherDesc"
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                rules={{ required: "Deskripsi voucer wajib diisi" }}
                placeholder="Masukan Deskripsi"
                multiline
                rows={6}
              />
            </VerticalGrid>

            <VerticalGrid label={"Syarat & Ketentuan"}>
              <MainController
                controller={Controller}
                name="VoucherTC"
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                rules={{ required: "Syarat & Ketentuan wajib diisi" }}
                render={({ field }) => (
                  <MainContentEditable
                    {...field}
                    placeholder="Masukan Syarat & Ketentuan"
                  />
                )}
              />
            </VerticalGrid>

            <VerticalGrid label={"Status"}>
              <MainController
                controller={Controller}
                name="Status"
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                rules={{ required: "Status wajib diisi" }}
                render={({ field }) => (
                  <MainAutocomplete
                    {...field}
                    options={status_voucher}
                    value={
                      status_voucher.find(
                        (option) => option.value === getValues("Status")
                      ) || null
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(_, nv) => {
                      if (nv === null) {
                        field.onChange(null);
                      } else {
                        field.onChange(nv.value);
                      }
                    }}
                    placeholder="Pilih status voucer"
                  />
                )}
              />
            </VerticalGrid>

            <VerticalGrid label={"Periode Voucer"}>
              <MainController
                controller={Controller}
                name="EndDate"
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                rules={{ required: "Periode voucer wajib diisi" }}
                render={({ field }) => (
                  <MainDateRange
                    onChange={(value) => {
                      setValue("StartDate", value[0]);
                      
                      field.onChange(value[1]);
                    }}
                    start={watch("StartDate") || null}
                    end={watch("EndDate") || null}
                    past
                  />
                )}
              />
            </VerticalGrid>
          </>
        )}

        {kode_voucher && (
          <>
            <VerticalGrid label={"Kode Prefiks"}>
              <MainTextField
                controller={Controller}
                name="PrefixCode"
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                rules={{
                  required: "Kode Prefiks wajib diisi",
                  maxLength: {
                    message: "Kode Prefiks harus terdiri dari 5 karakter",
                    value: 5,
                  },
                  minLength: {
                    message: "Kode Prefiks harus terdiri dari 5 karakter",
                    value: 5,
                  },
                }}
                placeholder="Masukan kode prefiks"
                onChange={(field, e) => {
                  e.target.value = e.target.value.toUpperCase();
                  if (e.target.value.length > 5) {
                    e.target.value = e.target.value.slice(0, 5);
                  }
                  field.onChange(e);
                }}
              />
            </VerticalGrid>

            <VerticalGrid label={"Nomor Awal"}>
              <MainTextField
                controller={Controller}
                name="Start"
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                rules={{ required: "Nomor awal wajib diisi" }}
                type="number"
                onChange={(field, e) => {
                  e.target.value = FormatNumber(e.target.value);
                  field.onChange(e);
                  const nomor_akhir = parseInt(getValues("End"));
                  if (e.target.value === "") {
                    setValue("End", "", {
                      shouldValidate: true,
                    });
                  }
                  setValue(
                    "Distributed",
                    nomor_akhir - parseInt(e.target.value),
                    {
                      shouldValidate: true,
                    }
                  );
                  const budget =
                    parseInt(watch("AmountValue")) *
                    parseInt(watch("Distributed"));
                  setValue("Budget", budget, {
                    shouldValidate: true,
                  });
                }}
                placeholder="Masukan nomor awal"
              />
            </VerticalGrid>

            <VerticalGrid label={"Nomor Akhir"}>
              <MainTextField
                controller={Controller}
                name="End"
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                rules={{ required: "Nomor akhir wajib diisi" }}
                type="number"
                onChange={(field, e) => {
                  e.target.value = FormatNumber(e.target.value);
                  field.onChange(e);
                  const nomor_awal = parseInt(getValues("Start"));
                  console.log(parseInt(e.target.value) - nomor_awal);
                  setValue(
                    "Distributed",
                    parseInt(e.target.value) - nomor_awal,
                    {
                      shouldValidate: true,
                    }
                  );
                  const budget =
                    parseInt(watch("AmountValue")) *
                    parseInt(watch("Distributed"));
                  setValue("Budget", budget, {
                    shouldValidate: true,
                  });
                }}
                placeholder="Masukan nomor akhir"
              />
            </VerticalGrid>
          </>
        )}

        {dokumen_info && (
          <>
            {watch("VoucherType") !== 3 &&
            watch("VoucherType") !== 4 &&
            watch("VoucherType") !== 5 &&
            watch("VoucherType") !== 6 ? (
              <VerticalGrid label={"Nominal Nilai"}>
                <MainTextField
                  controller={Controller}
                  name="AmountValue"
                  control={control}
                  errors={errors}
                  clearErrors={clearErrors}
                  rules={{ required: "Nominal nilai wajib diisi" }}
                  placeholder="Masukan nilai voucer"
                  type="text"
                  onChange={(field, e) => {
                    e.target.value = FormatNumber(e.target.value);
                    field.onChange(e);
                    const budget =
                      e.target.value * parseInt(watch("Distributed"));
                    setValue("Budget", budget, {
                      shouldValidate: true,
                    });
                  }}
                  format={FormatRupiah}
                />
              </VerticalGrid>
            ) : null}

            {watch("VoucherType") !== 1 &&
            watch("VoucherType") !== 2 &&
            watch("VoucherType") !== 3 &&
            watch("VoucherType") !== 4 &&
            watch("VoucherType") !== 5 &&
            watch("VoucherType") !== 6 ? (
              <VerticalGrid label={"Nominal Hadiah"}>
                <MainTextField
                  controller={Controller}
                  name="RewardAmount"
                  control={control}
                  errors={errors}
                  clearErrors={clearErrors}
                  rules={{ required: "Nominal hadiah wajib diisi" }}
                  placeholder="Masukan nominal hadiah"
                  type="text"
                  onChange={(field, e) => {
                    e.target.value = FormatNumber(e.target.value);
                    field.onChange(e);
                  }}
                  format={FormatRupiah}
                />
              </VerticalGrid>
            ) : null}

            {watch("VoucherType") !== 1 &&
            watch("VoucherType") !== 2 &&
            watch("VoucherType") !== 7 ? (
              <VerticalGrid label={"Presentase Nilai"}>
                <MainTextField
                  controller={Controller}
                  name="PercentageValue"
                  control={control}
                  errors={errors}
                  clearErrors={clearErrors}
                  rules={{ required: "Presentase nilai wajib diisi" }}
                  placeholder="Masukan presentase nilai"
                  type="text"
                  onChange={(field, e) => {
                    e.target.value = FormatNumber(e.target.value);
                    field.onChange(e);
                  }}
                  format={FormatRupiah}
                />
              </VerticalGrid>
            ) : null}

            {watch("VoucherType") !== 1 &&
            watch("VoucherType") !== 3 &&
            watch("VoucherType") !== 5 &&
            watch("VoucherType") !== 7 ? (
              <VerticalGrid label={"Minimum Transaksi"}>
                <MainTextField
                  controller={Controller}
                  name="MinTrx"
                  control={control}
                  errors={errors}
                  clearErrors={clearErrors}
                  rules={{ required: "Minimum Transaksi wajib diisi" }}
                  placeholder="Masukan minimum transaksi"
                  type="text"
                  onChange={(field, e) => {
                    e.target.value = FormatNumber(e.target.value);
                    field.onChange(e);
                  }}
                  format={FormatRupiah}
                />
              </VerticalGrid>
            ) : null}

            {watch("VoucherType") !== 1 &&
            watch("VoucherType") !== 2 &&
            watch("VoucherType") !== 3 &&
            watch("VoucherType") !== 4 &&
            watch("VoucherType") !== 7 ? (
              <VerticalGrid label={"Maksimum Transaksi"}>
                <MainTextField
                  controller={Controller}
                  name="MaxTrx"
                  control={control}
                  errors={errors}
                  clearErrors={clearErrors}
                  rules={{ required: "Maksimum Transaksi wajib diisi" }}
                  placeholder="Masukan maksimum transaksi"
                  type="text"
                  onChange={(field, e) => {
                    e.target.value = FormatNumber(e.target.value);
                    field.onChange(e);
                  }}
                  format={FormatRupiah}
                />
              </VerticalGrid>
            ) : null}

            <VerticalGrid label={"Jumlah Anggaran"}>
              <MainTextField
                controller={Controller}
                name="Budget"
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                rules={{ required: "Jumlah anggaran wajib diisi" }}
                placeholder="Masukan nominal anggaran"
                InputProps={{
                  ...StartAdorment("Rp"),
                }}
                type="text"
                format={FormatRupiah}
              />
            </VerticalGrid>

            <VerticalGrid label={"Jumlah Distribusi"}>
              <MainTextField
                controller={Controller}
                name="Distributed"
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                rules={{ required: "Jumlah distribusi wajib diisi" }}
                onChange={(field, e) => {
                  console.log("ok");
                  e.target.value = FormatNumber(e.target.value);
                  field.onChange(e);
                  const budget =
                    parseInt(watch("AmountValue")) * e.target.value;
                  setValue("Budget", budget, {
                    shouldValidate: true,
                  });
                }}
                format={FormatRupiah}
                placeholder="Total voucer yang akan didistribusikan"
                disabled
              />
            </VerticalGrid>
          </>
        )}
        {ikon && (
          <>
            {Boolean(gambar) && (
              <VerticalGrid label={" "}>
                <Box
                  component={"img"}
                  src={gambar}
                  sx={{
                    maxWidth: "350px",
                    width: "100%",
                    borderRadius: "8px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
              </VerticalGrid>
            )}
            <VerticalGrid label={"Gambar Voucer"}>
              <MainController
                controller={Controller}
                name="Image1"
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                rules={{
                  validate: {
                    a: () => {
                      if (watch("Image2") != "") {
                        return true;
                      }

                      return false;
                    },
                    a: () => {
                      if (watch("Image2") == "" && watch("Image1") == "") {
                        return false;
                      }

                      return true;
                    },
                  },
                }}
                desc="Maks 5MB. Gambar dengan ukuran 379x255 piksel untuk hasil optimal."
                render={({ field }) => (
                  <FileInput
                    value={field.value}
                    showImage={watch("Image1") && !chooseTemplate}
                    onClose={() => field.onChange("")}
                    placeholder="Pilih gambar kartu subsidi"
                    onChange={(e) => {
                      const file = e.target.files[0];

                      const err = FileValidator(file, {
                        size: {
                          maxSize: "5mb",
                        },
                        type: ["image/png", "image/jpg", "image/jpeg"],
                      });

                      if (err) {
                        setError("Image1", {
                          type: "file",
                          message: err,
                        });
                        return;
                      }

                      field.onChange(file);
                      setChooseTemplate(false);
                      setSelectedCircle({});
                      setValue("Image2", "", {
                        shouldValidate: true,
                      });
                    }}
                  />
                )}
              />

              {!Boolean(gambar) && (
                <MainController
                  controller={Controller}
                  name="Image2"
                  control={control}
                  errors={errors}
                  clearErrors={clearErrors}
                  rules={{
                    required: false,
                  }}
                  render={({ field }) => (
                    <MainTemplate
                      onClick={(item) => {
                        setChooseTemplate(!chooseTemplate);
                        setValue("Image1", "", {
                          shouldValidate: true,
                        });
                        if (chooseTemplate) {
                          setValue("Image2", "", {
                            shouldValidate: true,
                          });
                        } else {
                          SvgToFile(field, item);
                        }
                      }}
                      chooseTemplate={chooseTemplate}
                      selected={selectedCircle}
                      list={circleList}
                      onChange={(item) => SvgToFile(field, item)}
                    />
                  )}
                />
              )}
            </VerticalGrid>
          </>
        )}
      </Grid>
    </MainDialog>
  );
};

Dialog.propTypes = {
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  dokumen_info: PropTypes.bool,
  kode_voucher: PropTypes.bool,
  ikon: PropTypes.bool,
  data: PropTypes.any,
  id: PropTypes.any,
  setRes: PropTypes.any,
  gambar: PropTypes.any,
};

Dialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  dokumen_info: false,
  kode_voucher: false,
  ikon: false,
  data: null,
  id: null,
  setRes: null,
  gambar: null,
};
export default Dialog;
