import React from "react";
import PropTypes from "prop-types";
import StatusChip from "../StatusChip";

const color = {
  1: {
    label: "Berhasil",
    color: "#129350",
    background: "#E7F4ED",
  },
  2: {
    label: "Menunggu",
    color: "#F7B500",
    background: "#FEF7E5",
  },
  3: {
    label: "Aktif",
    color: "#129350",
    background: "#E7F4ED",
  },
  3: {
    label: "Aktif",
    color: "#129350",
    background: "#E7F4ED",
  },
  4: {
    label: "Tidak Berhasil",
    color: "#CC1D15",
    background: "#F9E8E7",
  },
};

const find = (value, text) => {
  return color?.[value]?.[text] || false;
};

const RedeemStatus = ({ value, ...props }) => (
  <StatusChip
    label={find(value, "label")}
    color={find(value, "color")}
    background={find(value, "background")}
    {...props}
  />
);

RedeemStatus.defaultProps = {
  value: 0,
};

RedeemStatus.propTypes = {
  value: PropTypes.any,
};

export default RedeemStatus;
