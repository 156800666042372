import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PropTypes from "prop-types";

const ExportHelper = ({ type, data, filename, opt }) => {
  type = "" + type.toLowerCase();

  if (opt?.header && opt?.header.length) {
    const head = opt?.header;
    // convert data variable from array of objects to array of array
    const dataArray = data.map((item) => {
      let arr = [];
      head.forEach((key) => {
        arr.push(item[key]);
      });
      return arr;
    });
    opt.data = dataArray;
    opt.data.unshift(head);
  }

  switch (type) {
    case "excel":
      const workbook = XLSX.utils.book_new();
      const worksheet = opt?.header
        ? XLSX.utils.aoa_to_sheet(opt.data)
        : XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, `${filename}.xlsx`);
      return;

    case "csv":
      const wb = XLSX.utils.book_new();
      const ws = opt?.header
        ? XLSX.utils.aoa_to_sheet(opt.data)
        : XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, `${filename}.csv`, {
        bookType: "csv",
        bookSST: true,
      });
      return;

    case "pdf":
      let head = Object.keys(data[0]).map(
        (key) => "" + key.replace(/_/g, " ").trim()
      );
      let body = data.map((item) => Object.values(item));

      if (opt?.header) {
        head = opt.header;
        body = opt.data;
        body.shift();
      }

      new jsPDF({
        orientation: "landscape",
        unit: "in",
        format: [11, 16],
      })
        .autoTable({
          head: [head],
          body,
        })
        .save(`${filename}.pdf`);
      return;

    default:
      return null;
  }
};

ExportHelper.propTypes = {
  type: PropTypes.any,
  filename: PropTypes.any,
  opt: PropTypes.any,
  data: PropTypes.any,
};

ExportHelper.defaultProps = {
  type: null,
  filename: null,
  opt: null,
  data: null,
};

export default ExportHelper;
