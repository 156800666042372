import { createAsyncThunk } from "@reduxjs/toolkit";
import { getParams, post, put, get, destroy } from "api";
import { Pengguna } from "api/endpoints";

export const getListPengguna = createAsyncThunk(
  "pengguna/getListPengguna",
  async (params) => {
    const response = await getParams(Pengguna.list, params);
    return response.data.data;
  }
);

export const getPengguna = async (params) => {
  const response = await getParams(Pengguna.list, params);
  return response;
};

export const createPengguna = async (data) => {
  const response = await post(Pengguna.action, data);

  return response;
};

export const updatePengguna = async (data, id) => {
  const withId = `${Pengguna.action}/${id}`;
  const response = await put(withId, data);
  return response;
};

export const getListRole = createAsyncThunk(
  "pengguna/getListRole",
  async (params) => {
    const response = await getParams(Pengguna.role, params);
    return response.data.data;
  }
);

export const getRole = async (params) => {
  const response = await getParams(Pengguna.role, params);
  return response;
};

export const getRoleDetaila = async (id) => {
  const withId = `${Pengguna.roleDetail}/${id}`;

  const response = await get(withId);
  return response;
};

export const updateRoleDetail = async (data, id) => {
  const withId = `${Pengguna.roleDetail}/${id}`;

  const response = await put(withId, data);
  return response;
};

export const getVoucerByIssuer = async (params, data) => {
  const response = await post(Pengguna.listVoucerByIssuer, data, params);
  return response;
};

export const getUserDetail = createAsyncThunk(
  "pengguna/getUserDetail",
  async ({ params, id }) => {
    const response = await getParams(`${Pengguna.action}/${id}`, params);
    return response.data.data;
  }
);

export const getUserDetailNoRedux = async (id) => {
  const response = await getParams(`${Pengguna.action}/${id}`, {
    filter: {
      activity: "",
      voucher: "",
    },
  });
  return response;
};

export const createRole = async (data) => {
  const response = await post(Pengguna.roleAction, data);

  return response;
};

export const getRoleDetail = createAsyncThunk(
  "pengguna/getRoleDetail",
  async ({ params, id }) => {
    const response = await getParams(`${Pengguna.roleDetail}/${id}`, params);
    return response.data.data;
  }
);

export const editRole = async (data, id) => {
  const wId = `${Pengguna.roleDetail}/${id}`;
  const response = await put(wId, data);

  return response;
};

export const deleteRole = async (id) => {
  const wId = `${Pengguna.roleDelete}/${id}`;
  const response = await destroy(wId);

  return response;
};

export const deleteUserBatch = async (_, params) => {
  const response = await destroy(Pengguna.action, params);

  return response;
};

export const deletePengguna = async (id) => {
  const wId = `${Pengguna.action}/${id}`;

  const response = await destroy(wId);

  return response;
};
