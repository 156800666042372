import PropTypes from "prop-types";
import React, { useRef, useState, useEffect } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Box, TextField } from "@mui/material";
import { ExpandMore, Search } from "@mui/icons-material";
import MainLoading from "components/Loading/MainLoading";

export default function MainPopper({
  value,
  onClick,
  options,
  onChange,
  loading,
  filter,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState([]);
  const tf = useRef();
  const [textFilter, setTextFilter] = useState("");

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    setData(options);
    setTextFilter("");
  }, [options]);

  const goFilter = data?.filter((option) => filter(option, textFilter));
  return (
    <>
      <TextField
        onClick={(event) => {
          onClick();
          setAnchorEl(event.currentTarget);
        }}
        value={value}
        ref={tf}
        {...props}
        InputProps={{
          endAdornment: (
            <>
              <ExpandMore
                sx={{
                  position: "absolute",
                  right: "13px",
                  color: "#4E4751",
                }}
              />
            </>
          ),
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 15],
            },
          },
        ]}
        PaperProps={{
          sx: {
            boxShadow: "0px 1px 8px 1px rgba(0, 0, 0, 0.10) !important",
          },
        }}
      >
        <Box sx={{ width: tf?.current?.offsetWidth, padding: "10px" }}>
          <TextField
            placeholder="Cari"
            onChange={(e) => {
              setTextFilter(e.target.value);
            }}
            InputProps={{
              endAdornment: <Search />,
            }}
          />
        </Box>
        <Box
          sx={{
            maxHeight: "250px",
            height: "100%",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              boxShadow: "inset 0 0 0px rgba(0, 0, 0, 0)",
              width: "12px",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "10px",
              backgroundColor: "#c6defd",
            },
          }}
        >
          <MainLoading loading={loading}>
            {goFilter
              ?.filter((option) => filter(option, textFilter))
              .map((item, i) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 20px",
                    borderBottom: "1px solid #f7f7f7",
                    cursor: "pointer",
                    position: "relative",
                    ...(item.IssuerCode == value && {
                      "&:after": {
                        position: "absolute",
                        content: '""',
                        left: "1px",
                        top: "10%",
                        backgroundColor: "#cc1d15",
                        width: "3px",
                        borderTopRightRadius: "15px",
                        borderBottomRightRadius: "15px",
                        height: "80%",
                      },
                      backgroundColor: "#F9E8E7",
                    }),
                    "&:hover": {
                      backgroundColor: "#F8F8F8",
                    },
                  }}
                  key={i}
                  onClick={() => {
                    onChange(item);
                    handleClose();
                  }}
                >
                  <Typography variant="textCommon">
                    {item.IssuerName}
                  </Typography>
                  <Typography variant="desc">{item.IssuerCode}</Typography>
                </Box>
              ))}
            {!goFilter.length && (
              <Typography
                variant="label"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 5,
                }}
              >
                Data tidak ditemukan
              </Typography>
            )}
          </MainLoading>
        </Box>
      </Popover>
    </>
  );
}

MainPopper.propTypes = {
  props: PropTypes.any,
  onChange: PropTypes.any,
  onClick: PropTypes.any,
  options: PropTypes.any,
  value: PropTypes.any,
  loading: PropTypes.bool,
  filter: PropTypes.func,
};

MainPopper.defaultProps = {
  props: null,
  onChange: null,
  onClick: null,
  options: [],
  value: null,
  loading: false,
  filter: () => {},
};
