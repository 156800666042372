import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Tooltip } from "@mui/material";
import MainPagination from "components/Pagination";
import MainCard from "components/Paper/MainCard";
import Providers from "components/Providers";
import RedeemStatus from "components/Status/RedeemStatus";
import MainTable from "components/Table/MainTable";
import RedeemSubsidiDialog from "./Dialog/RedeemSubsidi";
import ScanVoucerBansos from "./Dialog/VoucerBansos/ScanVoucerBansos";
import OfflineTransaksi from "./Dialog/SubsidiBansos/OfflineTransaksi";
import ManualValidasi from "./Dialog/VoucerBansos/ManualValidasi";
import PilihVoucer from "./Dialog/VoucerBansos/ManualValidasi/PilihVoucer";
import DetailVoucerDialog from "./Dialog/Detail/Voucer";
import DetailSubsidiDialog from "./Dialog/Detail/Subsidi";
import {
  getDetailSubsidiBansos,
  getDetailVoucerBansos,
  getListRedeemSubsidi,
  listVoucerById,
} from "redux/redeemSubsidi/action";
import useFetchData from "hook/useFetchData";
import {
  List,
  Loading,
  Params,
  TotalItems,
  CurrentPage,
} from "redux/redeemSubsidi/selector";
import { changeLimit, changePage } from "redux/redeemSubsidi/slice";
import { alert } from "redux/global/slice";
import OnlineTransaksiSubsidi from "./Dialog/SubsidiBansos/OnlineTransaksiSubsidi";

const defaultValues = {
  TypeBansos: null,
  TypeTransaksi: null,
};

const RedeemSubsidi = () => {
  const list = useSelector(List);
  const loading = useSelector(Loading);
  const params = useSelector(Params);
  const totalItems = useSelector(TotalItems);
  const currentPage = useSelector(CurrentPage);

  const dispatch = useDispatch();

  const [clipTxt, setClipTxt] = useState("");

  const [open, setOpen] = useState({
    redeemSubsidi: false,
    scanVoucerBansos: false,
    offlineTransaksi: false,
    manualValidasi: false,
    pilihVoucer: false,
    detailVoucerBansos: false,
    detailSubsidiBansos: false,
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    getValues,
    reset,
    clearErrors,
    watch,
    setValue,
    unregister,
    register,
    setError,
  } = useForm({
    defaultValues: { ...defaultValues },
    mode: "all",
  });

  const Form = {
    handleSubmit,
    isValid,
    errors,
    control,
    getValues,
    reset,
    clearErrors,
    watch,
    setValue,
    unregister,
    register,
    setError,
    Controller,
  };

  const headers = [
    {
      name: "Nama Merchant ",
      key: "merchant",
      width: 30,
    },
    {
      name: "Tanggal Transaksi",
      custom: (item) => {
        return moment(item.date).format("DD/MM/YYYY HH:ss");
      },
      width: 25,
    },
    {
      name: "No. Transaksi",
      custom: (item) => (
        <>
          <Tooltip
            title={clipTxt}
            onMouseEnter={() => setClipTxt("Copy No. Transaksi")}
            placement="bottom"
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setClipTxt("Copied");
                navigator.clipboard.writeText(item.trxNo);
              }}
            >
              {item.trxNo}
            </span>
          </Tooltip>
        </>
      ),
      width: 25,
    },
    {
      name: "Petugas",
      key: "officer",
      width: 25,
    },
    {
      name: "Status",
      width: 25,
      custom: (item) => <RedeemStatus value={item.status} minWidth="124px" maxWidth="124px" />,
    },
  ];

  const fetchData = () => dispatch(getListRedeemSubsidi(params));

  const listVoucerByIdReq = useFetchData({
    action: listVoucerById,
    snackbar: false,
    onSuccess: (data) => {
      if (!data?.data_diri?.NIK) {
        return dispatch(
          alert({
            open: true,
            message: "Informasi ID tidak ditemukan",
          })
        );
      }
      setValue("ManualValidasi.Fullname", data?.data_diri?.Fullname);
      setValue("ManualValidasi.Id", data?.data_diri?.NIK);
      setValue("ManualValidasi.KodeVoucer", "");
      setValue("ManualValidasi.FotoWajah", data?.data_diri?.foto_wajah);
      setValue("ManualValidasi.Voucer", data?.voucher);
      setOpen({
        ...open,
        manualValidasi: false,
        pilihVoucer: true,
      });
    },
  });

  const detailVoucer = useFetchData({
    action: getDetailVoucerBansos,
    snackbar: false,
  });

  const detailSubsidi = useFetchData({
    action: getDetailSubsidiBansos,
    snackbar: false,
  });

  return (
    <>
      <Providers
        title="Redeem Subsidi"
        currentPage={currentPage}
        params={params}
        get={fetchData}
        desc
      >
        <MainCard
          sx={{
            mt: 2,
            borderRadius: "8px",
          }}
          title="Daftar Redeem Subsidi"
          onClick={() => setOpen({ ...open, redeemSubsidi: true })}
          borderColor="#C8C8C8"
        >
          <MainTable
            sx={{
              mt: 2,
            }}
            headers={headers}
            loading={loading}
            data={list}
            summaryKey="merchant"
            detail={[
              {
                type: "button",
                tooltip: "Lihat Detail",
                variant: "icon:eye",
                onClick: (_, item) => {
                  if (item.isSubsidi == 0) {
                    detailVoucer.fetch({
                      voucherNo: item?.voucherNo,
                    });
                    return setOpen({
                      ...open,
                      detailVoucerBansos: true,
                    });
                  }
                  if (item.isSubsidi == 1) {
                    detailSubsidi.fetch(item?.id);
                    return setOpen({
                      ...open,
                      detailSubsidiBansos: true,
                    });
                  }
                },
              },
            ]}
          />
          <MainPagination
            params={params}
            changeLimit={changeLimit}
            changePage={changePage}
            totalItems={totalItems}
            currentPage={currentPage}
          />
        </MainCard>
      </Providers>

      <RedeemSubsidiDialog
        open={open.redeemSubsidi}
        onClose={() => {
          setOpen({
            ...open,
            redeemSubsidi: false,
          });
          reset();
        }}
        onSubmit={(data) => {
          if (data.TypeTransaksi == 1) {
            return setOpen({
              ...open,
              redeemSubsidi: false,
              scanVoucerBansos: true,
            });
          }
          if (data.TypeTransaksi == 2) {
            return setOpen({
              ...open,
              redeemSubsidi: false,
              offlineTransaksi: true,
            });
          }
          if (data.TypeTransaksi == 3) {
            return setOpen({
              ...open,
              redeemSubsidi: false,
              manualValidasi: true,
            });
          }
          if (data.TypeTransaksi == 4) {
            return setOpen({
              ...open,
              redeemSubsidi: false,
              onlineSubsidi: true,
            });
          }
        }}
        {...Form}
      />

      <ScanVoucerBansos
        open={open.scanVoucerBansos}
        onClose={() => {
          setOpen({
            ...open,
            scanVoucerBansos: false,
            redeemSubsidi: true,
          });
        }}
        refresh={fetchData}
        {...Form}
      />

      <OnlineTransaksiSubsidi
        open={open.onlineSubsidi}
        onClose={() => {
          setOpen({
            ...open,
            onlineSubsidi: false,
            redeemSubsidi: true,
          });
        }}
        refresh={fetchData}
        {...Form}
      />

      <OfflineTransaksi
        open={open.offlineTransaksi}
        onClose={() => {
          setOpen({
            ...open,
            offlineTransaksi: false,
            redeemSubsidi: true,
          });
        }}
        {...Form}
      />

      <ManualValidasi
        open={open.manualValidasi}
        onClose={() => {
          setOpen({
            ...open,
            manualValidasi: false,
            redeemSubsidi: true,
          });
        }}
        action={listVoucerByIdReq}
        {...Form}
      />

      <PilihVoucer
        open={open.pilihVoucer}
        onClose={() => {
          setOpen({
            ...open,
            pilihVoucer: false,
            manualValidasi: true,
          });
        }}
        listReq={listVoucerByIdReq}
        onOpen={() => {
          setOpen({
            ...open,
            pilihVoucer: true,
            manualValidasi: false,
          });
        }}
        {...Form}
      />

      <DetailVoucerDialog
        open={open.detailVoucerBansos}
        loading={detailVoucer.loading}
        onClose={() => {
          setOpen({
            ...open,
            detailVoucerBansos: false,
          });
        }}
        data={detailVoucer?.response}
      />

      <DetailSubsidiDialog
        open={open.detailSubsidiBansos}
        loading={detailSubsidi.loading}
        onClose={() => {
          setOpen({
            ...open,
            detailSubsidiBansos: false,
          });
        }}
        data={detailSubsidi?.response}
      />
    </>
  );
};

export default RedeemSubsidi;
