import React from "react";

const TimeIcon = () => {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99998 2.10217C11.6819 2.10217 14.6666 5.41201 14.6666 9.49491C14.6666 13.5778 11.6819 16.8876 7.99998 16.8876C4.31808 16.8876 1.33331 13.5778 1.33331 9.49491C1.33331 5.41201 4.31808 2.10217 7.99998 2.10217ZM7.99998 3.58072C5.05446 3.58072 2.66665 6.22859 2.66665 9.49491C2.66665 12.7612 5.05446 15.4091 7.99998 15.4091C10.9455 15.4091 13.3333 12.7612 13.3333 9.49491C13.3333 6.22859 10.9455 3.58072 7.99998 3.58072ZM8.66216 5.71233C8.62365 5.34466 8.34187 5.05927 7.99998 5.05927C7.63179 5.05927 7.33331 5.39025 7.33331 5.79854V9.49491L7.3378 9.58112C7.37631 9.94879 7.65809 10.2342 7.99998 10.2342H10.6666L10.7444 10.2292C11.076 10.1865 11.3333 9.87404 11.3333 9.49491L11.3288 9.4087C11.2903 9.04103 11.0085 8.75564 10.6666 8.75564H8.66665V5.79854L8.66216 5.71233Z"
        fill="#979797"
      />
      <mask
        id="mask0_1640_13686"
        maskUnits="userSpaceOnUse"
        x="1"
        y="2"
        width="14"
        height="15"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99998 2.10217C11.6819 2.10217 14.6666 5.41201 14.6666 9.49491C14.6666 13.5778 11.6819 16.8876 7.99998 16.8876C4.31808 16.8876 1.33331 13.5778 1.33331 9.49491C1.33331 5.41201 4.31808 2.10217 7.99998 2.10217ZM7.99998 3.58072C5.05446 3.58072 2.66665 6.22859 2.66665 9.49491C2.66665 12.7612 5.05446 15.4091 7.99998 15.4091C10.9455 15.4091 13.3333 12.7612 13.3333 9.49491C13.3333 6.22859 10.9455 3.58072 7.99998 3.58072ZM8.66216 5.71233C8.62365 5.34466 8.34187 5.05927 7.99998 5.05927C7.63179 5.05927 7.33331 5.39025 7.33331 5.79854V9.49491L7.3378 9.58112C7.37631 9.94879 7.65809 10.2342 7.99998 10.2342H10.6666L10.7444 10.2292C11.076 10.1865 11.3333 9.87404 11.3333 9.49491L11.3288 9.4087C11.2903 9.04103 11.0085 8.75564 10.6666 8.75564H8.66665V5.79854L8.66216 5.71233Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1640_13686)">
        <rect y="0.623657" width="16" height="17.7426" fill="#969696" />
      </g>
    </svg>
  );
};

export default TimeIcon;
