import React from "react";

const CSRIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.1701 12.3996C22.3201 11.1296 20.6501 10.7696 19.3101 11.6096L17.7901 12.6796C17.3401 12.9996 17.2301 13.6196 17.5501 14.0696C17.8701 14.5196 18.4901 14.6296 18.9401 14.3096L20.4101 13.2696C20.7801 13.0396 21.2601 13.1396 21.5001 13.5096C21.6201 13.6896 21.6601 13.9096 21.6201 14.1196C21.5701 14.3296 21.4501 14.5196 21.2601 14.6296C21.2301 14.6496 21.1901 14.6696 21.1601 14.6996L14.4001 20.1796C14.1901 20.3496 13.9101 20.4296 13.6501 20.3996L6.49012 19.5096C6.32012 19.4896 6.15012 19.5096 5.99012 19.5696L2.36012 21.0196V17.1096L7.60012 14.4896H13.3601C13.9101 14.4896 14.3601 14.9396 14.3601 15.4896C14.3601 16.0396 13.9101 16.4896 13.3601 16.4896H9.56012C9.01012 16.4896 8.56012 16.9396 8.56012 17.4896C8.56012 18.0396 9.01012 18.4896 9.56012 18.4896H13.3601C15.0101 18.4896 16.3601 17.1396 16.3601 15.4896C16.3601 13.8396 15.0101 12.4896 13.3601 12.4896H7.36012C7.20012 12.4896 7.05012 12.5296 6.91012 12.5996L0.920117 15.6096C0.580117 15.7796 0.370117 16.1296 0.370117 16.4996V22.4996C0.370117 22.8296 0.540117 23.1396 0.810117 23.3296C0.980117 23.4396 1.17012 23.4996 1.37012 23.4996C1.50012 23.4996 1.62012 23.4796 1.74012 23.4296L6.50012 21.5296L13.4001 22.3896C14.2001 22.4896 15.0301 22.2596 15.6601 21.7396L22.3801 16.2896C23.0001 15.8796 23.4201 15.2696 23.5801 14.5396C23.7401 13.7896 23.6001 13.0296 23.1701 12.3996Z"
        fill="currentColor"
      />
      <path
        d="M12.5 10.5C12.5 11.05 12.95 11.5 13.5 11.5C14.05 11.5 14.5 11.05 14.5 10.5V8.5H15.5C17.15 8.5 18.5 7.15 18.5 5.5C18.5 3.85 17.15 2.5 15.5 2.5H13.5C12.95 2.5 12.5 2.95 12.5 3.5V7.5V10.5ZM16.5 5.5C16.5 6.05 16.05 6.5 15.5 6.5H14.5V4.5H15.5C16.05 4.5 16.5 4.95 16.5 5.5Z"
        fill="currentColor"
      />
      <path
        d="M5.5 8.49977C5.5 9.04977 5.95 9.49977 6.5 9.49977C7.05 9.49977 7.5 9.04977 7.5 8.49977V6.49977H8.43L9.6 8.92977C9.77 9.28977 10.13 9.49977 10.5 9.49977C10.64 9.49977 10.79 9.46976 10.93 9.39977C11.43 9.15977 11.64 8.55976 11.4 8.06977L10.34 5.85977C11.04 5.30977 11.5 4.46977 11.5 3.50977C11.5 1.85977 10.15 0.509766 8.5 0.509766H6.5C5.95 0.509766 5.5 0.959766 5.5 1.50977V5.49977V8.49977ZM9.5 3.49977C9.5 4.04977 9.05 4.49977 8.5 4.49977H7.5V2.49977H8.5C9.05 2.49977 9.5 2.94977 9.5 3.49977Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CSRIcon;
