import PropTypes from "prop-types";
import { Box, Paper, Typography } from "@mui/material";
import React from "react";

const DasborCard = ({ icon, iconBackgroundColor, title, desc }) => {
  return (
    <Paper
      sx={{
        borderRadius: "8px",
        p: 2,
        height: "100%",
      }}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <Box
          sx={{
            backgroundColor: iconBackgroundColor,
            outline: "5px solid #FAE8E8",
            p: 1,
            my: "auto",
            display: "flex",
            width: "fit-content",
            borderRadius: "40px",
          }}
        >
          {icon}
        </Box>

        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="title3">{title}</Typography>
          <Typography variant="desc3" fontSize="11px">
            {desc}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

DasborCard.propTypes = {
  desc: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  iconBackgroundColor: PropTypes.string,
  title: PropTypes.string,
};

DasborCard.defaultProps = {
  desc: "",
  icon: "",
  iconBackgroundColor: "",
  title: "",
};

export default DasborCard;
