import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CurrentPage,
  FilterName,
  List,
  Loading,
  Params,
  TotalItems,
} from "redux/subsidiBansos/selectors";
import MainTable from "components/Table/MainTable";
import {
  changeFilterName,
  changeLimit,
  changePage,
  changeFilter,
  deleteFilter,
  addFilter,
} from "redux/subsidiBansos/slice";

import MainCard from "components/Paper/MainCard";
import MainCardHeader from "components/Paper/MainCardHeader";
import MainPagination from "components/Pagination";
import Providers from "components/Providers";
import {
  getListSubsidiBansos,
  getSubsidiBansos,
} from "redux/subsidiBansos/action";
import AktifStatus from "components/Status/AktifStatus";
import Dialog from "./Dialog";
import useFetchData from "hook/useFetchData";
import ExportHelper from "helper/ExportHelper";

const SubsidiBansos = () => {
  const list = useSelector(List);
  const loading = useSelector(Loading);
  const params = useSelector(Params);
  const totalItems = useSelector(TotalItems);
  const currentPage = useSelector(CurrentPage);
  const filterName = useSelector(FilterName);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const headers = [
    {
      name: "Penyelenggara",
      width: 30,
      custom: (item) => {
        return `${item.kode_penyelenggara} - ${item.penyelenggara}`;
      },
    },
    {
      name: "Nama Program Bantuan",
      key: "nama_program",
      width: 30,
    },
    {
      name: "Status Bantuan",
      width: 30,
      custom: (item) => {
        return <AktifStatus value={item.status} />;
      },
    },
  ];

  const filter = [
    { label: "Penyelenggara", value: "penyelenggara" },
    { label: "Nama Program", value: "nama_program" },
  ];

  const filterData = [
    {
      label: "Status Bantuan",
      key: "status",
      placeholder: "Pilih status bantuan",
      type: "switch",
    },
  ];

  const fetchData = async () => {
    await dispatch(getListSubsidiBansos(params));
  };

  const exportData = useFetchData({
    action: getSubsidiBansos,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "subsidi-bansos",
        opt: {
          header: data.keys,
        },
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(null, null, data);
  };

  return (
    <>
      <Providers
        title="Program Kartu Subsidi"
        desc
        currentPage={currentPage}
        params={params}
        get={fetchData}
      >
        <MainCard
          title="Daftar Program Kartu Subsidi"
          onClick={() => setOpen(true)}
        >
          <MainCardHeader
            filter={filter}
            filterName={filterName}
            changeFilterName={changeFilterName}
            deleteFilter={deleteFilter}
            changeFilter={changeFilter}
            params={params}
            filterData={filterData}
            addFilter={addFilter}
            title="Filter Program Kartu Subsidi"
            onChangeExport={(type) => handleExport(type)}
          />
          <MainTable
            headers={headers}
            loading={loading}
            data={list}
            summaryKey="nama_program"
            detail={[
              {
                type: "link",
                url_key: "csr-bansos/program-bantuan/kartu-subsidi/penerima:id",
                tooltip: "Detail Penerima Bantuan",
                variant: "icon:clipboard",
              },
              {
                type: "link",
                url_key: "csr-bansos/program-bantuan/kartu-subsidi/subsidi:id",
                tooltip: "Detail Program",
                variant: "icon:ticket",
              },
            ]}
            increment={params?.offset + 1}
          />
          <MainPagination
            params={params}
            changeLimit={changeLimit}
            changePage={changePage}
            totalItems={totalItems}
            currentPage={currentPage}
          />
        </MainCard>
      </Providers>
      <Dialog open={open} onClose={() => setOpen(false)} refresh={fetchData} />
    </>
  );
};

export default SubsidiBansos;
