const permission = [
  "Web:Dashboard:Get",
  "Web:Profile:Get",
  "Web:MasterData:MasterBantuan:KartuSubsidi:Get",
  "Web:MasterData:MasterBantuan:KartuSubsidi:Edit",
  "Web:MasterData:MasterBantuan:Penerbit:Get",
  "Web:MasterData:MasterBantuan:Penerbit:Edit",
  "Web:MasterData:MasterBantuan:Voucer:Get",
  "Web:MasterData:MasterBantuan:Voucer:Edit",
  "Web:CSRBansos:ProgramBantuan:KartuSubsidi:Get",
  "Web:CSRBansos:ProgramBantuan:VoucerBansos:Get",
  "Web:CSRBansos:PenerimaBantuan:Get",
  "Web:Pengguna:Get",
  "Web:Peran:Get",
  "Web:Officer:Get",
];

const ALL = [
  "Web:Profile:Get",
  "Web:Dashboard:Get",
  "Web:Pengguna:Get",
  "Web:Peran:Get",
];

const CSRBansos = [
  "Web:MasterData:MasterBantuan:KartuSubsidi:Get",
  "Web:MasterData:MasterBantuan:KartuSubsidi:Edit",
  "Web:MasterData:MasterBantuan:Penerbit:Get",
  "Web:MasterData:MasterBantuan:Penerbit:Edit",
  "Web:MasterData:MasterBantuan:Voucer:Get",
  "Web:MasterData:MasterBantuan:Voucer:Edit",
  "Web:CSRBansos:ProgramBantuan:KartuSubsidi:Get",
  "Web:CSRBansos:ProgramBantuan:VoucerBansos:Get",
  "Web:CSRBansos:PenerimaBantuan:Get",
];

const Merchant = [
  "Web:Inventory:MasterProduk:Get",
  "Web:Inventory:MasterProduk:Edit",
  "Web:Inventory:MasterProduk:Delete",
  "Web:Inventory:MasterDistribusi:TerimaProduk:Get",
  "Web:Inventory:MasterDistribusi:KirimProduk:Get",
  "Web:Inventory:MasterDistribusi:KirimProduk:Edit",
  "Web:Report:DailyReport:Get",
  "Web:Report:StockReport:Get",
  "Web:Pengaturan:Get",
  "Web:Pengaturan:Edit",
  "Web:Officer:Get",
];

const PowerMerchant = [...CSRBansos, ...Merchant];

export { ALL, CSRBansos, Merchant, PowerMerchant };

export default permission;
