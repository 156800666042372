import React from "react";
import Providers from "components/RouteRoleProviders"
import PenerimaBantuan from "pages/CSR/PenerimaBantuan";
import DetailPenerimaBantuan from "pages/CSR/PenerimaBantuan/DetailPenerimaBantuan";
import DetailKartuSubsidiPenerimaBantuan from "pages/CSR/PenerimaBantuan/KartuSubsidi/Detail";
import DetailVoucherPenerimaBantuan from "pages/CSR/PenerimaBantuan/Voucher/Detail";
import DetailUser from "pages/CSR/PenerimaBantuan/KartuSubsidi/DetailUser";

const penerimaBantuanRoutes = [
  {
    path: "/csr-bansos/penerima-bantuan",
    element: (
      <Providers permission="Web:CSRBansos:PenerimaBantuan:Get">
        <PenerimaBantuan />
      </Providers>
    ),
  },
  {
    path: "/csr-bansos/penerima-bantuan/:id",
    element: (
      <Providers permission="Web:CSRBansos:PenerimaBantuan:Get">
        <DetailPenerimaBantuan />
      </Providers>
    ),
  },
  {
    path: "/csr-bansos/penerima-bantuan/kartu-subsidi/:subsidi_id/:user_id",
    element: (
      <Providers permission="Web:CSRBansos:PenerimaBantuan:Get">
        <DetailKartuSubsidiPenerimaBantuan />
      </Providers>
    ),
  },
  {
    path: "/csr-bansos/penerima-bantuan/voucher/:voucher_id/:user_id",
    element: (
      <Providers permission="Web:CSRBansos:PenerimaBantuan:Get">
        <DetailVoucherPenerimaBantuan />
      </Providers>
    ),
  },
  {
    path: "/csr-bansos/penerima-bantuan/kartu-subsidi/detail/user/:id",
    element: (
      <Providers permission="Web:CSRBansos:PenerimaBantuan:Get">
        <DetailUser />
      </Providers>
    ),
  },
];

export default penerimaBantuanRoutes;