import React from "react";

const FilterIconv2 = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_886_5088)">
        <path
          d="M5.83324 5.00004H14.1666L9.99157 10.25L5.83324 5.00004ZM3.54157 4.67504C5.2249 6.83337 8.33324 10.8334 8.33324 10.8334V15.8334C8.33324 16.2917 8.70824 16.6667 9.16657 16.6667H10.8332C11.2916 16.6667 11.6666 16.2917 11.6666 15.8334V10.8334C11.6666 10.8334 14.7666 6.83337 16.4499 4.67504C16.8749 4.12504 16.4832 3.33337 15.7916 3.33337H4.1999C3.50824 3.33337 3.11657 4.12504 3.54157 4.67504Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_886_5088">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FilterIconv2;
