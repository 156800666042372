import { useEffect } from "react";

const useAutoFetch = (get, currentPage, params, params2, params3) => {
  useEffect(() => {
    const fetchData = async () => {
      await get();
    };

    fetchData();
  }, [currentPage, params, params2, params3]);
};

export default useAutoFetch;
