import React from "react";

const Inventory = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2502 3.05L13.2002 2L12.1502 3.05L11.1002 2L10.0502 3.05L9.00019 2L7.95019 3.05L6.9002 2L5.85019 3.05L4.8002 2V11.8H2.7002V13.9C2.7002 15.062 3.6382 16 4.8002 16H13.2002C14.3622 16 15.3002 15.062 15.3002 13.9V2L14.2502 3.05ZM11.1002 14.6H4.8002C4.4152 14.6 4.1002 14.285 4.1002 13.9V13.2H11.1002V14.6ZM13.2002 14.6C13.5852 14.6 13.9002 14.285 13.9002 13.9V4.1H6.2002V11.8H12.5002V13.9C12.5002 14.285 12.8152 14.6 13.2002 14.6ZM6.9002 5.5H11.1002V6.9H6.9002V5.5ZM13.2002 5.5H11.8002V6.9H13.2002V5.5ZM6.9002 7.6H11.1002V9H6.9002V7.6ZM13.2002 7.6H11.8002V9H13.2002V7.6Z"
        fill="black"
      />
      <mask
        id="mask0_1853_10616"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="14"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2502 3.05L13.2002 2L12.1502 3.05L11.1002 2L10.0502 3.05L9.00019 2L7.95019 3.05L6.9002 2L5.85019 3.05L4.8002 2V11.8H2.7002V13.9C2.7002 15.062 3.6382 16 4.8002 16H13.2002C14.3622 16 15.3002 15.062 15.3002 13.9V2L14.2502 3.05ZM11.1002 14.6H4.8002C4.4152 14.6 4.1002 14.285 4.1002 13.9V13.2H11.1002V14.6ZM13.2002 14.6C13.5852 14.6 13.9002 14.285 13.9002 13.9V4.1H6.2002V11.8H12.5002V13.9C12.5002 14.285 12.8152 14.6 13.2002 14.6ZM6.9002 5.5H11.1002V6.9H6.9002V5.5ZM13.2002 5.5H11.8002V6.9H13.2002V5.5ZM6.9002 7.6H11.1002V9H6.9002V7.6ZM13.2002 7.6H11.8002V9H13.2002V7.6Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1853_10616)">
        <rect
          x="0.600098"
          y="0.599976"
          width="16.8"
          height="16.8"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default Inventory;
