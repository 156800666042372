import { Close } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import NoImageIcon from "assets/paper/NoImageIcon";
import FileValidator from "helper/FileValidator";
import React, { useRef, forwardRef } from "react";
import PropTypes from "prop-types";

const FileDrop = forwardRef(
  ({ accept, setError, onChange, value, onClose, name }, ref) => {
    const imageRef = useRef();
    return (
      <>
        <input
          type="file"
          ref={imageRef}
          hidden
          accept={accept}
          onChange={(e) => {
            const err = FileValidator(e.target.files[0], {
              size: {
                maxSize: "2mb",
              },
              type: accept.split(","),
            });

            if (err) {
              setError(name, {
                type: "file",
                message: err,
              });
              return;
            }

            onChange(e.target.files[0]);
          }}
        />
        <Box
          ref={ref}
          sx={{
            width: "100%",
            minHeight: "250px",
            border: "2px dashed #878889",
            display: "flex",
            borderRadius: "8px",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 2,
            py: 2,
          }}
        >
          {!Boolean(value) ? (
            <>
              <NoImageIcon />
              <Typography variant="text3">Masukan Pemilik Bisnis</Typography>
              <Button
                variant="gray-outlined"
                onClick={() => imageRef.current.click()}
              >
                Browse
              </Button>
            </>
          ) : (
            <Box position="relative">
              <Box
                sx={{
                  position: "absolute",
                  top: "0px",
                  right: "4px",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
              >
                <Close
                  sx={{
                    fontSize: "15px",
                    color: "#111",
                  }}
                />
              </Box>
              <Box
                component="img"
                src={
                  value
                    ? typeof value === "object"
                      ? URL.createObjectURL(value)
                      : value
                    : ""
                }
                sx={{
                  objectFit: "contain",
                  borderRadius: "4px",
                  width: "300px",
                  height: "200px",
                }}
              ></Box>
            </Box>
          )}
        </Box>
      </>
    );
  }
);

FileDrop.displayName = "FD";

FileDrop.propTypes = {
  onChange: PropTypes.func,
  setError: PropTypes.func,
  value: PropTypes.any,
  onClose: PropTypes.any,
  name: PropTypes.any,
  accept: PropTypes.string,
};

FileDrop.defaultProps = {
  onChange: () => {},
  setError: () => {},
  value: null,
  onClose: () => {},
  accept: "image/png,image/jpg,image/jpeg",
  name: "",
};

export default FileDrop;
