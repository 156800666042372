import { Typography } from "@mui/material";
import FormError from "components/Alert";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";

const MainController = forwardRef(
  (
    {
      controller: Controller,
      name,
      control,
      rules,
      errors,
      clearErrors,
      desc,
      alertType,
      customError,
      ...props
    },
    ref
  ) => {
    return (
      <>
        <Controller
          ref={ref}
          name={name}
          control={control}
          rules={rules}
          {...props}
        />
        {Boolean(desc) && <Typography variant="inputDesc">{desc}</Typography>}

        <FormError
          type={alertType || 1}
          message={!customError ? errors?.[name]?.message : customError}
          onClose={() => clearErrors(name)}
        />
      </>
    );
  }
);

MainController.displayName = "mc";
MainController.propTypes = {
  control: PropTypes.any,
  controller: PropTypes.any,
  name: PropTypes.any,
  rules: PropTypes.any,
  props: PropTypes.any,
  errors: PropTypes.any,
  customError: PropTypes.string,
  clearErrors: PropTypes.any,
  onChange: PropTypes.any,
  desc: PropTypes.any,
  alertType: PropTypes.number,
};

MainController.defaultProps = {
  control: null,
  controller: null,
  name: null,
  rules: null,
  props: null,
  errors: null,
  customError: null,
  clearErrors: null,
  onChange: null,
  desc: "",
  alertType: 0,
};
export default MainController;
