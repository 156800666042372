import React from "react";
import PropTypes from "prop-types";
import StatusChip from "../StatusChip";

const color = [
  {
    label: "Belum Aktif",
    color: "#eb5050",
    background: "#f5e3e3",
    value: 0,
  },
  {
    label: "Aktif",
    color: "#2c8424",
    background: "#e9f2e9",
    value: 1,
  },
  {
    label: "Kadaluarsa",
    color: "#4e5d78",
    background: "#ececec",
    value: 2,
  },
  {
    label: "Ditukarkan",
    color: "#7300f7",
    background: "#ececec",
    value: 3,
  },
  {
    label: "Diblokir",
    color: "#ff6c00",
    background: "#f7efd9",
    value: 4,
  },
  {
    label: "Diklaim",
    color: "#2c8424",
    background: "#e9f2e9",
    value: 5,
  },
  {
    label: "Ditutup",
    color: "#eb5050",
    background: "#f5e3e3",
    value: 6,
  },
  {
    label: "Digunakan",
    color: "#ff6c00",
    background: "#f7efd9",
    value: 7,
  },
];

const find = (value, text) => {
  return color.find((i) => i.value === value)?.[text] || "-";
};

const VoucherStatus = ({ value }) => (
  <StatusChip
    label={find(value, "label")}
    color={find(value, "color")}
    background={find(value, "background")}
  />
);

VoucherStatus.defaultProps = {
  value: 0,
};

VoucherStatus.propTypes = {
  value: PropTypes.any,
};

export default VoucherStatus;
