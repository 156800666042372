import React from "react";
import Providers from "components/RouteRoleProviders";
import SubsidiBansosDetail from "pages/CSR/ProgramBantuan/SubsidiBansos/Subsidi";
import PenerimaSubsidiBansos from "pages/CSR/ProgramBantuan/SubsidiBansos/Penerima";
import SubsidiBansos from "pages/CSR/ProgramBantuan/SubsidiBansos";

const subsidiBansosRoutes = [
  {
    path: "/csr-bansos/program-bantuan/kartu-subsidi",
    element: (
      <Providers permission="Web:CSRBansos:ProgramBantuan:KartuSubsidi:Get">
        <SubsidiBansos />
      </Providers>
    ),
  },
  {
    path: "/csr-bansos/program-bantuan/kartu-subsidi/subsidi/:id",
    element: (
      <Providers permission="Web:CSRBansos:ProgramBantuan:KartuSubsidi:Get">
        <SubsidiBansosDetail />
      </Providers>
    ),
  },
  {
    path: "/csr-bansos/program-bantuan/kartu-subsidi/penerima/:id",
    element: (
      <Providers permission="Web:CSRBansos:ProgramBantuan:KartuSubsidi:Get">
        <PenerimaSubsidiBansos />
      </Providers>
    ),
  },
];

export default subsidiBansosRoutes;
