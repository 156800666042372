import { InputAdornment, TextField } from "@mui/material";
import PageTitle from "components/Page/PageTitle";
import MainCard from "components/Paper/MainCard";
import AktifStatus from "components/Status/AktifStatus";
import MainTable from "components/Table/MainTable";
import FormatRupiah from "helper/FormatRupiah";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { bansosSubsidiProgramUser } from "redux/penerimaBantuan/action";
import {
  List,
  Loading,
  NamaProgram,
  Params,
} from "redux/penerimaBantuan/KartuSubsidi/selectors";
import SearchIcon from "@mui/icons-material/Search";
import { changeFilter } from "redux/penerimaBantuan/KartuSubsidi/slice";

const Detail = () => {
  const loading = useSelector(Loading);
  const list = useSelector(List);
  const nama_program = useSelector(NamaProgram);
  const params = useSelector(Params);
  const { subsidi_id, user_id } = useParams();

  const dispatch = useDispatch();
  const headers = [
    {
      name: "No. Kartu",
      key: "PrefixCode",
      width: 20,
    },
    {
      name: "Nama Kartu Subsidi",
      key: "CardName",
      width: 35,
    },
    {
      name: "Saldo",
      width: 25,
      custom: (item) => {
        return `Rp. ${FormatRupiah(item.Balance)}`;
      },
    },
    {
      name: "Status Kartu Subsidi",
      custom: (item) => {
        return <AktifStatus value={item?.Status} />;
      },
      width: 20,
    },
  ];

  useEffect(() => {
    const io = () => {
      fetchData();
    };
    io();
  }, [params.search]);

  const fetchData = async () => {
    await dispatch(
      bansosSubsidiProgramUser({
        subsidi: subsidi_id,
        user: user_id,
        params: params,
      })
    );
  };

  return (
    <>
      <PageTitle
        title={`Kartu Subsidi ${nama_program || ""}`}
        text="Dasbor / CSR Bansos / Penerima Bantuan / Detail Penerima Bantuan / "
        lastText="Kartu Subsidi"
      />
      <MainCard
        title="Daftar Kartu Subsidi"
        addButton={false}
        isEdit={false}
        custom={
          <TextField
            placeholder="Search..."
            onChange={(e) => {
              dispatch(changeFilter(e.target.value));
            }}
            value={params?.search || ""}
            InputProps={{
              sx: {
                outline: "1px solid #D0D0D0",
                backgroundColor: "#fff !important",
              },
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: "20px" }}>
                  <SearchIcon
                    fontSize="medium"
                    sx={{
                      color: "#4E4751",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        }
      >
        <MainTable
          headers={headers}
          loading={loading}
          data={list}
          action="  "
          align="center"
          summaryKey="CardName"
          detail={[
            {
              type: "link",
              variant: "icon:eye",
              tooltip: "Detail Kartu Subsidi",
              url_key: `csr-bansos/penerima-bantuan/kartu-subsidi/detail/user:id`,
            },
          ]}
        />
      </MainCard>
    </>
  );
};

export default Detail;
