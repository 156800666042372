import { TextField, Typography } from "@mui/material";
import FormError from "components/Alert";
import PropTypes from "prop-types";
import React, { forwardRef, Fragment, useMemo } from "react";
import TextFieldv3 from "./TextFieldv3";

const MainTextField = forwardRef(
  (
    {
      controller: Controller,
      name,
      control,
      rules,
      errors,
      clearErrors,
      desc,
      formatchange,
      format,
      onChange,
      typeField,
      ...props
    },
    ref
  ) => {
    const TypeField = useMemo(() => {
      return typeField == 0 ? TextField : TextFieldv3;
    }, [typeField]);

    return (
      <Fragment ref={ref}>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <TypeField
              {...field}
              {...props}
              error={errors?.[name]?.message ? true : false}
              onChange={(e) => {
                Boolean(onChange)
                  ? onChange(field, e)
                  : field.onChange(e.target.value);
              }}
              value={Boolean(format) ? format(field.value) : field.value || ""}
            />
          )}
        />
        {Boolean(desc) && <Typography variant="inputDesc">{desc}</Typography>}
        <FormError
          type={1}
          message={errors?.[name]?.message}
          onClose={() => clearErrors(name)}
        />
      </Fragment>
    );
  }
);

MainTextField.displayName = "tf";
MainTextField.propTypes = {
  control: PropTypes.any,
  controller: PropTypes.any,
  name: PropTypes.any,
  rules: PropTypes.any,
  props: PropTypes.any,
  errors: PropTypes.any,
  clearErrors: PropTypes.any,
  onChange: PropTypes.any,
  format: PropTypes.any,
  desc: PropTypes.string,
  typeField: PropTypes.number,
  formatchange: PropTypes.any,
};

MainTextField.defaultProps = {
  control: null,
  controller: null,
  name: null,
  rules: null,
  props: null,
  errors: null,
  clearErrors: null,
  onChange: null,
  format: null,
  formatchange: null,
  typeField: 0,
  desc: "",
};
export default MainTextField;
