import { createAsyncThunk } from "@reduxjs/toolkit";
import { get } from "api";
import { Profile } from "api/endpoints";


const getProfile = createAsyncThunk("users/getProfile", async () => {
  const response = await get(Profile.profile);
  return response.data.data;
});

const getPhoto = createAsyncThunk("users/getPhoto", async () => {
  const response = await get(Profile.photo);
  return response.data.data;
});

export { getProfile, getPhoto };
