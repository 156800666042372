import React from "react";

const PageNotFoundSvg = () => {
  return (
    <svg
      width="330px"
      height="251px"
      style={{ margin: "auto" }}
      viewBox="0 0 330 251"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Under Constructions _Two Color</title>
      <g
        id="Empty-State"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Empty-State-Under-Development"
          transform="translate(-518.000000, -225.000000)"
          fillRule="nonzero"
        >
          <g id="Group" transform="translate(376.000000, 225.000000)">
            <g
              id="Under-Constructions-_Two-Color"
              transform="translate(142.000000, 0.000000)"
            >
              <path
                d="M111.164041,22.5337939 C65.717482,33.2538585 29.8101072,67.1278444 19.3052275,110.669981 C11.4736748,143.078379 18.351309,176.503233 71.064136,188.677066 C185.004395,214.986729 308.106983,206.252299 322.898607,153.70389 C337.690231,101.155481 310.238578,11.0218392 245.065928,5.44315067 C207.957322,2.24013247 152.253195,12.8774623 111.164041,22.5337939 Z"
                id="Path"
                fill="#FFCC00"
              ></path>
              <ellipse
                id="Oval"
                fill="#E6E6E6"
                opacity="0.45"
                cx="169.753477"
                cy="232.018276"
                rx="160.246523"
                ry="18.9817241"
              ></ellipse>
              <polygon
                id="Rectangle"
                fill="#75787D"
                transform="translate(281.132856, 183.022099) rotate(15.670000) translate(-281.132856, -183.022099) "
                points="277.726553 136.870948 284.452859 136.877256 284.539158 229.173251 277.812852 229.166942"
              ></polygon>
              <path
                d="M299.957457,142.889271 C299.44768,142.887475 298.94053,142.815899 298.45003,142.676525 L287.568294,139.662615 C286.138981,139.260373 284.927582,138.304467 284.200866,137.005403 C283.47415,135.70634 283.291715,134.170654 283.693736,132.736531 L285.884216,124.900365 C286.719231,121.912685 289.806891,120.167628 292.785404,121.00001 L303.643587,124.061197 C306.621696,124.904253 308.355922,128.009612 307.518144,130.9991 L305.327665,138.835267 C304.629063,141.230614 302.444317,142.879893 299.957457,142.889271 L299.957457,142.889271 Z M299.392172,139.343495 C299.930972,139.496145 300.508146,139.427421 300.996398,139.152481 C301.48465,138.877541 301.843873,138.418971 301.994839,137.877907 L304.185318,130.041741 C304.341015,129.499391 304.274327,128.917087 304.000084,128.424332 C303.725841,127.931578 303.266781,127.569227 302.724998,127.417866 L291.866816,124.368498 C291.328016,124.215848 290.750842,124.284572 290.26259,124.559511 C289.774337,124.834451 289.415115,125.293022 289.264149,125.834086 L287.07367,133.670252 C286.921569,134.210995 286.990045,134.790251 287.263997,135.280265 C287.537948,135.770279 287.99487,136.130797 288.533989,136.282307 L299.392172,139.343495 Z"
                id="Shape"
                fill="#595C61"
              ></path>
              <path
                d="M284.388566,232.715612 L289.794104,213.37931 L257.584635,204.349399 L251.931785,224.560326 C251.177664,227.233138 251.100749,230.052671 251.708026,232.762889 L284.388566,232.715612 Z"
                id="Path"
                fill="#595C61"
              ></path>
              <rect
                id="Rectangle"
                fill="#FBB12F"
                x="117.00532"
                y="77.8124512"
                width="16.4639259"
                height="157.101542"
              ></rect>
              <rect
                id="Rectangle"
                fill="#FBB12F"
                x="205.978825"
                y="77.8124512"
                width="16.4639259"
                height="157.101542"
              ></rect>
              <rect
                id="Rectangle"
                fill="#000000"
                opacity="0.08"
                x="117.00532"
                y="77.8124512"
                width="16.4639259"
                height="157.101542"
              ></rect>
              <rect
                id="Rectangle"
                fill="#000000"
                opacity="0.08"
                x="205.978825"
                y="77.8124512"
                width="16.4639259"
                height="157.101542"
              ></rect>
              <rect
                id="Rectangle"
                fill="#FBB12F"
                x="97.5147637"
                y="97.3142226"
                width="144.489203"
                height="51.1537372"
              ></rect>
              <polygon
                id="Path"
                fill="#F6F6F6"
                points="104.839915 97.3142226 122.104662 148.46796 145.987953 148.46796 126.367853 97.3142226"
              ></polygon>
              <polygon
                id="Path"
                fill="#F6F6F6"
                points="149.06169 97.3142226 166.326437 148.46796 190.209728 148.46796 170.577851 97.3142226"
              ></polygon>
              <polygon
                id="Path"
                fill="#F6F6F6"
                points="193.153921 97.3142226 210.418667 148.46796 234.301959 148.46796 214.670082 97.3142226"
              ></polygon>
              <rect
                id="Rectangle"
                fill="#FBB12F"
                x="97.5147637"
                y="167.839719"
                width="144.489203"
                height="23.9812691"
              ></rect>
              <path
                d="M59.0989367,224.938542 L73.5372579,158.254303 C73.8489016,156.904034 75.0474171,155.947965 76.4284552,155.947965 C77.8094933,155.947965 79.0080088,156.904034 79.3196525,158.254303 L95.0769721,226.049552 L59.0989367,224.938542 Z"
                id="Path"
                fill="#FBB12F"
              ></path>
              <rect
                id="Rectangle"
                fill="#FBB12F"
                x="54.2115767"
                y="218.508867"
                width="45.2934613"
                height="11.1691963"
                rx="5.21249583"
              ></rect>
              <rect
                id="Rectangle"
                fill="#FFFFFF"
                opacity="0.46"
                x="54.2115767"
                y="218.508867"
                width="45.2934613"
                height="11.1691963"
                rx="5.21249583"
              ></rect>
              <polygon
                id="Path"
                fill="#000000"
                opacity="0.08"
                points="68.1670504 183.003824 83.6299507 176.786896 88.140454 196.194113 63.8331986 202.942908"
              ></polygon>
              <path
                d="M61.807594,230.753616 C61.807594,230.753616 68.390809,221.298211 58.568982,218.62706 C48.747155,215.955908 60.9243362,203.202931 51.7855619,201.512778 C42.6467876,199.822624 44.1659911,213.332033 44.1659911,213.332033 C44.1659911,213.332033 37.9007489,207.895176 33.4962365,212.504685 C29.0917242,217.114195 35.2391986,222.078282 35.2391986,222.078282 C35.2391986,222.078282 26.4419507,225.12765 31.929926,230.706339 L61.807594,230.753616 Z"
                id="Path"
                fill="#3BA7C4"
              ></path>
              <path
                d="M48.594057,232.018276 C48.594057,232.018276 52.7748107,224.832169 58.9458387,225.718613 C65.1168667,226.605057 64.4455907,232.018276 64.4455907,232.018276 L48.594057,232.018276 Z"
                id="Path"
                fill="#C9C9C9"
              ></path>
              <path
                d="M201.786294,96.6287058 L251.248732,121.744623 C252.047158,122.143307 253.007428,122.010359 253.668477,121.409612 C254.329525,120.808865 254.556346,119.863012 254.240032,119.026195 L253.1919,116.343224 C252.009434,113.303131 252.009434,109.927913 253.1919,106.887819 C256.359851,98.6143407 261.047006,80.8854576 247.892353,70.9691024 C231.05157,58.2397644 205.024906,59.4689669 198.900985,90.7190781 C198.404988,93.1191187 199.592524,95.5514059 201.786294,96.6287058 Z"
                id="Path"
                fill="#595C61"
              ></path>
              <polygon
                id="Path"
                fill="#000000"
                opacity="0.08"
                points="203.140623 97.3142226 241.99219 134.946732 241.99219 117.028741"
              ></polygon>
              <path
                d="M208.887687,78.1197519 C211.890764,73.9002777 217.955801,67.6242531 227.518539,67.3642295 C228.291456,67.3433402 228.980594,67.8497862 229.194106,68.5955996 C229.407618,69.3414131 229.091439,70.1377422 228.42535,70.5317899 C223.143936,73.5404758 218.553369,77.6321205 214.952724,82.5401534 C213.890135,84.0495702 211.887355,84.5520713 210.242016,83.7220789 L210.242016,83.7220789 C209.244765,83.2324297 208.516398,82.3227334 208.254639,81.2399406 C207.99288,80.1571479 208.224891,79.013608 208.887687,78.1197519 L208.887687,78.1197519 Z"
                id="Path"
                fill="#FFFFFF"
                opacity="0.31"
              ></path>
              <path
                d="M56.5316006,103.980283 C56.5287724,101.554319 55.4651659,99.251841 53.6221974,97.6820554 C51.779229,96.1122699 49.3426274,95.4333746 46.9570858,95.8249964 C45.0295806,92.195394 41.2687193,89.9243781 37.1705891,89.9153687 L36.7584021,89.9153687 C37.0279189,88.8650217 37.1663749,87.7851396 37.1705891,86.7005313 C37.1705891,79.3830978 31.2599597,73.451146 23.9688288,73.451146 C16.6776979,73.451146 10.7670685,79.3830978 10.7670685,86.7005313 C10.7712827,87.7851396 10.9097387,88.8650217 11.1792555,89.9153687 L10.7670685,89.9153687 C4.76475755,90.1114061 0,95.0515023 0,101.078655 C0,107.105808 4.76475755,112.045905 10.7670685,112.241942 L49.3595471,112.241942 L49.3595471,112.159207 C53.4539196,111.620465 56.5194472,108.124574 56.5316006,103.980283 Z"
                id="Path"
                fill="#595C61"
              ></path>
              <path
                d="M326.208097,40.8418205 C326.201381,34.788829 321.310274,29.8853672 315.279036,29.8853708 C314.69093,29.8885494 314.104066,29.9399371 313.524297,30.0390211 C310.963048,25.2483244 305.987047,22.2564935 300.569849,22.2501318 L300.028118,22.2501318 C300.382755,20.8636707 300.564721,19.4384257 300.569849,18.0070191 C300.73478,11.6299758 297.439155,5.66521236 291.962168,2.42794815 C286.485182,-0.809316052 279.689283,-0.809316052 274.212297,2.42794815 C268.735311,5.66521236 265.439685,11.6299758 265.604616,18.0070191 C265.599016,19.4656144 265.777078,20.9190749 266.134571,22.3328666 L265.592839,22.3328666 C257.691614,22.6501191 251.449055,29.1708636 251.449055,37.1069358 C251.449055,45.043008 257.691614,51.5637524 265.592839,51.881005 L316.704026,51.881005 L316.704026,51.7746317 C322.166805,51.0590125 326.242334,46.3707058 326.208097,40.8418205 Z"
                id="Path"
                fill="#595C61"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PageNotFoundSvg;
