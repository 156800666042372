import PropTypes from "prop-types";
import React, { forwardRef, Fragment, useState } from "react";
import { Box, Button, Popover, styled, TextField } from "@mui/material";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import DateRange from "@mui/icons-material/DateRange";
import moment from "moment";
import "./datepicker2.css";

const DateRangePicker = styled(Box)({
  backgroundColor: "#fff",
  padding: "10px",
  paddingBottom: "20px",
  paddingTop: "15px",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  margin: "auto",
  justifyContent: "center",
  width: "375px",
});

const DateRangePickerNav = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontSize: "14px",
  margin: "0px 10px",
  // margin: "15px 11px",
}));

const ButtonChangeMonth = styled(Button)(({ theme }) => ({
  borderRadius: "4px",
  width: "10px 10px !important",
  minWidth: "0px !important",
  backgroundColor: "#f9e8e7",
  color: "#cc1d15",
  fontSize: "14px",
}));

const TableTd = styled(Box)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "600",
  transition: "all 0.2s",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#f9e8e7",
  },
}));

const Separator = styled(Box)({
  padding: "5px 5px !important",
  backgroundColor: "#fff",
});

const Table = styled(Box)({
  width: "100%",
  textAlign: "center",
  marginTop: "10px",
  transition: "all 1s",
  thead: {
    tr: {
      padding: "5px",
      fontWeight: "600 !important",
      transition: "all 1s",
      color: "#74788d",
    },
  },
});

const Heading = ({ date, changeMonth, resetDate }) => (
  <DateRangePickerNav>
    <ButtonChangeMonth onClick={() => changeMonth(date.month() - 1)}>
      <ChevronLeft />
    </ButtonChangeMonth>
    <div
      onClick={() => resetDate()}
      style={{ cursor: "pointer", fontWeight: "700", fontSize: "14px" }}
    >
      {date.format("MMMM")} {date.format("YYYY")}
    </div>
    <ButtonChangeMonth onClick={() => changeMonth(date.month() + 1)}>
      <ChevronRight />
    </ButtonChangeMonth>
  </DateRangePickerNav>
);

Heading.propTypes = {
  changeMonth: PropTypes.func,
  date: PropTypes.shape({
    format: PropTypes.func,
    month: PropTypes.func,
  }),
  resetDate: PropTypes.func,
};

Heading.defaultProps = {
  changeMonth: () => {},
  date: null,
  resetDate: () => {},
};

const Day = ({ currentDate, date, startDate, endDate, onClick, past }) => {
  const isPast = moment().subtract(1, "day").isAfter(date);
  if (date === null) {
    return (
      <td
        onClick={() => onClick(date)}
        //   currentDate={date}
      >
        {""}
      </td>
    );
  }
  let className = [];

  if (moment().isSame(date, "day")) {
    className.push("active");
  }

  if (date.isSame(startDate, "day")) {
    className.push("start");
  }

  if (date.isBetween(startDate, endDate, "day")) {
    className.push("between");
  }

  if (date.isSame(endDate, "day")) {
    className.push("end");
  }

  if (!date.isSame(currentDate, "month")) {
    className.push("muted");
  }

  if (past && isPast) {
    className.push("muted");
  }

  return (
    <TableTd
      component={"td"}
      onClick={() => {
        if (past && isPast) {
          return;
        }
        onClick(date);
      }}
      // currentDate={date}
      sx={{
        ...(past &&
          isPast && {
            cursor: "not-allowed",
          }),
        "&:has(.between)": {
          backgroundColor: "#F9E8E7",
        },
        "&:has(.start)": {
          background: "linear-gradient(90deg, #fff 50%, #F9E8E7 50%)",
        },
        "&:has(.end)": {
          background: "linear-gradient(90deg, #F9E8E7 50%, #fff 50%)",
        },
        "&:has(.between):last-child": {
          background: "linear-gradient(90deg, #F9E8E7 80%, #fff 50%)",
        },
        "&:has(.between):first-of-type": {
          background: "linear-gradient(270deg, #F9E8E7 80%, #fff 50%)",
        },
      }}
    >
      <Box className={"hello " + className.join(" ")}>{date.date() || ""}</Box>
    </TableTd>
  );
};

Day.propTypes = {
  currentDate: PropTypes.any,
  date: PropTypes.shape({
    date: PropTypes.func,
    isBetween: PropTypes.func,
    isSame: PropTypes.func,
  }),
  endDate: PropTypes.any,
  onClick: PropTypes.func,
  past: PropTypes.any,
  startDate: PropTypes.any,
};

Day.defaultProps = {
  currentDate: null,
  date: null,
  endDate: null,
  onClick: () => {},
  past: null,
  startDate: null,
};

const Days = ({ date, startDate, endDate, onClick, past }) => {
  const thisDate = moment(date);
  const daysInMonth = moment(date).daysInMonth();
  const firstDayDate = moment(date).startOf("month");
  const previousMonth = moment(date).subtract(1, "month");
  const previousMonthDays = previousMonth.daysInMonth();
  let days = [];
  let labels = [];

  for (let i = 0; i <= 6; i++) {
    labels.push(<span className="label">{moment().day(i).format("dd")}</span>);
  }

  for (let i = firstDayDate.day(); i > 0; i--) {
    previousMonth.date(previousMonthDays - i + 1);

    days.push(
      <Day
        key={moment(previousMonth).format("DD MM YYYY")}
        currentDate={date}
        date={null}
        startDate={null}
        endDate={null}
        past={past}
      />
    );
  }

  for (let i = 1; i <= daysInMonth; i++) {
    thisDate.date(i);
    days.push(
      <Day
        key={moment(thisDate).format("DD MM YYYY")}
        onClick={(date) => onClick(date)}
        currentDate={date}
        date={moment(thisDate)}
        startDate={startDate}
        endDate={endDate}
        past={past}
      />
    );
  }

  let list = [];

  for (let i = 0; i < days.concat().length; i += 7) {
    let slicIt = days.concat().slice(i, i + 7);
    list.push(slicIt);
  }

  return (
    <Table
      component={"table"}
      sx={{
        width: "100%",
        tableLayout: "auto",
        marginTop: "20px",
      }}
    >
      <thead>
        <tr>
          {labels.map((label, i) => (
            <Box
              component="td"
              // sx={{
              //   fontWeight: "500",
              //   "&:last-child": {
              //     width: "7px",
              //     paddingLeft: "10px",
              //   },
              //   "&:first-child": {
              //     width: "7px",
              //     paddingLeft: "10px",

              //   },
              // }}
              key={i}
            >
              <Box
                sx={{
                  width: "30px",
                  fontSize: "14px",
                  margin: "auto",
                }}
              >
                {label}
              </Box>
            </Box>
          ))}
        </tr>
        <tr>
          <Separator component="th" colSpan={7}></Separator>
        </tr>
      </thead>
      <tbody>
        {list.map((tr, i) => (
          <Fragment key={i}>
            <tr className="boy">{tr.map((td) => td)}</tr>
            <Box
              component="tr"
              sx={{
                "&:last-child": {
                  display: "none",
                },
              }}
            >
              <Separator component="td" colSpan={7}></Separator>
            </Box>
          </Fragment>
        ))}
      </tbody>
    </Table>
  );
};

Days.propTypes = {
  date: PropTypes.any,
  endDate: PropTypes.any,
  onClick: PropTypes.func,
  past: PropTypes.any,
  startDate: PropTypes.any,
};

Days.defaultProps = {
  date: null,
  endDate: null,
  onClick: () => {},
  past: null,
  startDate: null,
};

const MainDateRange = forwardRef(
  ({ onChange, start, end, past = false }, ref) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [state, setState] = useState({
      date: moment(),
    });

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const resetDate = () => {
      setState({
        date: moment(),
      });
    };

    const changeMonth = (month) => {
      const { date } = state;

      date.month(month);

      setState({
        ...state,
        date: date,
      });
    };

    const open = Boolean(anchorEl);

    const id = open ? "simple-popover" : undefined;
    const { date } = state;

    const handleChange = (date) => {
      const s = moment(start).format("YYYY-MM-DD");
      const e = moment(end).format("YYYY-MM-DD");
      const d = moment(date).format("YYYY-MM-DD");
      if (start !== null && end !== null) {
        onChange([null, null]);
      }
      if (start === null || date.isBefore(start, "day")) {
        onChange([d, null]);
      } else if (start !== null && end !== null) {
        onChange([d, null]);
      } else if (date.isSame(start, "day") && date.isSame(end, "day")) {
        onChange([null, null]);
      } else if (date.isAfter(start, "day")) {
        onChange([s, d]);
      }
    };
    return (
      <>
        <TextField
          ref={ref}
          sx={{
            border: open ? "1px solid #cc1d15" : "none",
            borderRadius: "4px",
            ".MuiOutlinedInput-root": {
              paddingRight: "0px",
            },
          }}
          onClick={handleClick}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <DateRange
                sx={{ position: "absolute", right: "13px", color: "#4E4751" }}
              />
            ),
          }}
          placeholder="DD/MM/YYYY -> DD/MM/YYYY"
          value={`${start ? moment(start).format("DD/MM/YYYY") : ""}${
            start ? " -> " : ""
          }${end ? moment(end).format("DD/MM/YYYY") : ""}`}
        />

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              marginTop: "10px",
              boxShadow: "0px 1px 8px 1px rgba(0, 0, 0, 0.08)",
            },
          }}
        >
          <DateRangePicker>
            <Heading
              date={date}
              changeMonth={(month) => changeMonth(month)}
              resetDate={() => resetDate()}
            />

            <Days
              onClick={(date) => handleChange(date)}
              date={date}
              startDate={start}
              endDate={end}
              past={past}
            />
          </DateRangePicker>
        </Popover>
      </>
    );
  }
);

MainDateRange.propTypes = {
  end: PropTypes.any,
  onChange: PropTypes.func,
  past: PropTypes.bool,
  start: PropTypes.any,
};

MainDateRange.defaultProps = {
  end: null,
  onChange: () => {},
  past: false,
  start: null,
};

MainDateRange.displayName = "dp";

export default MainDateRange;
