import React from "react";
import PropTypes from "prop-types";
import { TransitionGroup } from "react-transition-group";
import MainDialog from "components/Dialog/MainDialog";
import CardWidget from "../components/CardWidget";
import VoucerBansosIlu from "assets/redeem/VoucerBansosIlu";
import KartuSubsidiIlu from "assets/redeem/KartuSubsidiIlu";
import { Box, Collapse, Typography } from "@mui/material";
import PilihTransaksi from "../components/PilihTransaksi";
import OnlineTransaksi from "assets/redeem/OnlineTransaksi";
import ManualValidasi from "assets/redeem/ManualValidasi";
import MainController from "components/Controller/MainController";
import MerchantLogo from "assets/redeem/MerchantLogo";
import TextFieldv3 from "components/TextField/TextFieldv3";
import FormatRupiah from "helper/FormatRupiah";
import FormatNumber from "helper/FormatNumber";
import { detailMerchant } from "redux/merchant/action";
import useFetchData from "hook/useFetchData";
import OfflineTransaksi from "assets/redeem/OfflineTransaksi";

const RedeemSubsidiDialog = ({
  handleSubmit,
  isValid,
  errors,
  control,
  Controller,
  watch,
  open,
  onClose,
  setValue,
  unregister,
  onSubmit,
  getValues,
}) => {
  const goSubmit = (data) => {
    onSubmit(data);
  };

  const getUserMerchant = useFetchData({
    action: detailMerchant,
    snackbar: false,
    onSuccess: (data) => {
      setValue("namaBisnis", data?.mcName);
      setValue("alamatBisnis", data?.mcdFullAddress);
    },
  });

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
    };
  };

  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
      }}
      title={"Redeem Subsidi"}
      loading={false}
      valid={isValid}
      handleSubmit={handleSubmit}
      onSubmit={goSubmit}
      submitText={"Selanjutnya"}
      onReset={() => {
        reset();
      }}
      customWidth="1024px"
    >
      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          sx={{
            flexDirection: {
              xs: "column",
              md: "row",
            },
          }}
          margin="auto"
          justifyContent="center"
          gap={2}
        >
          <MainController
            {...getProps("TypeBansos")}
            rules={{
              required: true,
            }}
            render={({ field: { value, onChange } }) => (
              <CardWidget
                ilu={VoucerBansosIlu}
                title="Voucer Bansos"
                desc="Tukarkan voucher bantuan sosial elektronik."
                onChange={() => {
                  setValue("TypeTransaksi", null);
                  setValue("TotalTagihan", null);
                  value == 1 ? onChange(null) : onChange(1);
                }}
                isActive={value == 1}
              />
            )}
          />

          <MainController
            {...getProps("TypeBansos")}
            rules={{
              required: true,
            }}
            render={({ field: { value, onChange } }) => (
              <CardWidget
                ilu={KartuSubsidiIlu}
                title="Kartu Subsidi"
                desc="Tukarkan kartu subsidi elektronik."
                onChange={() => {
                  setValue("TypeTransaksi", null);
                  setValue("TotalTagihan", null);
                  value == 2 ? onChange(null) : onChange(2);
                }}
                isActive={value == 2}
              />
            )}
          />
        </Box>
        <TransitionGroup>
          {watch("TypeBansos") == 1 && (
            <Collapse>
              <Box
                display="flex"
                sx={{
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  mt: 5,
                }}
                justifyContent="center"
                gap={1}
              >
                <MainController
                  {...getProps("TypeTransaksi")}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { value, onChange, ref } }) => (
                    <PilihTransaksi
                      ref={ref}
                      ilu={OnlineTransaksi}
                      title="Online Transaksi"
                      desc="Scan kode QR bagi pengguna yang berada dalam jaringan untuk melakukan penukaran. "
                      onChange={() => {
                        getUserMerchant.fetch();
                        value == 1 ? onChange(null) : onChange(1);
                      }}
                      isActive={value == 1}
                    />
                  )}
                />

                <MainController
                  {...getProps("TypeTransaksi")}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { value, onChange, ref } }) => (
                    <PilihTransaksi
                      ref={ref}
                      ilu={ManualValidasi}
                      title="Manual Validasi"
                      desc="Validasi nomor ID bagi pengguna yang tidak memiliki  perangkat untuk melakukan penukaran."
                      onChange={() => {
                        value == 3 ? onChange(null) : onChange(3);
                      }}
                      isActive={value == 3}
                    />
                  )}
                />
              </Box>
            </Collapse>
          )}
        </TransitionGroup>

        <TransitionGroup>
          {watch("TypeBansos") == 2 && (
            <Collapse>
              <Box
                display="flex"
                sx={{
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  mt: 5,
                }}
                justifyContent="center"
                gap={1}
              >
                <MainController
                  {...getProps("TypeTransaksi")}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { value, onChange, ref } }) => (
                    <PilihTransaksi
                      ref={ref}
                      ilu={OnlineTransaksi}
                      title="Online Transaksi"
                      desc="Scan kode QR bagi pengguna yang berada dalam jaringan untuk melakukan penukaran. "
                      onChange={() => {
                        getUserMerchant.fetch();
                        value == 4 ? onChange(null) : onChange(4);
                      }}
                      isActive={value == 4}
                    />
                  )}
                />

                <MainController
                  {...getProps("TypeTransaksi")}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { value, onChange, ref } }) => (
                    <PilihTransaksi
                      ref={ref}
                      ilu={OfflineTransaksi}
                      title="Scan QR Offline"
                      desc="Scan kode QR bagi pengguna yang sedang di luar jaringan untuk melakukan penukaran."
                      onChange={() => {
                        getUserMerchant.fetch();
                        value == 2 ? onChange(null) : onChange(2);
                      }}
                      isActive={value == 2}
                    />
                  )}
                />
              </Box>
            </Collapse>
          )}
        </TransitionGroup>

        <TransitionGroup>
          {(watch("TypeTransaksi") == 2 || watch("TypeTransaksi") == 4) && (
            <Collapse onExited={() => unregister("TotalTagihan")}>
              <Box
                display="flex"
                gap={3}
                sx={{
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  mt: 5,
                }}
              >
                <Box display="flex" flexDirection="column" width="100%" gap={1}>
                  <Typography variant="label2">Merchant</Typography>
                  <Box display="flex" gap={1}>
                    <MerchantLogo />
                    <Box display="flex" flexDirection="column" gap={0.5}>
                      <Typography variant="label2">
                        {getValues("namaBisnis")}
                      </Typography>
                      <Typography variant="desc5">
                        {getValues("alamatBisnis")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" width="100%" gap={1}>
                  <Typography variant="label2">Total tagihan</Typography>
                  <Box display="flex" gap={1}>
                    <Box
                      sx={{
                        backgroundColor: "#EBECED",
                        alignItems: "center",
                        display: "flex",
                        alignItems: "center",
                        width: "60px",
                        borderRadius: "4px",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="labelMedium2">Rp.</Typography>
                    </Box>
                    <MainController
                      {...getProps("TotalTagihan")}
                      rules={{
                        required: true,
                      }}
                      defaultValue=""
                      render={({ field }) => (
                        <TextFieldv3
                          {...field}
                          name="phone"
                          errors={errors}
                          placeholder="Masukkan total tagihan"
                          onChange={(e) => {
                            e.target.value = FormatNumber(e.target.value);
                            field.onChange(e);
                          }}
                          value={FormatRupiah(field.value)}
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            </Collapse>
          )}
        </TransitionGroup>
      </Box>
    </MainDialog>
  );
};

RedeemSubsidiDialog.propTypes = {
  Controller: PropTypes.any,
  control: PropTypes.any,
  data: PropTypes.object,
  errors: PropTypes.any,
  handleSubmit: PropTypes.any,
  id: PropTypes.any,
  isEdit: PropTypes.bool,
  isValid: PropTypes.any,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  setRes: PropTypes.func,
  watch: PropTypes.any,
  register: PropTypes.any,
  unregister: PropTypes.any,
  setValue: PropTypes.any,
  getValues: PropTypes.any,
};

RedeemSubsidiDialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  onSubmit: () => {},
  open: false,
  refresh: () => {},
  data: null,
  id: null,
  setRes: null,
  handleSubmit: null,
  isValid: null,
  errors: null,
  control: null,
  Controller: null,
  watch: null,
  register: null,
  unregister: null,
  setValue: null,
  getValues: null,
};
export default RedeemSubsidiDialog;
