import React from "react";

const FilterIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2H20C20.2652 2 20.5196 2.11091 20.7071 2.30831C20.8946 2.50572 21 2.77346 21 3.05264V5.77898C21 6.32951 20.787 6.87056 20.417 7.26004L15 12.9622V18.8422C15 19.2412 14.786 19.6054 14.447 19.7843L10.447 21.8896C10.2945 21.9695 10.1251 22.0071 9.9549 21.9989C9.78471 21.9906 9.61934 21.9368 9.47444 21.8425C9.32955 21.748 9.20992 21.6164 9.1269 21.4598C9.04387 21.3032 9.0002 21.1269 9 20.9475V12.9622L3.583 7.26004C3.213 6.87056 3 6.32951 3 5.77898V3.05264C3 2.77346 3.10536 2.50572 3.29289 2.30831C3.48043 2.11091 3.73478 2 4 2ZM13.0759 11.9445C13.1262 11.818 13.2 11.7031 13.293 11.6064L19.003 5.65102L19.001 4H5V5.65832L10.707 11.6064C10.8 11.7031 10.8738 11.818 10.9241 11.9445C10.9744 12.0711 11.0002 12.2068 11 12.3438V19L13 17.957V12.3438C12.9998 12.2068 13.0256 12.0711 13.0759 11.9445Z"
        fill="currentColor"
      />
      <mask
        id="mask0_0_12785"
        maskUnits="userSpaceOnUse"
        x="3"
        y="2"
        width="18"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 2H20C20.2652 2 20.5196 2.11091 20.7071 2.30831C20.8946 2.50572 21 2.77346 21 3.05264V5.77898C21 6.32951 20.787 6.87056 20.417 7.26004L15 12.9622V18.8422C15 19.2412 14.786 19.6054 14.447 19.7843L10.447 21.8896C10.2945 21.9695 10.1251 22.0071 9.9549 21.9989C9.78471 21.9906 9.61934 21.9368 9.47444 21.8425C9.32955 21.748 9.20992 21.6164 9.1269 21.4598C9.04387 21.3032 9.0002 21.1269 9 20.9475V12.9622L3.583 7.26004C3.213 6.87056 3 6.32951 3 5.77898V3.05264C3 2.77346 3.10536 2.50572 3.29289 2.30831C3.48043 2.11091 3.73478 2 4 2ZM13.0759 11.9445C13.1262 11.818 13.2 11.7031 13.293 11.6064L19.003 5.65102L19.001 4H5V5.65832L10.707 11.6064C10.8 11.7031 10.8738 11.818 10.9241 11.9445C10.9744 12.0711 11.0002 12.2068 11 12.3438V19L13 17.957V12.3438C12.9998 12.2068 13.0256 12.0711 13.0759 11.9445Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_0_12785)">
        <rect width="24" height="24" fill="currentColor" />
      </g>
    </svg>
  );
};

export default FilterIcon;
