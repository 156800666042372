import React from "react";

const VisibilityOff = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1719_9925)">
        <path
          d="M10 5.00008C13.1583 5.00008 15.975 6.77508 17.35 9.58341C16.8583 10.6001 16.1667 11.4751 15.3417 12.1834L16.5167 13.3584C17.675 12.3334 18.5917 11.0501 19.1667 9.58341C17.725 5.92508 14.1667 3.33341 10 3.33341C8.94168 3.33341 7.92501 3.50008 6.96668 3.80841L8.34168 5.18341C8.88334 5.07508 9.43334 5.00008 10 5.00008ZM9.10834 5.95008L10.8333 7.67508C11.3083 7.88341 11.6917 8.26675 11.9 8.74175L13.625 10.4667C13.6917 10.1834 13.7417 9.88341 13.7417 9.57508C13.75 7.50841 12.0667 5.83341 10 5.83341C9.69168 5.83341 9.40001 5.87508 9.10834 5.95008ZM1.67501 3.22508L3.90834 5.45842C2.55001 6.52508 1.47501 7.94175 0.833344 9.58341C2.27501 13.2417 5.83334 15.8334 10 15.8334C11.2667 15.8334 12.4833 15.5917 13.6 15.1501L16.45 18.0001L17.625 16.8251L2.85001 2.04175L1.67501 3.22508ZM7.92501 9.47508L10.1 11.6501C10.0667 11.6584 10.0333 11.6667 10 11.6667C8.85001 11.6667 7.91668 10.7334 7.91668 9.58341C7.91668 9.54175 7.92501 9.51675 7.92501 9.47508ZM5.09168 6.64175L6.55001 8.10008C6.35834 8.55841 6.25001 9.05841 6.25001 9.58341C6.25001 11.6501 7.93334 13.3334 10 13.3334C10.525 13.3334 11.025 13.2251 11.475 13.0334L12.2917 13.8501C11.5583 14.0501 10.7917 14.1667 10 14.1667C6.84168 14.1667 4.02501 12.3917 2.65001 9.58341C3.23334 8.39175 4.08334 7.40841 5.09168 6.64175Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1719_9925">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VisibilityOff;
