import React from "react";
import PropTypes from "prop-types";
import StatusChip from "../StatusChip";

const color = [
  {
    label: "Gagal",
    color: "#cc1d15",
    background: "#f5e3e3",
    value: 0,
  },
  {
    label: "Berhasil",
    color: "#129350",
    background: "#e7f4ed",
    value: 1,
  },
];

const find = (value, text) => {
  return color.find((i) => i.value === value)?.[text] || "-";
};

const KartuSubsidiUserStatus = ({ value }) => (
  <StatusChip
    label={find(value, "label")}
    color={find(value, "color")}
    background={find(value, "background")}
  />
);

KartuSubsidiUserStatus.defaultProps = {
  value: 0,
};

KartuSubsidiUserStatus.propTypes = {
  value: PropTypes.any,
};

export default KartuSubsidiUserStatus;
