import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";


const SelectedBox = ({ selected, onChange, children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "10px 20px",
        borderBottom: "1px solid #f7f7f7",
        cursor: "pointer",
        position: "relative",
        ...(selected && {
          "&:after": {
            position: "absolute",
            content: '""',
            left: "1px",
            top: "10%",
            backgroundColor: "#cc1d15",
            width: "3px",
            borderTopRightRadius: "15px",
            borderBottomRightRadius: "15px",
            height: "80%",
          },
          backgroundColor: "#F9E8E7 !important",
        }),
        "&:hover": {
          backgroundColor: "#F8F8F8",
        },
      }}
      onClick={onChange}
    >
      {children}
    </Box>
  );
};

SelectedBox.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.any,
  onChange: PropTypes.func,
};

SelectedBox.defaultProps = {
  children: null,
  selected: null,
  onChange: () => {},
};

export default SelectedBox;
