import PropTypes from "prop-types";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import React from "react";
import Close from "@mui/icons-material/Close";
import { TransitionGroup } from "react-transition-group";

const List = ({ data, listKey, onDelete }) => {
  return (
    <Box display="flex" flexDirection="column">
      <TransitionGroup>
        {data.map((item, i) => (
          <Collapse key={i}>
            <Box
              key={i}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              py={1}
              px={1}
              borderBottom="1px solid #EBECED"
            >
              <Typography variant="label2" fontWeight={500}>
                {item?.[listKey] || "-"}
              </Typography>
              <IconButton
                sx={{
                  color: "#323232",
                  cursor: "pointer",
                  marginTop: "auto",
                  marginBottom: "auto",
                  padding: "4px",
                }}
                onClick={() => onDelete(item)}
              >
                <Close sx={{ fontSize: "20px" }} />
              </IconButton>
            </Box>
          </Collapse>
        ))}
      </TransitionGroup>
    </Box>
  );
};

List.propTypes = {
  data: PropTypes.any,
  listKey: PropTypes.any,
  onDelete: PropTypes.func,
};

List.defaultProps = {
  data: [],
  listKey: null,
  onDelete: () => {},
};

export default List;
