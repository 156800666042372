import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MainDialog from "components/Dialog/MainDialog";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import Validasi from "components/Status/Validasi";
import MainGrid from "components/Grid/MainGrid";
import Copyv2 from "assets/paper/v2/Copyv2";
import moment from "moment";
import MainLoading from "components/Loading/MainLoading";

const DetailSubsidiDialog = ({ data, open, loading, onClose }) => {
  const [clipTxt, setClipTxt] = useState("");

  const RincianKartuSubsidi = [
    {
      label: "Metode Pembayaran",
      value: data?.payment_method,
    },
    {
      label: "No. Kartu Subsidi",
      value: data?.card_no,
    },
    {
      label: "Nama Pemilik Kartu",
      value: data?.customer,
    },
    {
      label: "Total Tagihan",
      value: `Rp${data?.ammount_bill}`,
    },
    {
      label: "Biaya Admin",
      value: `Rp${data?.fee}`,
    },
    {
      label: "Total ",
      value: <b>{`Rp${data?.total}`}</b>,
    },
  ];

  const RincianPenukaran = [
    {
      label: "Status",
      value: (
        <Validasi
          textTrue="Berhasil"
          textFalse="Dibatalkan"
          value={data?.status}
        />
      ),
    },
    {
      label: "Tanggal Transaksi",
      value: moment(data?.date)?.format("DD/MM/YYYY HH:ss"),
    },
    {
      label: "Nomor Transaksi",
      value: (
        <Box display="flex" width="100%" justifyContent="space-between">
          {data?.receipt_extern}
          <Tooltip
            title={clipTxt}
            onMouseEnter={() => setClipTxt("Copy No. Transaksi")}
            placement="bottom"
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setClipTxt("Copied");
                navigator.clipboard.writeText(data?.receipt_extern);
              }}
            >
              <Copyv2 />
            </span>
          </Tooltip>
        </Box>
      ),
    },
    {
      label: "No. Referensi",
      value: (
        <Box display="flex" width="100%" justifyContent="space-between">
          {data?.receipt}
          <Tooltip
            title={clipTxt}
            onMouseEnter={() => setClipTxt("Copy No. Ref")}
            placement="bottom"
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setClipTxt("Copied");
                navigator.clipboard.writeText(data?.receipt);
              }}
            >
              <Copyv2 />
            </span>
          </Tooltip>
        </Box>
      ),
    },
    {
      label: "Nama Merchant",
      value: data?.merchant,
    },
    {
      label: "Nama Petugas",
      value: data?.officer,
    },
  ];

  return (
    <>
      <MainDialog
        open={open}
        onClose={() => {
          onClose();
        }}
        loading={false}
        action={false}
        customWidth="610px"
        closeButton={false}
      >
        <MainLoading loading={loading}>
          <Box display="flex" pb={2} justifyContent="space-between">
            <Typography variant="h2">Rincian Transaksi</Typography>
            <IconButton
              aria-label="close"
              sx={{ padding: 0 }}
              disabled={false}
              onClick={onClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          {RincianPenukaran.map((item, i) => (
            <MainGrid key={i} {...item} labelMd={4} valueMd={8} center />
          ))}
          <Box display="flex" py={2} justifyContent="space-between">
            <Typography variant="h2">Rincian Kartu Subsidi</Typography>
            <IconButton
              aria-label="close"
              sx={{ padding: 0 }}
              disabled={false}
              onClick={onClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          {RincianKartuSubsidi.map((item, i) => (
            <MainGrid
              key={i}
              {...item}
              labelMd={4}
              valueMd={5}
              striped="odd"
              center
            />
          ))}
        </MainLoading>
      </MainDialog>
    </>
  );
};

DetailSubsidiDialog.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.object,
  open: PropTypes.bool,
  loading: PropTypes.bool,
};

DetailSubsidiDialog.defaultProps = {
  onClose: () => {},
  open: false,
  data: {},
  loading: false,
};
export default DetailSubsidiDialog;
