import PropTypes from "prop-types";
import { ExpandMore } from "@mui/icons-material";
import { Autocomplete, Grow, Popper } from "@mui/material";
import React, { forwardRef } from "react";
import TextFieldv3 from "components/TextField/TextFieldv3";

const PopperCom = ({ children, ...others }) => {
  return (
    <Popper
      {...others}
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 10],
          },
        },
      ]}
      transition
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} timeout={300}>
          {children}
        </Grow>
      )}
    </Popper>
  );
};

PopperCom.propTypes = {
  children: PropTypes.any,
  io: PropTypes.any,
};

PopperCom.defaultProps = {
  children: null,
  io: null,
};

const Autocompletev3 = forwardRef(
  ({ error, placeholder, InputProps, ...props }, ref) => {
    return (
      <Autocomplete
        {...props}
        ref={ref}
        PopperComponent={PopperCom}
        renderInput={(p) => (
          <TextFieldv3
            {...p}
            error={error}
            placeholder={placeholder}
            InputProps={{
              ...p.InputProps,
              ...InputProps,
              endAdornment: (
                <>
                  <ExpandMore
                    onMouseDown={p.inputProps.onMouseDown}
                    sx={{
                      position: "absolute",
                      right: "13px",
                      color: "#4E4751",
                    }}
                  />
                </>
              ),
            }}
          />
        )}
      />
    );
  }
);

Autocompletev3.propTypes = {
  InputProps: PropTypes.object,
  error: PropTypes.bool,
  placeholder: PropTypes.string,
  typefield: PropTypes.number,
};

Autocompletev3.defaultProps = {
  placeholder: "-",
  typefield: 0,
  error: false,
  InputProps: null,
};

Autocompletev3.displayName = "mac";
export default Autocompletev3;
