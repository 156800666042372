import PropTypes from "prop-types";
import { Box, Typography, styled } from "@mui/material";
import React from "react";
import { Close, Check } from "@mui/icons-material";

const StyledBox = styled(Box)(({ value }) => ({
  backgroundColor: parseInt(value) == 1 ? "#129350" : "#cc1d15",
  borderRadius: "50px",
  height: "20px",
  width: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#fff",
}));

const Validasi = ({ value, noText, textTrue, textFalse, ...others }) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <StyledBox value={value} {...others}>
        {value == 1 ? (
          <Check
            sx={{
              fontSize: "14px",
            }}
          />
        ) : (
          <Close
            sx={{
              fontSize: "14px",
            }}
          />
        )}
      </StyledBox>
      {!noText && (
        <Typography variant="textCommon">
          {value == 1 ? textTrue : textFalse}
        </Typography>
      )}
    </Box>
  );
};

Validasi.propTypes = {
  noText: PropTypes.any,
  value: PropTypes.any,
  textTrue: PropTypes.string,
  textFalse: PropTypes.string,
};

Validasi.defaultProps = {
  noText: false,
  value: null,
  textTrue: "Ya",
  textFalse: "Tidak",
};

export default Validasi;
