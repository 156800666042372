import React from "react";
import Providers from "components/RouteRoleProviders";
import DailyReport from "pages/Report/Daily";
import StockReport from "pages/Report/Stock";

const reportRoutes = [
  {
    path: "/report/daily-report",
    element: (
      <Providers permission="Web:Pengguna:Get">
        <DailyReport />
      </Providers>
    ),
  },
  {
    path: "/report/stock-report",
    element: (
      <Providers permission="Web:Pengguna:Get">
        <StockReport />
      </Providers>
    ),
  },
];

export default reportRoutes;
