import React from "react";

const EyeIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2283 9.58689C17.695 8.66189 14.7617 4.02023 9.77833 4.17023C5.17 4.28689 2.50333 8.33689 1.77833 9.58689C1.70519 9.71357 1.66669 9.85728 1.66669 10.0036C1.66669 10.1498 1.70519 10.2935 1.77833 10.4202C2.30333 11.3286 5.11167 15.8369 10.02 15.8369H10.2283C14.8367 15.7202 17.5117 11.6702 18.2283 10.4202C18.3015 10.2935 18.34 10.1498 18.34 10.0036C18.34 9.85728 18.3015 9.71357 18.2283 9.58689ZM10.0033 12.9202C9.42649 12.9202 8.86256 12.7491 8.38292 12.4286C7.90328 12.1082 7.52944 11.6527 7.30868 11.1197C7.08793 10.5868 7.03017 10.0003 7.14271 9.43455C7.25525 8.86877 7.53303 8.34907 7.94093 7.94117C8.34884 7.53327 8.86854 7.25547 9.43433 7.14293C10.0001 7.0304 10.5866 7.08816 11.1195 7.30891C11.6524 7.52967 12.108 7.90351 12.4285 8.38315C12.7489 8.86279 12.92 9.4267 12.92 10.0036C12.92 10.7771 12.6127 11.519 12.0657 12.066C11.5187 12.6129 10.7769 12.9202 10.0033 12.9202ZM11.2533 10.0036C11.2533 10.6939 10.6937 11.2536 10.0033 11.2536C9.31299 11.2536 8.75333 10.6939 8.75333 10.0036C8.75333 9.31321 9.31299 8.75357 10.0033 8.75357C10.6937 8.75357 11.2533 9.31321 11.2533 10.0036Z"
        fill="black"
      />
      <mask
        id="mask0_1351_15582"
        maskUnits="userSpaceOnUse"
        x="1"
        y="4"
        width="18"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2283 9.58689C17.695 8.66189 14.7617 4.02023 9.77833 4.17023C5.17 4.28689 2.50333 8.33689 1.77833 9.58689C1.70519 9.71357 1.66669 9.85728 1.66669 10.0036C1.66669 10.1498 1.70519 10.2935 1.77833 10.4202C2.30333 11.3286 5.11167 15.8369 10.02 15.8369H10.2283C14.8367 15.7202 17.5117 11.6702 18.2283 10.4202C18.3015 10.2935 18.34 10.1498 18.34 10.0036C18.34 9.85728 18.3015 9.71357 18.2283 9.58689ZM10.0033 12.9202C9.42649 12.9202 8.86256 12.7491 8.38292 12.4286C7.90328 12.1082 7.52944 11.6527 7.30868 11.1197C7.08793 10.5868 7.03017 10.0003 7.14271 9.43455C7.25525 8.86877 7.53303 8.34907 7.94093 7.94117C8.34884 7.53327 8.86854 7.25547 9.43433 7.14293C10.0001 7.0304 10.5866 7.08816 11.1195 7.30891C11.6524 7.52967 12.108 7.90351 12.4285 8.38315C12.7489 8.86279 12.92 9.4267 12.92 10.0036C12.92 10.7771 12.6127 11.519 12.0657 12.066C11.5187 12.6129 10.7769 12.9202 10.0033 12.9202ZM11.2533 10.0036C11.2533 10.6939 10.6937 11.2536 10.0033 11.2536C9.31299 11.2536 8.75333 10.6939 8.75333 10.0036C8.75333 9.31321 9.31299 8.75357 10.0033 8.75357C10.6937 8.75357 11.2533 9.31321 11.2533 10.0036Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1351_15582)">
        <rect width="20" height="20" fill="currentColor" />
      </g>
    </svg>
  );
};

export default EyeIcon;
