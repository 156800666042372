import PropTypes from "prop-types";
import React from "react";
import { Box } from "@mui/material";
import moment from "moment";
import EastIcon from "@mui/icons-material/East";

const PeriodeFormat = ({ StartDate, EndDate, ...others }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "0.5rem",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: "30px",
        maxWidth: "300px",
        ...others.sx,
      }}
    >
      {StartDate
        ? moment(StartDate || new Date()).format("DD / MM / YYYY")
        : "-"}
      <EastIcon sx={{ fontSize: "14px" }} />
      {EndDate ? moment(EndDate || new Date()).format("DD / MM / YYYY") : "-"}
    </Box>
  );
};

PeriodeFormat.propTypes = {
  EndDate: PropTypes.string,
  StartDate: PropTypes.string,
};

PeriodeFormat.defaultProps = {
  EndDate: "",
  StartDate: "",
};

export default PeriodeFormat;
