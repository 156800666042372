import React from "react";

const DoneIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1950_71265)">
        <path
          d="M9.43332 17C9.42263 17 9.41266 17 9.40197 16.9993C9.07847 16.9914 8.77208 16.8601 8.55546 16.636L4.30297 12.2351C3.86689 11.7836 3.90608 11.0895 4.39133 10.6837C4.87657 10.278 5.62261 10.3151 6.05869 10.7659L9.47893 14.3056L16.9842 7.32171C17.4445 6.89276 18.1934 6.89276 18.6537 7.32171C19.1154 7.75133 19.1154 8.4468 18.6537 8.87576L10.2684 16.6778C10.0468 16.8846 9.74613 17 9.43332 17Z"
          fill="#CC1D15"
        />
      </g>
      <defs>
        <clipPath id="clip0_1950_71265">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DoneIcon;
