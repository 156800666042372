import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const select = (state) => state.bansosProgramVoucher || initialState;

export const List = createSelector(select, (p) => p.list);
export const NamaProgram = createSelector(select, (p) => p.namaProgram);
export const Loading = createSelector(select, (p) => p.loading);
export const Params = createSelector(select, (p) => p.params);
