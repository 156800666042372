import { Box, Button, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const StepView5 = ({ handleBack }) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box
        sx={{
          my: 3,
        }}
      >
        <Typography variant="cardText2" component="p">
          Syarat dan ketentuan sebagaimana disebutkan di bawah ini merupakan
          Perjanjian antara pengguna layanan situs www.qoin.id (“Merchant”) dan
          PT Digital Pemerintahan Indonesia dengan ketentuan-ketentuan
          sebagaimana tertera di bawah ini yang dituangkan dalam bentuk Kontrak
          Elektronik (“Perjanjian”).
        </Typography>
        <Typography
          variant="cardText2"
          component="p"
          sx={{
            my: 1,
          }}
        >
          Merchant wajib membaca, memahami, menerima, dan menyetujui semua
          persyaratan dan ketentuan di bawah ini sebelum menggunakan layanan
          yang akan diberikan oleh PT Digital Pemerintahan Indonesia.
          Persetujuan yang diberikan oleh Para Pihak dalam keadaan sadar dan
          tanpa ada paksaan dari Pihak manapun juga.
        </Typography>
        <Typography variant="cardText2" component="p">
          Dengan menggunakan layanan yang diberikan oleh PT Digital Pemerintahan
          Indonesia sebagaimana disebutkan pada Perjanjian ini, maka Merchant
          dianggap telah menyetujui ketentuan di bawah ini dan karenanya sepakat
          untuk memenuhi dan menaati seluruh ketentuaan yang tercantum di bawah
          ini berdasarkan itikad baik. Data dan informasi yang Merchant isi pada
          Form Registrasi Merchant merupakan satu kesatuan dan tidak terpisahkan
          dari Perjanjian ini. Dalam hal Merchant tidak dapat menerima dan
          menyetujui ketentuan di bawah ini, maka Merchant dipersilahkan
          meninggalkan situs ini.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          pt: 2,
          gap: 2,
        }}
      >
        <Button
          color="inherit"
          onClick={handleBack}
          variant="gray"
          sx={{ mr: 1 }}
        >
          Kembali
        </Button>

        <Button type="submit" variant="primary" sx={{ width: "200px" }}>
          {"Saya Setuju"}
        </Button>
      </Box>
    </Box>
  );
};

StepView5.propTypes = {
  handleBack: PropTypes.func,
};

StepView5.defaultProps = {
  handleBack: null,
};

export default StepView5;
