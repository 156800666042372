import { Box, Typography } from "@mui/material";
import React from "react";
import PageNotFoundSvg from "assets/layout/pageNotFoundSvg";
import PropTypes from "prop-types";
const PageNotFound = ({ type }) => {
  return (
    <Box
      sx={{
        height: type == 0 ? "100vh" : "calc(100vh - 190px)",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          margin: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <PageNotFoundSvg />
        <Typography
          variant="loginTitle"
          sx={{
            margin: "5px",
            fontSize: "60px",
          }}
        >
          404 Not Found
        </Typography>
      </Box>
    </Box>
  );
};

PageNotFound.propTypes = {
  type: PropTypes.number,
};

PageNotFound.defaultProps = {
  type: 0,
};
export default PageNotFound;
