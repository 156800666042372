import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React from "react";
import { Box, Button, DialogTitle, Divider, IconButton } from "@mui/material";
import useFetchData from "hook/useFetchData";
import Webcam from "react-webcam";
import { updateFoto } from "redux/redeemSubsidi/action";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const FotoWajahDialog = ({ open, onClose, getValues, setValue }) => {
  const action = useFetchData({
    action: updateFoto,
    snackbar: false,
    onSuccess: (data) => {
      setValue("ManualValidasi.FotoWajah", data?.foto);
      onClose();
    },
  });

  const base64toFile = (dataurl) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], "foto.png", { type: mime });
  };

  const videoConstraints = {
    facingMode: "user",
    width: 900,
  };

  return (
    <>
      <MainDialog
        open={open}
        onClose={() => {
          onClose();
        }}
        loading={false}
        onReset={() => {
          reset();
        }}
        action={false}
        customWidth="900px"
        sx={{
          padding: "0px !important",
          paddingBottom: "10px !important",
        }}
        closeButton={false}
      >
        <DialogTitle sx={{ m: 0, p: 2, display: "flex" }} variant="h2">
          <IconButton onClick={() => onClose()}>
            <ChevronLeftIcon />
          </IconButton>
          <Box
            component="div"
            sx={{
              margin: "auto",
            }}
          >
            Foto Wajah
          </Box>
        </DialogTitle>
        <Divider />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: "100%",
            minHeight: "500px",
            backgroundColor: "#ebeced",
            position: "relative",
            mt: 2,
          }}
        >
          <Webcam
            audio={false}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          >
            {({ getScreenshot }) => (
              <Button
                sx={{
                  backgroundColor: "#FFFFFF",
                  maxWidth: "50px",
                  minWidth: "50px",
                  height: "50px",
                  margin: "auto",
                  borderRadius: "100%",
                  outline: "3px solid #fff",
                  outlineOffset: "5px",
                  position: "absolute",
                  bottom: "50px",
                  left: "0%",
                  right: "0%",
                  "&:hover": {
                    backgroundColor: "#f7f7f7",
                  },
                }}
                onClick={() => {
                  const imageSrc = getScreenshot();
                  const file = base64toFile(imageSrc);
                  const formData = new FormData();
                  formData.append("foto", file);
                  action.fetch(getValues("ManualValidasi.Id"), formData);
                }}
              />
            )}
          </Webcam>
        </Box>
      </MainDialog>
    </>
  );
};

FotoWajahDialog.propTypes = {
  Controller: PropTypes.any,
  control: PropTypes.any,
  data: PropTypes.object,
  errors: PropTypes.any,
  handleSubmit: PropTypes.any,
  id: PropTypes.any,
  isEdit: PropTypes.bool,
  isValid: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  setRes: PropTypes.func,
  watch: PropTypes.any,
  register: PropTypes.any,
  setValue: PropTypes.any,
  getValues: PropTypes.any,
};

FotoWajahDialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  data: null,
  id: null,
  setRes: null,
  handleSubmit: null,
  isValid: null,
  errors: null,
  control: null,
  Controller: null,
  watch: null,
  register: null,
  setValue: null,
  getValues: null,
};
export default FotoWajahDialog;
