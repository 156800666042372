import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const selectPenerbit = (state) => state.penerbit || initialState;

export const penerbitList = createSelector(selectPenerbit, (p) => p.list);
export const penerbitLoading = createSelector(selectPenerbit, (p) => p.loading);
export const penerbitParams = createSelector(selectPenerbit, (p) => p.params);
export const penerbitTotalItems = createSelector(selectPenerbit, (p) => p.totalItems);
export const penerbitCurrentPage = createSelector(selectPenerbit, (p) => p.currentPage);
export const penerbitFilterName = createSelector(selectPenerbit, (p) => p.filterName);


