import { ExpandMore } from "@mui/icons-material";
import { Autocomplete, TextField } from "@mui/material";
import MainDatePicker from "components/Picker/DatePicker";
import DateRange2 from "components/Picker/DateRange2";
import SelectChips from "components/Status/SelectChips";
import moment from "moment";
import React from "react";

const DynamicForm = ({ filterData, grid: Grid, data, setData, md }) => {
  const menu = filterData.map((f, i) => {
    switch (f.type) {
      case "switch":
        return (
          <Grid label={f.label} key={i} md={12}>
            <SelectChips
              onClick={(item) => {
                setData({
                  ...data,
                  action: false,
                  list: {
                    [f.key]: item.value,
                  },
                });
              }}
              data={f?.options}
              isactive={(item) => {
                return data?.list?.[f.key] === item.value ? 1 : 0;
              }}
            />
          </Grid>
        );
      case "datepicker":
        return (
          <Grid label={f.label} key={i} md={12}>
            <MainDatePicker
              placeholder={f.placeholder}
              value={
                data.list?.[f.key]
                  ? moment(data.list?.[f.key]).format("YYYY-MM-DD HH:mm:ss")
                  : null
              }
              onChange={(newValue) => {
                setData({
                  ...data,
                  action: false,
                  list: {
                    [f.key]: moment(newValue).format("YYYY-MM-DD"),
                  },
                });
              }}
            />
          </Grid>
        );
      case "daterange":
        return (
          <Grid label={f.label} key={i} md={12}>
            <DateRange2
              startDate={data.list?.[f.startDate] || null}
              endDate={data.list?.[f.endDate]}
              onChange={(value) => {
                setData({
                  ...data,
                  action: false,
                  list: {
                    [f.startDate]: value[0],
                    [f.endDate]: value[1],
                  },
                });
              }}
            />
          </Grid>
        );
      case "select":
        return (
          <Grid label={f.label} key={i} md={12}>
            <Autocomplete
              options={f.options}
              value={
                f?.options?.find(
                  (option) => option.value == data.list?.[f.key] || ""
                ) || null
              }
              onChange={(_, nv) => {
                if (nv === null) {
                  setData({
                    ...data,
                    action: false,
                    list: {
                      [f.key]: "",
                    },
                  });
                } else {
                  setData({
                    ...data,
                    action: false,
                    list: {
                      [f.key]: nv.value,
                    },
                  });
                }
              }}
              renderInput={(p) => (
                <TextField
                  {...p}
                  InputProps={{
                    ...p.InputProps,
                    endAdornment: (
                      <>
                        <ExpandMore
                          onMouseDown={p.inputProps.onMouseDown}
                          sx={{ position: "absolute", right: "13px" }}
                        />
                      </>
                    ),
                  }}
                  placeholder={f.placeholder}
                />
              )}
            />
          </Grid>
        );
      default:
        break;
    }
  });
  return menu;
};

export default DynamicForm;
