import PropTypes from "prop-types";
import { Box, Paper, Typography } from "@mui/material";
import React, { useMemo } from "react";

const CardWidget = ({ ilu: Ilu, title, desc, onChange, isActive }) => {
  const Active = useMemo(() => {
    if (isActive) {
      return {
        outlineColor: "#CC1D15",
        outlineWidth: "2px",
      };
    } else {
      return false;
    }
  }, [isActive]);
  return (
    <Paper
      sx={{
        py: 2,
        px: 2,
        outline: "1px solid #E2E2E2",
        boxShadow: "0px 4px 5px -2px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        // margin: "auto",
        minWidth: "240px",
        maxWidth: "240px",
        minHeight: "232px",
        margin: "auto",
        cursor: "pointer",
        transition: "outline-color 0.2s ease-out",
        "&:hover": {
          outlineColor: !Active && "#878889",
          outlineWidth: !Active && "2px",
        },
        ...Active,
      }}
      onClick={onChange}
    >
      <Box display="flex" alignItems="center" flexDirection="column" gap={2}>
        <Box height="116px" display="flex" alignItems="center">
          <Ilu />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" gap={1}>
          <Typography variant="label3">{title}</Typography>
          <Typography variant="desc5" textAlign="center">
            {desc}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

CardWidget.propTypes = {
  desc: PropTypes.any,
  ilu: PropTypes.any,
  isActive: PropTypes.any,
  title: PropTypes.any,
  onChange: PropTypes.func,
};

CardWidget.defaultProps = {
  desc: "",
  ilu: null,
  isActive: false,
  title: "",
  onChange: null,
};
export default CardWidget;
