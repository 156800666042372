import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const select = (state) => state.detailPenerimaBantuan || initialState;

export const subsidi = createSelector(select, (p) => p.subsidi);
export const voucher = createSelector(select, (p) => p.voucher);
export const load = createSelector(select, (p) => p.loading);
export const params = createSelector(select, (p) => p.params);
