import PropTypes from "prop-types";
import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import CardProduct from "../components/CardProduct";
import PageTitle from "components/Page/PageTitle";
import CardProduct2 from "../components/CardProduct2";
import { ProdukList } from "../variable";
import MainController from "components/Controller/MainController";

const StepView1 = ({
  page,
  controller: Controller,
  control,
  errors,
  clearErrors,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={4} mt={1}>
      <PageTitle
        {...page}
        // variantTitle="title4"
        justifyContent="center"
        backBtn={false}
      />
      <Grid
        container
        spacing={3}
        sx={{
          pt: 1,
        }}
      >
        {ProdukList.map((item) => (
          <Grid key={item.id} item xs={12} md={4}>
            <MainController
              controller={Controller}
              name="BizProduct"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              render={({ field }) => (
                <CardProduct2 {...item} onChange={(id) => field.onChange(id)} />
              )}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

StepView1.propTypes = {
  clearErrors: PropTypes.any,
  control: PropTypes.any,
  controller: PropTypes.any,
  errors: PropTypes.any,
  handleBack: PropTypes.func,
  setValue: PropTypes.any,
  page: PropTypes.object,
};

StepView1.defaultProps = {
  handleBack: null,
  setValue: null,
  page: null,
  clearErrors: null,
  control: null,
  controller: null,
  errors: null,
};

export default StepView1;
