import PropTypes from "prop-types";
import { Avatar, Paper, Box, Typography, Divider, Button } from "@mui/material";
import React from "react";
import ProfilePicture from "assets/pengguna/profile-picture.png";
import RoleUser from "components/Status/RoleUser";
import PencilIconv2 from "assets/paper/v2/PencilIconv2";
import moment from "moment";

const Profile = ({ user, onClick }) => {
  return (
    <Paper
      sx={{
        borderRadius: "8px",
      }}
    >
      <Box
        p={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar
          alt="Remy Sharp"
          src={user?.Photo}
          sx={{ width: 180, height: 180, my: 2 }}
        />
        <Typography variant="title2">{user.Fullname}</Typography>
        <Typography variant="desc2" my={0.5}>
          <RoleUser value={user.TypeRole} string={user.RoleName} />
        </Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            width: "100%",
            mt: 2,
            alignItems: "center",
          }}
        >
          <Typography variant="label2" fontWeight="bold">
            Detail
          </Typography>
          <Button
            variant="gray"
            sx={{
              height: "34px",
              color: "#4C4D4F",
            }}
            onClick={onClick}
          >
            <PencilIconv2 />
            Ubah
          </Button>
        </Box>
      </Box>
      <Divider sx={{ borderColor: "#C8C8C8" }} />
      <Box display="flex" flexDirection="column" gap={1.5} p={2}>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="desc2">Email</Typography>
          <Typography variant="text2">{user.Email || "-"}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="desc2">No. Hp</Typography>
          <Typography variant="text2">{user.Phone || "-"}</Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={0.5}>
          <Typography variant="desc2">Tgl. Bergabung</Typography>
          <Typography variant="text2">
            {moment(user.RegisteredDate, "DD/MM/YYYY").isValid()
              ? moment(user.RegisteredDate, "DD/MM/YYYY").format(
                  "DD / MM / YYYY"
                )
              : "-"}
          </Typography>
        </Box>
        {user.hasOwnProperty("StartDate") && (
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="desc2">Tgl. Tugas</Typography>

            <Typography variant="text2">
              {moment(user.StartDate).isValid()
                ? moment(user.StartDate).format("DD / MM / YYYY")
                : "-"}{" "}
              {"->"}{" "}
              {moment(user.EndDate).isValid()
                ? moment(user.EndDate).format("DD / MM / YYYY")
                : "-"}{" "}
            </Typography>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

Profile.propTypes = {
  user: PropTypes.shape({
    Email: PropTypes.string,
    Fullname: PropTypes.any,
    Phone: PropTypes.string,
    RegisteredDate: PropTypes.any,
    RoleName: PropTypes.string,
    TypeRole: PropTypes.number,
    Photo: PropTypes.string,
    StartDate: PropTypes.string,
    EndDate: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

Profile.defaultProps = {
  user: {},
  onClick: () => {},
};

export default Profile;
