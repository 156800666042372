import React from "react";

const Logo = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.625 24.3594C44.625 15.0138 37.0839 7.4375 27.7813 7.4375C18.4788 7.4375 10.9375 15.0138 10.9375 24.3594C10.9375 31.0328 14.7838 36.8017 20.3697 39.5566L20.3707 39.5544C20.7287 39.7443 21.1361 39.8529 21.5693 39.8529C22.9894 39.8529 24.1408 38.6962 24.1408 37.2695C24.1408 36.2434 23.5438 35.3594 22.681 34.9428L22.6837 34.9374C18.7781 33.0343 16.0851 29.013 16.0851 24.3594C16.0851 17.8699 21.3217 12.609 27.7813 12.609C34.241 12.609 39.4774 17.8699 39.4774 24.3594C39.4774 28.2569 37.5866 31.709 34.6783 33.8467C32.4259 35.4767 30.2584 33.4225 30.2584 31.1686V24.425H30.2551V22.6758C30.2551 22.1625 29.8408 21.7462 29.3298 21.7462H26.0099C25.4989 21.7462 25.0846 22.1625 25.0846 22.6758V24.425V24.5967V32.3762H25.0849C25.0896 34.3478 27.0097 38.3883 30.6894 38.8995C35.5699 39.5409 37.914 37.8889 38.551 37.3676L38.5516 37.3684C38.5573 37.3637 38.5625 37.3584 38.5681 37.3538C38.5896 37.3361 38.6094 37.3195 38.6264 37.3049C38.6264 37.3049 38.6252 37.3038 38.625 37.3036C42.2923 34.1995 44.625 29.5543 44.625 24.3594Z"
        fill="#D8232A"
      />
      <path
        d="M27.6662 20.6161C29.0962 20.6161 30.2553 19.4515 30.2553 18.0148C30.2553 16.5782 29.0962 15.4136 27.6662 15.4136C26.2362 15.4136 25.0771 16.5782 25.0771 18.0148C25.0771 19.4515 26.2362 20.6161 27.6662 20.6161Z"
        fill="#AB2430"
      />
      <mask
        id="mask0_740_3648"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="10"
        y="7"
        width="35"
        height="33"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9375 24.3592C10.9375 31.0326 14.7836 36.8015 20.3695 39.5564L20.3706 39.5544C20.7287 39.7443 21.1361 39.8529 21.5691 39.8529C22.9894 39.8529 24.1406 38.696 24.1406 37.2693C24.1406 36.2432 23.5436 35.3594 22.6808 34.9428L22.6835 34.9374C18.7779 33.0341 16.0851 29.0129 16.0851 24.3592C16.0851 17.8698 21.3215 12.6088 27.7812 12.6088C34.2406 12.6088 39.4774 17.8698 39.4774 24.3592C39.4774 28.2569 37.5866 31.709 34.6783 33.8465C32.4257 35.4765 30.258 33.4223 30.258 31.1686V24.425H30.255V22.6756C30.255 22.1623 29.8406 21.746 29.3296 21.746H26.0097C25.4987 21.746 25.0844 22.1623 25.0844 22.6756V24.425V24.5967V32.3762H25.0847C25.0894 34.3476 27.0095 38.3883 30.6892 38.8995C35.5699 39.5407 37.9139 37.8887 38.5508 37.3676L38.5514 37.3682C38.5571 37.3635 38.5623 37.3583 38.5679 37.3536C38.5894 37.3361 38.6092 37.3195 38.6262 37.3047C38.6262 37.3047 38.625 37.3036 38.6248 37.3034C42.2921 34.1993 44.6248 29.5541 44.6248 24.3592C44.6248 15.0136 37.0837 7.4375 27.7812 7.4375C18.4784 7.4375 10.9375 15.0136 10.9375 24.3592Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_740_3648)">
        <path
          d="M30.2548 20.1558C30.2548 24.949 30.258 30.145 30.258 31.1688C30.258 32.6388 32.1917 40.0418 39.8216 37.1014C35.4318 44.0319 27.3836 39.6242 26.1294 37.7854C24.8752 35.999 23.2553 25.9933 24.2481 21.6938C25.8682 21.0112 30.2548 20.1558 30.2548 20.1558Z"
          fill="#AB2430"
        />
      </g>
      <path
        d="M14.0498 48.9997H15.4426V41.2061H14.0498V48.9997Z"
        fill="#D8232A"
      />
      <path
        d="M23.637 44.3354C23.5942 44.078 23.5133 43.8228 23.4007 43.5873C23.1957 43.1142 22.9188 42.7006 22.5707 42.3512C22.3906 42.1815 22.1998 42.0231 22.003 41.8815C21.7964 41.7324 21.5686 41.6092 21.333 41.5166C20.8393 41.3105 20.3375 41.2061 19.8407 41.2061C19.335 41.2061 18.8282 41.3105 18.3414 41.5136C18.0991 41.6088 17.8712 41.7324 17.6639 41.8815C17.5549 41.9602 17.4475 42.0437 17.3423 42.1324L17.351 41.2061H15.9551V48.9997H17.3479L17.3475 45.049C17.3556 44.8884 17.377 44.7301 17.4107 44.5773C17.4436 44.4269 17.4901 44.2777 17.5477 44.1365C17.6804 43.8196 17.8594 43.5506 18.089 43.3279C18.3007 43.106 18.5678 42.9259 18.8874 42.7902C19.1635 42.6676 19.4843 42.6052 19.8407 42.6052C20.1714 42.6052 20.4897 42.6685 20.7865 42.7927C21.0836 42.9177 21.3519 43.0992 21.5835 43.3324C21.6989 43.4487 21.8005 43.5745 21.8865 43.7061C21.9759 43.8432 22.0553 43.9902 22.1199 44.1363C22.2526 44.4547 22.3201 44.7799 22.3201 45.1029V48.9997H23.7127L23.7125 45.0751C23.7023 44.8242 23.677 44.575 23.637 44.3354Z"
        fill="#D8232A"
      />
      <path
        d="M24.1777 48.9997H25.5704V41.2061H24.1777V48.9997Z"
        fill="#D8232A"
      />
      <path
        d="M27.3602 44.1509C27.4269 43.9918 27.5048 43.8463 27.5909 43.7178C27.6782 43.5889 27.7811 43.4643 27.898 43.3468C28.1383 43.1053 28.406 42.9241 28.6981 42.8066C29.0143 42.673 29.338 42.6052 29.6601 42.6052C29.982 42.6052 30.3055 42.673 30.6213 42.8063C30.7809 42.8741 30.9261 42.9522 31.0525 43.0384C31.1811 43.1261 31.3055 43.23 31.422 43.3468L31.6556 43.5817L32.6665 42.5934L32.4141 42.3525C32.0389 41.9935 31.6258 41.7107 31.1768 41.5071C30.6935 41.3072 30.1829 41.2061 29.6601 41.2061C29.1265 41.2061 28.62 41.306 28.1545 41.5029C27.6913 41.6997 27.2737 41.9827 26.9141 42.344C26.5541 42.7056 26.2723 43.1252 26.0768 43.5903C25.8807 44.0569 25.7812 44.5659 25.7812 45.1027V45.8094H32.055C32.0285 45.8956 31.9962 45.9822 31.9577 46.0698C31.843 46.3561 31.6631 46.6228 31.4227 46.8627C31.305 46.9805 31.1809 47.0833 31.0531 47.1695C30.9258 47.2558 30.7811 47.3333 30.6222 47.4C30.3064 47.533 29.9825 47.6004 29.6601 47.6004C29.338 47.6004 29.0143 47.5326 28.6927 47.3968C28.4055 47.281 28.1382 47.1005 27.898 46.8588L27.6611 46.6205L26.6782 47.6086L26.9132 47.8465C27.2728 48.2104 27.6899 48.4965 28.1525 48.6963C28.6177 48.8976 29.1251 48.9997 29.6601 48.9997C30.1829 48.9997 30.6924 48.9009 31.1843 48.7014C31.6238 48.5068 32.0371 48.2279 32.4199 47.8656C32.5958 47.6894 32.7538 47.4944 32.8888 47.2861C33.0215 47.0824 33.14 46.8604 33.243 46.6234C33.4394 46.1575 33.5389 45.6503 33.5389 45.1159V44.4103H27.2694C27.2954 44.3201 27.326 44.233 27.3602 44.1509Z"
        fill="#D8232A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.3806 42.344C40.7307 42.6962 41.0097 43.1125 41.2099 43.582C41.4115 44.0542 41.5135 44.5659 41.5135 45.1027V48.9997H40.1209V48.08C39.9022 48.2654 39.662 48.4287 39.4017 48.5683C38.8679 48.8545 38.2735 48.9997 37.6347 48.9997C37.0997 48.9997 36.5902 48.8971 36.1205 48.6947C35.6528 48.4934 35.2386 48.2131 34.8887 47.8614C34.5383 47.5095 34.2593 47.0932 34.0593 46.6237C33.858 46.1523 33.7559 45.6406 33.7559 45.1027C33.7559 44.5654 33.858 44.0537 34.0593 43.582C34.2595 43.1122 34.5386 42.6959 34.8887 42.344C35.2386 41.9923 35.6528 41.7122 36.1205 41.5107C36.5902 41.3085 37.0997 41.2061 37.6347 41.2061C38.1697 41.2061 38.679 41.3085 39.1485 41.5107C39.6157 41.7116 40.03 41.9919 40.3806 42.344ZM40.0722 45.6424C40.1043 45.4957 40.1209 45.343 40.1209 45.1882V45.1027C40.1209 44.7676 40.0571 44.4355 39.9316 44.1154C39.8688 43.9743 39.7891 43.8277 39.7009 43.6926C39.6191 43.5674 39.523 43.457 39.4157 43.3647L39.4009 43.3521L39.388 43.3378C39.2828 43.224 39.1634 43.1203 39.0324 43.0299C38.8995 42.9383 38.755 42.8571 38.6042 42.7893C38.299 42.6692 37.9684 42.6052 37.6347 42.6052C37.2925 42.6052 36.9627 42.6687 36.6548 42.7942C36.3492 42.9316 36.0853 43.1163 35.8815 43.3376L35.8683 43.3517L35.8535 43.3647C35.7462 43.457 35.6501 43.5674 35.5686 43.6922C35.4794 43.8291 35.3998 43.9756 35.3323 44.1284C35.2124 44.4348 35.1487 44.767 35.1487 45.1027C35.1487 45.4474 35.2119 45.7784 35.3367 46.087C35.474 46.3869 35.6562 46.6559 35.8726 46.873C36.0889 47.0905 36.3566 47.2738 36.6688 47.4175C36.8144 47.4794 36.974 47.5272 37.1334 47.5559C37.2973 47.5855 37.4657 47.6004 37.6347 47.6004C37.8034 47.6004 37.9718 47.5855 38.135 47.5559C38.2952 47.5272 38.4548 47.4794 38.6101 47.4134C38.7569 47.3479 38.9033 47.2678 39.039 47.1786C39.1668 47.0941 39.2844 46.9944 39.3878 46.8822C39.613 46.6559 39.7954 46.3869 39.9388 46.0731C39.992 45.9432 40.0385 45.7939 40.0722 45.6424Z"
        fill="#D8232A"
      />
    </svg>
  );
};

export default Logo;
