import React from "react";
import Providers from "components/RouteRoleProviders";
import VoucherBansosDetail from "pages/CSR/ProgramBantuan/VoucherBansos/Voucher";
import PenerimaVoucherBansos from "pages/CSR/ProgramBantuan/VoucherBansos/Penerima";
import VoucherBansos from "pages/CSR/ProgramBantuan/VoucherBansos";

const voucherBansosRoutes = [
  {
    path: "/csr-bansos/program-bantuan/voucer-bansos/voucher/:id",
    element: (
      <Providers permission="Web:CSRBansos:ProgramBantuan:VoucerBansos:Get">
        <VoucherBansosDetail />
      </Providers>
    ),
  },
  {
    path: "/csr-bansos/program-bantuan/voucer-bansos/penerima/:id",
    element: (
      <Providers permission="Web:CSRBansos:ProgramBantuan:VoucerBansos:Get">
        <PenerimaVoucherBansos />
      </Providers>
    ),
  },
  {
    path: "/csr-bansos/program-bantuan/voucer-bansos",
    element: (
      <Providers permission="Web:CSRBansos:ProgramBantuan:VoucerBansos:Get">
        <VoucherBansos />
      </Providers>
    ),
  },
];

export default voucherBansosRoutes;
