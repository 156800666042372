import React from "react";

const Copyv2 = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2802_12891"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM10.0335 15.9995C10.0415 16 10.0489 16 10.0569 16C10.2904 16 10.515 15.9077 10.6804 15.7422L16.9414 9.5006C17.2862 9.15744 17.2862 8.60106 16.9414 8.25737C16.5977 7.91421 16.0386 7.91421 15.6949 8.25737L10.0909 13.8445L7.53716 11.0127C7.21155 10.6521 6.65451 10.6224 6.29219 10.947C5.92988 11.2716 5.90061 11.8269 6.22622 12.1881L9.40141 15.7088C9.56315 15.8881 9.79193 15.9931 10.0335 15.9995Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2802_12891)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5526 3H6.07895C5.21053 3 4.5 3.73636 4.5 4.63636V16.0909H6.07895V4.63636H15.5526V3ZM17.9211 6.27273H9.23684C8.36842 6.27273 7.65789 7.00909 7.65789 7.90909V19.3636C7.65789 20.2636 8.36842 21 9.23684 21H17.9211C18.7895 21 19.5 20.2636 19.5 19.3636V7.90909C19.5 7.00909 18.7895 6.27273 17.9211 6.27273ZM9.23684 19.3636H17.9211V7.90909H9.23684V19.3636Z"
          fill="black"
        />
        <mask
          id="mask1_2802_12891"
          maskUnits="userSpaceOnUse"
          x="4"
          y="3"
          width="16"
          height="18"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.5526 3H6.07895C5.21053 3 4.5 3.73636 4.5 4.63636V16.0909H6.07895V4.63636H15.5526V3ZM17.9211 6.27273H9.23684C8.36842 6.27273 7.65789 7.00909 7.65789 7.90909V19.3636C7.65789 20.2636 8.36842 21 9.23684 21H17.9211C18.7895 21 19.5 20.2636 19.5 19.3636V7.90909C19.5 7.00909 18.7895 6.27273 17.9211 6.27273ZM9.23684 19.3636H17.9211V7.90909H9.23684V19.3636Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_2802_12891)">
          <rect x="2.5" y="2" width="20" height="20" fill="#4E4751" />
        </g>
      </g>
    </svg>
  );
};

export default Copyv2;
