import { createSlice } from "@reduxjs/toolkit";
import { getDetailBansosVoucherSubsidi } from "../action";

export const initialState = {
  voucher: [],
  subsidi: [],
  params: {},
  message: "",
  loading: false,
};

export const detailPenerimaBantuanSlice = createSlice({
  name: "detailpenerimaBantuan",
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.params = {
        ...state.params,
        search: action.payload,
      };
    },
    deleteFilter: (state) => {
      delete state.params?.filter;
    },
  },
  extraReducers: {
    [getDetailBansosVoucherSubsidi.pending]: (state) => {
      state.loading = true;
    },
    [getDetailBansosVoucherSubsidi.rejected]: (state, action) => {
      state.loading = false;
      state.message = action.error.message;
    },
    [getDetailBansosVoucherSubsidi.fulfilled]: (state, action) => {
      state.loading = false;
      state.voucher = action.payload?.vouchers;
      state.subsidi = action.payload?.subsidi;
    },
  },
});

export const { changeFilter, deleteFilter } =
  detailPenerimaBantuanSlice.actions;

export default detailPenerimaBantuanSlice.reducer;
