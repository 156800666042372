import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const select = (state) => state.roleDetail || initialState;

export const RoleName = createSelector(select, (p) => p.RoleName);
export const Description = createSelector(select, (p) => p.Description);
export const Users = createSelector(select, (p) => p.Users);
export const Params = createSelector(select, (p) => p.params);
export const Loading = createSelector(select, (p) => p.loading);
export const TotalUser = createSelector(select, (p) => p.totalUser);
export const Permission = createSelector(select, (p) => p.permission);


