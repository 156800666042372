import InisaAlert from "components/Alert/InisaAlert";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { alert } from "redux/global/slice";

const AlertProviders = () => {
  const { open, message } = useSelector((state) => state.global.alert);
  const dispatch = useDispatch();

  return (
    <InisaAlert
      open={open}
      onClose={() =>
        dispatch(
          alert({
            open: false,
            message: "",
          })
        )
      }
      message={message}
    />
  );
};

export default AlertProviders;
