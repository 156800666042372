import React from "react";

const SearchIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.44198 1.66663C4.69999 1.66663 1.6665 4.69055 1.6665 8.42074C1.6665 12.1509 4.69999 15.1749 8.44198 15.1749C9.97867 15.1749 11.3959 14.6649 12.5326 13.8055L17.1446 18.3333L18.3332 17.1302L13.7447 12.6255C14.6665 11.4718 15.2174 10.0104 15.2174 8.42074C15.2174 4.69055 12.184 1.66663 8.44198 1.66663ZM8.44198 3.35515C11.2485 3.35515 13.5236 5.62309 13.5236 8.42074C13.5236 11.2184 11.2485 13.4863 8.44198 13.4863C5.63548 13.4863 3.36037 11.2184 3.36037 8.42074C3.36037 5.62309 5.63548 3.35515 8.44198 3.35515Z"
        fill="#4C4D4F"
      />
      <mask
        id="mask0_886_12149"
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="18"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.44198 1.66663C4.69999 1.66663 1.6665 4.69055 1.6665 8.42074C1.6665 12.1509 4.69999 15.1749 8.44198 15.1749C9.97867 15.1749 11.3959 14.6649 12.5326 13.8055L17.1446 18.3333L18.3332 17.1302L13.7447 12.6255C14.6665 11.4718 15.2174 10.0104 15.2174 8.42074C15.2174 4.69055 12.184 1.66663 8.44198 1.66663ZM8.44198 3.35515C11.2485 3.35515 13.5236 5.62309 13.5236 8.42074C13.5236 11.2184 11.2485 13.4863 8.44198 13.4863C5.63548 13.4863 3.36037 11.2184 3.36037 8.42074C3.36037 5.62309 5.63548 3.35515 8.44198 3.35515Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_886_12149)">
        <rect width="20" height="20" fill="#4C4D4F" />
      </g>
    </svg>
  );
};

export default SearchIcon;
