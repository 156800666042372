import React from "react";
import Providers from "components/RouteRoleProviders";
import KartuSubsidi from "pages/MasterData/MasterBantuan/KartuSubsidi";
import DetailKartuSubsidi from "pages/MasterData/MasterBantuan/KartuSubsidi/DetailKartuSubsidi";

const kartuSubsidiRoutes = [
  {
    path: "/master-data/master-bantuan/kartu-subsidi",
    element: (
      <Providers permission="Web:MasterData:MasterBantuan:KartuSubsidi:Get">
        <KartuSubsidi />
      </Providers>
    ),
  },
  {
    path: "/master-data/master-bantuan/kartu-subsidi/:id",
    element: (
      <Providers permission="Web:MasterData:MasterBantuan:KartuSubsidi:Get">
        <DetailKartuSubsidi />
      </Providers>
    ),
  },
];

export default kartuSubsidiRoutes;
