export const Auth = {
  initialization: "access/initialization",
  register: "merchant/register",
  // login: "access/login/web",
  // login: "merchant/login",
  login: "access/login/merchant",
  // login: "login/merchant",
  verifMerchant: "/merchant/verification",
};

export const Profile = {
  profile: "access/profile/get",
  photo: "profile-photo/list",
};

export const Penerbit = {
  list: "Penerbit/List",
  action: "Penerbit",
  validasiImport: "penerbitExcel",
  multiPenerbit: "multiPenerbit",
  merchantPenerbit: "merchant/issuer",
};

export const KartuSubsidi = {
  list: "kartuSubsidiWeb/list",
  action: "kartuSubsidiWeb",
};

export const Voucher = {
  list: "Voucher/List",
  action: "Voucher",
  validasiImport: "voucherCheck",
  multiVoucher: "multiVoucher",
};

export const SubsidiBansos = {
  list: "listSubsidi",
  voucherPenerbit: "listKartuSubsidiByPenerbit",
  action: "subsidi",
  detail: "detailSubsidi",
  listPenerima: "listPenerimaByProgramSubsidi",
  exportUser: "exportUserBySubsidiId",
};

export const VoucherBansos = {
  list: "listBansos",
  listPenerima: "listPenerimaByProgram",
  detailVoucher: "detailBansos",
  listPenerima: "listPenerimaByProgram",
  voucherPenerbit: "listVoucherByPenerbitId",
  createBansosMulti: "create-bansos-multi",
  exportUser: "exportUserBySubsidiId",
};

export const PenerimaBantuan = {
  list: "listBansosPenerima",
  bansosPenerima: "getBansosUserDetail",
  bansosPenerimaVoucherSubsidi: "getBansosUser",
  bansosSubsidiProgramUser: "listSubsidiByProgramAndUser",
  bansosVoucherProgramUser: "listVoucherByBansosAndUserId",
  bansosDetailUserSubsidi: "detailDataSubsidi",
  bansosRiwayatTransaksi: "detailKartuSubsidiPenerima",
  bansosVoucherRedeem: "redeemVoucher",
};

export const Pengguna = {
  list: "admin/list",
  action: "admin",
  role: "merchant/role/list",
  roleAction: "merchant/role/create",
  roleDetail: "merchant/role/permissions",
  listVoucerByIssuer: "listCardByIssuer",
  roleDelete: "/merchant/role/delete",
};

export const Merchant = {
  registerMerchant: "merchant/business/registration",
  detailUserMerchant: "getDetailMerchant",
  list: "inventory/merchant",
};

export const RedeemSubsidi = {
  list: "listTransactionRedeem",
  detailSubsidi: "transactionDetailSubsidi",
  detailVoucer: "transactionDetailBansos",
  listVoucer: "officer/listVoucherByNoId",
  updateFoto: "officer/updateFotoWajah",
  redeemSubsidiBansos: "/officer/subsidiOnlineTransaction",
};

export const Inventory = {
  masterDistribusi: "merchant/distribusiV2",
  masterProduk: "inventory/produk",
  masterMerchantProduk: "merchant/distributeV2/product",
};

export const Report = {
  stock: "merchantReport/stock",
  daily: "merchantReport/daily",
};
