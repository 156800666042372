import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import MainGrid from "components/Grid/MainGrid";
import PageTitle from "components/Page/PageTitle";
import MainCard from "components/Paper/MainCard";
import AktifStatus from "components/Status/AktifStatus";
import { useParams } from "react-router-dom";
import { getDetailSubsidiBansos } from "redux/redeemSubsidi/action";
import useFetchData from "hook/useFetchData";
import moment from "moment";
import FormatRupiah from "helper/FormatRupiah";

const DetailOfflineTransaksi = () => {
  const { id } = useParams();

  const { response: data, fetch } = useFetchData({
    action: getDetailSubsidiBansos,
    snackbar: false,
  });

  useEffect(() => {
    fetch(id);
  }, []);

  const RincianPenukaran = [
    {
      label: "Status",
      value: (
        <AktifStatus
          maxWidth="120px"
          minWidth="120px"
          customText={["Berhasil", "Gagal"]}
          value={data?.status}
        />
      ),
    },
    {
      label: "Tanggal Transaksi",
      value: moment(data?.date).format("DD/MM/YYYY HH:ss"),
    },
    {
      label: "Nomor Transaksi",
      value: data?.receipt,
    },
    {
      label: "No. Referensi",
      value: data?.receipt_extern,
    },
    {
      label: "Nama Merchant",
      value: data?.merchant,
    },
    {
      label: "Nama Petugas",
      value: data?.officer,
    },
  ];

  const RincianKartuSubsidi = [
    {
      label: "Metode Pembayaran",
      value: data?.payment_method,
    },
    {
      label: "No. Kartu Subsidi",
      value: data?.card_no,
    },
    {
      label: "Nama Pemilik Kartu",
      value: data?.customer,
    },
    {
      label: "Total Tagihan",
      value: `Rp${FormatRupiah(data?.total)}`,
    },
    {
      label: "Biaya Admin",
      value: `Rp${FormatRupiah(data?.fee)}`,
    },
    {
      label: "Total",
      value: `Rp${FormatRupiah(data?.ammount_bill)}`,
    },
  ];

  return (
    <>
      <PageTitle
        title={`Detail Penukaran`}
        text="Dashbor / Redeem Subsidi / "
        lastText="Detail Penukaran"
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <MainCard
            title="Rincian Penukaran"
            addButton={false}
            isEdit={false}
            sx={{
              height: "94%",
            }}
          >
            {RincianPenukaran.map((item, i) => (
              <MainGrid key={i} {...item} labelMd={4} valueMd={5} center />
            ))}
          </MainCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <MainCard
            title="Rincian Pembayaran"
            addButton={false}
            isEdit={false}
            sx={{
              height: "94%",
            }}
          >
            {RincianKartuSubsidi.map((item, i) => (
              <MainGrid key={i} {...item} labelMd={4} valueMd={5} />
            ))}
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default DetailOfflineTransaksi;
