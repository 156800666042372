import InventoryIcon from "assets/layout/sidebar/Inventory";

const permissionList = ["Web:Pengguna:Get", "Web:Peran:Get"];

const Inventory = {
  id: "inventory",
  type: "collapse",
  title: "Inventory",
  icon: InventoryIcon,
  permission: permissionList,
  children: [
    {
      id: "master-produk",
      title: "Master Produk",
      type: "item",
      url: "/inventory/master-produk",
      permission: permissionList[0],
    },
    {
      id: "master-distribusi",
      title: "Master Distribusi",
      type: "collapse",
      permission: permissionList[1],
      children: [
        {
          id: "terima-produk",
          title: "Terima Produk",
          type: "item",
          url: "/inventory/master-distribusi/terima-produk",
          permission: permissionList[1],
        },
        {
          id: "kirim-produk",
          title: "Kirim Produk",
          type: "item",
          url: "/inventory/master-distribusi/kirim-produk",
          permission: permissionList[1],
        },
      ],
    },
  ],
};

export default Inventory;
