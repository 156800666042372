import { Grid } from "@mui/material";
import MainGrid from "components/Grid/MainGrid";
import PageTitle from "components/Page/PageTitle";
import MainCard from "components/Paper/MainCard";
import AktifStatus from "components/Status/AktifStatus";
import useFetchData from "hook/useFetchData";
import moment from "moment";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getDetailVoucerBansos } from "redux/redeemSubsidi/action";

const DetailPenukaran = () => {
  const { id } = useParams();

  const { response: data, fetch } = useFetchData({
    action: getDetailVoucerBansos,
    snackbar: false,
  });

  useEffect(() => {
    fetch({
      voucherNo: id,
    });
  }, []);

  const RincianPenukaran = [
    {
      label: "Status",
      value: (
        <AktifStatus
          maxWidth="120px"
          minWidth="120px"
          customText={["Berhasil", "Gagal"]}
          value={1}
        />
      ),
    },
    {
      label: "Waktu Penukaran",
      value: moment(data?.date).format("DD/MM/YYYY HH:ss"),
    },
    {
      label: "Nama Merchant",
      value: data?.merchant,
    },
    {
      label: "Nama Petugas",
      value: data?.officer,
    },
  ];

  const RincianKartuSubsidi = [
    {
      label: "Nama Kartu",
      value: data?.voucher,
    },
    {
      label: "Kode Kartu",
      value: data?.code,
    },
    {
      label: "Nama Penerima",
      value: data?.claimed_by,
    },
  ];

  return (
    <>
      <PageTitle
        title={`Detail Penukaran`}
        text="Dashbor / Redeem Subsidi / "
        lastText="Detail Penukaran"
      />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <MainCard
            title="Rincian Penukaran"
            addButton={false}
            isEdit={false}
            sx={{
              height: "92%",
            }}
          >
            {RincianPenukaran.map((item, i) => (
              <MainGrid key={i} {...item} labelMd={4} valueMd={8} center />
            ))}
          </MainCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <MainCard
            title="Rincian Kartu Subsidi"
            addButton={false}
            isEdit={false}
            sx={{
              height: "92%",
            }}
          >
            {RincianKartuSubsidi.map((item, i) => (
              <MainGrid key={i} {...item} labelMd={4} valueMd={8} />
            ))}
          </MainCard>
        </Grid>
      </Grid>
    </>
  );
};

export default DetailPenukaran;
