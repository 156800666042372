import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useState, useEffect } from "react";
import { Box, Grid, Radio, Typography } from "@mui/material";
import TextFieldv3 from "components/TextField/TextFieldv3";
import { useNavigate } from "react-router-dom";
import VerticalGrid from "components/Grid/VerticalGrid";
import MainController from "components/Controller/MainController";
import Circle from "@mui/icons-material/Circle";
import PembayaranBerhasil from "assets/redeem/PembayaranBerhasil";
import AlertDialog from "components/Dialog/AlertDialog";
import ScanGagal from "assets/redeem/ScanGagal";
import FileDropComponent from "components/FileDrop/FileDropComponent";
import FotoWajahDialog from "../FotoWajah";
import ImgVoucher from "pages/CSR/PenerimaBantuan/Voucher/ImageVoucher";
import { bansosVoucherRedeem } from "redux/penerimaBantuan/action";
import useFetchData from "hook/useFetchData";

const PilihVoucer = ({
  handleSubmit,
  isValid,
  errors,
  control,
  Controller,
  clearErrors,
  open,
  onClose,
  onOpen,
  getValues,
  setValue,
  listReq,
}) => {
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState({
    success: false,
    fail: false,
    fotoWajah: false,
  });

  const action = useFetchData({
    action: bansosVoucherRedeem,
    message: "Berhasil redeem voucer",
    onSuccess: () => {
      setOpenDialog({
        ...openDialog,
        success: true,
      });
      listReq.fetch(getValues("ManualValidasi.Id"));
    },
    onError: () => {
      setOpenDialog({
        ...openDialog,
        fail: true,
      });
    },
  });

  const onSubmit = () => {
    const listVoucer = getValues("ManualValidasi.Voucer");
    const id = getValues("ManualValidasi.KodeVoucer");

    const find = listVoucer.find((i) => i.kode_voucher == id);

    const formData = new FormData();
    formData.append("kode_voucher", find?.kode_voucher);
    formData.append("install_id", "-");
    formData.append("expired_date", 0);
    formData.append("prefix_code", find.prefix_code);
    formData.append("public_key", "");
    formData.append("is_qrcode", 0);

    action.fetch(formData);
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  return (
    <>
      <MainDialog
        open={open}
        onClose={() => {
          onClose();
        }}
        title={"Redeem Subsidi - Manual Validasi"}
        valid={isValid}
        loading={action.loading}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        submitText={"Selanjutnya"}
        customWidth="1000px"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <VerticalGrid label={"Foto Penerima"} md={12}>
              <MainController
                {...getProps("ManualValidasi.FotoWajah")}
                // shouldUnregister={true}
                render={({ field }) => (
                  <FileDropComponent
                    {...field}
                    width="100%"
                    height="200px"
                    onChange={() => {
                      setOpenDialog({
                        ...openDialog,
                        fotoWajah: true,
                      });
                      onClose();
                    }}
                  />
                )}
              />
            </VerticalGrid>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={3}>
              <VerticalGrid label="NIK" md={12}>
                <MainController
                  {...getProps("ManualValidasi.Id")}
                  // shouldUnregister={true}
                  rules={{
                    required: true,
                  }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextFieldv3
                      {...field}
                      errors={errors}
                      disabled
                      placeholder="Masukkan NIK"
                    />
                  )}
                />
              </VerticalGrid>
              <VerticalGrid label="Nama Lengkap" md={12}>
                <MainController
                  {...getProps("ManualValidasi.Fullname")}
                  // shouldUnregister={true}
                  rules={{
                    required: true,
                  }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextFieldv3
                      {...field}
                      errors={errors}
                      disabled
                      placeholder="Masukkan Nama Lengkap"
                    />
                  )}
                />
              </VerticalGrid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <VerticalGrid label={"Voucer"} md={12}>
              <Grid container spacing={4}>
                <MainController
                  {...getProps("ManualValidasi.KodeVoucer")}
                  rules={{
                    required: true,
                  }}
                  defaultValue={[]}
                  shouldUnregister={true}
                  render={({ field }) => (
                    <>
                      {getValues("ManualValidasi.Voucer")?.map((item, i) => (
                        <Grid item xs={12} md={6} key={i}>
                          <Box
                            display="flex"
                            gap={1}
                            alignItems="center"
                            py={2}
                            onClick={() => {
                              field.onChange(item.kode_voucher);
                            }}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            <Radio
                              icon={<Circle />}
                              sx={{
                                p: 0,
                              }}
                              checked={field.value == item.kode_voucher}
                            />
                            <Typography variant="text2">
                              {item?.voucher_name}
                            </Typography>
                          </Box>
                          <ImgVoucher
                            dataVoucher={{
                              EndDate: item?.end_date,
                              Gambar: item?.gambar_voucher,
                              IsImage: item?.is_template == true ? 2 : 1,
                              StartDate: item?.start_date,
                              VoucherName: item?.voucher_name,
                              voucher: item?.prefix_code,
                            }}
                          />
                        </Grid>
                      ))}
                    </>
                  )}
                />
                {!getValues("ManualValidasi.Voucer")?.length && (
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        height: "200px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="label2">Voucer</Typography>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </VerticalGrid>
          </Grid>
        </Grid>
      </MainDialog>

      <AlertDialog
        buttonSX={{
          minWidth: "100%",
          minHeight: "50px",
        }}
        customWidth="329px"
        open={openDialog.success}
        alertTitle="Pembayaran Dikonfirmasi"
        // alertDesc="Pembayaran sejumlah Rp 100.000
        // dari Kartu Inisa Pintar"
        submitText="Lihat Detail Transaksi"
        onSubmit={() => {
          setOpenDialog({
            ...openDialog,
            success: false,
          });
          const encode = encodeURIComponent(action?.response?.kode_voucher);
          navigate(`/redeem-subsidi/voucer-bansos/${encode}`);
        }}
        onClose={() => {
          setOpenDialog({
            ...openDialog,
            success: false,
          });
        }}
        customIcon={<PembayaranBerhasil />}
      />

      <AlertDialog
        buttonSX={{
          minWidth: "100%",
          minHeight: "50px",
        }}
        customWidth="329px"
        open={openDialog.fail}
        onClose={() => {
          setOpenDialog({
            ...openDialog,
            fail: false,
          });
        }}
        alertTitle="Scan Gagal"
        submitText="Scan Ulang"
        customIcon={<ScanGagal />}
      />

      <FotoWajahDialog
        open={openDialog.fotoWajah}
        onClose={() => {
          setOpenDialog({
            ...openDialog,
            fotoWajah: false,
          });
          onOpen();
        }}
        setValue={setValue}
        getValues={getValues}
      />
    </>
  );
};

PilihVoucer.propTypes = {
  Controller: PropTypes.any,
  control: PropTypes.any,
  data: PropTypes.object,
  errors: PropTypes.any,
  handleSubmit: PropTypes.any,
  id: PropTypes.any,
  isEdit: PropTypes.bool,
  isValid: PropTypes.any,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  setRes: PropTypes.func,
  watch: PropTypes.any,
  getValues: PropTypes.any,
  unregister: PropTypes.any,
  clearErrors: PropTypes.any,
  setError: PropTypes.any,
  setValue: PropTypes.any,
  listReq: PropTypes.any,
};

PilihVoucer.defaultProps = {
  isEdit: false,
  onClose: () => {},
  onOpen: () => {},
  open: false,
  refresh: () => {},
  data: null,
  id: null,
  setRes: null,
  handleSubmit: null,
  isValid: null,
  errors: null,
  control: null,
  Controller: null,
  watch: null,
  getValues: null,
  unregister: null,
  clearErrors: null,
  setError: null,
  setValue: null,
  listReq: null,
};
export default PilihVoucer;
