import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

const Providers = ({ currentPage, params, get, title, desc, children }) => {
  useEffect(() => {
    const fetchData = async () => {
      await get();
    };

    fetchData();
  }, [currentPage, params]);

  return (
    <>
      <Typography variant="h1">{title}</Typography>
      {desc && (
        <Typography
          variant="desc"
          sx={{
            mt: 1,
            display: {
              xs: "none",
              md: "block",
            },
          }}
        >
          Dasbor{" "}
          {window.location.pathname
            .replaceAll("-", " ")
            .replaceAll(/[0-9]/g, "")
            .split("/")
            .map((path, i, arr) => {
              if (i == arr.length - 1)
                return (
                  <span style={{ color: "#cc1d15", fontWeight: 700 }} key={i}>
                    {path}
                  </span>
                );
              else {
                return path == "csr bansos" ? (
                  <span style={{ textTransform: "none" }} key={i}>
                    {" "}
                    CSR Bansos /{" "}
                  </span>
                ) : (
                  `${path} / `
                );
              }
            })}
        </Typography>
      )}
      {children}
    </>
  );
};

Providers.propTypes = {
  currentPage: PropTypes.any,
  children: PropTypes.any,
  params: PropTypes.shape({
    filter: PropTypes.any,
    limit: PropTypes.any,
  }),
  desc: PropTypes.bool,
  get: PropTypes.func,
  title: PropTypes.string,
};

Providers.defaultProps = {
  currentPage: 0,
  params: {},
  get: () => {},
  children: null,
  title: "",
  desc: false,
};
export default Providers;
