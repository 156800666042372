import { createSlice } from "@reduxjs/toolkit";
import { pagination } from "redux/pagination";
import { getListRole } from "../action";

export const initialState = {
  list: [],
  currentPage: 1,
  params: {
    limit: 10,
    offset: 0,
    filter: "",
  },
  totalItems: 0,
  message: "",
  filterName: "",
  loading: false,
};

export const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.params.filter = action.payload;
    },
  },
  extraReducers: {
    [getListRole.pending]: (state) => {
      state.loading = true;
    },
    [getListRole.rejected]: (state, action) => {
      state.loading = false;
      state.message = action.error.message;
    },
    [getListRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload.list;
      state.totalItems = action.payload.total_items;
    },
  },
});

export const { changeFilter } = roleSlice.actions;

export default roleSlice.reducer;
