import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const select = (state) => state.kartuSubsidi || initialState;

export const List = createSelector(select, (p) => p.list);
export const Loading = createSelector(select, (p) => p.loading);
export const Params = createSelector(select, (p) => p.params);
export const TotalItems = createSelector(select, (p) => p.totalItems);
export const CurrentPage = createSelector(select, (p) => p.currentPage);
export const FilterName = createSelector(select, (p) => p.filterName);


