import { Typography, Box, Button } from "@mui/material";
import MainStepper from "components/Stepper/MainStepper";
import React, { useState, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import StepView from "./components/StepView";
import StepView0 from "./Step/StepView0";
import StepView1 from "./Step/StepView1";
import StepView2 from "./Step/StepView2/";
import Form1 from "./Step/StepView2/Form1";
import Form2 from "./Step/StepView2/Form2";
import StepView3 from "./Step/StepView3";
import StepView4 from "./Step/StepView4";
import StepView5 from "./Step/StepView5";
import { useNavigate } from "react-router-dom";
import AlertDialog from "components/Dialog/AlertDialog";
import RiwayatPermohonanImage from "assets/dasbor/RiwayatPermohonan.png";
import { kategoriBisnis1, kategoriBisnis2 } from "./variable";
import { registerMerchant } from "redux/merchant/action";
import useFetchData from "hook/useFetchData";
import InisaIlu from "assets/login/new-ilustrasi.png";
import { useSelector } from "react-redux";

const defaultValues = {
  BizType: null, // perorangan / badan-usaha
  BizProduct: null,
  BizEntitasType: null,
  BizCategory: null,
  BizUrl: "",
  IsBizDeveloper: 2,
  BizEmailPicFinance: "",
  BizHpPicFinance: "",
  BizEmailPicTech: "",
  BizHpPicTech: "",
  BizAddress: "",
  BizRegion: null,
  BizPostCode: "",
  BizPhone: "",
  BizKtpImg: null,
  BizKtpName: "",
  BizKtpNo: "",
  BizNpwpImg: null,
  BizNpwpName: "",
  BizNpwpNo: "",
  BizNpwpAlamat: "",
  BankName: "",
  BankRekeningNo: "",
  BankRekeningName: "",
};

const Dasbor = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [openDialog, setOpenDialog] = useState({
    tipebisnis: false,
    onboarding: false,
    alert: false,
  });

  const {
    photo,
    profile: { Fullname },
  } = useSelector((state) => state.profile);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    getValues,
    reset: resetForm,
    clearErrors,
    setValue,
    watch,
    setError,
    trigger,
  } = useForm({
    defaultValues: defaultValues,
    mode: "onSubmit",
  });

  const navigate = useNavigate();

  const action = useFetchData({
    action: registerMerchant,
    message: "Berhasil register merchant ",
    onSuccess: (data) => {
      setOpenDialog({
        ...openDialog,
        onboarding: false,
      });
      navigate("/bussines-registration-complete");
    },
    onError: (data) => {
      Object.keys(data).forEach((item) => {
        setError(item, { type: "custom", message: data[item][0] });
      });
    },
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    setOpenDialog({
      ...openDialog,
      alert: true,
    });
  }, []);

  const steps = [
    "Tipe Bisnis",
    "Pilih Produk",
    "Informasi Pemilik & Bisnis",
    "Detail Bank",
    "Pratinjau Data",
    "Syarat & Ketentuan",
  ];

  const onSubmit = async (data) => {
    if (activeStep == 0) {
      setOpenDialog({
        ...openDialog,
        tipebisnis: false,
      });
    }
    if (activeStep < 5) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    if (openDialog.onboarding == false) {
      return setOpenDialog({
        ...openDialog,
        onboarding: true,
      });
    } else {
      const formData = new URLSearchParams({})
      await convertToBase64(data.BizKtpImg, async (base64) => {
          data.BizKtpImg = base64;
          await convertToBase64(data.BizNpwpImg, async (base) => {
            data.BizNpwpImg = base;
            for (let key in data) {
              formData.append(key, data[key]);
            }
            await action.fetch(formData);
          })
      })
    }
  };

  const convertToBase64 = async (file, handler = null) => {
    if(file == null){
      return handler(null)
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const arr = reader.result.split(",");
      if (handler != null) {
        handler(arr[1])
      }
    }
  }

  const KategoriBisnisData = useMemo(() => {
    if (watch("BizEntitasType") == 1) {
      return kategoriBisnis1;
    } else if (watch("BizEntitasType") == 2) {
      return kategoriBisnis2;
    }
  }, [watch("BizEntitasType")]);

  const desc = useMemo(() => {
    if (watch("BizType") == 1) {
      return (
        <ol style={{ paddingLeft: "15px" }}>
          <li>
            Menyiapkan file kartu identitas Direktur perusahaan, KITAS, serta
            passpor bagi WNA untuk memudahkan proses pendaftaran.
          </li>
          <li style={{ marginTop: "8px" }}>
            Menyiapkan file yang dibutuhkan bagi pemilik usaha seperti NPWP
            untuk WNI dan pastikan nama di NPWP serta KTP sama.
          </li>
        </ol>
      );
    } else if (watch("BizType") == 2) {
      return (
        <ol style={{ paddingLeft: "15px" }}>
          <li>
            Menyiapkan file kartu identitas Direktur perusahaan, KITAS, serta
            passpor bagi WNA untuk memudahkan proses pendaftaran
          </li>
          <li style={{ marginTop: "8px" }}>
            Menyiapkan file yang dibutuhkan bagi pemilik usaha seperti NPWP
            untuk WNI dan pastikan nama di NPWP serta KTP sama
          </li>
          <li style={{ marginTop: "8px" }}>
            Menyiapkan file NPWP atas nama badan usaha yang sudah terdaftar, dan
            pastikan NPWP tersebut bukan milik orang lain.
          </li>
          <li style={{ marginTop: "8px" }}>
            Menyiapkan file Akta Pendirian Badan Usaha (terbaru jika ada).
          </li>
          <li style={{ marginTop: "8px" }}>
            Menyiapkan file Surat Keputusan Menteri Kehakiman (SK Menkeh)
          </li>
        </ol>
      );
    }
  }, [watch("BizType")]);

  return (
    <>
      <Typography component="div" variant="text2" mb={2}>
        Pendaftaran Akun /{" "}
        <Box component="span" sx={{ color: "#cc1d15", fontWeight: "bold" }}>
          Detail Bisnis
        </Box>
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <MainStepper
          steps={steps}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          withAction={activeStep != 0 && activeStep != 2}
          handleNext={handleNext}
          handleBack={handleBack}
        >
          <StepView
            view={0}
            activeStep={activeStep}
            page={{
              title: `Pilih Tipe Bisnis`,
              text: "Silahkan pilih tipe bisnis sesuai kebutuhanmu.",
            }}
          >
            <StepView0
              controller={Controller}
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              setOpenDialog={() =>
                setOpenDialog({
                  ...openDialog,
                  tipebisnis: true,
                })
              }
            />
          </StepView>

          <StepView view={1} activeStep={activeStep} paper={false}>
            <StepView1
              page={{
                title: `Pilih Produk`,
                text: "Pilih produk yang sesuai kebutuhan bisnismu.",
              }}
              controller={Controller}
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              handleBack={handleBack}
            />
          </StepView>

          <StepView
            view={2}
            activeStep={activeStep}
            page={{
              title: `Informasi Bisnis`,
              text: "Lengkapi data informasi bisnis berikut",
            }}
          >
            <StepView2
              controller={Controller}
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              kategoriBisnisData={KategoriBisnisData}
              setValue={setValue}
              trigger={trigger}
            />
          </StepView>
          <StepView
            view={2}
            activeStep={activeStep}
            page={{
              title: `Informasi Pemilik`,
              text: "Lengkapi data informasi pemilik berikut",
            }}
          >
            <Form1
              controller={Controller}
              control={control}
              errors={errors}
              clearErrors={clearErrors}
            />
          </StepView>
          <StepView
            view={2}
            activeStep={activeStep}
            page={{
              title: `Unggah Dokumen`,
              text: "Lengkapi dokumen yang dibutuhkan",
            }}
          >
            <Form2
              controller={Controller}
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              setError={setError}
            />
          </StepView>

          <StepView view={2} activeStep={activeStep} paper={false}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                pt: 2,
                gap: 1,
              }}
            >
              <Button
                color="inherit"
                onClick={handleBack}
                variant="gray"
                sx={{ mr: 1 }}
              >
                Kembali
              </Button>

              <Button
                type="submit"
                variant="primary"
                sx={{ width: "200px" }}
                disabled={!isValid}
              >
                {"Selanjutnya"}
              </Button>
            </Box>
          </StepView>

          <StepView
            view={3}
            activeStep={activeStep}
            page={{
              title: `Detail Bank`,
              text: "Lengkapi detail bank berikut",
            }}
          >
            <StepView3
              handleBack={handleBack}
              controller={Controller}
              control={control}
              errors={errors}
              clearErrors={clearErrors}
            />
          </StepView>
          <StepView
            view={4}
            activeStep={activeStep}
            page={{
              title: `Pratinjau Data`,
              text: "Pastikan informasi yang di isi pada formulir sudah sesuai.",
            }}
          >
            <StepView4
              defaultValues={defaultValues}
              handleBack={handleBack}
              getValues={getValues}
              kategoriBisnisData={KategoriBisnisData}
            />
          </StepView>
          <StepView
            view={5}
            activeStep={activeStep}
            page={{
              title: `Syarat & Ketentuan`,
            }}
          >
            <StepView5 handleBack={handleBack} />
          </StepView>
        </MainStepper>

        <AlertDialog
          customWidth="620px"
          open={openDialog.tipebisnis}
          cancelText="Kembali"
          onClose={() =>
            setOpenDialog({
              ...openDialog,
              tipebisnis: false,
            })
          }
          alertTitle="Dokumen dan informasi yang harus disiapkan :"
          submitText="Lanjutkan"
          alertDesc={desc}
          textDescAlign="left"
          type="submit"
        />

        <AlertDialog
          customWidth="600px"
          customIcon={
            <Box
              component="img"
              sx={{
                width: "256px",
                height: "256px",
              }}
              src={RiwayatPermohonanImage}
            />
          }
          // titleVariant="text2"
          open={openDialog.onboarding}
          onClose={() =>
            setOpenDialog({
              ...openDialog,
              onboarding: false,
            })
          }
          loading={action.loading}
          alertTitle="Kamu belum menyelesaikan proses onboarding"
          submitText="Selesaikan Onboarding"
          alertDesc={
            "Segera selesaikan proses onboardingmu dan nikmati kemudahan fitur yang ada di INISA Web Merchant"
          }
          type="submit"
        />

        <AlertDialog
          customWidth="600px"
          customIcon={
            <Box
              component="img"
              sx={{
                width: "256px",
                height: "256px",
              }}
              src={InisaIlu}
            />
          }
          // titleVariant="text2"
          open={openDialog.alert}
          onClose={() =>
            setOpenDialog({
              ...openDialog,
              alert: false,
            })
          }
          alertTitle={`Selamat Datang ${Fullname || "-"}`}
          submitText="Selesaikan Onboarding"
          alertDesc={
            "It's great to have you with us! \n Lengkapi data yang dibutuhkan untuk aktivasi akun merchantmu"
          }
          onSubmit={() =>
            setOpenDialog({
              ...openDialog,
              alert: false,
            })
          }
        />
      </form>
    </>
  );
};

export default Dasbor;
