import React from "react";

const PencilIconv2 = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_867_1519)">
        <path
          d="M11.7186 7.51667L12.4853 8.28333L4.93529 15.8333H4.16862V15.0667L11.7186 7.51667ZM14.7186 2.5C14.5103 2.5 14.2936 2.58333 14.1353 2.74167L12.6103 4.26667L15.7353 7.39167L17.2603 5.86667C17.5853 5.54167 17.5853 5.01667 17.2603 4.69167L15.3103 2.74167C15.1436 2.575 14.9353 2.5 14.7186 2.5ZM11.7186 5.15833L2.50195 14.375V17.5H5.62695L14.8436 8.28333L11.7186 5.15833Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_867_1519">
          <rect
            width="20"
            height="20"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PencilIconv2;
