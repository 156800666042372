import PropTypes from "prop-types";
import { ExpandMore } from "@mui/icons-material";
import { Autocomplete, Grow, Popper, TextField } from "@mui/material";
import React, { forwardRef, useMemo } from "react";
import TextFieldv3 from "components/TextField/TextFieldv3";

const PopperCom = ({ children, ...others }) => {
  return (
    <Popper
      {...others}
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 10],
          },
        },
      ]}
      transition
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} timeout={300}>
          {children}
        </Grow>
      )}
    </Popper>
  );
};

PopperCom.propTypes = {
  children: PropTypes.any,
  io: PropTypes.any,
};

PopperCom.defaultProps = {
  children: null,
  io: null,
};

const MainAutocomplete = forwardRef((props, ref) => {
  const TypeField = useMemo(() => {
    return props.typefield == 0 ? TextField : TextFieldv3;
  }, [props.typefield]);

  return (
    <Autocomplete
      {...props}
      ref={ref}
      PopperComponent={PopperCom}
      renderInput={(p) => (
        <TypeField
          {...p}
          placeholder={props.placeholder}
          InputProps={{
            ...p.InputProps,
            endAdornment: (
              <>
                <ExpandMore
                  onMouseDown={p.inputProps.onMouseDown}
                  sx={{
                    position: "absolute",
                    right: "13px",
                    color: "#4E4751",
                  }}
                />
              </>
            ),
          }}
        />
      )}
    />
  );
});

MainAutocomplete.propTypes = {
  placeholder: PropTypes.string,
  typefield: PropTypes.number,
};

MainAutocomplete.defaultProps = {
  placeholder: "-",
  typefield: 0,
};
MainAutocomplete.displayName = "mac";
export default MainAutocomplete;
