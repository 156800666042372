
import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const selectPenerbit = (state) => state.merchantIssuer || initialState;

export const ListMerchant = createSelector(selectPenerbit, (p) => p.list);
export const LoadingMerchant = createSelector(selectPenerbit, (p) => p.loading);
export const ParamsMerchant = createSelector(selectPenerbit, (p) => p.params);


