import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useEffect, useState, useMemo } from "react";
import { statusList } from "./variables";
import { Controller, useForm } from "react-hook-form";
import MainTextField from "components/TextField/MainTextField";
import MainController from "components/Controller/MainController";
import MainAutocomplete from "components/AutoComplete/MainAutocomplete";
import useFetchData from "hook/useFetchData";
import {
  createPengguna,
  getRole,
  getVoucerByIssuer,
  updatePengguna,
} from "redux/managementPengguna/action";
import { Collapse, Grid } from "@mui/material";
import VerticalGrid from "components/Grid/VerticalGrid";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MainRadio from "components/Radio/MainRadio";
import DateRange2 from "components/Picker/DateRange2";
import List from "components/List/List";
import ListCollapse from "./components/ListCollapse";
import ListTitle from "./components/ListTitle";
import { TransitionGroup } from "react-transition-group";
import FileInputBox from "components/FileDrop";
import { getPenerbitMerchant } from "redux/penerbit/action";
import {
  ListMerchant,
  LoadingMerchant,
  ParamsMerchant,
} from "redux/penerbit/Merchant/selectors";
import { useDispatch, useSelector } from "react-redux";
import MainCheckbox from "components/Checkbox/MainCheckbox";
import MainLoading from "components/Loading/MainLoading";
import PopperInputCheckBox from "components/Popper/PopperInput";
import moment from "moment";

const defaultValues = {
  Fullname: "",
  Phone: "",
  Email: "",
  Password: "",
  PasswordConfirmation: "",
  RoleId: null,
  Photo: "",
  Source: "web",
};

const RenderOption = ({ props, option, selected }) => {
  return (
    <li
      {...props}
      style={{
        padding: "0px !important",
      }}
    >
      <MainCheckbox sx={{ marginRight: 3 }} checked={selected || false} />
      {option.IssuerName}
    </li>
  );
};

RenderOption.displayName = "ro";
RenderOption.propTypes = {
  option: PropTypes.shape({
    IssuerName: PropTypes.any,
  }),
  props: PropTypes.shape({}),
  selected: PropTypes.any,
};

RenderOption.defaultProps = {
  option: null,
  props: null,
  selected: null,
};

const Dialog = ({ open, onClose, refresh, data, setRes }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset: resetForm,
    clearErrors,
    watch,
    setValue,
    unregister,
    register,
    setError,
  } = useForm({
    defaultValues: { ...defaultValues },
  });

  const dispatch = useDispatch();

  const [showOfficer, setShowOfficer] = useState("");

  const ListM = useSelector(ListMerchant);
  const LoadingM = useSelector(LoadingMerchant);
  const ParamsM = useSelector(ParamsMerchant);

  const [showPassword, setShowPassword] = useState({
    password: false,
    conPassword: false,
  });

  const PasswordIcon = useMemo(
    () => (showPassword.password ? RemoveRedEyeIcon : VisibilityOffIcon),
    [showPassword.password]
  );

  const ConfPasswordIcon = useMemo(
    () => (showPassword.conPassword ? RemoveRedEyeIcon : VisibilityOffIcon),
    [showPassword.conPassword]
  );

  const action = useFetchData({
    action: data?.id ? updatePengguna : createPengguna,
    reset: resetForm,
    message: data?.id
      ? "Berhasil Update Pengguna"
      : "Berhasil menambah pengguna baru",
    refresh: refresh,
    onSuccess: () => {
      onClose();
    },
    onError: (data) => {
      Object.keys(data).forEach((item) => {
        setError(item, {
          type: "error",
          message: data[item][0],
        });
      });
    },
  });

  const roleList = useFetchData({
    action: getRole,
    snackbar: false,
  });

  useEffect(() => {
    if (data?.id) {
      const obj = {
        Fullname: data.Fullname,
        Phone: data.Phone,
        Email: data.Email,
        RoleId: data.RoleId,
        Photo: data.Photo,
        Source: "web",
      };

      if (data.hasOwnProperty("StartDate")) {
        obj.StartDate = moment(data.StartDate).format("YYYY-MM-DD");
      }
      if (data.hasOwnProperty("EndDate")) {
        obj.EndDate = moment(data.EndDate).format("YYYY-MM-DD");
      }
      if (data.hasOwnProperty("Status")) {
        obj.TaskStatus = data.Status;
      }
      if (data.hasOwnProperty("Issuers")) {
        obj.Issuers = data.Issuers;
        fetchData();
      }
      if (data.hasOwnProperty("Vouchers")) {
        obj.Vouchers = data.Vouchers;
      }
      setShowOfficer(data.TypeRole);

      resetForm(obj);
    } else {
      resetForm(defaultValues);
    }
  }, [data]);

  useEffect(() => {
    if (open) {
      // if (process.env.NODE_ENV !== "production") {
      //   API.defaults.baseURL = "http://10.10.101.0:9141/api/v1";
      // }
      roleList.fetch();
    }
  }, [open]);

  useEffect(() => {
    if (setRes) {
      setRes(action.response);
    }
  }, [action.response]);

  useEffect(() => {
    if (showOfficer !== 4) {
      unregister("Issuers", {
        keepDirty: false,
        keepDefaultValue: false,
      });
      unregister("Vouchers", {
        keepDirty: false,
        keepDefaultValue: false,
      });
      unregister("StartDate", {
        keepDirty: false,
        keepDefaultValue: false,
      });
      unregister("EndDate", {
        keepDirty: false,
        keepDefaultValue: false,
      });
      unregister("TaskStatus", {
        keepDirty: false,
        keepDefaultValue: false,
      });
    } else {
      register("StartDate", {
        shouldUnregister: true,
      });
    }
  }, [showOfficer]);

  const onSubmit = async (submitData) => {
    if (submitData.hasOwnProperty("Issuers")) {
      submitData.Issuers = JSON.stringify(submitData.Issuers);
    }
    if (submitData.hasOwnProperty("Vouchers")) {
      submitData.Vouchers = JSON.stringify(submitData.Vouchers);
    }
    if (data?.id) {
      if (data?.Photo === submitData?.Photo) {
        submitData.Photo = "";
      }
    }
    // if (process.env.NODE_ENV !== "production") {
    //   API.defaults.baseURL = "http://10.10.101.0:9141/api/v1";
    // }
    const formData = new URLSearchParams({})

    await convertToBase64(submitData.Photo, (base64) => {
      if (base64 != null){
        submitData.Photo = base64
      }else{
        submitData["Photo"] = ""
      }
      for (let key in submitData) {
        formData.append(key, submitData[key]);
      }
      action.fetch(formData, data?.id);
    })

  };

  const convertToBase64 = async (file, handler = null) => {
    if(file == null || typeof file == "string"){
      return handler(null)
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const arr = reader.result.split(",");
      if (handler != null) {
        handler(arr[1])
      }
    }
  }

  const listVoucerByIssuer = useFetchData({
    action: getVoucerByIssuer,
    snackbar: false,
  });

  const fetchData = async () => {
    // if (process.env.NODE_ENV !== "production") {
    //   API.defaults.baseURL = "http://10.10.101.0:8070/api/inisa";
    // }
    await dispatch(getPenerbitMerchant({ params: ParamsM }));
  };

  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
        resetForm();
        setShowOfficer("");
      }}
      title={!data?.id ? "Tambah Pengguna Baru" : "Ubah Data Pengguna"}
      loading={action.loading}
      valid={true}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      submitText={!data?.id ? "Tambah" : "Simpan Perubahan"}
      onReset={() => {
        resetForm();
        setShowOfficer("");
      }}
      type={1}
      width="lg"
    >
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          md={8}
          order={{
            xs: 2,
            md: 1,
          }}
        >
          <Grid container spacing={2}>
            <VerticalGrid label={"Nama Lengkap"} md={12}>
              <MainTextField
                controller={Controller}
                name="Fullname"
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                rules={{
                  required: "Nama lengkap wajib diisi",
                }}
                typeField={1}
                placeholder="Masukan nama lengkap"
              />
            </VerticalGrid>

            <VerticalGrid label={"Email"}>
              <MainTextField
                controller={Controller}
                name="Email"
                typeField={1}
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                rules={{
                  required: "Email wajib diisi",
                  maxLength: {
                    message: "Email tidak boleh melebihi 255 karakter",
                    value: 255,
                  },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Format Email salah",
                  },
                }}
                placeholder="Masukan alamat email"
              />
            </VerticalGrid>

            <VerticalGrid label={"No. HP"}>
              <MainTextField
                controller={Controller}
                name="Phone"
                typeField={1}
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                rules={{
                  required: "Phone number wajib diisi",
                  maxLength: {
                    message: "Phone number tidak boleh melebihi 20 karakter",
                    value: 20,
                  },
                  minLength: {
                    message: "Phone number tidak boleh kurang dari 8 karakter",
                    value: 8,
                  },
                }}
                type="number"
                placeholder="Masukan No. HP"
              />
            </VerticalGrid>

            {!data?.id && (
              <>
                <VerticalGrid label={"Kata Sandi"}>
                  <MainTextField
                    controller={Controller}
                    name="Password"
                    typeField={1}
                    control={control}
                    errors={errors}
                    clearErrors={clearErrors}
                    rules={{
                      required: "Password wajib diisi",
                      minLength: {
                        message: "Password tidak boleh kurang dari 6 karakter",
                        value: 6,
                      },
                    }}
                    desc="Password must be at least 8 character"
                    type={showPassword.password ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <PasswordIcon
                          sx={{
                            color: "#969696",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              password: !showPassword?.password,
                            })
                          }
                        />
                      ),
                    }}
                    placeholder="Masukan kata sandi"
                  />
                </VerticalGrid>

                <VerticalGrid label={"Konfirmasi Kata Sandi"}>
                  <MainTextField
                    controller={Controller}
                    name="PasswordConfirmation"
                    control={control}
                    errors={errors}
                    typeField={1}
                    clearErrors={clearErrors}
                    type={showPassword.conPassword ? "text" : "password"}
                    rules={{
                      required: "Confirm Password wajib diisi",
                      minLength: {
                        message:
                          "Confirm Password tidak boleh kurang dari 6 karakter",
                        value: 6,
                      },
                      validate: (value) =>
                        value === getValues("Password") ||
                        "Password Tidak sama",
                    }}
                    InputProps={{
                      endAdornment: (
                        <ConfPasswordIcon
                          sx={{
                            color: "#969696",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              conPassword: !showPassword?.conPassword,
                            })
                          }
                        />
                      ),
                    }}
                    placeholder="Ketik ulang kata sandi"
                  />
                </VerticalGrid>
              </>
            )}

            <VerticalGrid label={"Role"} md={12}>
              <MainLoading loading={roleList.loading}>
                <MainController
                  controller={Controller}
                  name="RoleId"
                  control={control}
                  errors={errors}
                  clearErrors={clearErrors}
                  rules={{ required: "Role wajib diisi" }}
                  render={({ field }) => (
                    <MainRadio
                      data={roleList?.response?.list || []}
                      value={field.value}
                      onChange={(item) => {
                        setShowOfficer(item.TypeRole);
                        field.onChange(item.MerchantRolesId);
                      }}
                      itemValue="MerchantRolesId"
                      itemLabel="MerchantRoleName"
                      itemDesc="Description"
                    />
                  )}
                />
              </MainLoading>
            </VerticalGrid>

            <TransitionGroup component={Grid} item xs={12}>
              {showOfficer == "4" && (
                <Collapse>
                  <Grid container spacing={2}>
                    <VerticalGrid label={"Tgl. Tugas"}>
                      <MainController
                        controller={Controller}
                        name="EndDate"
                        typeField={1}
                        defaultValue={null}
                        control={control}
                        errors={errors}
                        clearErrors={clearErrors}
                        rules={{ required: "Tgl. Tugas wajib diisi" }}
                        shouldUnregister={true}
                        render={({ field }) => (
                          <DateRange2
                            startDate={watch("StartDate") || null}
                            endDate={field.value}
                            onChange={(value) => {
                              setValue("StartDate", value[0]);
                              field.onChange(value[1]);
                            }}
                          />
                        )}
                      />
                    </VerticalGrid>

                    <VerticalGrid label={"Status"}>
                      <MainController
                        controller={Controller}
                        name="TaskStatus"
                        control={control}
                        defaultValue={1}
                        errors={errors}
                        clearErrors={clearErrors}
                        shouldUnregister={true}
                        rules={{ required: "TaskStatus wajib diisi" }}
                        render={({ field }) => (
                          <MainAutocomplete
                            {...field}
                            options={statusList}
                            typefield={1}
                            value={
                              statusList.find(
                                (item) => item.value == field.value
                              ) || null
                            }
                            isOptionEqualToValue={(option, value) =>
                              option.value == value.value
                            }
                            onChange={(_, nv) => {
                              if (nv == null) {
                                field.onChange(1);
                              }
                              field.onChange(nv.value);
                            }}
                          />
                        )}
                      />
                    </VerticalGrid>

                    <VerticalGrid label={"Penyelenggara Voucer"} md={12}>
                      <MainController
                        controller={Controller}
                        name="Issuers"
                        control={control}
                        defaultValue={[]}
                        errors={errors}
                        clearErrors={clearErrors}
                        rules={{ required: "Penyelenggara Voucer wajib diisi" }}
                        shouldUnregister={true}
                        render={({ field }) => (
                          <>
                            <PopperInputCheckBox
                              data={ListM || []}
                              placeholder="Pilih penyelenggara"
                              onClick={() => {
                                fetchData();
                              }}
                              loading={LoadingM}
                              value={field.value}
                              onChange={(nv) => {
                                if (
                                  Boolean(
                                    field.value.find((i) => i == nv.IssuerCode)
                                  )
                                ) {
                                  const filter = field.value.filter(
                                    (i) => i != nv.IssuerCode
                                  );
                                  field.onChange(filter);
                                } else {
                                  field.onChange([
                                    ...field.value,
                                    nv.IssuerCode,
                                  ]);
                                }
                              }}
                            />

                            <List
                              data={field.value.map((item) => {
                                return ListM?.find((i) => i.IssuerCode == item);
                              })}
                              listKey={"IssuerName"}
                              onDelete={(item) => {
                                const filter = field.value.filter(
                                  (i) => i != item.IssuerCode
                                );
                                field.onChange(filter);
                              }}
                            />
                          </>
                        )}
                      />
                    </VerticalGrid>

                    <VerticalGrid label={"Voucer"} md={12}>
                      <MainController
                        controller={Controller}
                        name="Vouchers"
                        control={control}
                        errors={errors}
                        defaultValue={[]}
                        clearErrors={clearErrors}
                        rules={{ required: "Voucer wajib diisi" }}
                        shouldUnregister={true}
                        render={({ field }) => (
                          <>
                            <ListCollapse
                              onOpen={() => {
                                // if (process.env.NODE_ENV !== "production") {
                                //   API.defaults.baseURL =
                                //     "http://10.10.101.0:8070/api/inisa";
                                // }
                                const data = new FormData();
                                data.append(
                                  "codes",
                                  JSON.stringify(getValues("Issuers"))
                                );

                                listVoucerByIssuer.setResponse([]);
                                listVoucerByIssuer.fetch(
                                  {
                                    limit: 10,
                                    offset: 0,
                                  },
                                  data
                                );
                              }}
                              loading={listVoucerByIssuer.loading}
                              value={field.value}
                              data={listVoucerByIssuer.response || []}
                              onClick={(item, list) => {
                                const copy = [...field.value];
                                const find = copy.some(
                                  (data) => data.IssuerCode == item.IssuerCode
                                );

                                const createObj = {
                                  ...item,
                                  list: [list],
                                };

                                if (find) {
                                  const findNested = copy.some(
                                    (i) =>
                                      i.IssuerCode == i.IssuerCode &&
                                      i.list.some(
                                        (j) => j.PrefixCode == list.PrefixCode
                                      )
                                  );
                                  const i = copy.findIndex(
                                    (data) => data.IssuerCode == item.IssuerCode
                                  );
                                  const j = copy[i].list.findIndex(
                                    (data) => data.PrefixCode == list.PrefixCode
                                  );

                                  if (findNested) {
                                    copy[i].list.splice(j, 1);
                                    if (copy[i].list.length == 0) {
                                      copy.splice(i, 1);
                                    }
                                    return field.onChange(copy);
                                  } else {
                                    copy[i].list = [...copy[i].list, list];
                                    return field.onChange(copy);
                                  }
                                } else {
                                  const ch = [...copy, createObj];
                                  return field.onChange(ch);
                                }
                              }}
                            />

                            <ListTitle
                              data={field.value}
                              listKey={"VoucherName"}
                              onDelete={(item, list) => {
                                const copy = [...field.value];
                                const i = copy.findIndex(
                                  (data) => data.IssuerCode == item.IssuerCode
                                );
                                const j = copy[i].list.findIndex(
                                  (data) => data.PrefixCode == list.PrefixCode
                                );
                                copy[i].list.splice(j, 1);
                                if (copy[i].list.length == 0) {
                                  copy.splice(i, 1);
                                }
                                return field.onChange(copy);
                              }}
                            />
                          </>
                        )}
                      />
                    </VerticalGrid>
                  </Grid>
                </Collapse>
              )}
            </TransitionGroup>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={4}
          order={{
            xs: 1,
            md: 2,
          }}
        >
          <VerticalGrid label={"Foto Profil"} md={12}>
            <MainController
              controller={Controller}
              name="Photo"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              desc="Allowed file types: png, jpg, jpeg."
              render={({ field }) => (
                <FileInputBox
                  {...field}
                  setError={setError}
                  onClose={() => field.onChange(null)}
                />
              )}
            />
          </VerticalGrid>
        </Grid>
      </Grid>
    </MainDialog>
  );
};

Dialog.propTypes = {
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  data: PropTypes.object,
  id: PropTypes.any,
  setRes: PropTypes.func,
};

Dialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  data: null,
  id: null,
  setRes: null,
};
export default Dialog;
