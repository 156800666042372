import PropTypes from "prop-types";
import { Box, Button, Grid, Typography } from "@mui/material";
import NoImageIcon from "assets/paper/NoImageIcon";
import Autocompletev2 from "components/AutoComplete/Autocompletev2";
import VerticalGrid from "components/Grid/VerticalGrid";
import MainCard from "components/Paper/MainCard";
import TextFieldv2 from "components/TextField/TextFieldv2";
import React from "react";
import FileDrop from "components/FileDrop/FileDrop";
import TextFieldv3 from "components/TextField/TextFieldv3";
import MainController from "components/Controller/MainController";


const Form2 = ({
  controller: Controller,
  control,
  errors,
  clearErrors,
  setError,
}) => {
  return (
    <>
      <Grid container spacing={3} mt={1}>
        <VerticalGrid label={"KTP Pemilik Bisnis"} md={12}>
          <MainController
            controller={Controller}
            name="BizKtpImg"
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            rules={{ required: "KTP Pemilik Bisnis Pemilik wajib diisi" }}
            render={({ field }) => (
              <FileDrop
                {...field}
                onClose={() => field.onChange(null)}
                setError={setError}
              />
            )}
          />
        </VerticalGrid>
        <VerticalGrid label={"Nama Sesuai KTP"}>
          <MainController
            controller={Controller}
            name="BizKtpName"
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            rules={{ required: "Nama Sesuai KTP Pemilik wajib diisi" }}
            render={({ field }) => (
              <TextFieldv3 {...field} placeholder="Masukkan nama" />
            )}
          />
        </VerticalGrid>
        <VerticalGrid label={"Nomor KTP"}>
          <MainController
            controller={Controller}
            name="BizKtpNo"
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            rules={{ required: "Nomor KTP Pemilik wajib diisi" }}
            render={({ field }) => (
              <TextFieldv3 {...field} placeholder="Masukkan nomor KTP" />
            )}
          />
        </VerticalGrid>
        <VerticalGrid label={"NPWP Pemilik Usaha"} md={12}>
          <MainController
            controller={Controller}
            name="BizNpwpImg"
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            rules={{ required: "Nomor KTP Pemilik wajib diisi" }}
            render={({ field }) => (
              <FileDrop
                {...field}
                onClose={() => field.onChange(null)}
                setError={setError}
              />
            )}
          />
        </VerticalGrid>
        <VerticalGrid label={"Nama Sesuai NPWP"}>
          <MainController
            controller={Controller}
            name="BizNpwpName"
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            rules={{ required: "Nama Sesuai NPWP wajib diisi" }}
            render={({ field }) => <TextFieldv3 {...field} placeholder="Masukkan nama" />}
          />
        </VerticalGrid>
        <VerticalGrid label={"Nomor NPWP"}>
          <MainController
            controller={Controller}
            name="BizNpwpNo"
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            rules={{ required: "Nomor NPWP wajib diisi" }}
            render={({ field }) => (
              <TextFieldv3 {...field} placeholder="Masukkan nomor npwp" />
            )}
          />
        </VerticalGrid>
        <VerticalGrid label={"Alamat Sesuai NPWP"} md={12}>
          <MainController
            controller={Controller}
            name="BizNpwpAlamat"
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            rules={{ required: "Alamat Sesuai NPWP wajib diisi" }}
            render={({ field }) => <TextFieldv3 {...field} placeholder="Masukkan alamat" />}
          />
        </VerticalGrid>
      </Grid>
    </>
  );
};

Form2.propTypes = {
  clearErrors: PropTypes.any,
  control: PropTypes.any,
  controller: PropTypes.any,
  errors: PropTypes.any,
  setError: PropTypes.func,
};

Form2.defaultProps = {
  setValue: null,
  clearErrors: null,
  control: null,
  controller: null,
  errors: null,
  setError: null,
};

export default Form2;
