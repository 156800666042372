export const defaultValues = {
  IssuerName: "",
  IssuerCode: "",
  IssuerStatus: 1,
};

export const statusList = [
  {
    label: "Aktif",
    value: 1,
  },
  {
    label: "Tidak Aktif",
    value: 0,
  },
];

const StatusVoucher = [
  {
    label: "Diajukan",
    value: 0,
  },
  {
    label: "Disetujui",
    value: 1,
  },
  {
    label: "Dipublikasikan",
    value: 2,
  },
  {
    label: "Dibatalkan",
    value: 3,
  },
  {
    label: "Dikeluarkan",
    value: 4,
  },
  {
    label: "Ditunda",
    value: 5,
  },
  {
    label: "Diakhiri",
    value: 6,
  },
];
