import { RemoveRedEye } from "@mui/icons-material";
import { Box, Grid, Paper, Typography } from "@mui/material";
import IlustrasiKartuSubsidi from "assets/penerimaBantuan/IlustrasiKartuSubsidi";
import MainLoading from "components/Loading/MainLoading";
import PageTitle from "components/Page/PageTitle";
import MainPagination from "components/Pagination";
import MainCard from "components/Paper/MainCard";
import MainStatus from "components/Status/MainStatus";
import Validasi from "components/Status/Validasi";
import MainTable from "components/Table/MainTable";
import FormatRupiah from "helper/FormatRupiah";
import useFetchData from "hook/useFetchData";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  bansosDetailUserSubsidi,
  bansosRiwayatTransaksi,
} from "redux/penerimaBantuan/action";
import {
  Params,
  CurrentPage,
  List,
  Loading,
  TotalItems,
} from "redux/penerimaBantuan/KartuSubsidi/RiwayatTransaksi/selectors";
import {
  changeLimit,
  changePage,
} from "redux/penerimaBantuan/KartuSubsidi/RiwayatTransaksi/slice";
import DataKartu from "./DataKartu";
import DetailKartuSubsidi from "./Dialog/DetailKartuSubsidi";
import DetailTransaksi from "./Dialog/DetailTransaksi";
import KartuSubsidiUserStatus from "components/Status/KartuSubsidiUser/";
const arr = [
  {
    label: "Gagal",
    value: 0,
  },
  {
    label: "Berhasil",
    value: 1,
  },
  {
    label: "Tertunda",
    value: 6,
  },
  {
    label: "Dibatalkan",
    value: 2,
  },
];

const headers = [
  {
    name: "No. Transaksi",
    key: "trxReceipt",
    // width: 20,
  },
  {
    name: "Nama Transaksi",
    key: "trxProductName",
    // width: 35,
  },
  {
    name: "Tgl. Transaksi",
    custom: (item) => {
      return item.trxDate ? moment(item.trxDate).format("DD / MM / YYYY") : "-";
    },
    // width: 25,
  },
  {
    name: "Nominal",
    custom: (item) => {
      return `${item.trxFilter === "TRXOUT" ? `-` : "+"} Rp. ${FormatRupiah(
        item.trxAmount
      )}`;
    },
    // width: 20,
  },
  {
    name: "Status Transaksi",
    custom: (item) => {
      return <KartuSubsidiUserStatus value={item?.trxStatus} />;
    },
    // width: 20,
  },
];

const DetailUser = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const params = useSelector(Params);
  const currentPage = useSelector(CurrentPage);
  const list = useSelector(List);
  const load = useSelector(Loading);
  const totalItems = useSelector(TotalItems);
  const [open, setOpen] = useState({
    openDialog: false,
    dialogTransaksi: false,
  });
  const [detailTransaksi, setDetailTransaksi] = useState([]);
  const { fetch, response, loading } = useFetchData({
    action: bansosDetailUserSubsidi,
    snackbar: false,
  });

  useEffect(() => {
    fetch(id);
  }, []);

  useEffect(() => {
    const io = () => {
      fetchData();
    };
    io();
  }, [params.limit]);

  const fetchData = async () => {
    await dispatch(
      bansosRiwayatTransaksi({
        id,
        params,
      })
    );
  };

  const handleOpenDetailTransaksi = (item) => {
    handleDetail(item);
    setOpen({ ...open, dialogTransaksi: true });
  };

  const handleDetail = (item) => {
    let informasi = {};
    let inf = [];

    const parse = item.trxDataResult;
    if (item.trxGroup === "PLNPASCA") {
      informasi = {
        Status: {
          name: item.trxStatus,
          format: "status",
        },
        "Tgl. Transaki": {
          name: item.trxDate,
        },
        "No. Transaksi": {
          name: item.trxReceipt,
          isCopy: true,
        },
        "No. Referensi": {
          name: parse.REF1,
          isCopy: true,
        },
        "Jenis Transaksi": {
          name: "PLN Pascabayar",
        },
        "ID Pelanggan": {
          name: parse.IDPEL1,
        },
        Nama: {
          name: parse.NAMA_PELANGGAN,
        },
        "Periode Tagihan": {
          name: parse.PERIODE,
        },
        "Total Tagihan": {
          name: item.trxAmount,
          format: "rupiah",
        },
        "Biaya Transaksi": {
          name: item.trxFee,
          format: "rupiah",
        },
        Total: {
          name: item.trxAmountBill,
          format: "rupiah",
        },
        "Metode Pembayaran": {
          name: "Kartu Subsidi",
        },
      };
    }

    if (item.trxGroup.includes("PLN")) {
      informasi = {
        Status: {
          name: item.trxStatus,
          format: "status",
        },
        "Tgl. Transaki": {
          name: item.trxDate,
        },
        "No. Transaksi": {
          name: item.trxReceipt,
          isCopy: true,
        },
        "No. Referensi": {
          name: parse.REF1,
          isCopy: true,
        },
        "Jenis Transaksi": {
          name: "PLN Prabayar",
        },
        "ID Pelanggan": {
          name: parse?.IDPEL1,
        },
        "No. Meter": {
          name: parse?.REF3,
        },
        Nama: {
          name: parse?.NAMA_PELANGGAN,
        },
        Token: {
          name: parse?.DETAIL?.TOKEN || "-",
        },
        Harga: {
          name: item.trxAmount,
          format: "rupiah",
        },
        "Biaya Transaksi": {
          name: item.trxFee,
          format: "rupiah",
        },
        Total: {
          name: item.trxAmountBill,
          format: "rupiah",
        },
        "Metode Pembayaran": {
          name: "Kartu Subsidi",
        },
      };
    }

    if (item.trxGroup.includes("PDAM")) {
      informasi = {
        Status: {
          name: item.trxStatus,
          format: "status",
        },
        "Tgl. Transaki": {
          name: item.trxDate,
          format: "tanggal",
        },
        "No. Transaksi": {
          name: item.trxReceipt,
          isCopy: true,
        },
        "No. Referensi": {
          name: parse.REF1,
          isCopy: true,
        },
        "Jenis Transaksi": {
          name: "PDAM",
        },
        "ID Pelanggan": {
          name: parse.IDPEL1,
        },
        Nama: {
          name: parse.NAMA_PELANGGAN,
        },
        "Periode Tagihan": {
          name: parse.PERIODE,
        },
        "Pemakaian Meter": {
          name: "0 M3",
        },
        "Total Tagihan": {
          name: item.trxAmount,
          format: "rupiah",
        },
        "Biaya Transaksi": {
          name: item.trxFee,
          format: "rupiah",
        },
        Total: {
          name: item.trxAmountBill,
          format: "rupiah",
        },
        "Metode Pembayaran": {
          name: "Kartu Subsidi",
        },
      };
    }

    if (item.trxGroup.includes("internet")) {
      informasi = {
        Status: {
          name: item.trxStatus,
          format: "status",
        },
        "Tgl. Transaki": {
          name: item.trxDate,
          format: "tanggal",
        },
        "No. Transaksi": {
          name: item.trxReceipt,
          isCopy: true,
        },
        "No. Referensi": {
          name: parse.REF1,
          isCopy: true,
        },
        "Jenis Transaksi": {
          name: "Internet & TV Kabel",
        },
        "ID Pelanggan": {
          name: parse.IDPEL1,
        },
        Nama: {
          name: parse.NAMA_PELANGGAN,
        },
        "Periode Tagihan": {
          name: parse.PERIODE,
        },
        "Total Tagihan": {
          name: item.trxAmount,
          format: "rupiah",
        },
        "Biaya Transaksi": {
          name: item.trxFee,
          format: "rupiah",
        },
        Total: {
          name: item.trxAmountBill,
          format: "rupiah",
        },
        "Metode Pembayaran": {
          name: "Kartu Subsidi",
        },
      };
    }

    for (const key in informasi) {
      inf.push({
        name: key,
        value: renderValue(informasi[key]),
        isCopy: informasi[key].isCopy ? true : false,
      });
    }
    setDetailTransaksi(inf);
  };

  const renderValue = (data) => {
    if (data.format === "status") {
      return (
        <Validasi value={data.name} textTrue="Berhasil" textFalse="Gagal" />
      );
    } else if (data.format === "rupiah") {
      return `Rp. ${FormatRupiah(data.name)}`;
    } else if (data.format === "tanggal") {
      return moment(data.name).format("YYYY / MM / DD");
    } else {
      return String(data.name) || "-";
    }
  };

  return (
    <>
      <PageTitle
        title={`Detail Kartu Subsidi`}
        text="Dasbor / CSR Bansos / Penerima Bantuan / Detail Penerima Bantuan / Kartu Subsidi / "
        lastText="Detail Kartu Subsidi"
      />
      <Grid container sx={{ p: 2 }} spacing={2}>
        <Grid xs={12} md={5} item>
          <DataKartu
            response={response}
            loading={loading}
            onClick={() =>
              setOpen({
                ...open,
                openDialog: true,
              })
            }
          />
        </Grid>
        <Grid xs={12} md={7} item>
          <Paper
            sx={{
              height: "100%",
              width: "100%",
              p: 2,
              borderRadius: "4px",
              boxShadow: "0px 1px 8px 1px rgba(0, 0, 0, 0.04)",
            }}
          >
            <Box display="flex" justifyContent="space-between" flexWrap="wrap">
              <Box display="flex" flexDirection="column">
                <Typography
                  variant="cardTitle"
                  sx={{
                    width: "290px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {response?.data_penerima?.nama}
                </Typography>
                <Typography
                  variant="cardDesc"
                  sx={{
                    width: "290px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Penerima kartu subsidi{" "}
                  {response?.data_kartu?.nama_kartu || "-"}
                </Typography>
                <Box
                  sx={{ marginTop: "20%" }}
                  display="flex"
                  flexDirection="column"
                >
                  <Typography variant="cardDesc">
                    Sisa saldo saat ini
                  </Typography>
                  <Typography variant="cardTitle" mt={2}>
                    <span style={{ float: "left", marginTop: "10px" }}>
                      Rp.
                    </span>
                    &nbsp;&nbsp;
                    <Typography
                      variant="cardTitle"
                      sx={{
                        fontSize: "40px !important",
                      }}
                    >
                      {FormatRupiah(response?.data_penerima?.saldo)}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  marginTop: "auto",
                  display: {
                    xs: "none",
                    md: "block",
                  },
                }}
              >
                <IlustrasiKartuSubsidi />
              </Box>
            </Box>
          </Paper>
        </Grid>
        <Grid xs={12} item>
          <MainCard
            title="Riwayat Transaksi"
            addButton={false}
            isEdit={false}
            sx={{
              marginTop: "0px !important",
            }}
          >
            <MainTable
              sx={{
                width: "100%",
                maxWidth: "100%",
              }}
              headers={headers}
              loading={load}
              data={list}
              action="Aksi"
              align="center"
              summaryKey="trxProductName"
              detail={[
                {
                  type: "button",
                  tooltip: "Lihat Detail Transaksi",
                  variant: "icon:eye",
                  onClick: (_, item) => handleOpenDetailTransaksi(item),
                },
              ]}
            />
            <MainPagination
              params={params}
              changeLimit={changeLimit}
              changePage={changePage}
              totalItems={totalItems}
              currentPage={currentPage}
            />
          </MainCard>
        </Grid>
      </Grid>

      <DetailKartuSubsidi
        open={open.openDialog}
        onClose={() =>
          setOpen({
            ...open,
            openDialog: false,
          })
        }
        response={response}
      />

      <DetailTransaksi
        open={open.dialogTransaksi}
        onClose={() =>
          setOpen({
            ...open,
            dialogTransaksi: false,
          })
        }
        detail={detailTransaksi}
      />
    </>
  );
};

export default DetailUser;
