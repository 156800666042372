import React from "react";

const TukarKartuSubsidi = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6 3.40002H3.4C2.623 3.40002 2.007 4.02302 2.007 4.80002L2 13.2C2 13.977 2.623 14.6 3.4 14.6H14.6C15.377 14.6 16 13.977 16 13.2V4.80002C16 4.02302 15.377 3.40002 14.6 3.40002ZM14.6 13.2H3.4V9.00002H14.6V13.2ZM14.6 6.20002H3.4V4.80002H14.6V6.20002Z"
        fill="white"
      />
    </svg>
  );
};

export default TukarKartuSubsidi;
