import PropTypes from "prop-types";
import {
  Typography,
  Box,
  Pagination,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";

const MainPagination = ({
  params,
  changeLimit,
  changePage,
  totalItems,
  currentPage,
}) => {
  const dispatch = useDispatch();

  return (
    <Box
      display={"flex"}
      justifyContent="space-between"
      flexWrap={"wrap"}
      gap={2}
      p={2}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Typography variant="textSelect">Tampilkan</Typography>
        <Typography
          component="select"
          sx={{
            borderRadius: "4px",
            border: "1px solid #d0d0d0",
            padding: "5px",
            fontFamily: "inherit",
            outline: "none",
          }}
          variant="textMedium"
          onChange={(e) => {
            dispatch(changeLimit({ limit: e.target.value }));
          }}
          value={params?.limit}
        >
          <option>10</option>
          <option>25</option>
          <option>50</option>
          <option>100</option>
        </Typography>
        <Typography variant="textSelect">dari {totalItems}</Typography>
      </Box>
      <Pagination
        count={parseInt(Math.ceil(totalItems / params?.limit))}
        onChange={(_, page) => {
          dispatch(
            changePage({
              limit: params?.limit,
              page: page,
            })
          );
        }}
        shape="rounded"
        page={currentPage}
      />
    </Box>
  );
};

MainPagination.propTypes = {
  changeLimit: PropTypes.func,
  changePage: PropTypes.func,
  params: PropTypes.shape({
    limit: PropTypes.any,
  }),
  totalItems: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MainPagination.defaultProps = {
  changeLimit: () => {},
  changePage: () => {},
  params: {},
  totalItems: 0,
  currentPage: 0,
};
export default MainPagination;
