import React from "react";

const ScanGagal = () => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="128" height="128" rx="64" fill="#F5F6F8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5373 29.3203C16.248 29.7735 13.9523 32.8121 14.4149 36.1L21.7034 87.9035C22.1359 90.9777 25.236 92.9188 28.1904 91.9652L37.5119 88.9565C40.9064 87.8608 44.6276 88.8733 46.9991 91.5376L56.5026 102.215C57.644 103.497 59.7643 102.84 59.9806 101.137L60.9258 93.6944C61.4204 89.8005 64.3814 86.6762 68.2432 85.9736L113.9 77.6674C117.316 77.0459 119.493 73.6627 118.643 70.2958L106.631 22.7084C105.882 19.7433 103.023 17.8156 99.994 18.2331L19.5373 29.3203ZM12.977 45.4402L19.3242 92.2353C19.356 92.4699 19.4085 92.7011 19.4812 92.9264C20.1386 94.963 22.2748 96.1098 24.3191 95.5626L24.5165 95.5043L33.8094 92.5048C37.4818 91.3195 41.4984 92.3548 44.1396 95.1429L44.3508 95.3728L53.8543 106.05C54.0149 106.23 54.2355 106.347 54.4753 106.377C54.984 106.442 55.4519 106.112 55.5719 105.626L55.5933 105.511L57.5773 105.763C57.3686 107.407 55.8669 108.57 54.2233 108.361C53.576 108.279 52.9753 107.988 52.5102 107.536L52.3603 107.38L42.8568 96.7025C40.8127 94.4059 37.6404 93.4901 34.6988 94.3248L34.4238 94.4081L25.1309 97.4076C21.9774 98.4255 18.5958 96.6942 17.5779 93.5407C17.4961 93.2873 17.4313 93.0288 17.3839 92.767L17.3423 92.5041L10.9951 45.709L12.977 45.4402ZM110.647 81.1119L111.146 83.0488L110.901 83.1071L110.654 83.1565L65.0269 91.4575L64.6689 89.4898L110.296 81.1888C110.414 81.1674 110.531 81.1417 110.647 81.1119ZM74.1733 34.7604C75.0757 33.3708 76.9337 32.9759 78.3233 33.8783C79.6614 34.7473 80.0771 36.5024 79.3003 37.872L79.2054 38.0282L69.1499 53.5122L84.6339 63.5676C86.0235 64.47 86.4184 66.328 85.516 67.7176C84.6471 69.0557 82.892 69.4714 81.5223 68.6946L81.3661 68.5997L65.8821 58.5442L55.8267 74.0282C54.9243 75.4178 53.0663 75.8127 51.6768 74.9103C50.3387 74.0414 49.9229 72.2863 50.6997 70.9166L50.7947 70.7604L60.8501 55.2764L45.3661 45.221C43.9765 44.3186 43.5816 42.4606 44.484 41.0711C45.353 39.733 47.1081 39.3172 48.4777 40.094L48.6339 40.189L64.1179 50.2444L74.1733 34.7604Z"
        fill="#EB5050"
      />
      <mask
        id="mask0_2316_86940"
        maskUnits="userSpaceOnUse"
        x="108"
        y="11"
        width="10"
        height="10"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M108 11H118V21H108V11Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2316_86940)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118 15.9997C116.618 15.9997 115.37 16.5607 114.469 17.4615C113.561 18.3695 113 19.6175 113 21C113 19.6175 112.439 18.3695 111.539 17.4615C110.63 16.5607 109.382 15.9997 108 15.9997C109.382 15.9997 110.63 15.4388 111.539 14.5307C112.439 13.6299 113 12.382 113 10.9995C113 13.7561 115.244 15.9997 118 15.9997Z"
          fill="#B5B5B5"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 113.001C21.8939 113.001 20.8961 113.449 20.1757 114.169C19.4489 114.896 18.9995 115.895 18.9995 117C18.9995 115.895 18.5511 114.896 17.8307 114.169C17.1039 113.449 16.1061 113.001 15 113.001C16.1061 113.001 17.1039 112.552 17.8307 111.825C18.5511 111.105 18.9995 110.106 18.9995 109C18.9995 111.206 20.7941 113.001 23 113.001Z"
        fill="#B5B5B5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61 10C60.1703 10 59.4213 10.3364 58.8814 10.8761C58.3359 11.4216 57.9994 12.1705 57.9994 13C57.9994 12.1705 57.663 11.4216 57.1231 10.8761C56.5776 10.3364 55.8297 10 55 10C55.8297 10 56.5776 9.66364 57.1231 9.11818C57.663 8.57841 57.9994 7.82955 57.9994 7C57.9994 8.65455 59.3463 10 61 10Z"
        fill="#B5B5B5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126 66C125.17 66 124.421 66.3364 123.881 66.8761C123.336 67.4216 122.999 68.1705 122.999 69C122.999 68.1705 122.663 67.4216 122.123 66.8761C121.578 66.3364 120.83 66 120 66C120.83 66 121.578 65.6636 122.123 65.1182C122.663 64.5784 122.999 63.8295 122.999 63C122.999 64.6545 124.346 66 126 66Z"
        fill="#B5B5B5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 55C9.1703 55 8.42129 55.3364 7.88142 55.8761C7.33586 56.4216 6.99943 57.1705 6.99943 58C6.99943 57.1705 6.663 56.4216 6.12313 55.8761C5.57757 55.3364 4.8297 55 4 55C4.8297 55 5.57757 54.6636 6.12313 54.1182C6.663 53.5784 6.99943 52.8295 6.99943 52C6.99943 53.6545 8.34628 55 10 55Z"
        fill="#B5B5B5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113 117C111.894 117 110.895 117.448 110.176 118.169C109.448 118.896 109 119.894 109 121C109 119.894 108.551 118.896 107.83 118.169C107.104 117.448 106.105 117 105 117C106.105 117 107.104 116.552 107.83 115.825C108.551 115.104 109 114.106 109 113C109 115.205 110.795 117 113 117Z"
        fill="#B5B5B5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 17.5C21.203 17.5 19.5806 18.2291 18.41 19.3997C17.2291 20.5806 16.5 22.203 16.5 24C16.5 22.203 15.7709 20.5806 14.6003 19.3997C13.4194 18.2291 11.797 17.5 10 17.5C11.797 17.5 13.4194 16.7709 14.6003 15.59C15.7709 14.4194 16.5 12.797 16.5 11C16.5 14.5837 19.4163 17.5 23 17.5Z"
        fill="#B5B5B5"
      />
    </svg>
  );
};

export default ScanGagal;
