import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import React, { useMemo, forwardRef } from "react";

const PilihTransaksi = forwardRef(
  ({ ilu: Ilu, title, desc, onChange, isActive }, ref) => {
    const Active = useMemo(() => {
      if (isActive) {
        return {
          borderBottom: "1px solid #CC1D15",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
        };
      } else {
        return false;
      }
    }, [isActive]);
    return (
      <Box
        ref={ref}
        sx={{
          display: "flex",
          gap: 1,
          p: 1,
          cursor: "pointer",
          borderBottom: "none",
          boxShadow: "none",
          transition: "all 0.2s ease-out",
          "&:hover": {
            borderBottom: !Active && "1px solid #878889",
            boxShadow: !Active && "0px 4px 10px rgba(0, 0, 0, 0.2)",
          },
          borderRadius: "8px",
          ...Active,
        }}
        onClick={onChange}
      >
        <Box width="35px">
          <Ilu />
        </Box>
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="label3">{title}</Typography>
          <Typography variant="desc2">{desc}</Typography>
        </Box>
      </Box>
    );
  }
);

PilihTransaksi.displayName = "pt";
PilihTransaksi.propTypes = {
  desc: PropTypes.any,
  ilu: PropTypes.any,
  isActive: PropTypes.any,
  title: PropTypes.any,
  onChange: PropTypes.func,
};

PilihTransaksi.defaultProps = {
  desc: "",
  ilu: null,
  isActive: false,
  title: "",
  onChange: null,
};

export default PilihTransaksi;
