import PropTypes from "prop-types";
import { ExpandMore } from "@mui/icons-material";
import { Autocomplete, Popper } from "@mui/material";
import React, { forwardRef } from "react";
import TextFieldv2 from "components/TextField/TextFieldv2";

const PopperCom = (io) => {
  return (
    <Popper
      {...io}
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 10],
          },
        },
      ]}
    />
  );
};

const Autocompletev2 = forwardRef((props, ref) => {
  return (
    <Autocomplete
      {...props}
      ref={ref}
      PopperComponent={PopperCom}
      renderInput={(p) => (
        <TextFieldv2
          {...p}
          placeholder={props.placeholder}
          InputProps={{
            ...p.InputProps,
            endAdornment: (
              <>
                <ExpandMore
                  onMouseDown={p.inputProps.onMouseDown}
                  sx={{
                    position: "absolute",
                    right: "13px",
                    color: "#4E4751",
                  }}
                />
              </>
            ),
          }}
        />
      )}
    />
  );
});

Autocompletev2.propTypes = {
  placeholder: PropTypes.string,
};

Autocompletev2.defaultProps = {
  placeholder: "-",
};
Autocompletev2.displayName = "mac";
export default Autocompletev2;
