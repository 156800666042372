import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/Authentication/Login";
import PrivateRoute from "components/PrivateRoute";
import Dasbor from "pages/Dasbor";
import PageNotFound from "pages/PageNotFound";
import Register from "pages/Authentication/Register";
import penebitRoutes from "./penerbit";
import kartuSubsidiRoutes from "./kartu-subsidi";
import voucherRoutes from "./voucer";
import subsidiBansosRoutes from "./subsidi-bansos";
import penerimaBantuanRoutes from "./penerima-bantuan";
import voucherBansosRoutes from "./voucher-bansos";
import penggunaRoutes from "./pengguna";
import BussinesRegistration from "pages/BussinesRegistration";
import ChartDasbor from "pages/Dasbor/ChartDasbor";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import ForgotPasswordConfirm from "pages/Authentication/ForgotPasswordKonfirmasi";
import PrivateRoutev2 from "components/PrivateRoutev2";
import NotifVerifikasiEmail from "pages/Authentication/NotifVerifikasiEmail";
import VerifikasiEmail from "pages/Authentication/VerfikasiEmail";
import redeemRoutes from "./redeem";
import inventoryRoutes from "./inventory";
import reportRoutes from "./report";

const router = createBrowserRouter([
  {
    path: "/auth/login/",
    element: <Login />,
  },
  {
    path: "/auth/register/",
    element: <Register />,
  },
  {
    path: "/auth/forgot-password/",
    element: <ForgotPassword />,
  },
  {
    path: "/auth/forgot-password-confirm",
    element: <ForgotPasswordConfirm />,
  },
  {
    element: <PrivateRoutev2 />,
    children: [
      {
        path: "/bussines-registration/",
        element: <Dasbor />,
      },
    ],
  },
  {
    element: <PrivateRoute />,
    children: [
      {
        path: "/",
        element: <ChartDasbor />,
      },
      ...penebitRoutes,
      ...kartuSubsidiRoutes,
      ...voucherRoutes,
      ...subsidiBansosRoutes,
      ...voucherBansosRoutes,
      ...penerimaBantuanRoutes,
      ...penggunaRoutes,
      ...redeemRoutes,
      ...inventoryRoutes,
      ...reportRoutes,
    ],
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
  {
    path: "/bussines-registration-complete/",
    element: <BussinesRegistration />,
  },
  {
    path: "/notif-verifikasi-email/",
    element: <NotifVerifikasiEmail />,
  },
  {
    path: "/verifikasi-merchant/",
    element: <VerifikasiEmail />,
  },
]);

export default router;
