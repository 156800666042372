import React from "react";

const FileUpload = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00837 8.67501L5.83337 7.50001L10 3.33334L14.1667 7.50001L12.9917 8.67501L10.8334 6.52501V13.3333H9.16671V6.52501L7.00837 8.67501ZM15 15V12.5H16.6667V15C16.6667 15.9167 15.9167 16.6667 15 16.6667H5.00004C4.08337 16.6667 3.33337 15.9167 3.33337 15V12.5H5.00004V15H15Z"
        fill="black"
      />
      <mask
        id="mask0_1231_4423"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="14"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.00837 8.67501L5.83337 7.50001L10 3.33334L14.1667 7.50001L12.9917 8.67501L10.8334 6.52501V13.3333H9.16671V6.52501L7.00837 8.67501ZM15 15V12.5H16.6667V15C16.6667 15.9167 15.9167 16.6667 15 16.6667H5.00004C4.08337 16.6667 3.33337 15.9167 3.33337 15V12.5H5.00004V15H15Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1231_4423)">
        <rect width="20" height="20" fill="currentColor" />
      </g>
    </svg>
  );
};

export default FileUpload;
