import PropTypes from "prop-types";
import { ExpandMore, Square } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ClickAwayListener,
  Grow,
  Popper,
  Typography,
} from "@mui/material";
import TextFieldv3 from "components/TextField/TextFieldv3";
import React, { useState } from "react";
import MainLoading from "components/Loading/MainLoading";
import MainCheckbox from "components/Checkbox/MainCheckbox";

const ListCollapse = ({ value, data, onOpen, loading, onClick }) => {
  const [open, setOpen] = useState(null);

  return (
    <Box display="flex" flexDirection="column">
      <TextFieldv3
        onClick={(e) => {
          e.stopPropagation();
          if (!open) {
            onOpen();
            setOpen(e.currentTarget);
          }
        }}
        readOnly
        placeholder="Pilih voucer"
        InputProps={{
          readOnly: true,
          endAdornment: (
            <>
              <ExpandMore
                sx={{
                  position: "absolute",
                  right: "13px",
                  color: "#4E4751",
                }}
              />
            </>
          ),
        }}
      />

      <ClickAwayListener
        onClickAway={(e) => {
          e.stopPropagation();
          setOpen(null);
        }}
      >
        <Popper
          open={Boolean(open)}
          anchorEl={open}
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 10],
              },
            },
            {
              name: "preventOverflow",
              enabled: true,
              options: {
                altAxis: true,
                altBoundary: true,
                rootBoundary: "document",
              },
            },
          ]}
          transition
          sx={{
            zIndex: 9999,
            boxShadow: "0px 1px 8px 1px rgba(0, 0, 0, 0.06)",
            width: open?.offsetWidth,
            background: "#fff",
            height: "300px",
            overflow: "auto",
            borderRadius: "6px",
          }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={300}>
              <Box>
                <MainLoading
                  customEmptyData={!loading && !data.length}
                  loading={loading}
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {data.map((item, i) => (
                    <Accordion
                      key={i}
                      disableGutters
                      elevation={0}
                      defaultExpanded={true}
                    >
                      <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography variant="gridLabel">
                          {item.IssuerName}
                        </Typography>
                      </AccordionSummary>

                      <AccordionDetails
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        {(item?.list || []).map((list, index) => (
                          <Box
                            key={index}
                            display="flex"
                            py={1.5}
                            alignItems="flex-start"
                          >
                            <MainCheckbox
                              sx={{
                                mr: 2,
                              }}
                              onClick={() => onClick(item, list)}
                              checked={value.some(
                                (i) =>
                                  i.IssuerCode == item.IssuerCode &&
                                  i.list.some(
                                    (j) => j.PrefixCode == list.PrefixCode
                                  )
                              )}
                            />
                            <Box display="flex" flexDirection="column" gap={1}>
                              <Typography
                                key={i}
                                variant="labelMedium2"
                                sx={{
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {list.VoucherName}
                              </Typography>
                              <Typography
                                variant="desc2"
                                sx={{
                                  whiteSpace: "pre-line",
                                }}
                              >
                                {list.PrefixCode}
                              </Typography>
                            </Box>
                          </Box>
                        ))}

                        {!Boolean(item.list) && (
                          <Typography
                            key={i}
                            variant="labelMedium2"
                            sx={{
                              whiteSpace: "pre-line",
                              textAlign: "center",
                              // marginTop: "30px",
                            }}
                          >
                            Data Tidak Ditemukan
                          </Typography>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </MainLoading>
              </Box>
            </Grow>
          )}
        </Popper>
      </ClickAwayListener>
    </Box>
  );
};

ListCollapse.propTypes = {
  onClick: PropTypes.func,
  onOpen: PropTypes.func,
  selected: PropTypes.bool,
  data: PropTypes.array,
  loading: PropTypes.bool,
  value: PropTypes.array,
};

ListCollapse.defaultProps = {
  onClick: () => {},
  onOpen: () => {},
  selected: false,
  data: [],
  value: [],
  loading: false,
};

export default ListCollapse;
