import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import React from "react";

const VerticalGrid = ({ label, props, md, children }) => {
  return (
    <Grid
      item
      xs={12}
      md={md}
      {...props}
      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
    >
      <Typography variant="label2">{label}</Typography>
      {children}
    </Grid>
  );
};

VerticalGrid.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
  props: PropTypes.object,
  md: PropTypes.number,
};

VerticalGrid.defaultProps = {
  children: null,
  label: "",
  props: {},
  md: 6
};

export default VerticalGrid;
