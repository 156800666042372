import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import VerfikasiEmail from "assets/ilustrasi/verfikasi-email.png";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const NotifVerifikasiEmail = () => {
  const auth = useSelector((state) => {
    return state.auth;
  });

  return (
    <Box
      sx={{
        backgroundColor: "#F2F2F2",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          borderRadius: "16px",
          width: "90%",
          height: "90%",
          margin: "auto",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
        }}
      >
        <Box
          component="img"
          src={VerfikasiEmail}
          sx={{
            display: "flex",
            objectFit: "contain",
          }}
        />
        <Typography variant="title3">Periksa E-mail Anda</Typography>
        <Typography
          variant="text5"
          sx={{
            width: "100%",
            maxWidth: "900px",
          }}
        >
          Terimakasih telah memilih INISA Web Merchant. Kami telah mengirimkan
          email verifikasi ke{" "}
          <span
            style={{
              color: "#cc1d15",
              fontWeight: "bold",
            }}
          >
            {auth?.email}
          </span>
          . Lakukan verifikasi email sebelum 24 jam kedepan untuk melanjutkan
          proses registrasi akun bisnismu.
        </Typography>
        <Link to="/auth/login" style={{ textDecoration: "none" }}>
          <Button variant="primary" sx={{ minWidth: "300px", height: "50px" }}>
            Kembali ke Halaman Masuk
          </Button>
        </Link>
      </Paper>
    </Box>
  );
};

export default NotifVerifikasiEmail;
