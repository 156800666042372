import React from "react";

const ManualValidasi = () => {
  return (
    <svg
      width="37"
      height="40"
      viewBox="0 0 37 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.3527 8.33333V31.6667C33.3527 32.5833 33.0556 33.3667 32.4761 34.0167C31.8967 34.6667 31.1984 35 30.3812 35H6.6092C5.79203 35 5.09373 34.6667 4.51429 34.0167C3.93485 33.3667 3.6377 32.5833 3.6377 31.6667V8.33333C3.6377 7.41667 3.93485 6.63333 4.51429 5.98333C5.09373 5.33333 5.79203 5 6.6092 5H30.3812C31.1984 5 31.8967 5.33333 32.4761 5.98333C33.0556 6.63333 33.3527 7.41667 33.3527 8.33333Z"
        fill="#EB4A42"
      />
      <mask
        id="mask0_2578_95313"
        maskUnits="userSpaceOnUse"
        x="3"
        y="5"
        width="31"
        height="30"
      >
        <path
          d="M33.3527 8.33333V31.6667C33.3527 32.5833 33.0556 33.3667 32.4761 34.0167C31.8967 34.6667 31.1984 35 30.3812 35H6.6092C5.79203 35 5.09373 34.6667 4.51429 34.0167C3.93485 33.3667 3.6377 32.5833 3.6377 31.6667V8.33333C3.6377 7.41667 3.93485 6.63333 4.51429 5.98333C5.09373 5.33333 5.79203 5 6.6092 5H30.3812C31.1984 5 31.8967 5.33333 32.4761 5.98333C33.0556 6.63333 33.3527 7.41667 33.3527 8.33333Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2578_95313)">
        <path
          d="M33.3534 33.2222V34.7778C33.3534 34.8389 33.0592 34.8911 32.4856 34.9344C31.9119 34.9778 31.2206 35 30.4116 35H6.87733C6.06834 35 5.37702 34.9778 4.80337 34.9344C4.22973 34.8911 3.93555 34.8389 3.93555 34.7778V33.2222C3.93555 33.1611 4.22973 33.1089 4.80337 33.0656C5.37702 33.0222 6.06834 33 6.87733 33H30.4116C31.2206 33 31.9119 33.0222 32.4856 33.0656C33.0592 33.1089 33.3534 33.1611 33.3534 33.2222Z"
          fill="#D63129"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.09521 14.4666V12.0166H21.2293V14.4666H8.09521ZM8.09521 16.9333V19.3833H21.2293V16.9333H8.09521ZM8.09521 21.8499V24.2999H16.8463V21.8499H8.09521ZM22.7075 27.9749L22.715 27.9833H22.7001L22.7075 27.9749ZM22.7075 27.9749L28.8808 21.0499L27.3505 19.2666L22.7001 24.4833L20.3526 21.8999L18.8223 23.6166L22.7075 27.9749Z"
        fill="white"
      />
    </svg>
  );
};

export default ManualValidasi;
