import PropTypes from "prop-types";
import {
  Box,
  Divider,
  IconButton,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useEffect, useState, useMemo } from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import moment from "moment";
import TableDate from "./components/Table";
import Days from "./components/Days";
import TextFieldv3 from "components/TextField/TextFieldv3";
import CalendarIcon from "assets/paper/CalendarIcon";

const DateRange2 = ({ startDate, endDate, onChange }) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState({});
  const [loading, setLoading] = useState("");
  // const [startDate, setStartDate] = useState("2022-12-16");
  // const [endDate, setEndDate] = useState("2023-1-7");

  const imageRef = useRef();

  useEffect(() => {
    const firstDate = moment();
    const secondDate = moment(firstDate).add(1, "M");

    setDate({ firstDate, secondDate });
  }, []);

  const firstPrevious = () => {
    const newDate = moment(date.firstDate).subtract(1, "M");

    setDate({ ...date, firstDate: newDate });
  };

  const handleChange = (date) => {
    const s = moment(startDate).format("YYYY-MM-DD");
    const d = moment(date).format("YYYY-MM-DD");

    // if (start !== null && end !== null) {
    //   onChange([null, null]);
    // }
    // if (start === null || date.isBefore(start, "day")) {
    //   onChange([d, null]);
    // } else if (start !== null && end !== null) {
    //   onChange([d, null]);
    // } else if (date.isSame(start, "day") && date.isSame(end, "day")) {
    //   onChange([null, null]);
    // } else if (date.isAfter(start, "day")) {
    //   onChange([s, d]);
    // }

    if (startDate !== null && endDate !== null) {
      onChange([null, null]);
    }
    if (startDate === null || date.isBefore(startDate, "day")) {
      onChange([d, null]);
    } else if (startDate !== null && endDate !== null) {
      onChange([d, null]);
    } else if (date.isSame(startDate, "day") && date.isSame(endDate, "day")) {
      onChange([null, null]);
    } else if (date.isAfter(startDate, "day")) {
      onChange([s, d]);
    }
  };
  const firstNext = () => {
    const newDate = moment(date.firstDate).add(1, "M");

    if (moment(date.secondDate).isSame(newDate)) {
      return setDate({
        ...date,
        firstDate: newDate,
        secondDate: moment(date.secondDate).add(1, "M"),
      });
    }

    setDate({ ...date, firstDate: newDate });
  };

  const secondPrevious = () => {
    const newDate = moment(date.secondDate).subtract(1, "M");
    if (moment(date.firstDate).isSame(newDate)) {
      return setDate({
        ...date,
        firstDate: moment(date.firstDate).subtract(1, "M"),
        secondDate: newDate,
      });
    }
    setDate({ ...date, secondDate: newDate });
  };

  const secondNext = () => {
    const newDate = moment(date.secondDate).add(1, "M");
    setDate({ ...date, secondDate: newDate });
  };

  const firstDayDate = useMemo(() => {
    setLoading({
      ...loading,
      first: true,
    });
    const firstDay = moment(date.firstDate).startOf("month");
    setTimeout(() => {
      setLoading({
        ...loading,
        first: false,
      });
    }, 300);
    return firstDay;
  }, [date.firstDate]);

  const firstDays = useMemo(() => {
    const map = [];
    const daysInMonth = moment(date.firstDate).daysInMonth();

    for (let i = firstDayDate.day(); i > 0; i--) {
      map.push(<Box component="td" textAlign="center"></Box>);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const showDate = moment(date.firstDate).date(i);
      map.push(
        <Days
          date={showDate}
          startDate={startDate}
          endDate={endDate}
          onClick={(date) => handleChange(date)}
        />
      );
    }

    let list = [];

    for (let i = 0; i < map.concat().length; i += 7) {
      let slicIt = map.concat().slice(i, i + 7);
      list.push(slicIt);
    }

    return list;
  }, [firstDayDate, startDate, endDate]);

  const secondDayDate = useMemo(() => {
    setLoading({
      ...loading,
      second: true,
    });
    const secondDay = moment(date.secondDate).startOf("month");
    setTimeout(() => {
      setLoading({
        ...loading,
        second: false,
      });
    }, 300);
    return secondDay;
  }, [date.secondDate]);

  const secondDays = useMemo(() => {
    const map = [];
    const daysInMonth = moment(date.secondDate).daysInMonth();

    for (let i = secondDayDate.day(); i > 0; i--) {
      map.push(<Box component="td" textAlign="center"></Box>);
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const showDate = moment(date.secondDate).date(i);
      map.push(
        <Days
          date={showDate}
          startDate={startDate}
          endDate={endDate}
          onClick={(date) => handleChange(date)}
        />
      );
    }

    let list = [];

    for (let i = 0; i < map.concat().length; i += 7) {
      let slicIt = map.concat().slice(i, i + 7);
      list.push(slicIt);
    }

    return list;
  }, [secondDayDate, startDate, endDate]);

  return (
    <>
      <TextFieldv3
        ref={imageRef}
        onClick={() => setOpen(!open)}
        placeholder="DD / MM / YYYY -> DD / MM / YYY"
        value={`${
          startDate ? moment(new Date(startDate)).format("DD / MM / YYYY") : ""
        }${startDate ? " -> " : ""}${
          endDate ? moment(new Date(endDate)).format("DD / MM / YYYY") : ""
        }`}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <Box
              sx={{
                position: "absolute",
                top: 13,
                right: "13px",
                color: "#4E4751",
              }}
            >
              <CalendarIcon />
            </Box>
          ),
        }}
      />

      <Popover
        open={open}
        anchorEl={imageRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            marginTop: "10px",
            boxShadow: "0px 1px 8px 1px rgba(0, 0, 0, 0.09)",
            borderRadius: "10px",
          },
        }}
      >
        <Box sx={{ p: 2, py: 3 }}>
          <Box display="flex" gap={3}>
            <Box display="flex" flexDirection="column" minWidth="280px">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <IconButton
                  onClick={() => {
                    firstPrevious();
                  }}
                >
                  <NavigateBeforeIcon cursor="pointer" />
                </IconButton>
                <Typography variant="label" fontWeight="700">
                  {date?.firstDate?.format("MMMM")}
                </Typography>
                <Typography variant="label">
                  {date?.firstDate?.format("YYYY")}
                </Typography>
                <IconButton onClick={firstNext}>
                  <NavigateNextIcon cursor="pointer" />
                </IconButton>
              </Box>

              <TableDate days={firstDays} loading={loading?.first} />
            </Box>
            <Box display="flex" flexDirection="column" minWidth="280px">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <IconButton onClick={secondPrevious}>
                  <NavigateBeforeIcon cursor="pointer" />
                </IconButton>

                <Typography variant="label" fontWeight="700">
                  {date?.secondDate?.format("MMMM")}
                </Typography>
                <Typography variant="label">
                  {date?.secondDate?.format("YYYY")}
                </Typography>

                <IconButton onClick={secondNext}>
                  <NavigateNextIcon cursor="pointer" />
                </IconButton>
              </Box>
              <TableDate days={secondDays} loading={loading?.second} />
            </Box>
          </Box>
          <Box
            sx={{
              pt: 3,
            }}
          >
            <Divider sx={{ borderColor: "#202021" }} />
            <Box display="flex" gap={3} mt={2}>
              <Typography variant="label2">Range : </Typography>
              <Typography variant="gridLabel">
                {endDate
                  ? moment(new Date(endDate)).diff(
                      moment(new Date(startDate)),
                      "days"
                    ) + 1 || "-"
                  : "-"}{" "}
                days
              </Typography>
            </Box>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

DateRange2.propTypes = {
  endDate: PropTypes.any,
  onChange: PropTypes.func,
  startDate: PropTypes.any,
};

DateRange2.defaultProps = {
  endDate: null,
  onChange: () => {},
  startDate: null,
};

export default DateRange2;
