const color = (value) => {
  let color0 = "#cc1d15";
  let color1 = "#129350";
  let color2 = "#0C6CE2";
  let color3 = "#FF6C00";
  let color4 = "#70481E";
  let color5 = "#7300F7";
  let color6 = "#3F4144";

  let bg0 = "#F5E3E3";
  let bg1 = "#f5e3e3";
  let bg2 = "#D4E7FF";
  let bg3 = "#FEF7E5";

  let bg4 = "#f5e3e3";
  let bg5 = "#FEF7E5";
  let bg6 = "#F2F2F2";

  switch (value) {
    case 0:
      return {
        color: color0,
        background: bg0,
      };
    case 1:
      return {
        color: color1,
        background: bg1,
      };
    case 2:
      return {
        color: color2,
        background: bg2,
      };
    case 3:
      return {
        color: color3,
        background: bg3,
      };
    case 4:
      return {
        color: color4,
        background: bg4,
      };
    case 5:
      return {
        color: color5,
        background: bg5,
      };
    case 6:
      return {
        color: color6,
        background: bg6,
      };
    default:
      return {
        color: color0,
        background: bg0,
      };
  }
};

export default color;
