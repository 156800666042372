import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import FormError from "components/Alert";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { alert } from "redux/global/slice";
import { getIpAddress, initialization, LoginAction } from "redux/auth/actions";
import { Link, useSearchParams } from "react-router-dom";
import { getCookie, setCookie } from "helper/cokkie";
import MainController from "components/Controller/MainController";
import VerticalGrid from "components/Grid/VerticalGrid";
import moment from "moment";
import TextFieldv3 from "components/TextField/TextFieldv3";
import VisibilityOff from "assets/paper/v2/VisibilityOff";
import VisibilityOn from "assets/paper/v2/VisibilityOn";
import PublicRoute from "components/PublicRoute";
import InisaIlu from "assets/login/new-ilustrasi.png";
import MainTextField from "components/TextField/MainTextField";

const Form = () => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
  } = useForm({
    defaultValues: {
      Email: "",
      Password: "",
      Source: "web",
    },
  });

  const Appid = useRef(null);
  const dispatch = useDispatch();
  const [sparams] = useSearchParams();
  const [response, setResponse] = useState({
    loading: false,
    toast: false,
    message: "",
  });
  const [retry, setRetry] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    (async () => {
      const data = new FormData();
      data.append("AppName", "qoin-apps");
      const response = await initialization(data);
      if (response) {
        Appid.current = response.data.data.AppID;
      } else {
        Appid.current = "hello world";
      }
    })();
    getIpAddress();

    if (sparams.get("w")) {
      dispatch(
        alert({
          open: true,
          message: "Expired token atau token tidak sesuai",
        })
      );
    }
  }, []);

  useEffect(() => {
    if (retry > 0) {
      const intervalId = setInterval(() => {
        setRetry(retry - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    } else {
      setResponse({
        ...response,
        message: "",
      });
    }
  }, [retry]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    setResponse({
      ...response,
      loading: true,
    });
    formData.append("Email", data.Email);
    formData.append("Password", data.Password);
    formData.append("Source", "web");

    const io = await LoginAction(formData, Appid.current);

    if (io?.data?.code === 202) {
      const br = io?.data?.data?.bussinesRegistered;
      const ia = io?.data?.data?.isApproved;
      const irb = io?.data?.data?.isRegBusiness;

      setResponse({
        ...response,
        message: "",
      });

      if (br >= 1 && ia > 0) {
        dispatch(
          alert({
            open: true,
            message: "Login Berhasil",
          })
        );
      }
      setCookie("token-ntt-webadmin", "expired-token");

      localStorage.setItem("merchant-id-ntt", io.data.data.ClientId);
      localStorage.setItem("token-ntt-webadmin", io.data.data.Token);
      localStorage.setItem("profile-ntt", JSON.stringify(io.data.data));
      let token = io.data.data.Token;
      let account;
      if (token) {
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        account = JSON.parse(jsonPayload);

        localStorage.setItem(
          "permission-ntt",
          JSON.stringify(account.Permission)
        );
      }
      setResponse({
        ...response,
        loading: false,
        message: "",
      });

      if (br == 1 && ia == 0 && irb == 0) {
        setTimeout(() => {
          return window.location.replace("/bussines-registration");
        }, 1500);
      } else if (irb == 1 && ia == 0) {
        setResponse({
          ...response,
          loading: false,
          message: "Akun Belum terverfikasi",
        });
      } else {
        if (sparams.get("navigateTo")) {
          // if (sparams.get("navigateTo") == "/") {
          //   setTimeout(() => {
          //     return window.location.replace(
          //       "/master-data/master-bantuan/penerbit"
          //     );
          //   }, 1500);
          // }
          setTimeout(() => {
            return window.location.replace(sparams.get("navigateTo"));
          }, 1500);
        } else {
          setTimeout(() => {
            return window.location.replace("/");
          }, 1500);
        }
      }
    } else if (
      io?.data?.message === "Too Many Request" ||
      io?.data.code == 429
    ) {
      setResponse({
        ...response,
        loading: false,
        message: io?.data?.message,
      });
      setRetry(io.data.data.RetryAfter);
    } else {
      dispatch(
        alert({
          open: true,
          message: "Maaf terjadi kesalahan",
        })
      );
      setResponse({
        ...response,
        loading: false,
        message: io?.data?.message,
      });
    }
  };

  const PasswordIcon = useMemo(
    () => (showPassword ? VisibilityOn : VisibilityOff),
    [showPassword]
  );

  return (
    <PublicRoute
      ilustration={InisaIlu}
      title="Selamat Datang Kembali!"
      desc="Nikmati kemudahan inisa dengan masuk ke akunmu."
    >
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "100%",
          marginTop: "30px",
        }}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <FormError
          message={`${response.message} ${
            retry > 0 ? `| ${moment.utc(retry * 1000).format("mm:ss")}` : ""
          }`}
          open={response.message ? 0 : 1}
          onClose={() => {
            if (retry) {
              return;
            }
            setResponse({
              ...response,
              message: "",
            });
          }}
        />
        <Grid container spacing={3}>
          <VerticalGrid label="Email" md={12}>
            <MainTextField
              controller={Controller}
              name="Email"
              typeField={1}
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              rules={{
                required: "Email wajib diisi",
                maxLength: {
                  message: "Email tidak boleh melebihi 255 karakter",
                  value: 255,
                },
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  message: "Format Email salah",
                },
              }}
              placeholder="Masukkan email"
            />
          </VerticalGrid>
          <VerticalGrid label="Kata Sandi" md={12}>
            <MainController
              controller={Controller}
              name="Password"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              rules={{
                required: "Kata Sandi wajib diisi",
                minLength: {
                  message: "Kata Sandi Harus Minimal 6 Karakter.",
                  value: 6,
                },
              }}
              render={({ field }) => (
                <TextFieldv3
                  {...field}
                  type={showPassword ? "text" : "password"}
                  errors={errors}
                  placeholder="Masukkan kata sandi"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{
                          color: "#4C4D4F",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <PasswordIcon />
                      </IconButton>
                    ),
                    autoComplete: "new-password",
                  }}
                />
              )}
            />
          </VerticalGrid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: "5px",
          }}
        >
          <Link
            to="/auth/forgot-password"
            style={{ marginLeft: "auto", textDecoration: "none" }}
          >
            <Typography variant="textCommon" sx={{ color: "#cc1d15" }}>
              Lupa Kata Sandi?
            </Typography>
          </Link>
        </Box>
        <Button
          variant="primary"
          disabled={retry || response.loading ? true : false}
          type="submit"
          sx={{ margin: "10px 0px", padding: "13px", borderRadius: "8px" }}
        >
          Masuk
        </Button>
        <Typography variant="textCommon" sx={{ textAlign: "center" }}>
          Belum Punya Akun?{"  "}
          <Link to="/auth/register" style={{ textDecoration: "none" }}>
            <span style={{ color: "#cc1d15", fontWeight: "700" }}>
              {" "}
              Daftar disini
            </span>
          </Link>
        </Typography>
      </Box>
    </PublicRoute>
  );
};

export default Form;
