import React from "react";
import PropTypes from "prop-types";
import StatusChip from "../StatusChip";

const color = {
  0: {
    label: "Aktif",
    color: "#129350",
    background: "#E7F4ED",
  },
  1: {
    label: "Didistribusikan",
    color: "#129350",
    background: "#E7F4ED",
  },
  2: {
    label: "Diterima",
    color: "#129350",
    background: "#E7F4ED",
  },
  3: {
    label: "Tidak Aktif",
    color: "#cc1d15",
    background: "#f5e3e3",
  },
};

const find = (value, text) => {
  return color?.[value]?.[text] || false;
};

const InventoryStatus = ({ value, ...props }) => (
  <StatusChip
    {...props}
    label={find(value, "label")}
    color={find(value, "color")}
    background={find(value, "background")}
  />
);

InventoryStatus.defaultProps = {
  value: 0,
};

InventoryStatus.propTypes = {
  value: PropTypes.any,
};

export default InventoryStatus;
