export const statusList = [
  {
    label: "Aktif",
    value: 1,
  },
  {
    label: "Tidak Aktif",
    value: 0,
  },
];

export const roleId = [
  {
    label: "Superadmin",
    desc: "Memiliki akses penuh ke semua pengaturan",
    value: "1147",
  },
  {
    label: "Supervisor",
    desc: "Mengelola fungsi akses dalam persetujuan dan pembaruan",
    value: "1148",
  },
  {
    label: "Operator",
    desc: "Mengatur semua akses pembaruan, kecuali validasi dan persetujuan.",
    value: "1149",
  },
  {
    label: "Officer",
    desc: "Mengatur proses menebus voucher",
    value: "1146",
  },
];
