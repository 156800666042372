const config = {
  Accept: "application/json",
  locale: "en",
  Authorization: `Bearer ${localStorage.getItem("token-ntt-webadmin")}`,
};

const configPost = {
  Accept: "application/json",
  locale: "en",
  Authorization: `Bearer ${localStorage.getItem("token-ntt-webadmin")}`,
  "Content-Type": "application/json",
};

const configPut = {
  Accept: "application/json",
  Authorization: `Bearer ${localStorage.getItem("token-ntt-webadmin")}`,
  "Content-Type": "application/x-www-form-urlencoded",
};

const configUpload = {
  Accept: "application/json",
  Authorization: `Bearer ${localStorage.getItem("token-ntt-webadmin")}`,
  "Content-Type": "multipart/form-data",
};

export { config, configPost, configPut, configUpload };
