export const tipe_voucher = [
  {
    label: "Nilai Tetap",
    value: 1,
  },
  {
    label: "Nilai Tetap dengan minimum transaksi",
    value: 2,
  },
  {
    label: "Persentase tetap",
    value: 3,
  },
  {
    label: "Persentase tetap dengan minimum transaksi",
    value: 4,
  },
  {
    label: "Persentase tetap dengan maksimum transaksi",
    value: 5,
  },
  {
    label: "Persentase tetap dengan minimum transaksi dan maksimum nilai",
    value: 6,
  },
  {
    label: "Rujukan nilai tetap dan dapatkan nilai hadiah",
    value: 7,
  },
];

export const status_voucher = [
  {
    label: "Diajukan",
    value: 0,
  },
  {
    label: "Disetujui",
    value: 1,
  },
  {
    label: "Dipublikasikan",
    value: 2,
  },
  {
    label: "Dibatalkan",
    value: 3,
  },
  {
    label: "Dikeluarkan",
    value: 4,
  },
  {
    label: "Ditunda",
    value: 5,
  },
  {
    label: "Diakhiri",
    value: 6,
  },
];

export const statusBansos = [
  { value: 1, label: "Aktif" },
  { value: 0, label: "Tidak Aktif" },
  { value: 2, label: "Ditunda" },
  { value: 3, label: "Dibatalkan" },
];

export const validasi_id = [
  { value: 0, label: "Tidak Pakai" },
  { value: 1, label: "NIK", isDisabled: false },
  { value: 2, label: "NIP", isDisabled: false },
  { value: 3, label: "ID Lainnya", isDisabled: false },
];

export const NIKStatusV = [
  {
    label: "Terverifikasi",
    value: 1,
  },
  {
    label: "Tidak Terverifikasi",
    value: 2,
  },
  {
    label: "Tidak Ditemukan",
    value: 3,
  },
  {
    label: "Tidak Sama",
    value: 4,
  },
  {
    label: "Data Salah",
    value: 5,
  },
];
