import RedeemSubsidiIcon from "assets/layout/sidebar/Inventory";

const permissionList = ["Web:Pengguna:Get"];
const RedeemSubsidi = {
  id: "redeem-subsidi",
  title: "Redeem Subsidi",
  url: "/redeem-subsidi",
  type: "item",
  icon: RedeemSubsidiIcon,
  permission: permissionList,
};

export default RedeemSubsidi;
