import { createAsyncThunk } from "@reduxjs/toolkit";
import { getParams } from "api";
import { Report } from "api/endpoints";

export const getListStok = createAsyncThunk(
  "report/getListStock",
  async (params) => {
    const response = await getParams(Report.stock, params);
    return response.data.data;
  }
);

export const getStok = async (params) => {
  const response = await getParams(Report.stock, params);
  return response;
};

export const getListDaily = createAsyncThunk(
  "report/getListDaily",
  async (params) => {
    const response = await getParams(Report.daily, params);
    return response.data.data;
  }
);

export const getDaily = async (params) => {
  const response = await getParams(Report.daily, params);
  return response;
};
