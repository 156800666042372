import React from "react";
import PropTypes from "prop-types";
import StatusChip from "./StatusChip";

const AktifStatus = ({ value, customText, ...props }) => (
  <StatusChip
    label={Boolean(value) ? customText[0] : customText[1]}
    color={Boolean(value) ? "#129350" : "#cc1d15"}
    background={Boolean(value) ? "#e7f4ed" : "#f5e3e3"}
    {...props}
  />
);

AktifStatus.defaultProps = {
  value: 0,
  customText: ["Aktif", "Tidak Aktif"],
};

AktifStatus.propTypes = {
  value: PropTypes.number,
  customText: PropTypes.array,
};
export default AktifStatus;
