import React, { useState } from "react";
import FileDownload from "assets/paper/fileDownload";
import FileUpload from "assets/paper/fileUpload";
import FilterIcon from "assets/paper/filter";
import SearchIcon from "@mui/icons-material/Search";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Menu,
  MenuItem,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import FilterDialog from "components/Dialog/FilterDialog";

const MainCardHeader = ({
  filter,
  filterName,
  changeFilterName,
  deleteFilter,
  changeFilter,
  params,
  filterData,
  addFilter,
  title,
  onChangeExport,
  onClickImport,
  FilterButton,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState({
    filter: false,
  });
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const inputSX = (theme) => ({
    "& fieldset": {
      border: "1px solid #D0D0D0 !important",
      [theme.breakpoints.up("sm")]: {
        borderRight: "none !important",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
    outline: "none !important",
    padding: "0px !important",
    paddingLeft: "9px !important",
    backgroundColor: "#fff !important",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    width: "220px",
  });

  const autoCompleteSX = (theme) => ({
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
    outline: "none !important",
  });

  const inputParamSX = (theme) => ({
    "& fieldset": {
      border: "1px solid #D0D0D0 !important",
      [theme.breakpoints.up("sm")]: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    outline: "none !important",
    backgroundColor: "#fff !important",
    width: 300,
  });

  const flexInput = (theme) => ({
    display: "inline-flex",
    [theme.breakpoints.up("md")]: {
      mr: 1,
    },
    // width: "calc(100% - 50%)",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      gap: "10px",
    },
  });

  const flexContainer = (theme) => ({
    display: "flex",
    // [theme.breakpoints.down("md")]: {
    //   flexDirection: "column",
    //   gap: "5px",
    // },
    justifyContent: "space-between",
    p: 2,
    gap: 1,
    alignItems: "center",
    flexWrap: "wrap",
  });

  const PopperCom = (io) => {
    return (
      <Popper
        {...io}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 10],
            },
          },
        ]}
      />
    );
  };

  return (
    <>
      <Box sx={flexContainer}>
        <Box sx={flexInput}>
          <Autocomplete
            PopperComponent={PopperCom}
            options={filter}
            sx={autoCompleteSX}
            value={filter?.find((item) => item.value == filterName) || null}
            getOptionLabel={(option) => option.label || ""}
            onChange={(_, v) => {
              if (v === null) {
                dispatch(
                  changeFilterName({
                    name: "",
                  })
                );
                dispatch(deleteFilter());
              } else {
                if (filterName != "") {
                  dispatch(deleteFilter());
                }
                dispatch(
                  changeFilterName({
                    name: v.value,
                  })
                );
              }
            }}
            renderInput={(p) => (
              <TextField
                {...p}
                placeholder="Pilih Parameter"
                InputProps={{
                  ...p.InputProps,
                  endAdornment: (
                    <>
                      <KeyboardArrowDown
                        fontSize="medium"
                        onMouseDown={p.inputProps.onMouseDown}
                        sx={{
                          color: "#4E4751",
                          position: "absolute",
                          right: "13px",
                        }}
                      />
                    </>
                  ),
                  sx: inputSX,
                }}
              />
            )}
          />
          <TextField
            type="text"
            autoComplete="off"
            onChange={(e) => {
              dispatch(changeFilter(e.target.value));
            }}
            value={params.filter?.[filterName] || params?.[filterName] || ""}
            InputProps={{
              sx: inputParamSX,
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: "20px" }}>
                  <SearchIcon
                    fontSize="medium"
                    sx={{
                      color: "#4E4751",
                    }}
                  />
                </InputAdornment>
              ),
            }}
            placeholder="Cari berdasarkan parameter"
          />
        </Box>

        <Box
          sx={{
            display: "inline-flex",
            justifyContent: "space-between",
            gap: "10px",
            flex: "auto",
          }}
        >
          {FilterButton && (
            <Button
              variant="warning"
              onClick={() => setOpenDialog({ ...open, filter: true })}
            >
              <FilterIcon />
            </Button>
          )}
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              height: "46px",
              marginLeft: "auto",
            }}
          >
            {Boolean(onClickImport) && (
              <Button
                variant="primary-outlined"
                onClick={onClickImport}
                startIcon={<FileUpload />}
              >
                Unggah
              </Button>
            )}
            <Button
              onClick={handleClick}
              variant="primary-outlined"
              startIcon={<FileDownload />}
              endIcon={
                <KeyboardArrowDown
                  sx={{ position: "absolute", right: 8, top: 13 }}
                />
              }
              sx={{
                "&:after": {
                  position: "absolute",
                  content: '""',
                  top: "0",
                  left: "105px",
                  backgroundColor: "#323232",
                  width: "1px",
                  height: "100%",
                },
                width: "145px",
                position: "relative",
                justifyContent: "start",
                pl: 2.3,
                fontWeight: "bold",
                "&:hover": {
                  "&:after": {
                    backgroundColor: "#fff !important",
                  },
                },
              }}
            >
              Unduh
            </Button>
          </Box>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          sx: {
            width: "140px",
          },
        }}
      >
        <MenuItem
          onClick={() => onChangeExport("excel")}
          sx={{
            py: 2,
          }}
        >
          <Typography variant="label">Excel</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => onChangeExport("pdf")}
          sx={{
            py: 2,
          }}
        >
          <Typography variant="label">PDF</Typography>
        </MenuItem>
        <MenuItem
          onClick={() => onChangeExport("csv")}
          sx={{
            py: 2,
          }}
        >
          <Typography variant="label">CSV</Typography>
        </MenuItem>
      </Menu>
      <FilterDialog
        open={openDialog.filter}
        onClose={() => setOpenDialog({ ...open, filter: false })}
        filterData={filterData}
        addFilter={addFilter}
        title={title}
      />
    </>
  );
};

MainCardHeader.propTypes = {
  addFilter: PropTypes.func,
  changeFilter: PropTypes.func,
  changeFilterName: PropTypes.func,
  deleteFilter: PropTypes.func,
  filter: PropTypes.array,
  filterData: PropTypes.array,
  filterName: PropTypes.string,
  params: PropTypes.shape({
    filter: PropTypes.any,
  }),
  title: PropTypes.string,
  onChangeExport: PropTypes.any,
  onClickImport: PropTypes.any,
  FilterButton: PropTypes.bool,
};

MainCardHeader.defaultProps = {
  filter: [],
  changeFilter: () => {},
  changeFilterName: () => {},
  deleteFilter: () => {},
  addFilter: () => {},
  filterName: "",
  filterData: [],
  params: {},
  title: "-",
  onChangeExport: null,
  onClickImport: null,
  FilterButton: true,
};

export default MainCardHeader;
