import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import useFetchData from "hook/useFetchData";
import { Box, Grid, Typography } from "@mui/material";
import VerticalGrid from "components/Grid/VerticalGrid";
import TextFieldv3 from "components/TextField/TextFieldv3";
import MainController from "components/Controller/MainController";
import FormatNumber from "helper/FormatNumber";
import FormatRupiah from "helper/FormatRupiah";
import Autocompletev3 from "components/AutoComplete/Autocompletev3";
import { createMasterProduk, updateMasterProduk } from "redux/inventory/action";

const defaultValues = {
  kodeBarang: "",
  namaBarang: "",
  keterangan: "",
  harga: "",
  stok: "",
  satuan: "",
  status: null,
};

export const statusList = [
  {
    label: "Aktif",
    value: 1,
  },
  {
    label: "Tidak Aktif",
    value: 0,
  },
];

export const satuanList = [
  {
    label: "Ton",
    value: "t",
  },
  {
    label: "Kilogram",
    value: "kg",
  },
  {
    label: "Ons",
    value: "ons",
  },
  {
    label: "Pound",
    value: "lb",
  },
  {
    label: "Gram",
    value: "g",
  },
  {
    label: "Miligram",
    value: "mg",
  },
];

const Dialog = ({ open, onClose, refresh, data }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset: resetForm,
    clearErrors,
  } = useForm({
    defaultValues: { ...defaultValues },
  });

  const action = useFetchData({
    action: !data?.id ? createMasterProduk : updateMasterProduk,
    reset: resetForm,
    message: !data?.id
      ? "Berhasil menambah produk baru"
      : "berhasil update data produk",
    refresh: refresh,
    onSuccess: () => {
      onClose();
    },
  });

  const onSubmit = async (submitData) => {
    delete submitData.satuan;
    const formData = new FormData();

    for (let key in submitData) {
      formData.append(key, submitData[key]);
    }

    await action.fetch(formData, data?.id);
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
      clearErrors: clearErrors,
    };
  };

  useEffect(() => {
    if (data != null) {
      const copy = { ...data };
      delete copy.id;
      resetForm(copy);
    } else {
      resetForm(defaultValues);
    }
  }, [data]);
  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
        resetForm();
      }}
      title={!data?.id ? "Tambah Inventory" : "Edit Inventory"}
      loading={action.loading}
      valid={true}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      submitText={!data?.id ? "Tambah" : "Simpan Perubahan"}
      onReset={() => {
        resetForm();
      }}
      type={1}
      customWidth="700px"
    >
      <Grid container spacing={2}>
        <VerticalGrid label={"Kode Barang"}>
          <MainController
            {...getProps("kodeBarang")}
            rules={{ required: "Kode Barang wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <TextFieldv3
                {...field}
                error={invalid}
                onChange={(e) => {
                  e.target.value = String(e.target.value).toUpperCase();
                  field.onChange(e);
                }}
                placeholder="Masukkan kode barang"
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Nama Barang"}>
          <MainController
            {...getProps("namaBarang")}
            rules={{ required: "Nama Barang wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <TextFieldv3
                {...field}
                error={invalid}
                placeholder="Masukkan nama barang"
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Keterangan"} md={12}>
          <MainController
            {...getProps("keterangan")}
            rules={{ required: "Keterangan wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <TextFieldv3
                {...field}
                multiline
                error={invalid}
                rows={5}
                placeholder="Masukkan deskripsi barang"
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Harga "}>
          <MainController
            {...getProps("harga")}
            rules={{ required: "Harga wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <Box display="flex" gap={1}>
                <Box
                  sx={{
                    backgroundColor: "#EBECED",
                    alignItems: "center",
                    display: "flex",
                    alignItems: "center",
                    width: "60px",
                    borderRadius: "4px",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="labelMedium2">Rp.</Typography>
                </Box>
                <TextFieldv3
                  {...field}
                  error={invalid}
                  placeholder="Masukkan nominal harga"
                  value={FormatRupiah(field.value, ",")}
                  onChange={(e) => {
                    e.target.value = FormatNumber(e.target.value);
                    field.onChange(e);
                  }}
                />
              </Box>
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Satuan"}>
          <MainController
            {...getProps("satuan")}
            rules={{ required: "Satuan wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <Box display="flex" gap={1}>
                <Box
                  sx={{
                    backgroundColor: "#EBECED",
                    alignItems: "center",
                    display: "flex",
                    alignItems: "center",
                    width: "60px",
                    borderRadius: "4px",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="labelMedium2">.kg</Typography>
                </Box>
                <Autocompletev3
                  {...field}
                  options={satuanList}
                  error={invalid}
                  value={
                    satuanList.find((item) => item.value === field.value) ||
                    null
                  }
                  sx={{ width: "100%" }}
                  placeholder="Pilih Satuan"
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  onChange={(_, nv) => {
                    if (nv == null) {
                      field.onChange(1);
                    }
                    field.onChange(nv.value);
                  }}
                />
              </Box>
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Stok"}>
          <MainController
            {...getProps("stok")}
            rules={{ required: "Stok wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <TextFieldv3
                {...field}
                error={invalid}
                placeholder="Masukkan jumlah ketersediaan"
                onChange={(e) => {
                  e.target.value = FormatNumber(e.target.value);
                  field.onChange(e);
                }}
              />
            )}
          />
        </VerticalGrid>

        <VerticalGrid label={"Status"}>
          <MainController
            {...getProps("status")}
            rules={{ required: "Status wajib diisi" }}
            render={({ field, fieldState: { invalid } }) => (
              <Autocompletev3
                {...field}
                options={statusList}
                error={invalid}
                value={
                  statusList.find((item) => item.value === field.value) || null
                }
                placeholder="Masukan status barang"
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                onChange={(_, nv) => {
                  if (nv == null) {
                    field.onChange(1);
                  }
                  field.onChange(nv.value);
                }}
              />
            )}
          />
        </VerticalGrid>
      </Grid>
    </MainDialog>
  );
};

Dialog.propTypes = {
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  data: PropTypes.object,
  id: PropTypes.any,
  setRes: PropTypes.func,
};

Dialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  data: null,
  id: null,
  setRes: null,
};
export default Dialog;
