import React from "react";

const InventoryIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2951_16065)">
        <path
          d="M22.38 1.07993C22.01 0.929928 21.58 1.00993 21.29 1.29993L19 3.58993L16.71 1.29993C16.32 0.909928 15.69 0.909928 15.3 1.29993L13.01 3.58993L10.72 1.29993C10.33 0.909928 9.7 0.909928 9.31 1.29993L7.02 3.58993L4.71 1.28993C4.42 0.999928 3.99 0.919928 3.62 1.06993C3.25 1.21993 3 1.58993 3 1.98993V15.9999H2C1.45 15.9999 1 16.4499 1 16.9999V18.9999C1 21.2099 2.79 22.9999 5 22.9999H19C21.21 22.9999 23 21.2099 23 18.9999V1.99993C23 1.59993 22.76 1.22993 22.38 1.07993ZM3 18.9999V17.9999H15V18.9999C15 19.7299 15.21 20.4099 15.56 20.9999H5C3.9 20.9999 3 20.0999 3 18.9999ZM19 20.9999C17.9 20.9999 17 20.0999 17 18.9999V16.9999C17 16.4499 16.55 15.9999 16 15.9999H5V4.40993L6.29 5.69993C6.68 6.08993 7.31 6.08993 7.7 5.69993L9.99 3.40993L12.28 5.69993C12.67 6.08993 13.3 6.08993 13.69 5.69993L15.98 3.40993L18.27 5.69993C18.66 6.08993 19.29 6.08993 19.68 5.69993L20.97 4.40993V18.9999C20.97 20.0999 20.07 20.9999 18.97 20.9999H19Z"
          fill="currentColor"
        />
        <path
          d="M14 8.35993H8C7.45 8.35993 7 8.80993 7 9.35993C7 9.90993 7.45 10.3599 8 10.3599H14C14.55 10.3599 15 9.90993 15 9.35993C15 8.80993 14.55 8.35993 14 8.35993Z"
          fill="currentColor"
        />
        <path
          d="M14 12.3599H8C7.45 12.3599 7 12.8099 7 13.3599C7 13.9099 7.45 14.3599 8 14.3599H14C14.55 14.3599 15 13.9099 15 13.3599C15 12.8099 14.55 12.3599 14 12.3599Z"
          fill="currentColor"
        />
        <path
          d="M18 8.35993C17.45 8.35993 17 8.80993 17 9.35993C17 9.90993 17.45 10.3599 18 10.3599C18.55 10.3599 19 9.90993 19 9.35993C19 8.80993 18.55 8.35993 18 8.35993Z"
          fill="currentColor"
        />
        <path
          d="M18 12.3599C17.45 12.3599 17 12.8099 17 13.3599C17 13.9099 17.45 14.3599 18 14.3599C18.55 14.3599 19 13.9099 19 13.3599C19 12.8099 18.55 12.3599 18 12.3599Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2951_16065">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InventoryIcon;
