import React from "react";

const IlustrasiKartuSubsidi = () => {
  return (
    <svg
      width="220"
      height="168"
      viewBox="0 0 220 168"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M132.022 21.2468C116.055 43.8422 169.518 25.3687 196.677 43.8422C223.837 62.3158 187.048 132.555 180.369 141.329C173.69 150.102 103.581 175.444 82.3449 156.06C61.1087 136.677 24.3778 158.267 12.5826 136.559C0.787365 114.851 22.0224 104.277 19.8046 51.6021C17.5868 -1.07326 147.99 -1.34861 132.022 21.2468Z"
        fill="#E7E7E7"
      />
      <g clipPath="url(#clip0_788_246906)">
        <path
          d="M149.196 40.7637L69.0021 34.2123C65.8505 33.9549 63.0882 36.3175 62.8324 39.4894L59.7436 77.7871C59.4877 80.959 61.8352 83.739 64.9869 83.9965L145.181 90.5479C148.333 90.8054 151.095 88.4428 151.351 85.2709L154.44 46.9731C154.696 43.8012 152.348 41.0212 149.196 40.7637Z"
          fill="white"
        />
        <path
          d="M147.802 53.7242C144.46 53.4512 141.983 50.3218 142.273 46.7353C142.28 46.6432 142.297 46.5568 142.309 46.4651L74.8857 40.957C74.8783 41.0491 74.8857 41.1375 74.8782 41.2296C74.589 44.8161 71.6433 47.5025 68.3012 47.2295C68.1664 47.2185 68.0375 47.1933 67.9079 47.1778L65.9843 71.0279C66.1151 71.0288 66.2459 71.0298 66.3808 71.0408C69.7228 71.3138 72.1995 74.4432 71.9102 78.0297C71.9028 78.1218 71.8861 78.2083 71.8739 78.3L139.297 83.808C139.305 83.7159 139.297 83.6275 139.305 83.5354C139.594 79.949 142.54 77.2625 145.882 77.5355C146.017 77.5465 146.145 77.5717 146.275 77.5872L148.199 53.7323C148.068 53.7313 147.937 53.7304 147.803 53.7194L147.802 53.7242Z"
          fill="#E7E7E7"
        />
        <path
          d="M106.259 72.7375C111.942 73.2018 116.924 68.9411 117.385 63.221C117.846 57.5009 113.613 52.4875 107.929 52.0231C102.246 51.5588 97.2645 55.8195 96.8031 61.5396C96.3418 67.2597 100.575 72.2732 106.259 72.7375Z"
          fill="#666666"
        />
        <path
          d="M104.094 63.0961L102.601 62.9741L102.344 66.1583L101.006 66.0489L101.673 57.7758L104.384 57.9973C105.275 58.0701 105.942 58.3392 106.393 58.8103C106.84 59.2761 107.036 59.9165 106.97 60.7308C106.926 61.2833 106.763 61.7383 106.482 62.0911C106.202 62.4438 105.832 62.7014 105.373 62.8688L106.828 66.4563L106.822 66.529L105.387 66.4118L104.098 63.1013L104.094 63.0961ZM102.695 61.8158L104.072 61.9283C104.525 61.9653 104.886 61.8728 105.161 61.6465C105.436 61.4202 105.588 61.1008 105.622 60.6743C105.657 60.2478 105.57 59.8797 105.354 59.6181C105.137 59.3565 104.796 59.2018 104.334 59.1592L102.918 59.0435L102.695 61.8158Z"
          fill="white"
        />
        <path
          d="M109.363 63.6389L109.115 66.7068L107.777 66.5974L108.444 58.3243L111.396 58.5654C112.258 58.6359 112.922 58.9341 113.389 59.4552C113.856 59.9763 114.06 60.6369 113.996 61.4269C113.932 62.2169 113.629 62.8507 113.097 63.2609C112.564 63.6711 111.853 63.8423 110.957 63.7691L109.363 63.6389ZM109.456 62.4805L111.065 62.6119C111.542 62.6509 111.917 62.5596 112.187 62.3426C112.456 62.1256 112.61 61.7868 112.646 61.3313C112.683 60.8757 112.586 60.5165 112.352 60.2291C112.119 59.9417 111.783 59.7777 111.336 59.7314L109.689 59.5968L109.456 62.4854L109.456 62.4805Z"
          fill="white"
        />
        <path
          d="M86.749 63.9527C88.5123 64.0968 90.0578 62.7749 90.2009 61.0003C90.344 59.2256 89.0306 57.6702 87.2673 57.5261C85.504 57.3821 83.9585 58.704 83.8154 60.4786C83.6723 62.2533 84.9857 63.8087 86.749 63.9527Z"
          fill="#666666"
        />
        <path
          d="M126.921 67.2345C128.684 67.3786 130.23 66.0567 130.373 64.2821C130.516 62.5074 129.202 60.952 127.439 60.8079C125.676 60.6639 124.13 61.9858 123.987 63.7604C123.844 65.5351 125.158 67.0905 126.921 67.2345Z"
          fill="#666666"
        />
      </g>
      <g filter="url(#filter0_d_788_246906)">
        <path
          d="M195.506 75.5436H88.1994C85.1815 75.5436 82.735 78.0059 82.735 81.0432V145.292C82.735 148.329 85.1815 150.791 88.1994 150.791H195.506C198.524 150.791 200.97 148.329 200.97 145.292V81.0432C200.97 78.0059 198.524 75.5436 195.506 75.5436Z"
          fill="url(#paint0_linear_788_246906)"
        />
      </g>
      <g filter="url(#filter1_d_788_246906)">
        <path
          d="M120.048 98.9956V105.764C120.048 107.879 118.348 109.591 116.246 109.591H101.911C99.8142 109.591 98.1087 107.879 98.1087 105.764V98.9956C98.1087 96.8804 99.8142 95.1639 101.911 95.1639H116.246C118.348 95.1639 120.048 96.8804 120.048 98.9956Z"
          fill="white"
        />
        <path
          d="M106.999 95.1639V96.8561C106.999 98.8789 105.361 100.527 103.346 100.527H98.1087V99.5548H103.346C104.829 99.5548 106.032 98.344 106.032 96.8561V95.1639H106.999Z"
          fill="#666666"
        />
        <path
          d="M120.164 102.156V103.129H113.178C111.487 103.129 110.105 104.519 110.105 106.221V109.591H109.139V106.221C109.139 103.98 110.951 102.156 113.178 102.156H120.164Z"
          fill="#666666"
        />
      </g>
      <g clipPath="url(#clip1_788_246906)">
        <path
          d="M202.119 75.2004L201.532 75.7481L195.918 78.1177L201.977 80.5549L204.426 86.5865L206.992 80.5611L213.063 78.1177L206.998 75.5574L204.426 69.5196L202.119 75.2004ZM214.522 88.5498L214.522 88.5745L214.491 88.5745L214.522 88.5498Z"
          fill="#666666"
        />
        <path
          d="M181.778 68.8025L181.018 69.5165L173.716 72.6L181.593 75.7696L184.777 83.6106L188.116 75.7758L196.011 72.6L188.128 69.2703L184.783 61.4231L181.784 68.8087L181.778 68.8025ZM197.903 86.1587L197.903 86.1895L197.86 86.1895L197.903 86.1587Z"
          fill="#666666"
        />
        <path
          d="M190.205 89.9962L189.853 90.3224L186.477 91.7441L190.112 93.2089L191.584 96.8279L193.124 93.2089L196.771 91.7441L193.13 90.2054L191.584 86.5803L190.199 89.99L190.205 89.9962ZM197.656 98.0157L197.656 98.028L197.637 98.028L197.656 98.0157Z"
          fill="#666666"
        />
      </g>
      <g clipPath="url(#clip2_788_246906)">
        <path
          d="M39.9883 76.0098C39.9883 76.0098 25.1219 66.3188 25.4359 73.2576C25.75 80.1964 37.1715 83.6683 37.1715 83.6683L47.535 81.5093C47.535 81.5093 57.4878 62.8469 51.1393 64.1695C44.7907 65.4921 39.9883 76.0098 39.9883 76.0098Z"
          fill="white"
        />
        <path
          d="M51.6321 84.2663L41.5827 86.3572L31.5332 88.4481C19.8798 105.292 16.0678 116.092 19.9281 128.749C21.7302 134.657 26.9385 139.125 33.6639 140.501C33.7315 140.516 33.7992 140.531 33.8668 140.54C46.2643 143.025 59.6233 140.244 70.0254 133.013C70.0834 132.974 70.1365 132.935 70.1945 132.896C75.8231 128.948 78.8476 122.768 78.1712 116.622C76.7266 103.464 68.9721 95.0709 51.6369 84.2615L51.6321 84.2663Z"
          fill="white"
        />
        <path
          d="M49.867 78.8115L30.9934 82.7382C29.5066 83.0476 28.5504 84.5114 28.8578 86.0078L28.8989 86.2078C29.2062 87.7042 30.6607 88.6665 32.1476 88.3572L51.0212 84.4304C52.508 84.1211 53.4642 82.6572 53.1568 81.1608L53.1157 80.9608C52.8083 79.4644 51.3538 78.5021 49.867 78.8115Z"
          fill="#666666"
        />
        <path
          d="M44.0367 83.8123L44.032 83.8133C43.086 84.0101 42.4777 84.9414 42.6733 85.8935L45.0139 97.2886C45.2094 98.2406 46.1348 98.8528 47.0807 98.656L47.0854 98.655C48.0314 98.4582 48.6397 97.5269 48.4441 96.5749L46.1035 85.1798C45.908 84.2278 44.9826 83.6155 44.0367 83.8123Z"
          fill="#666666"
        />
        <path
          d="M45.8812 84.0924L45.8778 84.0958C45.1825 84.7861 45.1749 85.9129 45.8608 86.6126L50.5363 91.3828C51.2222 92.0825 52.3418 92.0902 53.0371 91.3999L53.0405 91.3965C53.7358 90.7062 53.7434 89.5794 53.0575 88.8797L48.3819 84.1095C47.6961 83.4098 46.5765 83.4021 45.8812 84.0924Z"
          fill="#666666"
        />
        <path
          d="M47.8539 129.118C54.5647 129.118 60.005 123.643 60.005 116.889C60.005 110.135 54.5647 104.66 47.8539 104.66C41.143 104.66 35.7028 110.135 35.7028 116.889C35.7028 123.643 41.143 129.118 47.8539 129.118Z"
          fill="#666666"
        />
        <path
          d="M44.6893 118.625L42.9596 118.985L43.6698 122.437L42.1189 122.758L40.2733 113.782L43.4089 113.13C44.438 112.916 45.2787 112.984 45.9358 113.33C46.5929 113.68 47.0084 114.292 47.1871 115.173C47.3127 115.776 47.2693 116.306 47.0663 116.772C46.8634 117.239 46.5252 117.633 46.0566 117.959L48.8298 121.28L48.8443 121.358L47.1823 121.703L44.6844 118.625H44.6893ZM42.7035 117.725L44.2979 117.395C44.8197 117.288 45.2014 117.069 45.443 116.743C45.6845 116.417 45.757 116.024 45.6604 115.562C45.5638 115.1 45.3512 114.735 45.0226 114.531C44.6941 114.326 44.2641 114.273 43.723 114.375L42.0851 114.715L42.7035 117.725Z"
          fill="white"
        />
        <path
          d="M50.8155 117.477L51.5016 120.808L49.9507 121.129L48.1051 112.153L51.5161 111.443C52.5114 111.234 53.3569 111.331 54.0526 111.735C54.7435 112.134 55.1832 112.766 55.3571 113.626C55.531 114.487 55.3909 115.25 54.9174 115.858C54.444 116.466 53.6903 116.879 52.6563 117.093L50.8107 117.477H50.8155ZM50.5595 116.218L52.4196 115.829C52.9704 115.712 53.3666 115.498 53.6033 115.173C53.84 114.847 53.9077 114.443 53.8062 113.952C53.7048 113.461 53.4777 113.111 53.125 112.882C52.7723 112.654 52.3375 112.591 51.8205 112.688L49.9169 113.087L50.5595 116.218Z"
          fill="white"
        />
      </g>
      <rect
        x="82.735"
        y="125.905"
        width="118.462"
        height="10.382"
        fill="white"
      />
      <g clipPath="url(#clip3_788_246906)">
        <path
          d="M38.8173 51.5881C38.7915 51.4324 38.7656 51.2766 38.7656 51.1079C38.7656 49.304 40.2264 47.8376 42.0233 47.8376C43.8202 47.8376 45.281 49.304 45.281 51.1079C45.281 51.2766 45.2551 51.4324 45.2293 51.5881H49.4953C49.4953 47.4353 46.1471 44.0741 42.0104 44.0741C37.8736 44.0741 34.5513 47.4483 34.5513 51.5881H38.8173Z"
          fill="white"
        />
        <path
          d="M38.8173 44.087C38.7915 43.9313 38.7656 43.7756 38.7656 43.6069C38.7656 41.803 40.2264 40.3365 42.0233 40.3365C43.8202 40.3365 45.281 41.803 45.281 43.6069C45.281 43.7756 45.2551 43.9313 45.2293 44.087H49.4953C49.5082 39.9342 46.1601 36.5731 42.0362 36.5731C37.9124 36.5731 34.5513 39.9342 34.5513 44.087H38.8173Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_788_246906"
          x="66.735"
          y="59.5436"
          width="148.235"
          height="105.248"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="-1" />
          <feGaussianBlur stdDeviation="7.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_788_246906"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_788_246906"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_788_246906"
          x="78.735"
          y="75.5436"
          width="126.235"
          height="83.2476"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_788_246906"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_788_246906"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_788_246906"
          x1="157.949"
          y1="191.124"
          x2="141.843"
          y2="59.0037"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#11998E" />
          <stop offset="1" stopColor="#38EF7D" />
        </linearGradient>
        <clipPath id="clip0_788_246906">
          <rect
            width="91.9126"
            height="49.9458"
            fill="white"
            transform="matrix(0.99668 0.0814227 -0.080392 0.996763 63.2956 33.7462)"
          />
        </clipPath>
        <clipPath id="clip1_788_246906">
          <rect
            width="40.8064"
            height="43.7473"
            fill="white"
            transform="translate(214.522 98.028) rotate(-180)"
          />
        </clipPath>
        <clipPath id="clip2_788_246906">
          <rect
            width="59.7892"
            height="77.3336"
            fill="white"
            transform="translate(18.4738 64.1014)"
          />
        </clipPath>
        <clipPath id="clip3_788_246906">
          <rect
            width="14.9569"
            height="15.015"
            fill="white"
            transform="translate(34.5513 36.5731)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IlustrasiKartuSubsidi;
