import { Box, Button, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import VerfikasiEmail from "assets/ilustrasi/verfikasi-email.png";
import { Link,  useSearchParams } from "react-router-dom";
import { verifMerchant } from "redux/auth/actions";
import useFetchData from "hook/useFetchData";

const VerifikasiEmail = () => {
  const [sparams] = useSearchParams();
  const [text, setText] = useState("");

  const action = useFetchData({
    action: verifMerchant,
    onSuccess: () => {
      setText("Berhasil");
    },
    snackbar: false,
    onError: () => {
      setText("Gagal");
    },
  });

  useEffect(() => {
    const id = sparams.get("user_id");
    const formData = new FormData();
    formData.append("status", 1);
    const fetch = async () => {
      await action.fetch(formData, id);
    };

    fetch();
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "#F2F2F2",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          borderRadius: "16px",
          width: "90%",
          height: "90%",
          margin: "auto",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
        }}
      >
        <Box
          component="img"
          src={VerfikasiEmail}
          sx={{
            display: "flex",
            objectFit: "contain",
          }}
        />
        <Typography variant="title3">
          {action.loading ? "Sedang Verifikasi" : `Verifikasi ${text}`}
        </Typography>
        {/* <Typography variant="text5" width="900px">
          Terimakasih telah memilih INISA Web Merchant
        </Typography> */}
        <Link to="/auth/login" style={{ textDecoration: "none" }}>
          <Button variant="primary" sx={{ minWidth: "300px", height: "50px" }}>
            Kembali ke Halaman Masuk
          </Button>
        </Link>
      </Paper>
    </Box>
  );
};

export default VerifikasiEmail;
