import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  ip: "",
  email: "-",
};

export const authReducer = createSlice({
  name: "login",
  initialState,
  reducers: {
    addName: (state, action) => {
      state.email = action.payload;
    },
  },
});

export const { addName } = authReducer.actions;

export default authReducer.reducer;
