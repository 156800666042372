import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  Typography,
} from "@mui/material";
import PageTitle from "components/Page/PageTitle";
import React, { useEffect, useState, useRef } from "react";

import EyeIconv2 from "assets/paper/v2/EyeIconv2";
import PencilIconv2 from "assets/paper/v2/PencilIconv2";
import TrashIcon from "assets/paper/TrashIcon";
import AddIcon from "@mui/icons-material/Add";
import CardRole from "./components/cardRole";
import Dialog from "./Dialog";
import { Link, useNavigate } from "react-router-dom";
import {
  List,
  CurrentPage,
  FilterName,
  Loading,
  Params,
  TotalItems,
} from "redux/managementPengguna/Role/selectors";
import { useSelector, useDispatch } from "react-redux";
import { deleteRole, getListRole } from "redux/managementPengguna/action";
import DeleteDialog from "components/Dialog/DeleteDialog";
import useFetchData from "hook/useFetchData";
import TextFieldv4 from "components/TextField/TextFieldv4";
import SearchIcon from "assets/paper/SearchIcon";
import { changeFilter } from "redux/managementPengguna/Role/slice";

const Peran = () => {
  const [open, setOpen] = useState({
    open: false,
    delete: false,
  });
  const [data, setData] = useState(null);
  const id = useRef("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const list = useSelector(List);
  const loading = useSelector(Loading);
  const params = useSelector(Params);
  const totalItems = useSelector(TotalItems);
  const currentPage = useSelector(CurrentPage);
  const filterName = useSelector(FilterName);

  useEffect(() => {
    fetchData();
  }, [params]);

  const fetchData = async () => {
    await dispatch(getListRole(params));
  };

  const action = useFetchData({
    action: deleteRole,
    message: "Berhasil hapus role",
    refresh: fetchData,
    onSuccess: () => {
      setOpen({
        ...open,
        delete: false,
      });
    },
  });

  return (
    <>
      <PageTitle
        title={`Peran`}
        text="Dashboard / Management Pengguna / "
        lastText="Peran "
        backBtn={false}
        custom={
          <Box display="flex" gap={1}>
            <TextFieldv4
              placeholder="Cari..."
              onChange={(e) => {
                dispatch(changeFilter(e.target.value));
              }}
              value={params?.filter || ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="primary-icon"
              sx={{
                width: "50px",
              }}
              onClick={() => {
                setData({});
                setOpen({
                  ...open,
                  open: true,
                });
              }}
            >
              <AddIcon />
            </Button>
          </Box>
        }
      />

      <Grid sx={{ mt: 1 }} container spacing={2}>
        {list.map((item, i) => (
          <Grid item xs={12} md={4} xl={3} key={i}>
            <Paper
              sx={{
                borderRadius: "8px",
                minHeight: "280px",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  p: 2,
                }}
              >
                <Typography variant="title2">
                  {item.MerchantRoleName}
                </Typography>

                <Typography variant="desc3" my={1}>
                  Total users with this role : {item.TotalUsers || 0}
                </Typography>

                <Box display="flex" flexDirection="column" gap={0.5}>
                  <CardRole data={item.PermissionsResult} />
                </Box>

                <Box display="flex" gap={1} mt="auto" ml="auto">
                  <Link to={`/management-pengguna/peran/${item.MerchantRolesId}`}>
                    <Button
                      variant="gray-icon"
                    >
                      <EyeIconv2 />
                    </Button>
                  </Link>
                  <Button
                    variant="gray-icon"
                    onClick={() => {
                      setData({
                        data: {
                          id: item.MerchantRolesId,
                          MerchantRoleName: item.MerchantRoleName,
                          Description: item.Description,
                          Permission: item.PermissionsResult,
                        },
                      });

                      setOpen({
                        ...open,
                        open: true,
                      });
                    }}
                  >
                    <PencilIconv2 />
                  </Button>
                  <Button
                    variant="gray-icon"
                    onClick={() => {
                      setOpen({
                        ...open,
                        delete: true,
                      });
                      id.current = item.MerchantRolesId;
                    }}
                  >
                    <TrashIcon />
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={open.open}
        onClose={() =>
          setOpen({
            ...open,
            open: false,
          })
        }
        refresh={fetchData}
        {...data}
      />

      <DeleteDialog
        open={open.delete}
        onClose={() => setOpen({ ...open, delete: false })}
        onSubmit={() => action.fetch(id.current)}
      />
    </>
  );
};

export default Peran;
