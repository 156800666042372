import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import MainTable from "components/Table/MainTable";
import {
  changeFilterName,
  changeLimit,
  changePage,
  changeFilter,
  deleteFilter,
  addFilter,
} from "redux/inventory/MasterProduk/slice";
import AktifStatus from "components/Status/AktifStatus";
import MainCard from "components/Paper/MainCard";
import MainPagination from "components/Pagination";
import useFetchData from "hook/useFetchData";
import ExportHelper from "helper/ExportHelper";
import moment from "moment";
import MainCardHeader3 from "components/Paper/MainCardHeader3";
import { getListMasterProduk, getMasterProduk } from "redux/inventory/action";
import FormatRupiah from "helper/FormatRupiah";
import useSelect from "hook/useSelect";
import Dialog, { statusList } from "./Dialog";
import useAutoFetch from "hook/useAutoFetch";
import Page from "components/Page";
import AlertDialog from "components/Dialog/AlertDialog";

const headers = [
  {
    name: "Barang",
    custom: (item) => {
      return `${item.mpCode} - ${item.mpName}`;
    },
    width: 30,
  },
  {
    name: "Stok",
    custom: (item) => {
      return `${item.mcStokAmount} ${item.mcCurrency || "-"}.`;
    },
    width: 30,
  },
  {
    name: "Harga Satuan",
    custom: (item) => {
      return `Rp. ${FormatRupiah(item.mpSellPrice, ",")}.-`;
    },
    width: 30,
  },
  {
    name: "Harga Seluruh",
    custom: (item) => {
      return `Rp. ${FormatRupiah(item.mpSellTotal, ",")}.-`;
    },
    width: 30,
  },
  {
    name: "Tgl. Dibuat",
    custom: (item) => {
      return moment(item.createdAt).isValid()
        ? moment(item.createdAt).format("DD / MM / YYYY")
        : "-";
    },
    width: 30,
  },
  {
    name: "Dibuat oleh",
    key: "createdByName",
    width: 30,
  },
  {
    name: "Tgl. Diubah",
    custom: (item) => {
      return moment(item.updatedAt).isValid()
        ? moment(item.updatedAt).format("DD / MM / YYYY")
        : "-";
    },
    width: 30,
  },
  {
    name: "Diubah oleh",
    key: "updatedByName",
    width: 30,
  },
  {
    name: "Status",
    custom: (item) => {
      return (
        <AktifStatus value={item.mpActive} minWidth="120px" maxWidth="120px" />
      );
    },
    width: 30,
  },
];

const filter = [
  {
    label: "Kode Barang",
    value: "mpCode",
  },
  {
    label: "Nama Barang",
    value: "mpName",
  },
  {
    label: "Tanggal dibuat",
    value: "createdAt",
    type: "date",
  },
  {
    label: "Status",
    value: "mpActive",
    type: "option",
    option: statusList,
  },
  {
    label: "Dibuat Oleh",
    value: "createdByName",
  },
];

const MasterProduk = () => {
  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.masterProduk);

  const data = useRef(null);
  const dispatch = useDispatch();

  const [open, setOpen] = useState({
    dialog: false,
    delete: false,
  });

  const fetchData = async () => {
    await dispatch(getListMasterProduk(params));
  };

  useAutoFetch(fetchData, currentPage, filterName);

  const exportData = useFetchData({
    action: getMasterProduk,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "master-produk",
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(null, null, data);
  };

  return (
    <>
      <Page title="Master Produk" desc />
      <MainCard
        title="Daftar Barang"
        onClick={() => {
          data.current = null;
          setOpen({
            ...open,
            dialog: true,
          });
        }}
        borderColor="#C8C8C8"
      >
        <MainCardHeader3
          filterName={filterName}
          changeFilterName={changeFilterName}
          deleteFilter={deleteFilter}
          changeFilter={changeFilter}
          params={params}
          addFilter={addFilter}
          onChangeExport={(type) => handleExport(type)}
          filter={filter}
          fetchData={fetchData}
          onClickFilter={() => {
            fetchData();
          }}
        />
        <MainTable
          headers={headers}
          loading={loading}
          data={list}
          summaryKey="mpName"
          detail={[
            {
              type: "button",
              tooltip: "Edit Master Produk",
              variant: "icon:pencil",
              onClick: (_, item) => {
                data.current = {
                  id: item.mpId,
                  kodeBarang: item.mpCode,
                  namaBarang: item.mpName,
                  keterangan: item.mpLongDesc,
                  harga: item.mpSellPrice,
                  stok: item.mcStokAmount,
                  satuan: "kg",
                  status: item.mpActive,
                };
                setOpen({
                  ...open,
                  dialog: true,
                });
              },
            },
            {
              type: "button",
              tooltip: "Hapus Master Produk",
              variant: "icon:trash",
              onClick: () => {
                setOpen({
                  ...open,
                  delete: true,
                });
              },
            },
          ]}
          increment={params?.offset + 1}
        />
        <MainPagination
          params={params}
          changeLimit={changeLimit}
          changePage={changePage}
          totalItems={totalItems}
          currentPage={currentPage}
        />
      </MainCard>

      <Dialog
        open={open.dialog}
        onClose={() =>
          setOpen({
            ...open,
            dialog: false,
          })
        }
        refresh={fetchData}
        data={data.current}
      />

      <AlertDialog
        buttonSX={{
          minWidth: "160px",
        }}
        open={open.delete}
        alertTitle="Yakin Hapus Data !"
        submitText="Hapus!"
        cancelText="Batal"
        onClose={() => {
          setOpen({
            ...open,
            delete: false,
          });
        }}
        onSubmit={() => {
          setOpen({
            ...open,
            delete: false,
          });
        }}
      />
    </>
  );
};

export default MasterProduk;
