import { Check } from "@mui/icons-material";
import { Box, Checkbox } from "@mui/material";
import CheckIcon from "assets/paper/CheckIcon";
import React from "react";
import PropTypes from "prop-types";

const Square = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#C8C8C8",
        width: "20px",
        height: "20px",
        borderRadius: "4px",
        padding: "0px",
        boxShadow: "0px 1px 8px 1px rgba(0, 0, 0, 0.05)",
      }}
    />
  );
};

const Selected = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#cc1d15",
        width: "20px",
        height: "20px",
        display: "flex",
        boxShadow: "0px 1px 8px 1px rgba(0, 0, 0, 0.05)",
        borderRadius: "4px",
        color: "#fff",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CheckIcon />
    </Box>
  );
};

const MainCheckbox = (props) => {
  return (
    <Checkbox
      {...props}
      icon={<Square />}
      sx={{
        padding: 0.5,
        ...props.sx,
      }}
      checkedIcon={<Selected />}
    />
  );
};

MainCheckbox.propTypes = {
  sx: PropTypes.object,
};
MainCheckbox.defaultProps = {
  sx: {},
};
export default MainCheckbox;
