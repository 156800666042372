import { useState } from "react";
import { useDispatch } from "react-redux";
import { alert } from "redux/global/slice";

const useFetchData = ({
  action,
  reset,
  snackbar = true,
  message,
  onSuccess,
  onError,
  refresh,
  customMessageError = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const dispatch = useDispatch();

  const fetch = async (data, id, params) => {
    setLoading(true);
    try {
      const res = await action(data, id);
      if (
        res?.data?.status_code === 200 ||
        res?.data?.code === 200 ||
        res?.data?.code === 201 ||
        res?.data?.code === 202 ||
        res?.data?.status_code === 202
      ) {
        reset && reset();
        snackbar &&
          dispatch(
            alert({
              open: true,
              message: message,
            })
          );
        setResponse(res?.data?.data);
        onSuccess && onSuccess(res?.data?.data, params);
        refresh && refresh();
      } else {
        dispatch(
          alert({
            open: true,
            message: !customMessageError
              ? res?.data?.message || "Maaf terjadi kesalahan"
              : customMessageError(res?.data) || "Maaf terjadi kesalahan",
          })
        );
        onError && onError(res?.data?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return { fetch, response, setResponse, loading };
};

export default useFetchData;
