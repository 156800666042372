import penggunaDetailSlice from "./Pengguna/Detail/slice";
import penggunaSlice from "./Pengguna/slice";
import roleDetailSlice from "./Role/Detail/slice";
import roleSlice from "./Role/slice";

const penggunaReducer = {
  pengguna: penggunaSlice,
  penggunaDetail: penggunaDetailSlice,
  role: roleSlice,
  roleDetail: roleDetailSlice,
};

export default penggunaReducer;
