import React from "react";

const OnlineTransaksi = () => {
  return (
    <svg
      width="36"
      height="40"
      viewBox="0 0 36 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="9.38965"
        y="8.78333"
        width="16.893"
        height="22.4333"
        fill="#FCC039"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.3053 37.35C8.88475 38 9.58305 38.3333 10.4002 38.3333H25.2577C26.0749 38.3333 26.7732 38 27.3526 37.35C27.9321 36.7 28.2292 35.9166 28.2292 35V4.99996C28.2292 4.08329 27.9321 3.29996 27.3526 2.64996C26.7732 1.99996 26.0749 1.66663 25.2577 1.66663H10.4002C9.58305 1.66663 8.88475 1.99996 8.3053 2.64996C7.72586 3.29996 7.42871 4.08329 7.42871 4.99996V35C7.42871 35.9166 7.72586 36.7 8.3053 37.35ZM25.2582 30H10.4007V9.99995H25.2582V30Z"
        fill="#EB4A42"
      />
      <mask
        id="mask0_2578_95241"
        maskUnits="userSpaceOnUse"
        x="7"
        y="1"
        width="22"
        height="38"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.3053 37.35C8.88475 38 9.58305 38.3333 10.4002 38.3333H25.2577C26.0749 38.3333 26.7732 38 27.3526 37.35C27.9321 36.7 28.2292 35.9166 28.2292 35V4.99996C28.2292 4.08329 27.9321 3.29996 27.3526 2.64996C26.7732 1.99996 26.0749 1.66663 25.2577 1.66663H10.4002C9.58305 1.66663 8.88475 1.99996 8.3053 2.64996C7.72586 3.29996 7.42871 4.08329 7.42871 4.99996V35C7.42871 35.9166 7.72586 36.7 8.3053 37.35ZM25.2582 30H10.4007V9.99995H25.2582V30Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2578_95241)">
        <rect
          x="7.42871"
          y="35"
          width="20.8005"
          height="3.33333"
          fill="#D63129"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8862 13.3334V16.6667H13.0748V14.6667H14.8577V13.3334H11.8862ZM11.8862 23.3334V26.6667H14.8577V25.3334H13.0748V23.3334H11.8862ZM20.8007 26.6667V25.3334H22.5836V23.3334H23.7722V26.6667H20.8007ZM22.5836 14.6667V16.6667H23.7722V13.3334H20.8007V14.6667H22.5836ZM20.7411 23.2667H20.7412V23.2834H20.7411V23.2667ZM19.1219 22.3834H18.3346V23.2834L19.1369 23.2834V24.1834H19.9392V23.2834L20.7411 23.2834V24.1667H21.5434V23.2667H20.7412V22.3834H19.9389V23.2834H19.137V22.4H19.9242V21.5H19.1219V22.3834ZM19.1219 22.3834V22.4H19.137V22.3834H19.1219ZM21.5434 21.4834H20.7411V22.3834H21.5434V21.4834ZM20.7262 20.6H19.9239V21.5H20.7262V20.6ZM19.122 20.6H18.3197V21.5H19.122V20.6ZM21.4993 15.8334V19.4H18.3198V15.8334H21.4993ZM17.2499 24.1667V20.6H14.0704V24.1667H17.2499ZM17.2499 15.8334V19.4H14.0704V15.8334H17.2499ZM16.4478 21.4834V23.2667H14.8581V21.4834H16.4478ZM16.4478 18.5V16.7167H14.8581V18.5H16.4478ZM20.697 16.7167V18.5H19.1072V16.7167H20.697Z"
        fill="white"
      />
    </svg>
  );
};

export default OnlineTransaksi;
