import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, getParams, post, put } from "api";
import { Penerbit } from "api/endpoints";

export const getListPenerbit = createAsyncThunk(
  "penerbit/getPenerbit",
  async (params) => {
    const response = await getParams(Penerbit.list, params);
    return response.data.data;
  }
);

export const getPenerbit = async (params) => {
  const response = await getParams(Penerbit.list, params);
  return response;
};

export const penerbitDetail = async (id) => {
  const withId = `${Penerbit.action}/${id}`;
  const response = await get(withId);

  return response;
};

export const newPenerbit = async (data) => {
  const response = await post(Penerbit.action, data);

  return response;
};

export const updatePenerbit = async (data, id) => {
  const withId = `${Penerbit.action}/${id}`;
  const response = await put(withId, data);
  return response;
};

export const validasiImport = async (data) => {
  const response = await post(Penerbit.validasiImport, data);
  return response;
};

export const multiPenerbit = async (data) => {
  const response = await post(Penerbit.multiPenerbit, data);
  return response;
};

// export const getPenerbitMerchant = async (data) => {
//   const response = await getParams(Penerbit.merchantPenerbit, data);
//   return response;
// };

export const getPenerbitMerchant = createAsyncThunk(
  "penerbit/getPenerbitMerchant",
  async ({ params }) => {
    const response = await getParams(Penerbit.merchantPenerbit, params);
    return response.data.data;
  }
);
