import React from "react";
import PropTypes from "prop-types";
import StatusChip from "../StatusChip";

const NIKStatus = ({ value, arr }) => {
  const find = (text) => {
    return arr.find((i) => i.id === value)?.[text] || "-";
  };

  return (
    <StatusChip
      label={find("label")}
      color={find("color")}
      background={find("backgroundColor")}
    />
  );
};

NIKStatus.defaultProps = {
  value: 0,
  arr: [],
};

NIKStatus.propTypes = {
  value: PropTypes.number,
  arr: PropTypes.array,
};
export default NIKStatus;
