export const defaultValues = (informasi, ikon) => {
  let obj = {};
  if (informasi) {
    obj = {
      ...obj,
      IssuerId: null,
      MerchantCode: "",
      PrefixCode: "",
      CardName: "",
      CardType: null,
      CardDesc: "",
      CardTC: "",
      Status: "",
      StartDate: null,
      EndDate: null,
      AmountValue: null,
      Budget: null,
      Distributed: null,
    };
  }
  if (ikon) {
    obj = {
      ...obj,
      Image1: "",
      Image2: "",
    };
  }
  return obj;
};

export const card_tipe = [
  {
    label: "Pendidikan",
    value: 1,
  },
  {
    label: "Kesehatan",
    value: 2,
  },
  {
    label: "Subsidi",
    value: 3,
  },
  {
    label: "PDAM",
    value: 4,
  },
  {
    label: "Listrik",
    value: 5,
  },
  {
    label: "Lainnya",
    value: 6,
  },
];

export const card_status = [
  {
    label: "Diajukan",
    value: 0,
  },
  {
    label: "Disetujui",
    value: 1,
  },
  {
    label: "Dipublikasikan",
    value: 2,
  },
  {
    label: "Dibatalkan",
    value: 3,
  },
  {
    label: "Dikeluarkan",
    value: 4,
  },
  {
    label: "Ditunda",
    value: 5,
  },
  {
    label: "Diakhiri",
    value: 6,
  },
];


