import { createSlice } from "@reduxjs/toolkit";
import role from "helper/role";
import { getPhoto, getProfile } from "./action";

const initialState = {
  profile: {},
  photo: "",
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  extraReducers: {
    [getProfile.fulfilled]: (state, action) => {
      state.profile = {
        ...action.payload,
        role: role()
      }
    },
    [getPhoto.fulfilled]: (state, action) => {
        state.photo = action.payload.ProfileImage
      },
  },
});

export default profileSlice.reducer;
