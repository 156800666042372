import React from "react";

const VisibilityOn = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2999_6687)">
        <path
          d="M10 4.99992C13.1584 4.99992 15.975 6.77492 17.35 9.58325C15.975 12.3916 13.1584 14.1666 10 14.1666C6.84171 14.1666 4.02504 12.3916 2.65004 9.58325C4.02504 6.77492 6.84171 4.99992 10 4.99992ZM10 3.33325C5.83337 3.33325 2.27504 5.92492 0.833374 9.58325C2.27504 13.2416 5.83337 15.8333 10 15.8333C14.1667 15.8333 17.725 13.2416 19.1667 9.58325C17.725 5.92492 14.1667 3.33325 10 3.33325ZM10 7.49992C11.15 7.49992 12.0834 8.43325 12.0834 9.58325C12.0834 10.7333 11.15 11.6666 10 11.6666C8.85004 11.6666 7.91671 10.7333 7.91671 9.58325C7.91671 8.43325 8.85004 7.49992 10 7.49992ZM10 5.83325C7.93337 5.83325 6.25004 7.51659 6.25004 9.58325C6.25004 11.6499 7.93337 13.3333 10 13.3333C12.0667 13.3333 13.75 11.6499 13.75 9.58325C13.75 7.51659 12.0667 5.83325 10 5.83325Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2999_6687">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VisibilityOn;
