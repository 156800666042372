import { config } from "./config";
import { API, APIAuth } from "./axios";
import { removeCookie } from "helper/cokkie";
import axios from "axios";

const handleError = (error) => {
  if (
    error?.response?.status == 401 ||
    error?.response?.data?.message ===
      "authorization token credentials do not match" ||
    error?.response?.data?.message == "authorization token has expired"
  ) {
    removeCookie("token-ntt-webadmin");
    window.location.replace("/auth/login?w=expiredToken");
  } else {
    console.log(error);
  }
};

export const get = (url) => {
  const response = API.get(url, {
    headers: config,
  })
    .then((response) => response)
    .catch(handleError);
  return response;
};

export const postLogin = (url, body) => {
  const response = APIAuth.post(url, body, {
    headers: config,
  })
    .then((response) => response)
    .catch((err) => console.log(err));
  return response;
};

export const postLoginInit = (url, body, appId) => {
  const response = APIAuth.post(url, body, {
    headers: {
      Accept: "application/json",
      "App-Id": appId,
    },
  })
    .then((response) => response)
    .catch((err) => {
      console.log(err);
      return err.response;
    });

  return response;
};

export const getParams = (url, params) => {
  const response = API.get(url, {
    headers: config,
    params,
    paramsSerializer: {
      serialize: (obj) => {
        var str = "";
        for (var key in obj) {
          if (str != "") {
            str += "&";
          }
          if (typeof obj[key] == "object") {
            str += key + "=" + encodeURIComponent(JSON.stringify(obj[key]));
          } else {
            str += key + "=" + encodeURIComponent(obj[key]);
          }
        }
        return str;
      },
    },
  })
    .then((response) => response)
    .catch(handleError);
  return response;
};

export const post = (url, body, params) => {
  const response = API.post(url, body, {
    headers: config,
    params,
  })
    .then((response) => response)
    .catch((err) => {
      console.log(err);
      return err.response;
    });
  return response;
};

export const put = (url, body) => {
  const response = API.put(url, body, {
    headers: config,
  })
    .then((response) => response)
    .catch((err) => {
      console.log(err);
      return err.response;
    });
  return response;
};

export const upload = (url, body) => {
  const response = API.post(url, body, {
    headers: config,
  })
    .then((response) => response)
    .catch((err) => {
      console.log(err);
      return err.response;
    });
  return response;
};

export const destroy = (url, params) => {
  return API.delete(url, {
    headers: config,
    data: params,
    paramsSerializer: {
      serialize: (obj) => {
        var str = "";
        for (var key in obj) {
          if (str != "") {
            str += "&";
          }
          if (typeof obj[key] == "object") {
            str += key + "=" + encodeURIComponent(JSON.stringify(obj[key]));
          } else {
            str += key + "=" + encodeURIComponent(obj[key]);
          }
        }
        return str;
      },
    },
  });
};

export const getIP = () => {
  const response = axios
    .get("https://api.ipify.org/?format=json")
    .then((response) => {
      if (response.data.ip) {
        // APIAuth.defaults.headers.common["X-Forwarded-For"] = response.data.ip;
        API.defaults.headers.common["X-Forwarded-For"] = response.data.ip;
      }
      return response;
    })
    .catch((err) => {
      return err.response;
    });
  return response;
};
