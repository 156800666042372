import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import MainDialog from "components/Dialog/MainDialog";
import MainGrid from "components/Grid/MainGrid";
import moment from "moment";
import AktifStatus from "components/Status/AktifStatus";

const DialogStock = ({ data, open, loading, onClose }) => {
  const goData = [
    {
      label: "Status",
      value: (
        <AktifStatus
          customText={["Ada", "Habis"]}
          value={data?.mpActive}
          minWidth="120px"
          maxWidth="120px"
        />
      ),
    },
    {
      label: "Kode Barang",
      value: data?.mpCode,
    },
    {
      label: "Nama Barang",
      value: data?.mpName,
    },
    {
      label: "Penerbit",
      value: data?.penerbitName,
    },
    {
      label: "Program",
      value: data?.programName,
    },
    {
      label: "Voucher",
      value: data?.voucherName,
    },
    {
      label: "Merchant",
      value: data?.mcName,
    },
    {
      label: "Periode",
      value: `${
        moment(data?.startPeriod).isValid()
          ? moment(data?.startPeriod).format("DD/MM/YYYY")
          : "-"
      } - ${
        moment(data?.endPeriod).isValid()
          ? moment(data?.endPeriod).format("DD/MM/YYYY")
          : "-"
      }`,
    },
    {
      label: "Penukaran",
      value: `${
        moment(data?.startPenukaran).isValid()
          ? moment(data?.startPenukaran).format("DD/MM/YYYY")
          : "-"
      } - ${
        moment(data?.endPenukaran).isValid()
          ? moment(data?.endPenukaran).format("DD/MM/YYYY")
          : "-"
      }`,
    },
    {
      label: "Tgl. Dibuat",
      value: moment(data?.createdAt).isValid()
        ? moment(data?.createdAt).format("DD/MM/YYYY")
        : "-",
    },
    {
      label: "Dibuat",
      value: data?.createdByName,
    },
    {
      label: "Tgl. Diubah",
      value: moment(data?.updatedAt).isValid()
        ? moment(data?.updatedAt).format("DD/MM/YYYY")
        : "-",
    },
    {
      label: "Dibuat",
      value: data?.updatedByName,
    },
    {
      label: "Tgl. Masuk",
      value: moment(data?.masukDate).isValid()
        ? moment(data?.masukDate).format("DD/MM/YYYY")
        : "-",
    },
    {
      label: "Stok Awal",
      value: data?.firstStock,
    },
    {
      label: "Masuk",
      value: data?.masukCount,
    },
    {
      label: "Keluar",
      value: data?.keluarCount,
    },
    {
      label: "Stok Akhir",
      value: data?.lastStock,
    },
    {
      label: "Tgl. Keluar",
      value: moment(data?.keluarDate).isValid()
        ? moment(data?.keluarDate).format("DD/MM/YYYY")
        : "-",
    },
  ];

  return (
    <>
      <MainDialog
        open={open}
        onClose={() => {
          onClose();
        }}
        loading={false}
        action={false}
        customWidth="610px"
        title="Detail Transaksi"
      >
        {goData.map((item, i) => (
          <MainGrid
            key={i}
            {...item}
            labelMd={4}
            valueMd={5}
            striped="odd"
            center
          />
        ))}
      </MainDialog>
    </>
  );
};

DialogStock.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.object,
  open: PropTypes.bool,
  loading: PropTypes.bool,
};

DialogStock.defaultProps = {
  onClose: () => {},
  open: false,
  data: {},
  loading: false,
};
export default DialogStock;
