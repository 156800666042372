import PropTypes from "prop-types";
import { RemoveRedEye } from "@mui/icons-material";
import { Box, Paper, Typography } from "@mui/material";
import MainLoading from "components/Loading/MainLoading";
import React from "react";
import moment from "moment";

const dataKartu = [
  "no_kartu",
  "nik",
  "nama",
  "alamat",
  "no_hp",
  "email",
  "masa_berlaku",
];

const DataKartu = ({ response, loading, center, onClick }) => {
  const renderDataKartu = () => {
    const renderValue = (datak) => {
      switch (datak) {
        case "masa_berlaku":
          return `${
            response?.data_kartu?.startDate
              ? moment(response?.data_kartu?.startDate).format("DD MMM YYYY")
              : "-"
          } - ${
            response?.data_kartu?.endDate
              ? moment(response?.data_kartu?.endDate).format("DD MMM YYYY")
              : "-"
          }`;
        default:
          return response?.data_kartu?.[datak] || "-";
      }
    };

    const renderTitle = (datak) => {
      switch (datak) {
        case "no_kartu":
          return "No. Kartu";
        case "no_hp":
          return "No. Handphone";
        case "nik":
          return "NIK";
        default:
          return datak.replace("_", " ");
      }
    };

    return dataKartu.map((datak, i) => (
      <Box
        key={i}
        sx={{
          display: "flex",
          height: "21px",
          whiteSpace: "nowrap",
        }}
      >
        <Box sx={{ width: "30%" }}>
          <Typography
            variant="desc"
            sx={{
              fontSize: "10px !important",
              color: "#111",
              fontWeight: "700",
            }}
          >
            {renderTitle(datak)}
          </Typography>
        </Box>
        <Box sx={{ width: "60%", overflow: "hidden" }}>
          <Typography
            variant="desc"
            sx={{
              fontSize: "10px !important",
              color: "#111",
              fontWeight: "700",
            }}
          >
            : {renderValue(datak)}
          </Typography>
        </Box>
      </Box>
    ));
  };

  return (
    <Paper
      sx={{
        backgroundColor: "#fff",
        height: "240px",
        width: "393px",
        margin: center ? "auto" : "0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 1px 8px 1px rgba(0, 0, 0, 0.04)",
      }}
    >
      <MainLoading loading={loading} loader={false}>
        {response?.data_kartu?.gambar && (
          <Box
            sx={{
              borderRadius: "4px",
              position: "relative",
              width: "100%",
              height: "100%",
              verticalAlign: "middle",
              ":hover": {
                cursor: "pointer",
                ".dialog": {
                  backgroundColor: "#111",
                  opacity: "0.3",
                },
                svg: {
                  visibility: "visible",
                  opacity: "1",
                },
              },
            }}
          >
            <Box
              component="img"
              src={response?.data_kartu?.gambar}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.style.display = "none";
              }}
              sx={{
                width: "100%",
                borderRadius: "8px",
                height: "100%",
              }}
            />

            <Box
              sx={{
                position: "absolute",
                bottom: "0",
                left: "2%",
                right: "0",
                top: "25%",
                overflow: "hidden",
                borderRadius: "10px",
                pl: "3%",
                pt: "1%",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {renderDataKartu()}
              </Box>
            </Box>
            {Boolean(onClick) && (
              <Box
                className="dialog"
                sx={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  opacity: 0,
                  transition: "all 0.4s",
                  display: "flex",
                  borderRadius: "10px",
                  zIndex: 11,
                }}
                onClick={onClick}
              ></Box>
            )}
            {response?.data_kartu?.isImage == 2 && (
              <Typography
                sx={{
                  position: "absolute",
                  top: "7.1%",
                  left: "15%",
                  opacity: 1,
                  color: "#fff",
                  zIndex: 10,
                }}
                variant="textCommon"
              >
                {response?.data_kartu?.nama_kartu || "-"}
              </Typography>
            )}
            {Boolean(onClick) && (
              <RemoveRedEye
                sx={{
                  visibility: "hidden",
                  color: "#fff",
                  margin: "auto",
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 999,
                  opacity: 0,
                  transition: "all 0.4s",
                }}
              />
            )}
          </Box>
        )}
      </MainLoading>
    </Paper>
  );
};

DataKartu.propTypes = {
  loading: PropTypes.any,
  onClick: PropTypes.any,
  response: PropTypes.shape({
    data_kartu: PropTypes.shape({
      gambar: PropTypes.any,
      endDate: PropTypes.any,
      startDate: PropTypes.any,
      isImage: PropTypes.any,
      nama_kartu: PropTypes.any,
    }),
  }),
  center: PropTypes.bool,
};

DataKartu.defaultProps = {
  loading: false,
  onClick: null,
  response: null,
  center: true,
};
export default DataKartu;
