const RoleProviders = ({ permission, children }) => {
  const permissionList = JSON.parse(localStorage.getItem("permission-ntt"));

  if (permission && permissionList) {
    if (!Array.isArray(permission)) {
      let inc = permissionList.includes(permission);
      if (!inc) {
        return;
      }
    } else {
      let arrinc = permission.some((arr) => permissionList.includes(arr));
      if (!arrinc) {
        return;
      }
    }
  }

  return children;
};

export default RoleProviders;
