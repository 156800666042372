import PropTypes from "prop-types";
import { ExpandMore } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import MainCheckbox from "components/Checkbox/MainCheckbox";
import React from "react";
import PopOut from "./PopOut";
import SelectedBox from "./SelectedBox";

const PopperInputCheckBox = ({
  loading,
  data,
  onChange,
  value,
  selected,
  onClick,
  ...props
}) => {
  return (
    <>
      <PopOut onClick={onClick} loading={loading} {...props}>
        {data.map((item, i) => (
          <SelectedBox
            onChange={() => onChange(item)}
            selected={value.some((i) => i == item.IssuerCode)}
            key={i}
          >
            <MainCheckbox
              sx={{ marginRight: 1 }}
              checked={value.some((i) => i == item.IssuerCode)}
            />
            <Typography variant="labelMedium2">{item.IssuerName}</Typography>
          </SelectedBox>
        ))}

        {!data.length && (
          <Typography
            variant="labelMedium2"
            sx={{
              whiteSpace: "pre-line",
              textAlign: "center",
              margin: "auto",
              display: "block",
              mt: 5,
              // marginTop: "30px",
            }}
          >
            Data Tidak Ditemukan
          </Typography>
        )}
      </PopOut>
    </>
  );
};

PopperInputCheckBox.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.array,
  selected: PropTypes.any,
};

PopperInputCheckBox.defaultProps = {
  data: [],
  value: [],
  loading: false,
  onChange: () => {},
  onClick: () => {},
  selected: null,
};

export default PopperInputCheckBox;
