import MainDialog from "./MainDialog.js";
import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import WarningIcon from "assets/paper/WarningIcon.js";

const AlertDialog = ({
  open,
  onClose,
  alertTitle,
  alertDesc,
  cancelText,
  submitText,
  onSubmit,
  loading,
  type,
  customIcon,
  customWidth,
  textDescAlign,
  titleVariant,
  buttonSX,
}) => {
  return (
    <MainDialog
      open={open}
      onClose={() => {
        if (loading) {
          return;
        }
        onClose();
      }}
      customWidth={!customWidth ? "382px" : customWidth}
      action={false}
      closeButton={false}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // pt: 1,
          // px: 2,
          gap: 3,
        }}
      >
        {!customIcon ? <WarningIcon /> : customIcon}

        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: "column",
          }}
        >
          <Typography variant={titleVariant} textAlign="center">
            {alertTitle}
          </Typography>
          <Typography variant="text2" textAlign={textDescAlign}>
            {alertDesc}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 2,
            width: "100%",
            // mt: 5,
            margin: "auto",
            justifyContent: "center",
          }}
        >
          {Boolean(cancelText) && (
            <Button
              variant="gray"
              sx={{
                minWidth: "150px",
                minHeight: "50px",
              }}
              onClick={onClose}
            >
              {cancelText}
            </Button>
          )}
          {type === "submit" ? (
            <Button
              variant="primary"
              sx={{
                ...buttonSX,
                minWidth: "150px",
                minHeight: "50px",
              }}
              type={type}
              disabled={loading}
            >
              {submitText}
            </Button>
          ) : (
            <Button
              variant="primary"
              sx={{
                ...buttonSX,
              }}
              onClick={onSubmit}
            >
              {submitText}
            </Button>
          )}
        </Box>
      </Box>
    </MainDialog>
  );
};

AlertDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  alertTitle: PropTypes.string,
  alertDesc: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  loading: PropTypes.bool,
  type: PropTypes.string,
  customIcon: PropTypes.any,
  customWidth: PropTypes.string,
  textDescAlign: PropTypes.string,
  titleVariant: PropTypes.string,
  buttonSX: PropTypes.object,
};

AlertDialog.defaultProps = {
  open: false,
  onClose: null,
  onSubmit: null,
  alertTitle: "",
  alertDesc: "",
  cancelText: "",
  submitText: "",
  type: "button",
  loading: false,
  customIcon: null,
  customWidth: null,
  textDescAlign: "center",
  titleVariant: "title5",
  buttonSX: null,
};

export default AlertDialog;
