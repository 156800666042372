import PropTypes from "prop-types";
import React, { useState } from "react";
import MainDialog from "components/Dialog/MainDialog";
import MainGrid from "components/Grid/MainGrid";
import moment from "moment";
import AktifStatus from "components/Status/AktifStatus";
import { Box, Tooltip } from "@mui/material";
import Copyv2 from "assets/paper/v2/Copyv2";

const DialogStock = ({ data, open, onClose }) => {
  const [clipTxt, setClipTxt] = useState("");

  const goData = [
    {
      label: "Status",
      value: (
        <AktifStatus
          customText={["Aktif", "Ditukarkan"]}
          value={data?.status}
          minWidth="120px"
          maxWidth="120px"
        />
      ),
    },
    {
      label: "Tanggal Transaksi",
      value: moment(data?.date).isValid()
        ? moment(data?.date).format("DD/MM/YYYY")
        : "-",
    },
    {
      label: "Nomor Transaksi",
      value: (
        <Box display="flex" width="100%" justifyContent="space-between">
          {data?.trxNo}
          <Tooltip
            title={clipTxt}
            onMouseEnter={() => setClipTxt("Copy No. Transaksi")}
            placement="bottom"
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setClipTxt("Copied");
                navigator.clipboard.writeText(data?.trxNo);
              }}
            >
              <Copyv2 />
            </span>
          </Tooltip>
        </Box>
      ),
    },
    {
      label: "Nomor Referensi",
      value: (
        <Box display="flex" width="100%" justifyContent="space-between">
          {data?.trxNoReceipt}
          <Tooltip
            title={clipTxt}
            onMouseEnter={() => setClipTxt("Copy No. Transaksi")}
            placement="bottom"
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                setClipTxt("Copied");
                navigator.clipboard.writeText(data?.trxNoReceipt);
              }}
            >
              <Copyv2 />
            </span>
          </Tooltip>
        </Box>
      ),
    },
    {
      label: "Aktivitas",
      value: data?.activityName,
    },
    {
      label: "Nama",
      value: data?.name,
    },
    {
      label: "Nama Program",
      value: data?.programName,
    },
    {
      label: "Nama Voucher",
      value: data?.voucherName,
    },
    {
      label: "Nama Barang",
      value: data?.productName,
    },
    {
      label: "Nama Penerbit",
      value: data?.penerbit,
    },
    {
      label: "NIK",
      value: data?.nik,
    },
    {
      label: "Catatan",
      value: data?.note,
    },
  ];

  return (
    <>
      <MainDialog
        open={open}
        onClose={() => {
          onClose();
        }}
        loading={false}
        action={false}
        customWidth="610px"
        title="Detail Transaksi"
      >
        {goData.map((item, i) => (
          <MainGrid
            key={i}
            {...item}
            labelMd={4}
            valueMd={8}
            striped="odd"
            center
          />
        ))}
      </MainDialog>
    </>
  );
};

DialogStock.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.object,
  open: PropTypes.bool,
  loading: PropTypes.bool,
};

DialogStock.defaultProps = {
  onClose: () => {},
  open: false,
  data: {},
  loading: false,
};
export default DialogStock;
