import { createSlice } from "@reduxjs/toolkit";
import { getRoleDetail } from "../../action";

export const initialState = {
  RoleName: "",
  Description: "",
  Users: [],
  params: {},
  totalUser: 0,
  permission: [],
  message: "filter",
  loading: false,
};

export const roleDetailSlice = createSlice({
  name: "roleDetail",
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.params.filter = action.payload;
    },
  },
  extraReducers: {
    [getRoleDetail.pending]: (state) => {
      state.loading = true;
    },
    [getRoleDetail.rejected]: (state, action) => {
      state.loading = false;
      state.message = action.error.message;
    },
    [getRoleDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.RoleName = action.payload.RoleName;
      state.Description = action.payload.Description;
      state.totalUser = action.payload.TotalUser;
      state.Users = action.payload.Users;
      state.permission = action.payload.Permission;
    },
  },
});

export const { changeFilter } = roleDetailSlice.actions;

export default roleDetailSlice.reducer;
