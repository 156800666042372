import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useEffect, useState, useMemo, Fragment } from "react";
import { Controller, useForm } from "react-hook-form";
import VerticalGrid from "components/Grid/VerticalGrid";
import MainTextField from "components/TextField/MainTextField";
import { Grid, Typography, Box } from "@mui/material";
import MainCheckbox from "components/Checkbox/MainCheckbox";
import permission from "api/permission";
import { createRole, editRole } from "redux/managementPengguna/action";
import useFetchData from "hook/useFetchData";

export const groupByArray = (data, io) => {
  if (!data?.length) return;
  const assign = (obj, keyPath, value) => {
    let lastKeyIndex = keyPath.length - 1;
    for (let i = 0; i < lastKeyIndex; ++i) {
      let key = keyPath[i];
      if (!(key in obj)) {
        obj[key] = {};
      }
      obj = obj[key];
    }
    if (!Array.isArray(obj[keyPath[lastKeyIndex]])) {
      obj[keyPath[lastKeyIndex]] = [];
    }
    obj[keyPath[lastKeyIndex]].push({ name: value, ref: keyPath, value: io });
  };

  let obj = {};

  data.forEach((item) => {
    const split = item.split(":");
    const slice = split.slice(0, split.length - 1);
    const getRole = split[split.length - 1];
    assign(obj, slice, getRole);
  });

  return obj;
};

const { Web: group } = groupByArray(permission, false);

const changeName = (string) => {
  switch (string) {
    case "Get":
      return "Lihat";
      break;
    case "Edit":
      return "Ubah";
    default:
      break;
  }
};

const changeListText = (item) => {
  switch (item) {
    case "CSRBansos":
      return "CSR Bansos";
    default:
      return item.replace(/([A-Z])/g, " $1").trim();
  }
};

const RenderRole = ({
  data,
  title,
  level,
  type,
  handleSelectAll,
  handleSelect,
}) => {
  const keys = Object.keys(data);

  const checkedValue = useMemo(() => {
    if (level == 0 && !type) {
      let tf = {};
      const newObject = (object) => {
        const clonedObj = { ...object };
        const keys = Object.keys(clonedObj);

        keys.forEach((key) => {
          if (!Array.isArray(clonedObj[key])) {
            newObject(clonedObj[key]);
          } else {
            if (!Array.isArray(tf[title])) {
              tf[title] = [];
            }
            tf[title].push(clonedObj[key].every((i) => i.value == true));
          }
        });
      };

      newObject(data);
      return tf;
    }
  });

  return (
    <Box
      display="flex"
      flexDirection={!type ? "column" : "row"}
      justifyContent="end"
      pt={type && level > 1 ? 1.5 : !type && level > 1 ? 1.5 : 0.5}
      pb={type && level > 1 ? 1 : 0}
      borderBottom={level > 0 && type ? "1px dashed #C8C8C8" : "none"}
    >
      <Typography
        variant={"label2"}
        sx={{
          marginRight: "auto",
          width: "100%",
          marginLeft: level,
          mt: "auto",
          display: "flex",
          justifyContent: "space-between",
          mb: "auto",
        }}
        fontWeight={level == 0 ? "700" : "600"}
      >
        {changeListText(title)}
        {level == 0 && !type ? (
          <Box display="flex" gap={1}>
            <Box display="flex" alignItems="center" gap={0.5}>
              <Typography variant="text2" color="#4C4D4F">
                Pilih Semua
              </Typography>

              <MainCheckbox
                checked={checkedValue?.[title].every((i) => i == true)}
                onClick={() => handleSelectAll(data, title)}
              />
            </Box>
          </Box>
        ) : null}
      </Typography>
      {!type &&
        keys.map((item, i) => {
          return (
            <Fragment key={i}>
              <RenderRole
                data={data[item]}
                title={item}
                level={level + 2}
                type={Array.isArray(data[item])}
                handleSelect={handleSelect}
              />
            </Fragment>
          );
        })}

      {type && (
        <Box display="flex" gap={2}>
          {data.map((item, i) => (
            <Box key={i} display="flex" alignItems="center" gap={0.5}>
              <Typography variant="text2" color="#4C4D4F">
                {changeName(item.name)}
              </Typography>

              <MainCheckbox
                checked={item.value}
                onClick={(e) => {
                  handleSelect(item);
                }}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

RenderRole.propTypes = {
  data: PropTypes.any,
  title: PropTypes.any,
  level: PropTypes.number,
  type: PropTypes.bool,
  handleSelectAll: PropTypes.func,
  handleSelect: PropTypes.func,
};

RenderRole.defaultProps = {
  data: null,
  title: null,
  level: 0,
  type: false,
  handleSelectAll: null,
  handleSelect: null,
};

const Dialog = ({ open, onClose, refresh, data, id, setRes }) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset: resetForm,
    clearErrors,
  } = useForm({
    defaultValues: {
      MerchantRoleName: "",
      Description: "",
    },
  });

  const [realData, setRealData] = useState({});
  useEffect(() => {
    if (data?.id) {
      resetForm({
        MerchantRoleName: data.MerchantRoleName,
        Description: data.Description,
      });

      const newObject = (group, getGroup) => {
        const clonedObj = { ...group };
        const cloneGetGroup = { ...getGroup };

        const entries = Object.entries(clonedObj);
        entries.forEach(([key, value]) => {
          if (!Array.isArray(value)) {
            if (cloneGetGroup[key]) {
              clonedObj[key] = newObject(value, cloneGetGroup[key]);
            }
          } else {
            clonedObj[key] = clonedObj[key].map((item) => {
              return {
                ...item,
                value:
                  cloneGetGroup[key]?.find((i) => i.name == item.name)?.value ||
                  false,
              };
            });
          }
        });

        return clonedObj;
      };

      const { Web: getGroup } = groupByArray(data?.Permission, true) || {
        Web: [],
      };

      const newdata = newObject(group, getGroup);

      setRealData(newdata);
    } else {
      resetForm({
        MerchantRoleName: "",
        Description: "",
      });
      setRealData(group);
    }
  }, [data]);

  const handleSelectAll = (object, keyyyyy) => {
    let selectedAll = [];

    const getSelectAll = (object) => {
      const clone = { ...object };
      const keys = Object.keys(clone);

      keys.forEach((item) => {
        if (!Array.isArray(clone[item])) {
          getSelectAll(clone[item]);
        } else {
          clone[item].forEach((kon) => {
            selectedAll.push(kon);
          })
        }
      });
    };
    getSelectAll(object);

    const newObject = (object) => {
      const clonedObj = { ...object };
      const entries = Object.entries(clonedObj);

      entries.forEach(([key, value]) => {
        if (!Array.isArray(value)) {
          clonedObj[key] = newObject(value);
        } else {
          clonedObj[key] = clonedObj[key].map((item) => {
            if (selectedAll.every((i) => i.value == true)) {
              return {
                ...item,
                value: false,
              };
            }
            return {
              ...item,
              value: true,
            };
          });
        }
      });

      return clonedObj;
    };

    const newData = newObject(object);

    const copy = { ...realData };

    copy[keyyyyy] = {};
    copy[keyyyyy] = newData;

    setRealData(copy);
  };

  const action = useFetchData({
    action: data?.id ? editRole : createRole,
    reset: resetForm,
    message: data?.id ? "Berhasil edit data" : "Berhasil menambah role baru",
    refresh: refresh,
    onSuccess: () => {
      setRealData(group);
      onClose();
    },
  });

  const onSubmit = async (submitData) => {
    const permis = changePermission();
    const formData = new FormData();
    for (let key in submitData) {
      formData.append(key, submitData[key]);
    }
    formData.append("Permissions", JSON.stringify(permis));
    await action.fetch(formData, data?.id);
  };

  const changePermission = () => {
    let data = [];

    const loop = (obj) => {
      let keys = Object.keys(obj);

      keys.forEach((item) => {
        if (!Array.isArray(obj[item])) {
          loop(obj[item]);
        } else {
          obj[item]?.forEach((i) => {
            if (i.value) {
              data.push([...i.ref, i.name]);
            }
          });
        }
      });
    };

    loop(realData);

    const newData = data.map((item) => item.join(":"));

    return newData;
  };

  const handleSelect = (item) => {
    const slice = item.ref.slice(1, item.ref.length);
    const copy = { ...realData };

    const loop = () => {
      let schema = copy;
      let pList = slice;
      let len = pList.length;
      for (let i = 0; i < len - 1; i++) {
        let elem = pList[i];
        if (!schema[elem]) schema[elem] = {};
        schema = schema[elem];
      }

      schema[pList[len - 1]] = schema[pList[len - 1]].map((j) => {
        if (j.name == item.name) {
          return {
            ...j,
            value: !item.value,
          };
        }
        return j;
      });
    };
    loop();

    setRealData(copy);
  };

  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
        resetForm();
        setRealData(group);
      }}
      title={!data?.id ? "Tambah Peran Pengguna" : "Ubah Peran Pengguna"}
      submitText={data?.id ? "Simpan Perubahan" : "Tambah"}
      loading={false}
      valid={true}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      onReset={() => {
        resetForm();
        setRealData(group);
      }}
      type={1}
      customWidth="700px"
    >
      <Grid container spacing={2}>
        <VerticalGrid label={"Nama Peran"} md={12}>
          <MainTextField
            controller={Controller}
            name="MerchantRoleName"
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            rules={{
              required: "Nama Peran wajib diisi",
            }}
            typeField={1}
            placeholder="Masukan nama peran"
          />
        </VerticalGrid>
        <VerticalGrid label={"Deskripsi Peran"} md={12}>
          <MainTextField
            controller={Controller}
            name="Description"
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            rules={{
              required: "Deskripsi Peran wajib diisi",
            }}
            typeField={1}
            placeholder="Berikan penjelasan singkat terkatit peran"
          />
        </VerticalGrid>
        {Object.keys(realData).map((item, i) => (
          <Grid item xs={12} key={i}>
            <RenderRole
              data={realData[item]}
              title={item}
              type={Array.isArray(realData[item])}
              handleSelectAll={handleSelectAll}
              handleSelect={handleSelect}
              level={0}
            />
          </Grid>
        ))}
      </Grid>
    </MainDialog>
  );
};

Dialog.propTypes = {
  isEdit: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  data: PropTypes.object,
  id: PropTypes.any,
  setRes: PropTypes.func,
};

Dialog.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  data: null,
  id: null,
  setRes: null,
};
export default Dialog;
