import React from "react";

const SidebarOpen = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6V8H16V6H3ZM13 13H3V11H13V13ZM16 18H3V16H16V18ZM17.42 12L21 15.59L19.59 17L14.59 12L19.59 7L21 8.41L17.42 12Z"
        fill="black"
      />
      <mask
        id="mask0_0_3413"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="3"
        y="6"
        width="18"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 6V8H16V6H3ZM13 13H3V11H13V13ZM16 18H3V16H16V18ZM17.42 12L21 15.59L19.59 17L14.59 12L19.59 7L21 8.41L17.42 12Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_0_3413)">
        <rect width="24" height="24" fill="#4E4751" />
      </g>
    </svg>
  );
};

export default SidebarOpen;
