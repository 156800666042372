import React from "react";
import Providers from "components/RouteRoleProviders";
import MasterProduk from "pages/Inventory/MasterProduk";
import MasterDistribusi from "pages/Inventory/MasterDistribusi/KirimProduk";
import TerimaProduk from "pages/Inventory/MasterDistribusi/TerimaProduk";

const inventoryRoutes = [
  {
    path: "/inventory/master-produk",
    element: (
      <Providers permission="Web:Pengguna:Get">
        <MasterProduk />
      </Providers>
    ),
  },
  {
    path: "/inventory/master-distribusi/kirim-produk",
    element: (
      <Providers permission="Web:Pengguna:Get">
        <MasterDistribusi />
      </Providers>
    ),
  },
  {
    path: "/inventory/master-distribusi/terima-produk",
    element: (
      <Providers permission="Web:Pengguna:Get">
        <TerimaProduk />
      </Providers>
    ),
  },
];

export default inventoryRoutes;
