import PropTypes from "prop-types";
import { ErrorOutline } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import React, { forwardRef } from "react";

const formInput = () => ({
  padding: "0px",
});

const inputSX = (theme) => ({
  borderRadius: "8px !important",
  backgroundColor: "#fff !important",
  height: "40px",
  outline: "1px solid #CBD5E1 !important",
  "&:hover": {
    outline: "1px solid #cc1d15 !important",
  },
  [theme.breakpoints.up("xl")]: {
    height: "44px",
  },
  input: {
    "&::-webkit-input-placeholder": {
      color: "#94A3B8 !important",
    },
    "&:hover": {
      borderColor: "#CC1D15",
    },
    ":-webkit-autofill": {
      WebkitTextFillColor: "#74788d !important",
      WebkitBoxShadow: "0 0 0 30px white inset !important",
    },
    "::-internal-input-suggested": {
      fontFamily: "Manrope !important",
    },
    fontWeight: "400 !important",
    color: "#74788d",
  },
});

const TextFieldv2 = forwardRef(({ name, errors, ...props }, ref) => {
  return (
    <TextField
      {...props}
      ref={ref}
      error={errors?.[name]?.message ? true : false}
      sx={formInput}
      InputProps={{
        sx: inputSX,
        endAdornment: (
          <InputAdornment position="end">
            {props?.InputProps?.endAdornment || null}
            {errors?.[name]?.message ? (
              <ErrorOutline sx={{ color: "#cc1d15" }} />
            ) : (
              ""
            )}
          </InputAdornment>
        ),
        ...props.InputProps,
      }}
      autoComplete="off"
    />
  );
});

TextFieldv2.displayName = "tfv2";
TextFieldv2.propTypes = {
  errors: PropTypes.any,
  name: PropTypes.any,
  InputProps: PropTypes.any,
};

TextFieldv2.defaultProps = {
  errors: null,
  name: null,
  InputProps: null,
};

export default TextFieldv2;
