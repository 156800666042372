import ImportDialog from "components/Dialog/ImportDialog";
import React from "react";
import PropTypes from "prop-types";
import FileTemplate from "assets/penerbit/template/penerbit-template.xlsx";
import downloadFile from "helper/downloadFile";
import { multiPenerbit, validasiImport } from "redux/penerbit/action";
import useFetchData from "hook/useFetchData";
import AktifStatus from "components/Status/AktifStatus";
import MainTable from "components/Table/MainTable";

const Import = ({ open, refresh, onClose }) => {

  const importSubmit = useFetchData({
    action: multiPenerbit,
    message: "Import Data penerbit berhasil",
    refresh: refresh,
    onSuccess: () => {
      onClose();
      importAction.setResponse(null);
    },
  });

  const importAction = useFetchData({
    action: validasiImport,
    snackbar: false,
  });

  const headers = [
    {
      name: "Nama Penerbit",
      key: "IssuerName",
      width: 50,
    },
    {
      name: "Kode Penerbit",
      key: "IssuerCode",
      width: 20,
    },
    {
      name: "Status Penerbit",
      custom: (item) => <AktifStatus value={parseInt(item.IssuerStatus)} />,
      width: 25,
    },
  ];

  const handleValidasi = (e) => {
    const data = new FormData();
    data.append("excel", e.target.files[0]);
    importAction.fetch(data);
  };

  const handleSubmit = () => {
    importSubmit.fetch(importAction?.response);
  };

  return (
    <ImportDialog
      open={open}
      onClose={() => {
        onClose();
        importAction.setResponse(null);
      }}
      onClickTemplate={() =>
        downloadFile(FileTemplate, "penerbit-template.xlsx")
      }
      onChange={handleValidasi}
      loading={importAction.loading || !Boolean(importAction?.response)}
      onClear={() => importAction.setResponse(null)}
      onSubmit={handleSubmit}
    >
      <MainTable
        headers={headers}
        loading={importAction?.loading}
        data={importAction?.response || []}
        summaryKey="IssuerName"
        action=""
        is_duplicate
      />
    </ImportDialog>
  );
};

Import.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
};

Import.defaultProps = {
  onClose: () => {},
  open: false,
  refresh: null,
};
export default Import;
