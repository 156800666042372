import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import MainTable from "components/Table/MainTable";
import {
  changeFilterName,
  changeLimit,
  changePage,
  changeFilter,
  deleteFilter,
  addFilter,
} from "redux/report/Daily/slice";
import AktifStatus from "components/Status/AktifStatus";
import MainCard from "components/Paper/MainCard";
import MainPagination from "components/Pagination";
import useFetchData from "hook/useFetchData";
import ExportHelper from "helper/ExportHelper";
import moment from "moment";
import MainCardHeader3 from "components/Paper/MainCardHeader3";
import useSelect from "hook/useSelect";
import useAutoFetch from "hook/useAutoFetch";
import Page from "components/Page";
import { getDaily, getListDaily } from "redux/report/action";
import DialogStock from "./Dialog";

const headers = [
  {
    name: "Tgl. Transaksi",
    custom: (item) => {
      return moment(item.date).isValid()
        ? moment(item.date).format("DD/MM/YYYY")
        : "-";
    },
    width: 30,
  },
  {
    name: "No. Transaksi",
    key: "trxNo",
    width: 30,
  },
  {
    name: "Aktivitas",
    key: "activityName",
    width: 30,
  },
  {
    name: "Nama",
    key: "name",
    width: 30,
  },
  {
    name: "Nama Program",
    key: "programName",
    width: 30,
  },
  {
    name: "Nama Voucher",
    key: "voucherName",
    width: 30,
  },
  {
    name: "Nama Barang",
    key: "productName",
    width: 30,
  },
  {
    name: "Status",
    custom: (item) => {
      return (
        <AktifStatus
          customText={["Aktif", "Ditukarkan"]}
          value={item.status}
          minWidth="120px"
          maxWidth="120px"
        />
      );
    },
    width: 30,
  },
];

const filter = [
  {
    label: "Nomor Transaksi",
    value: "trxNo",
  },
  {
    label: "Aktivitas",
    value: "activityName",
  },
  {
    label: "Nama",
    value: "name",
  },
  {
    label: "Nama Program",
    value: "programName",
  },
  {
    label: "Nama Voucher",
    value: "voucherName",
  },
];

export const card_tipe = [
  {
    label: "Ada",
    value: 1,
  },
  {
    label: "Habis",
    value: 0,
  },
];

const DailyReport = () => {
  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.reportDaily);

  const data = useRef(null);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const fetchData = async () => {
    await dispatch(getListDaily(params));
  };

  useAutoFetch(fetchData, currentPage, filterName);

  const filterData = [
    {
      label: "Status",
      key: "status",
      type: "switch",
      options: [
        {
          label: "Aktif",
          value: 1,
          activecolor: "#129350",
        },
        {
          label: "Ditukarkan",
          activecolor: "#cc1d15",
          value: 0,
        },
      ],
    },
    {
      label: "Tanggal",
      startDate: "startDate",
      endDate: "endDate",
      type: "daterange",
    },
  ];

  const exportData = useFetchData({
    action: getDaily,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "report-daily",
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(null, null, data);
  };

  return (
    <>
      <Page title="Daily Report" desc />
      <MainCard title="Daily Report" borderColor="#C8C8C8" addButton={false}>
        <MainCardHeader3
          filterName={filterName}
          changeFilterName={changeFilterName}
          deleteFilter={deleteFilter}
          changeFilter={changeFilter}
          params={params}
          addFilter={addFilter}
          onChangeExport={(type) => handleExport(type)}
          filter={filter}
          title="Filter"
          filterData={filterData}
          downloadText="Ekspor"
          fetchData={fetchData}
        />
        <MainTable
          headers={headers}
          loading={loading}
          data={list}
          summaryKey="mpName"
          detail={[
            {
              type: "button",
              tooltip: "Lihat Detail",
              variant: "icon:eye",
              onClick: (_, item) => {
                data.current = item;
                setOpen(true);
              },
            },
          ]}
          increment={params?.offset + 1}
        />
        <MainPagination
          params={params}
          changeLimit={changeLimit}
          changePage={changePage}
          totalItems={totalItems}
          currentPage={currentPage}
        />
      </MainCard>

      <DialogStock
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        data={data?.current}
      />
    </>
  );
};

export default DailyReport;
