import PropTypes from "prop-types";
import { Box, Checkbox, Grid, Typography } from "@mui/material";
import React from "react";
import MainAutocomplete from "components/AutoComplete/MainAutocomplete";
import VerticalGrid from "components/Grid/VerticalGrid";
import TextFieldv3 from "components/TextField/TextFieldv3";
import MainController from "components/Controller/MainController";
import { TipeEntitasBisnis } from "pages/Dasbor/variable";
import FormatNumber from "helper/FormatNumber";

const StepView2 = ({
  controller: Controller,
  control,
  errors,
  clearErrors,
  trigger,
  kategoriBisnisData,
  setValue,
}) => {
  return (
    <Grid container spacing={3} mt={1}>
      <VerticalGrid label={"Tipe Entitas Bisnis"} md={6}>
        <MainController
          controller={Controller}
          name="BizEntitasType"
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          rules={{ required: "Tipe Entitas Bisnis wajib diisi" }}
          render={({ field }) => (
            <MainAutocomplete
              {...field}
              typefield={1}
              options={TipeEntitasBisnis}
              value={
                TipeEntitasBisnis.find((item) => item.value === field.value) ||
                null
              }
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(_, nv) => {
                if (nv == null) {
                  field.onChange(null);
                }
                field.onChange(nv.value);
                setValue("BizCategory", null);
              }}
              placeholder="Pilih tipe entitas bisnis"
            />
          )}
        />
      </VerticalGrid>
      <VerticalGrid label={"Kategori Bisnis"} md={6}>
        <MainController
          controller={Controller}
          name="BizCategory"
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          rules={{ required: "Kategori Bisnis wajib diisi" }}
          render={({ field }) => (
            <MainAutocomplete
              {...field}
              typefield={1}
              options={kategoriBisnisData}
              value={
                kategoriBisnisData.find((item) => item.value === field.value) ||
                null
              }
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(_, nv) => {
                if (nv == null) {
                  field.onChange(null);
                }
                field.onChange(nv.value);
              }}
              placeholder="Pilih kategori"
            />
          )}
        />
      </VerticalGrid>
      <VerticalGrid label={"URL Bisnis"} md={12}>
        <MainController
          controller={Controller}
          name="BizUrl"
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          rules={{ required: "URL Bisnis wajib diisi" }}
          render={({ field }) => (
            <TextFieldv3 {...field} placeholder="Masukkan URL bisnis" />
          )}
        />
        <Box display="flex" gap={1} alignItems="center">
          <MainController
            controller={Controller}
            name="IsBizDeveloper"
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            render={({ field }) => (
              <Checkbox
                {...field}
                onChange={(e) => {
                  if (e.target.value == 2) {
                    field.onChange(1);
                  } else if (e.target.value == 1) {
                    field.onChange(2);
                  }
                }}
                checked={field.value == 1}
                sx={{
                  padding: "0px",
                  color: "#4c4d4f",
                }}
              />
            )}
          />

          <Typography variant="cardText2">
            Saya adalah web developer/partner yang mendaftar atas nama klien
            saya
          </Typography>
        </Box>
      </VerticalGrid>
      <VerticalGrid label={"Email PIC Finance"}>
        <MainController
          controller={Controller}
          name="BizEmailPicFinance"
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          rules={{
            required: "Email PIC Finance wajib diisi",
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Format Email salah",
            },
          }}
          render={({ field }) => (
            <TextFieldv3 {...field} placeholder="Masukkan email" />
          )}
        />
      </VerticalGrid>
      <VerticalGrid label={"Nomor Ponsel PIC Finance"}>
        <Box display="flex" gap={1}>
          <Box
            sx={{
              backgroundColor: "#EBECED",
              alignItems: "center",
              display: "flex",
              alignItems: "center",
              width: "50px",
              height: "45px",
              borderRadius: "4px",
              justifyContent: "center",
            }}
          >
            <Typography variant="labelMedium2">+62</Typography>
          </Box>
          <Box display="flex" flexDirection="column" flex={1}>
            <MainController
              controller={Controller}
              name="BizHpPicFinance"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              rules={{
                required: "Nomor Ponsel PIC Finance wajib diisi",
                maxLength: {
                  message:
                    "Nomor Ponsel PIC Finance tidak boleh melebihi 13 karakter",
                  value: 13,
                },
                minLength: {
                  message:
                    "Nomor Ponsel PIC Finance tidak boleh kurang dari 10 karakter",
                  value: 10,
                },
              }}
              render={({ field }) => (
                <TextFieldv3
                  {...field}
                  type="text"
                  placeholder="Masukkan nomor ponsel"
                  onChange={(e) => {
                    trigger("BizHpPicFinance");
                    let v = FormatNumber(e.target.value);
                    field.onChange(v);
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </VerticalGrid>
      <VerticalGrid label={"Email PIC Teknis"}>
        <MainController
          controller={Controller}
          name="BizEmailPicTech"
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          rules={{
            required: "Email PIC Teknis wajib diisi",
            pattern: {
              value:
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "Format Email salah",
            },
          }}
          render={({ field }) => (
            <TextFieldv3 {...field} placeholder="Masukkan email" />
          )}
        />
      </VerticalGrid>
      <VerticalGrid label={"Nomor Ponsel PIC Teknis"}>
        <Box display="flex" gap={1}>
          <Box
            sx={{
              backgroundColor: "#EBECED",
              alignItems: "center",
              display: "flex",
              alignItems: "center",
              width: "50px",
              height: "45px",
              borderRadius: "4px",
              justifyContent: "center",
            }}
          >
            <Typography variant="labelMedium2">+62</Typography>
          </Box>
          <Box display="flex" flexDirection="column" flex={1}>
            <MainController
              controller={Controller}
              name="BizHpPicTech"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              // rules={{ required: "Nomor Ponsel PIC Teknis wajib diisi" }}
              rules={{
                required: "Nomor Ponsel PIC Teknis wajib diisi",
                maxLength: {
                  message:
                    "Nomor Ponsel PIC Teknis tidak boleh melebihi 13 karakter",
                  value: 13,
                },
                minLength: {
                  message:
                    "Nomor Ponsel PIC Teknis tidak boleh kurang dari 10 karakter",
                  value: 10,
                },
              }}
              render={({ field }) => (
                <TextFieldv3
                  {...field}
                  name="phone"
                  type="text"
                  placeholder="Masukkan nomor ponsel"
                  onChange={(e) => {
                    trigger("BizHpPicTech");
                    let v = FormatNumber(e.target.value);
                    field.onChange(v);
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </VerticalGrid>
    </Grid>
  );
};

StepView2.propTypes = {
  clearErrors: PropTypes.any,
  control: PropTypes.any,
  controller: PropTypes.any,
  errors: PropTypes.any,
  trigger: PropTypes.any,
  kategoriBisnisData: PropTypes.array,
  setValue: PropTypes.func,
};

StepView2.defaultProps = {
  setActiveStep: null,
  setValue: null,
  clearErrors: null,
  control: null,
  controller: null,
  errors: null,
  trigger: null,
  kategoriBisnisData: [],
  setValue: null,
};

export default StepView2;
