import { Box, Divider, Snackbar, Typography } from "@mui/material";
import React from "react";
import { Close } from "@mui/icons-material";
import moment from "moment/moment";
import PropTypes from "prop-types";
import InisaLogo from "assets/login/inisalogo";

const InisaAlert = ({ open, onClose, message }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={3000}
      open={open}
      onClose={(_, reason) => {
        if (reason === "clickaway") {
          return;
        }
        onClose();
      }}

    >
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: "10px",
          boxShadow: "0px 10px 15px rgba(100, 116, 139, 0.12)",
          minWidth: "330px",
          width: "100%",
          borderLeft: "4px solid #cc1d15",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              padding: "10px",
            }}
          >
            <InisaLogo />
            <Typography variant="alertTitle">INISA</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="alertDesc">
              {moment(new Date()).fromNow()}
            </Typography>

            <Close
              onClick={onClose}
              sx={{
                color: "#4E4751",
                fontSize: "16px",
                margin: "10px",
                cursor: "pointer",
              }}
            />
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            padding: "10px",
          }}
        >
          <Typography variant="textCommon">{message}</Typography>
        </Box>
      </Box>
    </Snackbar>
  );
};

InisaAlert.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  message: PropTypes.string,
};

InisaAlert.defaultProps = {
  open: false,
  message: "",
  onClose: () => {},
};
export default InisaAlert;
