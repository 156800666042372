const stringToBytes = (str) => {
  let bytes = 0;
  let s = str.toLowerCase();
  let last = s.charAt(s.length - 2);
  let size = parseInt(s.substring(0, s.length - 1));

  switch (last) {
    case "g":
      bytes = size * 1073741824;
      break;
    case "m":
      bytes = size * 1048576;
      break;
    case "k":
      bytes = size * 1024;
      break;
    default:
      bytes = size;
  }
  return bytes;
};

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const FileValidator = (value, options) => {

  let err = null;
  function reject(message) {
    err = message;
  }

  function resolve() {
    return err;
  }

  if (!value || !options) {
    reject("File tidak boleh kosong");
  }

  let availableValidator = ["size", "type"];
  let validator = {
    size: () => {
      // value is file
      if (options.size.maxSize) {
        let max =
          typeof options.size.maxSize === "string"
            ? stringToBytes(options.size.maxSize)
            : options.size.maxSize;
        // console.log({ max });
        if (value.size > max) {
          reject(`File terlalu besar, maksimal ${formatBytes(max)}`);
        }
      }

      if (options.size.minSize) {
        let min =
          typeof options.size.minSize === "string"
            ? stringToBytes(options.size.minSize)
            : options.size.minSize;
        // console.log({ size: value.size, min });
        if (value.size < min) {
          reject(`File terlalu kecil, minimal ${formatBytes(min)}`);
        }
      }
    },
    type: () => {
      if (options.type) {
        let arrTypes = Array.isArray(options.type)
          ? options.type
          : [options.type];
        if (!arrTypes.includes(value.type)) {
          reject(`File tidak dapat diterima`);
        }
      }
    },
  };

  for (let key in options) {
    if (availableValidator.includes(key)) {
      validator[key]();
    }
  }

  return resolve();
};

export default FileValidator;
