import React from "react";
import { Drawer, DrawerHeader } from "../style";
import Logo from "assets/layout/logo";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import MenuList from "./MenuList";
import { useMediaQuery, useTheme } from "@mui/material";

const Sidebar = ({ open, setOpen }) => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.between("xs", "md"));
  return (
    <>
      <Drawer
        variant={match ? "temporary" : "permanent"}
        open={open}
        PaperProps={{
          sx: {
            borderRight: "none",
            boxShadow: "none !important",
            zIndex: "200",
          },
        }}
        onClose={() => setOpen(false)}
      >
        <DrawerHeader>
          <Logo />
          {open && (
            <Typography variant="sidebarTitle">INISA Merchant</Typography>
          )}
        </DrawerHeader>
        <PerfectScrollbar
          component="div"
          style={{
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingTop: "10px",
          }}
        >
          <MenuList openSidebar={open} />
        </PerfectScrollbar>
      </Drawer>
    </>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool,
  location: PropTypes.object,
  setOpen: PropTypes.func,
};

Sidebar.defaultProps = {
  open: false,
  location: {},
  setOpen: () => {},
};

export default Sidebar;
