import { createSlice } from "@reduxjs/toolkit";
import { bansosVoucherProgramUser } from "../action";

export const initialState = {
  list: [],
  namaProgram: "",
  params: {},
  message: "",
  loading: false,
};

export const bansosVoucherProgramUserSlice = createSlice({
  name: "voucherdetailpenerimaBantuan",
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.params = {
        ...state.params,
        search: action.payload,
      };
    },
    deleteFilter: (state) => {
      delete state.params?.filter;
    },
  },
  extraReducers: {
    [bansosVoucherProgramUser.pending]: (state) => {
      state.loading = true;
    },
    [bansosVoucherProgramUser.rejected]: (state, action) => {
      state.loading = false;
      state.message = action.error.message;
    },
    [bansosVoucherProgramUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload?.list;
      state.namaProgram = action.payload?.nama_program;
    },
  },
});

export const { changeFilter, deleteFilter } =
  bansosVoucherProgramUserSlice.actions;

export default bansosVoucherProgramUserSlice.reducer;
