import PropTypes from "prop-types";
import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import DoneIcon from "assets/paper/DoneIcon";
import React from "react";

const CardProduct2 = ({
  id,
  onChange,
  icon: Icon,
  title,
  desc,
  dataTitle,
  data,
}) => {
  return (
    <Paper
      sx={{
        borderRadius: "8px",
        px: 3,
        py: 4,
        display: "flex",
        flexDirection: "column",
        gap: 1.5,
        boxShadow: "-4px 8px 32px -12px rgba(0, 0, 0, 0.12)",
        minHeight: "568px",
      }}
    >
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" gap={2}>
          <Box color="#cc1d15">
            <Icon />
          </Box>
          <Typography variant="title4">{title}</Typography>
        </Box>
        <Typography variant="desc4">{desc}</Typography>
      </Box>
      <Divider sx={{ borderColor: "#C8C8C8" }} />

      <Box display="flex" flexDirection="column" gap={1.5} mt={1}>
        <Typography variant="label2">{dataTitle} :</Typography>
        <Box display="flex" flexDirection="column" gap={1}>
          {data.map((item, i) => (
            <Box key={i} display="flex" gap={1}>
              <DoneIcon />
              <Typography variant="text4">{item}</Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box display="flex" flex={1} mb={1}>
        <Button
          variant="primary-outlined"
          sx={{
            width: "100%",
            mt: "auto",
          }}
          onClick={() => onChange(id)}
          type="submit"
        >
          Get Started
        </Button>
      </Box>
    </Paper>
  );
};

CardProduct2.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.array,
  desc: PropTypes.string,
  icon: PropTypes.any,
  title: PropTypes.string,
  dataTitle: PropTypes.string,
  onChange: PropTypes.func,
};

CardProduct2.defaultProps = {
  id: null,
  data: [],
  desc: "",
  icon: null,
  title: "",
  dataTitle: "",
  onChange: () => {},
};

export default CardProduct2;
