import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import React, { forwardRef } from "react";

const TextFieldv4 = forwardRef((props, ref) => {
  return (
    <TextField
      ref={ref}
      {...props}
      InputProps={{
        ...props.InputProps,
        sx: {
          backgroundColor: "#fff !important",
          "&.Mui-focused": {
            backgroundColor: "#fff !important",
          },
          ...props?.InputProps?.sx,
        },
      }}
    />
  );
});

TextFieldv4.displayName = "tf3";

TextFieldv4.propTypes = {
  InputProps: PropTypes.any,
};

TextFieldv4.defaultProps = {
  InputProps: null,
};

export default TextFieldv4;
