import { InputAdornment, TextField } from "@mui/material";
import PageTitle from "components/Page/PageTitle";
import MainCard from "components/Paper/MainCard";
import MainTable from "components/Table/MainTable";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  bansosVoucherProgramUser,
  bansosVoucherRedeem,
} from "redux/penerimaBantuan/action";
import {
  List,
  Loading,
  NamaProgram,
  Params,
} from "redux/penerimaBantuan/Voucher/selectors";
import SearchIcon from "@mui/icons-material/Search";
import { changeFilter } from "redux/penerimaBantuan/Voucher/slice";
import PeriodeFormat from "components/Format/PeriodeFormat";
import VoucherStatus from "components/Status/Voucher/Status";
import DetailVoucher from "./Dialog/DetailVoucher";
import CetakQR from "./Dialog/CetakQR";
import QRCode from "./Dialog/QRCode";
import useFetchData from "hook/useFetchData";

const headers = [
  {
    name: "Prefix Voucer",
    key: "PrefixCode",
    width: 20,
  },
  {
    name: "Nama Voucer",
    key: "VoucherName",
    width: 35,
  },
  {
    name: "Periode Voucer",
    custom: (item) => {
      return (
        <PeriodeFormat StartDate={item?.StartDate} EndDate={item?.EndDate} />
      );
    },
    width: 25,
  },
  {
    name: "Status Voucer",
    custom: (item) => {
      return <VoucherStatus value={item?.Status} />;
    },
    width: 20,
  },
];

const Detail = () => {
  const [open, setOpen] = useState({
    open: false,
    cetakQr: false,
    openQR: false,
  });
  const [dataVoucher, setDataVoucher] = useState(null);
  const [dataQR, setDataQr] = useState(null);

  const loading = useSelector(Loading);
  const list = useSelector(List);
  const nama_program = useSelector(NamaProgram);
  const params = useSelector(Params);
  const { voucher_id, user_id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const io = () => {
      fetchData();
    };
    io();
  }, [params.search]);

  const fetchData = async () => {
    await dispatch(
      bansosVoucherProgramUser({
        voucher: voucher_id,
        user: user_id,
        params: params,
      })
    );
  };

  const redeem = useFetchData({
    action: bansosVoucherRedeem,
    message: "Berhasil redeem voucer",
    onSuccess: () => {
      fetchData();
      setOpen({
        ...open,
        open: false,
      });
    },
  });

  const handleOpenQr = () => {
    let newDate = new Date();
    newDate.setHours(newDate.getHours() + 1);
    const expired = newDate.getTime();

    const dataQr = {
      kode_voucher: dataVoucher?.kode_voucher || "",
      install_id: "-",
      expired_qr: String(expired) || "",
      prefix_voucher: dataVoucher?.PrefixCode || "",
    };

    const stringify = JSON.stringify(dataQr);
    setDataQr(stringify);
  };

  const handleRedeem = () => {
    const newDate = new Date();
    newDate.setHours(newDate.getHours() + 1);
    const expired = newDate.getTime();

    const data = {
      kode_voucher: dataVoucher?.kode_voucher || "",
      install_id: "-",
      expired_qr: expired || "",
      prefix_voucher: dataVoucher?.PrefixCode || "",
      is_qrcode: 0,
    };

    redeem.fetch(data);
  };
  return (
    <>
      <PageTitle
        title={`Voucer ${nama_program || ""}`}
        to={`/csr-bansos/penerima-bantuan/${user_id}`}
        text="Dasbor / CSR Bansos / Penerima Bantuan / Detail Penerima Bantuan / "
        lastText="Voucer"
      />
      <MainCard
        title="Daftar Voucer Bansos"
        addButton={false}
        isEdit={false}
        custom={
          <TextField
            placeholder="Search..."
            onChange={(e) => {
              dispatch(changeFilter(e.target.value));
            }}
            value={params?.search || ""}
            InputProps={{
              sx: {
                outline: "1px solid #D0D0D0",
                backgroundColor: "#fff !important",
              },
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: "20px" }}>
                  <SearchIcon
                    fontSize="medium"
                    sx={{
                      color: "#4E4751",
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        }
      >
        <MainTable
          headers={headers}
          loading={loading}
          data={list}
          action="  "
          align="center"
          summaryKey="VoucherName"
          detail={[
            {
              type: "button",
              variant: "icon:eye",
              tooltip: "Detail Voucer",
              onClick: (_, item) => {
                setOpen({
                  ...open,
                  open: true,
                });
                setDataVoucher(item);
              },
            },
          ]}
        />
      </MainCard>

      <DetailVoucher
        open={open.open}
        onClose={() => setOpen({ ...open, open: false })}
        dataVoucher={dataVoucher}
        QRClick={() => setOpen({ ...open, open: false, cetakQr: true })}
        handleRedeem={handleRedeem}
      />

      <CetakQR
        open={open.cetakQr}
        onClose={() => setOpen({ ...open, cetakQr: false })}
        cetakQR={() => {
          setOpen({ ...open, cetakQr: false, openQR: true });
          handleOpenQr();
        }}
        goBack={() =>
          setOpen({ ...open, cetakQr: false, open: true, cetakQr: false })
        }
        dataVoucher={dataVoucher}
      />

      <QRCode
        open={open.openQR}
        onClose={() => {
          setOpen({ ...open, openQR: false });
          fetchData();
        }}
        dataVoucher={dataVoucher}
        dataQr={dataQR}
      />
    </>
  );
};

export default Detail;
