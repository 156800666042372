export const role = () => {
  let StringRole = "";
  let permission = localStorage.getItem("permission-ntt");
  if (permission) {
    permission = JSON.parse(permission);
    let supervisor = permission?.find((p) => p === "Web:AdjSp:All");
    let operator = permission?.find((p) => p === "Web:AdjOps:All");
    let superadmin = permission?.find((p) => p === "Web:AdjAdmin:All");
    if (supervisor) StringRole = "Supervisor";
    else if (operator) StringRole = "Operator";
    else if (superadmin) StringRole = "Superadmin";
    else StringRole = "Pengguna";
  }

  return StringRole;
};

export default role;
