import PropTypes from "prop-types";
import { Box, Radio, Typography } from "@mui/material";
import React from "react";
import { Circle } from "@mui/icons-material";

const MainRadio = ({
  data,
  value,
  itemValue,
  itemLabel,
  itemDesc,
  onChange,
}) => {
  return (
    <Box display="flex" flexDirection="column">
      {data.map((item, i) => (
        <Box
          key={i}
          display="flex"
          alignItems="center"
          gap={1}
          borderBottom="1px dotted #C8C8C8"
          height={70}
        >
          <Radio
            icon={<Circle />}
            checked={item[itemValue] == value}
            onChange={() => onChange(item)}
          />
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="labelMedium2">{item[itemLabel]}</Typography>
            <Typography variant="desc2">{item[itemDesc]}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

MainRadio.propTypes = {
  data: PropTypes.array,
  selected: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  itemValue: PropTypes.string,
  itemLabel: PropTypes.string,
  itemDesc: PropTypes.string,
};

MainRadio.defaultProps = {
  data: [],
  selected: null,
  onChange: null,
  value: null,
  itemValue: "",
  itemLabel: "",
  itemDesc: "",
};
export default MainRadio;
