import React from "react";

const SidebarClose = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5 6V8H8.5V6H21.5ZM11.5 13H21.5V11H11.5V13ZM8.5 18H21.5V16H8.5V18ZM7.08 12L3.5 15.59L4.91 17L9.91 12L4.91 7L3.5 8.41L7.08 12Z"
        fill="black"
      />
      <mask
        id="mask0_772_3883"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="3"
        y="6"
        width="19"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.5 6V8H8.5V6H21.5ZM11.5 13H21.5V11H11.5V13ZM8.5 18H21.5V16H8.5V18ZM7.08 12L3.5 15.59L4.91 17L9.91 12L4.91 7L3.5 8.41L7.08 12Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_772_3883)">
        <rect x="0.5" width="24" height="24" fill="#4E4751" />
      </g>
    </svg>
  );
};

export default SidebarClose;
