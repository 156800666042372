import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainTable from "components/Table/MainTable";
import {
  changeFilterName,
  changeLimit,
  changePage,
  changeFilter,
  deleteFilter,
  addFilter,
} from "redux/managementPengguna/Pengguna/slice";
import AktifStatus from "components/Status/AktifStatus";
import MainCard from "components/Paper/MainCard";
import MainPagination from "components/Pagination";
import Providers from "components/Providers";
import Dialog from "./Dialog";
import useFetchData from "hook/useFetchData";
import ExportHelper from "helper/ExportHelper";
import {
  deletePengguna,
  getListPengguna,
  getPengguna,
  getUserDetailNoRedux,
} from "redux/managementPengguna/action";
import {
  List,
  Loading,
  FilterName,
  Params,
  TotalItems,
} from "redux/managementPengguna/Pengguna/selectors";
import RoleUser from "components/Status/RoleUser";
import moment from "moment";
import MainCardHeader2 from "components/Paper/MainCardHeader2";
import DeleteDialog from "components/Dialog/DeleteDialog";

const Pengguna = () => {
  const list = useSelector(List);
  const loading = useSelector(Loading);
  const params = useSelector(Params);
  const totalItems = useSelector(TotalItems);
  const filterName = useSelector(FilterName);

  const id = useRef(null);
  const dispatch = useDispatch();
  const [dialogData, setDialogData] = useState({});

  const [open, setOpen] = useState({
    open: false,
    import: false,
    isEdit: false,
  });

  const headers = [
    {
      name: "Email ",
      key: "Email",
      width: 30,
    },
    {
      name: "Nama",
      key: "Fullname",
      width: 25,
    },
    {
      name: "Last Login",
      custom: (item) => {
        const string = item.LastUpdate.substr(0, item.LastUpdate.length - 1);
        return moment(string).fromNow();
      },
      width: 25,
    },
    {
      name: "Role",
      custom: (item) => (
        <RoleUser value={item.TypeRole} string={item.RoleName} />
      ),
      width: 30,
    },
    {
      name: "Status",
      custom: (item) => <AktifStatus value={parseInt(item.Status)} />,
      width: 25,
    },
  ];

  const filterData = [
    {
      label: "Status",
      key: "Status",
      type: "switch",
      options: [
        {
          label: "Aktif",
          value: 1,
        },
        {
          label: "Aktif",
          value: 0,
        },
      ],
    },
  ];

  const fetchData = async () => {
    await dispatch(getListPengguna(params));
  };

  const exportData = useFetchData({
    action: getPengguna,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.records,
        filename: "penerbit",
        opt: {
          header: ["Email", "Fullname", "LastUpdate", "RoleName", "Status"],
        },
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(null, null, data);
  };

  const action = useFetchData({
    action: deletePengguna,
    message: "Berhasil hapus data",
    refresh: fetchData,
    onSuccess: () => {
      setOpen({
        ...open,
        delete: false,
      });
    },
  });

  const detail = useFetchData({
    action: getUserDetailNoRedux,
    snackbar: false,
    onSuccess: (data, id) => {
      const obj = { ...data.User, id };
      if (data.Tasks.length > 0) {
        obj.Vouchers = data.Tasks;
      }
      setDialogData(obj);
      setOpen({
        ...open,
        open: true,
      });
    },
  });

  return (
    <>
      <Providers
        title="Pengguna"
        currentPage={params.page}
        desc
        params={params}
        get={fetchData}
      >
        <MainCard
          title="Daftar Pengguna"
          onClick={() => {
            setDialogData({});
            setOpen({
              ...open,
              open: true,
            });
          }}
          borderColor="#C8C8C8"
        >
          <MainCardHeader2
            filterName={filterName}
            changeFilterName={changeFilterName}
            deleteFilter={deleteFilter}
            changeFilter={changeFilter}
            params={params}
            filterData={filterData}
            addFilter={addFilter}
            onChangeExport={(type) => handleExport(type)}
            title="Filter Pengguna"
          />
          <MainTable
            headers={headers}
            loading={loading}
            data={list}
            summaryKey="Fullname"
            detail={[
              {
                type: "link",
                url_key: "management-pengguna/pengguna:Id",
                tooltip: "Lihat Detail Pengguna",
                variant: "icon:eye2",
              },
              {
                type: "button",
                tooltip: "Edit Pengguna",
                variant: "icon:pencil",
                onClick: (_, item) => detail.fetch(item.Id, _, item.Id),
              },
              {
                type: "button",
                tooltip: "Hapus Pengguna",
                variant: "icon:trash",
                onClick: (_, item) => {
                  id.current = item.Id;
                  setOpen({
                    ...open,
                    delete: true,
                  });
                },
              },
            ]}
            increment={(params.page - 1) * params.limit + 1}
          />
          <MainPagination
            params={params}
            changeLimit={changeLimit}
            changePage={changePage}
            totalItems={totalItems}
            currentPage={params?.page}
          />
        </MainCard>
      </Providers>

      <Dialog
        open={open.open}
        onClose={() =>
          setOpen({
            ...open,
            open: false,
          })
        }
        isEdit={open.isEdit}
        refresh={fetchData}
        data={dialogData}
      />

      <DeleteDialog
        open={open.delete}
        onClose={() => setOpen({ ...open, delete: false })}
        onSubmit={() => action.fetch(id.current)}
      />
    </>
  );
};

export default Pengguna;
