import React from "react";

const PPOB = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.20015 11.8H11.8001V13.2H6.20015V11.8ZM6.20015 9H11.8001V10.4H6.20015V9ZM10.4001 2H4.80015C4.03015 2 3.40015 2.63 3.40015 3.4V14.6C3.40015 15.37 4.02315 16 4.79315 16H13.2001C13.9701 16 14.6001 15.37 14.6001 14.6V6.2L10.4001 2ZM13.2001 14.6H4.80015V3.4H9.70015V6.9H13.2001V14.6Z"
        fill="white"
      />
    </svg>
  );
};

export default PPOB;
