export const pagination = {
  changePage: (state, action) => {
    let limit = action.payload.limit;
    let offset = (action.payload.page - 1) * action.payload.limit;
    state.params.limit = limit;
    state.params.offset = offset;
    state.currentPage = action.payload.page;
  },
  changeLimit: (state, action) => {
    let limit = action.payload.limit;
    state.params.limit = limit;
  },
  changeFilterName: (state, action) => {
    let filterName = action.payload.name;
    state.filterName = filterName;
  },
  addFilter: (state, action) => {
    if (!action.payload.action) {
      state.params = {
        ...state.params,
        filter: {
          ...state.params.filter,
          ...action.payload.list,
        },
      };
    } else {
      for (let i in action.payload.tempList) {
        delete state.params.filter[i];
      }
    }
  },
  changeFilter: (state, action) => {
    state.params = {
      ...state.params,
      limit: 10,
      offset: 0,
      filter: {
        ...state.params.filter,
        [state.filterName]: action.payload,
      },
    };
    state.currentPage = 1;
  },
  deleteFilter: (state) => {
    delete state.params?.filter;
  },
};
