import React from "react";
import Providers from "components/RouteRoleProviders";
import Penerbit from "pages/MasterData/MasterBantuan/Penerbit";
import DetailPenerbit from "pages/MasterData/MasterBantuan/Penerbit/DetailPenerbit";

const penebitRoutes = [
  {
    path: "/master-data/master-bantuan/penerbit",
    element: (
      <Providers permission="Web:MasterData:MasterBantuan:Penerbit:Get" >
        <Penerbit />
      </Providers>
    ),
  },
  {
    path: "/master-data/master-bantuan/penerbit/:id",
    element: (
      <Providers permission="Web:MasterData:MasterBantuan:Penerbit:Get" >
        <DetailPenerbit />
      </Providers>
    ),
  },
];

export default penebitRoutes;