import ImportDialog from "components/Dialog/ImportDialog";
import React from "react";
import PropTypes from "prop-types";
import FileTemplate from "assets/voucher/template/voucher-template.xlsx";
import downloadFile from "helper/downloadFile";
import useFetchData from "hook/useFetchData";
import MainTable from "components/Table/MainTable";
import { multiVoucer, validasiImport } from "redux/voucher/action";
import { status_voucher, tipe_voucher } from "./variables";
import MainStatus from "components/Status/MainStatus";

const Import = ({ open, refresh, onClose }) => {
  const importSubmit = useFetchData({
    action: multiVoucer,
    message: "Import Data voucer berhasil",
    refresh: refresh,
    onSuccess: () => {
      onClose();
      importAction.setResponse(null);
    },
  });

  const importAction = useFetchData({
    action: validasiImport,
    snackbar: false,
  });

  const headers = [
    {
      name: "Nama Penerbit",
      key: "IssuerName",
      width: 70,
    },
    {
      name: "Kode Penerbit",
      key: "MerchantCode",
      width: 20,
    },
    {
      name: "Nama Voucher",
      key: "VoucherName",
      width: 20,
    },
    {
      name: "Kode Prefix",
      key: "PrefixCode",
      width: 20,
    },
    {
      name: "Deskripsi Voucher",
      key: "VoucherDesc",
      width: 20,
    },
    {
      name: "Syarat & Ketentuan",
      key: "VoucherTC",
      width: 20,
    },
    {
      name: "Presentasi Nilai",
      key: "PercentageValue",
      width: 20,
    },
    {
      name: "Tipe Voucher",
      custom: (item) => {
        return tipe_voucher.find((i) => i.value == item.VoucherType)?.label;
      },
      width: 20,
    },
    {
      name: "Status Voucher",
      width: 20,
      custom: (item) => {
        return <MainStatus value={item.Status} arr={status_voucher} /> || "-";
      },
      align: "center",
    },
    {
      name: "Tanggal Mulai",
      key: "StartDate",
      align: "center",
      width: 20,
    },
    {
      name: "Tanggal Selesai",
      key: "EndDate",
      align: "center",
      width: 20,
    },
    {
      name: "Nomor Awal",
      key: "Start",
      width: 20,
    },
    {
      name: "Nomor Akhir",
      key: "End",
      width: 20,
    },
    {
      name: "Nominal Nilai",
      key: "AmountValue",
      width: 20,
    },
    {
      name: "Minimum Transaksi",
      key: "MinTrx",
      width: 20,
    },
    {
      name: "Maksimum Transaksi",
      key: "MaxTrx",
      width: 20,
    },
    {
      name: "Nominal Hadiah",
      key: "RewardAmount",
      width: 20,
    },
    {
      name: "Jumlah Distribusi",
      key: "Distributed",
      width: 20,
    },
    {
      name: "Jumlah Anggaran",
      key: "Budget",
      width: 20,
    },
  ];

  const handleValidasi = (e) => {
    const data = new FormData();
    data.append("excel", e.target.files[0]);
    importAction.fetch(data);
  };

  const handleSubmit = () => {
    importSubmit.fetch(importAction?.response);
  };

  return (
    <ImportDialog
      open={open}
      onClose={() => {
        onClose();
        importAction.setResponse(null);
      }}
      onClickTemplate={() =>
        downloadFile(FileTemplate, "voucher-template.xlsx")
      }
      onChange={handleValidasi}
      loading={importAction.loading || !Boolean(importAction?.response)}
      onClear={() => importAction.setResponse(null)}
      onSubmit={handleSubmit}
    >
      <MainTable
        headers={headers}
        loading={importAction?.loading}
        data={importAction?.response || []}
        summaryKey="IssuerName"
        action=""
        is_duplicate
      />
    </ImportDialog>
  );
};

Import.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
};

Import.defaultProps = {
  onClose: () => {},
  open: false,
  refresh: null,
};
export default Import;
