import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

const useSelect = (selectors) => {
  const list = useSelector(createSelector(selectors, (p) => p.list));
  const loading = useSelector(createSelector(selectors, (p) => p.loading));
  const params = useSelector(createSelector(selectors, (p) => p.params));
  const totalItems = useSelector(
    createSelector(selectors, (p) => p.totalItems)
  );
  const currentPage = useSelector(
    createSelector(selectors, (p) => p.currentPage)
  );
  const filterName = useSelector(
    createSelector(selectors, (p) => p.filterName)
  );

  return {
    list,
    loading,
    params,
    totalItems,
    currentPage,
    filterName,
  };
};

export default useSelect;
