import PropTypes from "prop-types";
import React from "react";
import { Chip, Stack, styled } from "@mui/material";

const StyledChip = styled(Chip)(({ theme, activecolor, isactive, width }) => ({
  cursor: "pointer",
  color: isactive ? activecolor : "#111",
  border: "1px solid",
  height: "40px",
  width: width ? width : "120px",
  background: "#e6e6e6",
  borderColor: isactive ? activecolor : "#e5e5e5",
  fontSize: "14px !important",
  fontWeight: "600",
}));

const SelectChips = ({ data, onClick, isactive, getLabel, wrap, scroll }) => {
  const status = [
    {
      label: "Aktif",
      value: 1,
    },
    {
      label: "Tidak Aktif",
      value: 0,
    },
  ];

  return (
    <Stack
      direction="row"
      spacing={1}
      flexWrap={wrap ? "wrap" : ""}
      overflow={scroll ? "auto" : ""}
    >
      {(data || status).map((item, index) => {
        return (
          <StyledChip
            onClick={() => onClick(item)}
            key={index}
            label={getLabel(item)}
            isactive={isactive(item)}
            activecolor={item.activecolor || "#cc1d15"}
            sx={item?.sx}
          />
        );
      })}
    </Stack>
  );
};

SelectChips.propTypes = {
  data: PropTypes.any,
  getLabel: PropTypes.func,
  isactive: PropTypes.func,
  onClick: PropTypes.func,
  scroll: PropTypes.bool,
  wrap: PropTypes.bool,
};

SelectChips.defaultProps = {
  data: null,
  getLabel: (item) => item.label,
  isactive: () => {},
  onClick: () => {},
  scroll: false,
  wrap: false,
};

export default SelectChips;
