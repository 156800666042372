import PropTypes from "prop-types";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import VerticalGrid from "components/Grid/VerticalGrid";
import { useDispatch } from "react-redux";
import DynamicForm from "components/Form/DynamicForm";

const FilterDialog = ({
  open,
  onClose,
  filterData,
  addFilter,
  fetchData,
  title,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    list: {},
    tempList: {},
    action: false,
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiDialog-paper": {
            maxWidth: "570px",
            width: "calc(100% - 10%)",
            margin: "0px",
          },
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} variant="h2">
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#4E4751",
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <DynamicForm
            filterData={filterData}
            md={12}
            grid={VerticalGrid}
            data={data}
            setData={setData}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: {
              xs: "column",
              sm: "row",
            },
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="secondary-outlined" onClick={onClose}>
            Batal
          </Button>
          <Button
            variant="secondary-outlined"
            onClick={() =>
              setData({
                ...data,
                tempList: data.list,
                list: {},
                action: true,
              })
            }
          >
            Bersihkan
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (fetchData) {
                dispatch(addFilter(data));
                fetchData();
              }
              dispatch(addFilter(data));
              onClose();
            }}
          >
            Terapkan
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

FilterDialog.propTypes = {
  filterData: PropTypes.array,
  onClose: PropTypes.func,
  addFilter: PropTypes.func,
  open: PropTypes.bool,
  params: PropTypes.object,
  title: PropTypes.string,
  fetchData: PropTypes.func,
};

FilterDialog.defaultProps = {
  filterData: [],
  onClose: () => {},
  addFilter: () => {},
  fetchData: () => {},
  open: false,
  params: {},
  title: "",
};
export default FilterDialog;
