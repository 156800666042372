import React from "react";
import color from "./color";
import PropTypes from "prop-types";
import color2 from "./color2";
import StatusChip from "../StatusChip";

const MainStatus = ({ value, arr, type }) => {
  return (
    <StatusChip
      label={arr.find((i) => i.value === value)?.label || "-"}
      color={type == 1 ? color(value)?.color : color2(value)?.color}
      background={color(value)?.background}
    />
  );
};

MainStatus.defaultProps = {
  value: 0,
  arr: [],
  type: 1,
};

MainStatus.propTypes = {
  value: PropTypes.number,
  arr: PropTypes.array,
  type: PropTypes.number,
};
export default MainStatus;
