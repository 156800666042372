import { createSlice } from "@reduxjs/toolkit";
import { getUserDetail } from "../../action";

export const initialState = {
  user: {},
  logActivity: [],
  tasks: [],
  currentPage: 1,
  params: {
    filter: {
      activity: "",
      voucher: "",
    },
  },
  totalItems: 0,
  message: "",
  loading: false,
};

export const penggunaDetailSlice = createSlice({
  name: "penggunaDetail",
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.params.filter = {
        ...state.params.filter,
        [action.payload.type]: action.payload.value,
      };
    },
  },
  extraReducers: {
    [getUserDetail.pending]: (state) => {
      state.loading = true;
    },
    [getUserDetail.rejected]: (state, action) => {
      state.loading = false;
      state.message = action.error.message;
    },
    [getUserDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.logActivity = action.payload.LogActivity;
      state.tasks = action.payload.Tasks;
      state.user = action.payload.User;
    },
  },
});

export const { changeFilter } = penggunaDetailSlice.actions;

export default penggunaDetailSlice.reducer;
