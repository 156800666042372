import penerimaBantuanReducer from "redux/penerimaBantuan/slice";
import detailPenerimaBantuanReducer from "redux/penerimaBantuan/detail/slice";
import bansosRiwayatTransaksiReducer from "redux/penerimaBantuan/KartuSubsidi/RiwayatTransaksi/slice";
import bansosProgramKartuSubsidiReducer from "redux/penerimaBantuan/KartuSubsidi/slice";
import bansosProgramVoucherReducer from "redux/penerimaBantuan/Voucher/slice";

const penerimaReducer = {
  penerimaBantuan: penerimaBantuanReducer,
  detailPenerimaBantuan: detailPenerimaBantuanReducer,
  bansosRiwayatTransaki: bansosRiwayatTransaksiReducer,
  bansosProgramKartuSubsidi: bansosProgramKartuSubsidiReducer,
  bansosProgramVoucher: bansosProgramVoucherReducer,
};

export default penerimaReducer;
