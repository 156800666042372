import MasterDataIcon from "assets/layout/sidebar/MasterDataIcon";

const permisPenerbit = [
  "Web:MasterData:MasterBantuan:KartuSubsidi:Get",
  "Web:MasterData:MasterBantuan:Penerbit:Get",
  "Web:MasterData:MasterBantuan:Voucer:Get",
];

const MasterData = {
  id: "master-data",
  type: "collapse",
  title: "Master Data",
  icon: MasterDataIcon,
  permission: permisPenerbit,
  children: [
    {
      id: "master-bantuan",
      title: "Master Bantuan",
      type: "collapse",
      permission: permisPenerbit,
      children: [
        {
          id: "penerbit",
          title: "Penerbit",
          type: "item",
          url: "/master-data/master-bantuan/penerbit",
          permission: permisPenerbit[0],
        },
        {
          id: "kartu-subsidi",
          title: "Kartu Subsidi",
          type: "item",
          url: "/master-data/master-bantuan/kartu-subsidi",
          permission: permisPenerbit[1],
        },
        {
          id: "voucer",
          title: "Voucer",
          type: "item",
          url: "/master-data/master-bantuan/voucer",
          permission: permisPenerbit[2],
        },
      ],
    },
  ],
};

export default MasterData;
