import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, getParams, post, put } from "api";

import { RedeemSubsidi } from "api/endpoints";

export const getListRedeemSubsidi = createAsyncThunk(
  "redeemSubsidi/getListRedeemSubsidi",
  async (params) => {
    const response = await getParams(RedeemSubsidi.list, params);
    return response.data.data;
  }
);

export const getDetailSubsidiBansos = async (id) => {
  const response = await get(`${RedeemSubsidi.detailSubsidi}/${id}`);

  return response;
};

export const getDetailVoucerBansos = async (params) => {
  const response = await getParams(RedeemSubsidi.detailVoucer, params);

  return response;
};

export const listVoucerById = async (id) => {
  const response = await get(`${RedeemSubsidi.listVoucer}/${id}`);

  return response;
};

export const updateFoto = async (id, data) => {
  const response = await put(`${RedeemSubsidi.updateFoto}/${id}`, data);

  return response;
};

export const redeemSubsidiBansos = async (data) => {
  const response = await post(RedeemSubsidi.redeemSubsidiBansos, data);
  return response;
};
