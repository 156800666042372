import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import MainTable from "components/Table/MainTable";
import {
  changeFilterName,
  changeLimit,
  changePage,
  changeFilter,
  deleteFilter,
  addFilter,
} from "redux/inventory/MasterDistribusi/KirimProduk/slice";
import MainCard from "components/Paper/MainCard";
import MainPagination from "components/Pagination";
import useFetchData from "hook/useFetchData";
import ExportHelper from "helper/ExportHelper";
import moment from "moment";
import {
  getListMasterDistribusi,
  getMasterDistribusi,
} from "redux/inventory/action";
import useSelect from "hook/useSelect";
import Dialog from "./Dialog";
import Page from "components/Page";
import useAutoFetch from "hook/useAutoFetch";
import AlertDialog from "components/Dialog/AlertDialog";
import MainCardHeader3 from "components/Paper/MainCardHeader3";
import InventoryStatus from "components/Status/Inventory/inventory";

const headers = [
  {
    name: "Kode",
    custom: (item) => {
      return `${item.details.mdCode || "-"}`;
    },
    width: 30,
  },
  {
    name: "Merchant Asal",
    custom: (item) => {
      return item.details.mdIssuerName || "-";
    },
    width: 30,
  },
  {
    name: "Merchant Tujuan",
    custom: (item) => {
      return item.details.mdMerchantName || "-";
    },
    width: 30,
  },
  {
    name: "Tanggal",
    custom: (item) => {
      return moment(item.mdDate).isValid()
        ? moment(item.mdDate).format("DD / MM / YYYY")
        : "-";
    },
    width: 30,
  },
  {
    name: "Jumlah",
    custom: (item) => {
      return item.details.totalStock || "-";
    },
    align: "center",
    width: 30,
  },
  {
    name: "Status",
    custom: (item) => {
      return <InventoryStatus value={1} minWidth="140px" maxWidth="140px" />;
    },
    width: 30,
  },
];

const filter = [
  {
    label: "Kode",
    value: "mdCode",
  },
  {
    label: "Merchant Asal",
    value: "mdIssuerName",
  },
  {
    label: "Merchant Tujuan",
    value: "mdMerchantName",
  },
  {
    label: "Tanggal",
    value: "mdDate",
    type: "date",
  },
  {
    label: "Jumlah",
    value: "totalStock",
  },
];

const MasterDistribusi = () => {
  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.masterDistribusi);

  const data = useRef(null);
  const dispatch = useDispatch();

  const [open, setOpen] = useState({
    dialog: false,
    delete: false,
  });

  const fetchData = () => dispatch(getListMasterDistribusi(params));

  useAutoFetch(fetchData, currentPage);

  const exportData = useFetchData({
    action: getMasterDistribusi,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "master-distribusi",
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(null, null, data);
  };

  return (
    <>
      <Page title="Master Distribusi" desc />

      <MainCard
        title="Daftar Barang"
        onClick={() => {
          data.current = null;
          setOpen({
            ...open,
            dialog: true,
          });
        }}
        borderColor="#C8C8C8"
      >
        <MainCardHeader3
          filterName={filterName}
          changeFilterName={changeFilterName}
          deleteFilter={deleteFilter}
          changeFilter={changeFilter}
          params={params}
          addFilter={addFilter}
          onChangeExport={(type) => handleExport(type)}
          filter={filter}
          fetchData={fetchData}
          onClickFilter={() => {
            fetchData();
          }}
        />
        <MainTable
          headers={headers}
          loading={loading}
          data={list}
          summaryKey={(item) => item.details.mdIssuerName}
          action=""
          detail={[
            {
              type: "button",
              tooltip: "Edit Master Distribusi",
              variant: "icon:pencil",
              onClick: (_, item) => {
                const newstok = item.stocks.map(({ mpId, mdsStock }) => {
                  return {
                    mpId,
                    mdsStock,
                  };
                });
                data.current = {
                  id: item.details.mdId,
                  mdCode: item.details.mdCode,
                  mdDate: item.details.mdDate,
                  mdIssuerId: Number(item.details.mdIssuerId),
                  mdMerchantId: Number(item.details.mdMerchantId),
                  stocks: newstok,
                };
                setOpen({
                  ...open,
                  dialog: true,
                });
              },
            },
            {
              type: "button",
              tooltip: "Hapus Master Distribusi",
              variant: "icon:trash",
              onClick: () => {
                setOpen({
                  ...open,
                  delete: true,
                });
              },
            },
          ]}
          increment={params?.offset + 1}
        />
        <MainPagination
          params={params}
          changeLimit={changeLimit}
          changePage={changePage}
          totalItems={totalItems}
          currentPage={currentPage}
        />
      </MainCard>

      <Dialog
        open={open.dialog}
        onClose={() =>
          setOpen({
            ...open,
            dialog: false,
          })
        }
        refresh={fetchData}
        data={data.current}
      />

      <AlertDialog
        buttonSX={{
          minWidth: "160px",
        }}
        open={open.delete}
        onClose={() => {
          setOpen({
            ...open,
            delete: false,
          });
        }}
        alertTitle="Yakin Hapus Data !"
        submitText="Hapus!"
        cancelText="Batal"
        onSubmit={() => {
          setOpen({
            ...open,
            delete: false,
          });
        }}
      />
    </>
  );
};

export default MasterDistribusi;
