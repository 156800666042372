import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, getParams, post, put } from "api";
import { KartuSubsidi } from "api/endpoints";

export const getListKartuSubsidi = createAsyncThunk(
  "kartuSubsidi/getKartuSubsidi",
  async (params) => {
    const response = await getParams(KartuSubsidi.list, params);
    return response.data.data;
  }
);

export const getKartuSubsidi = async (params) => {
  const response = await getParams(KartuSubsidi.list, params);
  return response;
};

export const kartuSubsidiNew = async (data) => {
  const response = await post(KartuSubsidi.action, data);

  return response;
};

export const kartuSubsidiUpdate = async (data, id) => {
  const withId = `${KartuSubsidi.action}/${id}`;
  const response = await put(withId, data);
  return response;
};

export const kartuSubsidiDetail = async (id) => {
  const withId = `${KartuSubsidi.action}/${id}`;
  const response = await get(withId);

  return response;
};
