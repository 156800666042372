import React from "react";

const InisaWhite = () => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M102 55.6786C102 34.3173 84.7627 17 63.5002 17C42.2369 17 25.0001 34.3173 25.0001 55.6786C25.0001 70.9321 33.7917 84.1181 46.5592 90.415L46.5617 90.4101C47.38 90.8442 48.3111 91.0924 49.3009 91.0924C52.5473 91.0924 55.1789 88.4485 55.1789 85.1875C55.1789 82.8421 53.814 80.8216 51.8423 79.8692L51.8484 79.8569C42.9214 75.507 36.766 66.3155 36.766 55.6786C36.766 40.8456 48.735 28.8205 63.5002 28.8205C78.2646 28.8205 90.2341 40.8456 90.2341 55.6786C90.2341 64.5873 85.9126 72.4777 79.2651 77.3639C74.1163 81.0895 69.162 76.3942 69.162 71.2425V55.8286H69.1546V51.8305C69.1546 50.6572 68.2075 49.7056 67.0396 49.7056H59.4512C58.2833 49.7056 57.3362 50.6572 57.3362 51.8305V55.8286V56.2211V74.0027H57.3366C57.3473 78.5092 61.7365 87.7448 70.1473 88.9132C81.3026 90.3792 86.6606 86.6032 88.1166 85.4118L88.1183 85.4134C88.131 85.4027 88.1429 85.3907 88.156 85.38C88.2048 85.3396 88.2496 85.3017 88.2894 85.2683C88.2894 85.2683 88.2857 85.2658 88.2857 85.2654C96.6681 78.1703 102 67.5527 102 55.6786Z"
        fill="#FFFFFE"
      />
      <path
        d="M63.2364 47.1219C66.5049 47.1219 69.1546 44.4599 69.1546 41.1762C69.1546 37.8925 66.5049 35.2305 63.2364 35.2305C59.9678 35.2305 57.3181 37.8925 57.3181 41.1762C57.3181 44.4599 59.9678 47.1219 63.2364 47.1219Z"
        fill="#CBCCCB"
      />
      <mask
        id="mask0_0_956"
        maskUnits="userSpaceOnUse"
        x="25"
        y="17"
        width="77"
        height="75"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25 55.6782C25 70.9317 33.7916 84.1177 46.5596 90.4146L46.5617 90.4101C47.3803 90.8442 48.311 91.0924 49.3012 91.0924C52.5476 91.0924 55.1788 88.4481 55.1788 85.1871C55.1788 82.8417 53.8144 80.8216 51.8426 79.8692L51.8483 79.8569C42.9213 75.5066 36.7663 66.3151 36.7663 55.6782C36.7663 40.8452 48.7349 28.8201 63.5002 28.8201C78.2646 28.8201 90.234 40.8452 90.234 55.6782C90.234 64.5873 85.9125 72.4777 79.265 77.3635C74.1162 81.0891 69.1619 76.3938 69.1619 71.2425V55.8286H69.1545V51.8301C69.1545 50.6567 68.2074 49.7052 67.0395 49.7052H59.4511C58.2832 49.7052 57.3361 50.6567 57.3361 51.8301V55.8286V56.2211V74.0027H57.3365C57.3476 78.5087 61.7364 87.7448 70.1472 88.9132C81.3029 90.3788 86.6606 86.6028 88.1166 85.4118L88.1182 85.413C88.1309 85.4023 88.1432 85.3903 88.1559 85.3796C88.2048 85.3396 88.2499 85.3017 88.2893 85.2679C88.2893 85.2679 88.2856 85.2654 88.2856 85.265C96.6681 78.1699 102 67.5523 102 55.6782C102 34.3169 84.7627 17 63.5002 17C42.2368 17 25 34.3169 25 55.6782Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_0_956)">
        <path
          d="M69.1546 46.0703C69.1546 57.0263 69.162 68.903 69.162 71.243C69.162 74.603 73.5816 91.5241 91.0215 84.8033C80.9874 100.644 62.5921 90.5697 59.7249 86.3666C56.8585 82.2835 53.1554 59.4134 55.4251 49.5857C59.1278 48.0257 69.1546 46.0703 69.1546 46.0703Z"
          fill="#CBCCCB"
        />
      </g>
      <path d="M32.1132 112H35.2968V94.1855H32.1132V112Z" fill="#FFFFFE" />
      <path
        d="M54.0265 101.338C53.9289 100.75 53.7438 100.167 53.4869 99.6283C53.0179 98.5469 52.3851 97.6016 51.5894 96.803C51.1782 96.4151 50.7416 96.0531 50.2918 95.7295C49.8191 95.3885 49.2987 95.107 48.7604 94.8955C47.6319 94.4243 46.4849 94.1855 45.3494 94.1855C44.1934 94.1855 43.0354 94.4243 41.9225 94.8885C41.3685 95.1061 40.8478 95.3885 40.3738 95.7295C40.1247 95.9092 39.8793 96.1001 39.6384 96.303L39.6585 94.1855H36.468V112H39.6515L39.6507 102.969C39.6692 102.602 39.718 102.24 39.7948 101.891C39.8703 101.547 39.9766 101.206 40.1083 100.884C40.4111 100.159 40.8207 99.5446 41.3455 99.0355C41.8293 98.5284 42.44 98.1165 43.1704 97.8065C43.8019 97.5262 44.5349 97.3835 45.3494 97.3835C46.1049 97.3835 46.8329 97.5282 47.5112 97.8123C48.1904 98.098 48.803 98.5127 49.3328 99.0458C49.5963 99.3117 49.8289 99.599 50.0255 99.9C50.2299 100.213 50.4112 100.549 50.559 100.883C50.8622 101.611 51.0165 102.354 51.0165 103.093V112H54.1997L54.1993 103.029C54.1759 102.456 54.118 101.886 54.0265 101.338Z"
        fill="#FFFFFE"
      />
      <path d="M55.2614 112H58.4449V94.1855H55.2614V112Z" fill="#FFFFFE" />
      <path
        d="M62.5376 100.917C62.6898 100.553 62.8684 100.22 63.0649 99.9268C63.2644 99.632 63.4999 99.3471 63.7666 99.0788C64.3165 98.5267 64.928 98.1124 65.596 97.844C66.3187 97.5385 67.0581 97.3835 67.7947 97.3835C68.5305 97.3835 69.2696 97.5385 69.9918 97.8432C70.3562 97.9982 70.6882 98.1767 70.9771 98.3738C71.2713 98.5741 71.5557 98.8116 71.8216 99.0788L72.3559 99.6155L74.6663 97.3567L74.0893 96.8059C73.232 95.9855 72.2878 95.3391 71.2615 94.8736C70.1564 94.4168 68.9897 94.1855 67.7947 94.1855C66.5747 94.1855 65.4175 94.4139 64.3535 94.8641C63.2947 95.3139 62.3402 95.9608 61.5183 96.7865C60.6955 97.6131 60.0508 98.5721 59.6043 99.6353C59.1562 100.702 58.9285 101.865 58.9285 103.092V104.707H73.2686C73.2082 104.905 73.134 105.102 73.0466 105.303C72.7843 105.957 72.3727 106.567 71.8237 107.115C71.5541 107.384 71.2705 107.619 70.9783 107.816C70.6878 108.013 70.357 108.191 69.9939 108.343C69.2716 108.647 68.5313 108.801 67.7947 108.801C67.0581 108.801 66.3187 108.646 65.5833 108.336C64.9267 108.071 64.3157 107.658 63.7666 107.106L63.2254 106.561L60.9786 108.82L61.5162 109.364C62.3382 110.196 63.2914 110.849 64.3485 111.306C65.4122 111.766 66.5715 112 67.7947 112C68.9897 112 70.1539 111.774 71.2787 111.318C72.2833 110.873 73.2279 110.236 74.1028 109.407C74.5046 109.005 74.8661 108.559 75.1747 108.083C75.4775 107.617 75.7484 107.11 75.9843 106.568C76.4329 105.503 76.6602 104.344 76.6602 103.122V101.509H62.33C62.3895 101.303 62.4596 101.104 62.5376 100.917Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.2975 96.7865C93.0977 97.5917 93.7354 98.5432 94.1926 99.6164C94.6534 100.696 94.8873 101.865 94.8873 103.092V112H91.7037V109.897C91.2039 110.321 90.6548 110.694 90.0594 111.014C88.8398 111.668 87.4815 112 86.021 112C84.7981 112 83.6335 111.765 82.5604 111.303C81.4906 110.842 80.5443 110.202 79.7445 109.398C78.9435 108.593 78.3058 107.642 77.8487 106.569C77.3886 105.491 77.1552 104.322 77.1552 103.092C77.1552 101.864 77.3886 100.694 77.8487 99.6164C78.3062 98.5424 78.9443 97.5909 79.7445 96.7865C80.5443 95.9826 81.4906 95.3424 82.5604 94.8819C83.6335 94.4197 84.7981 94.1855 86.021 94.1855C87.2439 94.1855 88.4085 94.4197 89.4816 94.8819C90.549 95.3411 91.4961 95.9818 92.2975 96.7865ZM91.5925 104.326C91.666 103.991 91.7037 103.641 91.7037 103.288V103.092C91.7037 102.326 91.558 101.567 91.2712 100.835C91.1276 100.513 90.9454 100.178 90.7439 99.8691C90.5568 99.583 90.3372 99.3307 90.0918 99.1196L90.0582 99.0907L90.0286 99.0581C89.7881 98.798 89.5153 98.5609 89.2161 98.3544C88.9116 98.145 88.5817 97.9594 88.237 97.8044C87.5394 97.5299 86.7839 97.3835 86.021 97.3835C85.2385 97.3835 84.485 97.5286 83.7809 97.8156C83.0828 98.1297 82.4796 98.5519 82.0138 99.0577L81.9835 99.0899L81.9494 99.1196C81.7044 99.3307 81.4853 99.583 81.2986 99.8682C81.0946 100.181 80.9128 100.516 80.7585 100.865C80.4844 101.566 80.3387 102.325 80.3387 103.092C80.3387 103.88 80.4828 104.637 80.7688 105.342C81.0823 106.028 81.4988 106.642 81.9933 107.139C82.4878 107.636 83.0992 108.055 83.8133 108.383C84.1465 108.525 84.5109 108.634 84.8753 108.699C85.2495 108.767 85.6349 108.801 86.021 108.801C86.4068 108.801 86.7917 108.767 87.1647 108.699C87.5307 108.634 87.8956 108.525 88.2505 108.374C88.5862 108.224 88.9206 108.041 89.2313 107.837C89.5231 107.644 89.7918 107.416 90.0278 107.16C90.5428 106.642 90.9597 106.028 91.2876 105.31C91.4091 105.013 91.5149 104.672 91.5925 104.326Z"
        fill="#FFFFFE"
      />
    </svg>
  );
};

export default InisaWhite;
