import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Grid, Typography } from "@mui/material";
import FormatRupiah from "helper/FormatRupiah";
import AlertDialog from "components/Dialog/AlertDialog";
import ScanGagal from "assets/redeem/ScanGagal";
import moment from "moment";
import { QRCodeSVG } from "qrcode.react";
import KonfirmasiManual from "./KonfirmasiManual";

const OfflineTransaksi = ({ handleSubmit, isValid, watch, open, onClose }) => {
  const { Id } = JSON.parse(localStorage.getItem("profile-ntt"));
  const [openDialog, setOpenDialog] = useState({
    success: false,
    fail: false,
    konfirmasiManual: false,
  });

  const [time, setTime] = useState({
    minutes: "",
    seconds: "",
  });

  useEffect(() => {
    if (open) {
      const timenow = moment().valueOf();
      const timego = moment().add(15, "m").valueOf();
      let duration = timego - timenow;
      const interval = 1000;

      const intervalId = setInterval(() => {
        duration = moment.duration(duration - interval, "milliseconds");
        if (duration.asSeconds() <= 0) {
          clearInterval(intervalId);
          onClose();
        }
        setTime({
          minutes: duration.minutes(),
          seconds: duration.seconds(),
        });
      }, interval);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [open]);

  return (
    <>
      <MainDialog
        open={open}
        onClose={() => {
          onClose();
        }}
        title={"Detail Transaksi"}
        loading={false}
        action={false}
        customWidth="1000px"
      >
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{ paddingTop: "5px !important" }}
            order={{
              xs: 2,
              md: 1,
            }}
          >
            <Alert
              icon={false}
              variant="warning"
              sx={{
                ".MuiAlert-message": {
                  color: "#F7B500",
                  margin: "auto",
                  fontSize: "12px",
                  display: "flex",
                },
              }}
            >
              Mohon selesaikan pembayaran dalam{"   "}
              <Box
                component="span"
                sx={{
                  backgroundColor: "#F7B500",
                  color: "#fff",
                  fontWeight: "600",
                  borderRadius: "4px",
                  minWidth: "18px",
                  ml: 1,
                  textAlign: "center",
                }}
              >
                {time.minutes}
              </Box>
              &nbsp;:&nbsp;
              <Box
                component="span"
                sx={{
                  backgroundColor: "#F7B500",
                  color: "#fff",
                  fontWeight: "600",
                  borderRadius: "4px",
                  minWidth: "18px",
                  textAlign: "center",
                }}
              >
                {time.seconds}
              </Box>{" "}
            </Alert>
            <Box display="flex" flexDirection="column" gap={3} px={3} pt={1}>
              <Typography variant="label2">
                Kartu Subsidi - Offline Transaksi
              </Typography>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="label2">Merchant</Typography>
                <Typography variant="label2">{watch("namaBisnis")}</Typography>
                <Typography variant="desc5">{watch("alamatBisnis")}</Typography>
              </Box>

              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="label2">Rincian Transaksi</Typography>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="desc5">Status</Typography>
                  <Typography variant="desc3" color="#F7B500">
                    Menunggu Pembayaran
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Typography variant="desc5">Tanggal Transaksi</Typography>
                  <Typography variant="desc3">
                    {moment(new Date()).format("DD-MM-YYYY HH:mm")}
                  </Typography>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="label2">Rincian Pembayaran</Typography>
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="desc5">Metode Pembayaran</Typography>
                  <Typography variant="desc3">Kartu Inisa Pintar</Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Typography variant="desc5">Total Tagihan</Typography>
                  <Typography variant="desc3">
                    Rp{FormatRupiah(watch("TotalTagihan"))}
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Typography variant="desc5">Biaya Admin</Typography>
                  <Typography variant="desc3">Rp0</Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" px={3} pt={1} gap={1}>
              <Box sx={{ borderBottom: "1px dashed #979797" }} />
              <Box display="flex" justifyContent="space-between">
                <Typography variant="text2">Total</Typography>
                <Typography variant="text2">
                  Rp{FormatRupiah(watch("TotalTagihan"))}
                </Typography>
              </Box>
            </Box>
            <Button
              sx={{
                width: "100%",
                minHeight: "46px",
                mt: 3,
              }}
              variant="primary"
              onClick={() =>
                setOpenDialog({
                  ...openDialog,
                  konfirmasiManual: true,
                })
              }
            >
              Konfirmasi Manual
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            order={{
              xs: 1,
              md: 2,
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={1}
              height="100%"
            >
              <Typography variant="label2">Pindai Kode QR</Typography>
              <Typography variant="desc3" mb={2}>
                Tunjukkan QR untuk dipindai
              </Typography>
              <QRCodeSVG
                value={`${Id};${watch("TotalTagihan")}`}
                size={380}
                level={"L"}
              />
            </Box>
          </Grid>
        </Grid>
      </MainDialog>

      <AlertDialog
        buttonSX={{
          minWidth: "100%",
          minHeight: "50px",
        }}
        customWidth="329px"
        open={openDialog.fail}
        alertTitle="Scan Gagal"
        submitText="Scan Ulang"
        customIcon={<ScanGagal />}
      />

      <KonfirmasiManual
        open={openDialog.konfirmasiManual}
        onClose={() => {
          setOpenDialog({
            ...openDialog,
            konfirmasiManual: false,
          });
        }}
      />
    </>
  );
};

OfflineTransaksi.propTypes = {
  Controller: PropTypes.any,
  control: PropTypes.any,
  data: PropTypes.object,
  errors: PropTypes.any,
  handleSubmit: PropTypes.any,
  id: PropTypes.any,
  isEdit: PropTypes.bool,
  isValid: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  setRes: PropTypes.func,
  watch: PropTypes.any,
  register: PropTypes.any,
  unregister: PropTypes.any,
};

OfflineTransaksi.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  data: null,
  id: null,
  setRes: null,
  handleSubmit: null,
  isValid: null,
  errors: null,
  control: null,
  Controller: null,
  watch: null,
  register: null,
  unregister: null,
};
export default OfflineTransaksi;
