import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import TextFieldv3 from "components/TextField/TextFieldv3";
import FormatRupiah from "helper/FormatRupiah";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import QRScanner from "components/Camera/QRScanner";
import ScanBerhasil from "assets/redeem/ScanBerhasil";
import AlertDialog from "components/Dialog/AlertDialog";
import ScanGagal from "assets/redeem/ScanGagal";
import { useNavigate } from "react-router-dom";
import useFetchData from "hook/useFetchData";
import { redeemSubsidiBansos } from "redux/redeemSubsidi/action";

const OnlineTransaksiSubsidi = ({
  handleSubmit,
  isValid,
  watch,
  open,
  onClose,
  getValues,
  refresh,
}) => {
  const navigate = useNavigate();

  const refScanner = useRef(null);

  const [openDialog, setOpenDialog] = useState({
    success: false,
    fail: false,
    notFound: false,
  });

  const action = useFetchData({
    action: redeemSubsidiBansos,
    message: "Berhasil redeem subsidi",
    onSuccess: () => {
      setOpenDialog({
        ...openDialog,
        success: true,
      });
      refresh();
    },
    customMessageError: (res) => res?.data?.message,
    onError: () => {
      setOpenDialog({
        ...openDialog,
        fail: true,
      });
    },
  });

  return (
    <>
      <MainDialog
        open={open}
        onClose={() => {
          onClose();
        }}
        title={"Redeem Subsidi"}
        loading={false}
        valid={isValid}
        handleSubmit={handleSubmit}
        submitText={"Selanjutnya"}
        onReset={() => {
          reset();
        }}
        action={false}
        customWidth="1024px"
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <Box display="flex" flexDirection="column" gap={3} pl={1} pt={2}>
              <Typography variant="label2">
                Kartu Subsidi - Scan QR Online
              </Typography>
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="label2">Merchant</Typography>
                <Typography variant="label2">
                  {getValues("namaBisnis")}
                </Typography>
                <Typography variant="desc5">
                  {getValues("alamatBisnis")}
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" width="100%" gap={1}>
                <Typography variant="label2">Total tagihan</Typography>
                <Box display="flex" gap={1}>
                  <Box
                    sx={{
                      backgroundColor: "#EBECED",
                      alignItems: "center",
                      display: "flex",
                      alignItems: "center",
                      width: "60px",
                      borderRadius: "4px",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="labelMedium2">Rp.</Typography>
                  </Box>
                  <TextFieldv3
                    placeholder="Masukkan total tagihan"
                    disabled
                    value={FormatRupiah(watch("TotalTagihan"))}
                  />
                </Box>
              </Box>

              <Box sx={{ borderBottom: "1px dashed #979797" }} />
              <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant="label2">Panduan Scan QR</Typography>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Box display="flex" gap={1}>
                    <RadioButtonCheckedIcon
                      sx={{
                        fontSize: "16px",
                        mt: 0.5,
                      }}
                    />
                    <Typography variant="text6">Scan kode QR</Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <RadioButtonCheckedIcon
                      sx={{
                        fontSize: "16px",
                        mt: 0.5,
                      }}
                    />
                    <Typography variant="text6">
                      Pastikan QR Code di dalam area foto dan pastikan dalam
                      pencahayaan yang baik
                    </Typography>
                  </Box>
                  <Box display="flex" gap={1}>
                    <RadioButtonCheckedIcon
                      sx={{
                        fontSize: "16px",
                        mt: 0.5,
                      }}
                    />
                    <Typography variant="text6">
                      Informasi voucher tampil dan dapat diverifikasi petugas
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box sx={{ position: "relative" }}>
              <QRScanner
                noScan={openDialog.success}
                onSuccess={({ data }, qrScanner) => {
                  if (data) {
                    console.log(data);
                    refScanner.current = qrScanner;
                    const { Id } = JSON.parse(
                      localStorage.getItem("profile-ntt")
                    );
                    const split = data.split("-");
                    const helo = JSON.parse(split[0]);
                    // if (helo?.includes(Id)) {
                    const formData = new FormData();
                    formData.append("qr", split[1]);
                    formData.append("amount", watch("TotalTagihan"));
                    formData.append("fee", "0");
                    action.fetch(formData);
                    // } else {
                    //   setOpenDialog({
                    //     ...openDialog,
                    //     notFound: true,
                    //   });
                    // }

                    qrScanner.stop();
                  }
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </MainDialog>

      <AlertDialog
        buttonSX={{
          minWidth: "100%",
          minHeight: "50px",
        }}
        customWidth="329px"
        open={openDialog.success}
        onClose={() => {
          setOpenDialog({
            ...openDialog,
            success: false,
          });
          refScanner.current.start();
        }}
        alertTitle="Scan Berhasil"
        submitText="Lihat Detail Transaksi"
        onSubmit={() => {
          setOpenDialog({
            ...openDialog,
            success: false,
          });
          navigate(`/redeem-subsidi/subsidi-bansos/${action.response.TrxId}`);
        }}
        customIcon={<ScanBerhasil />}
      />

      <AlertDialog
        buttonSX={{
          minWidth: "100%",
          minHeight: "50px",
        }}
        customWidth="329px"
        open={openDialog.fail}
        alertTitle="Scan Gagal"
        submitText="Scan Ulang"
        onSubmit={() => {
          setOpenDialog({
            ...openDialog,
            fail: false,
          });
          refScanner.current.start();
        }}
        onClose={() => {
          setOpenDialog({
            ...openDialog,
            fail: false,
          });
          refScanner.current.start();
        }}
        customIcon={<ScanGagal />}
      />
      <AlertDialog
        buttonSX={{
          minWidth: "100%",
          minHeight: "50px",
        }}
        customWidth="329px"
        open={openDialog.notFound}
        alertTitle="QR Code tidak sesuai"
        submitText="Scan Ulang"
        onSubmit={() => {
          setOpenDialog({
            ...openDialog,
            notFound: false,
          });
          refScanner.current.start();
        }}
        onClose={() => {
          setOpenDialog({
            ...openDialog,
            notFound: false,
          });
          refScanner.current.start();
        }}
        customIcon={<ScanGagal />}
      />
    </>
  );
};

OnlineTransaksiSubsidi.propTypes = {
  Controller: PropTypes.any,
  control: PropTypes.any,
  data: PropTypes.object,
  errors: PropTypes.any,
  handleSubmit: PropTypes.any,
  id: PropTypes.any,
  isEdit: PropTypes.bool,
  isValid: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  setRes: PropTypes.func,
  watch: PropTypes.any,
  register: PropTypes.any,
  unregister: PropTypes.any,
  getValues: PropTypes.any,
};

OnlineTransaksiSubsidi.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  data: null,
  id: null,
  setRes: null,
  handleSubmit: null,
  isValid: null,
  errors: null,
  control: null,
  Controller: null,
  watch: null,
  register: null,
  unregister: null,
  getValues: null,
};
export default OnlineTransaksiSubsidi;
