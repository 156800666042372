import PropTypes from "prop-types";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React from "react";

const PenyelenggaraBantuan = ({ data }) => {
  return (
    <Box
      sx={{
        borderBottomLeftRadius: "8px",
        borderBottomRightRadius: "8px",
      }}
    >
      {data.map((item, i) => (
        <Accordion key={i} disableGutters elevation={0} defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{
              background: "#F6F6F6 !important",
            }}
          >
            <Typography variant="gridLabel">{item.IssuerName}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {item?.list?.map((list, index) => (
              <Box key={index} display="flex" py={1.5} alignItems="flex-start">
                <Box display="flex" flexDirection="column" gap={1}>
                  <Typography
                    key={i}
                    variant="labelMedium2"
                    sx={{
                      whiteSpace: "pre-line",
                    }}
                  >
                    {list.VoucherName || "-"}
                  </Typography>
                  <Typography
                    variant="desc2"
                    sx={{
                      whiteSpace: "pre-line",
                    }}
                  >
                    {list.PrefixCode || "-"}
                  </Typography>
                </Box>
              </Box>
            ))}

            {!Boolean(item.list) && (
              <Typography
                key={i}
                variant="labelMedium2"
                sx={{
                  whiteSpace: "pre-line",
                }}
              >
                Data Tidak Ditemukan
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
      {!data.length && (
        <Box
          sx={{
            height: "250px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="labelMedium2"
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            Data Tidak Ditemukan
          </Typography>
        </Box>
      )}
    </Box>
  );
};

PenyelenggaraBantuan.propTypes = {
  data: PropTypes.array,
};

PenyelenggaraBantuan.defaultProps = {
  data: [],
};
export default PenyelenggaraBantuan;
