import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "redux/global/slice";
import profileReducer from "redux/profile/slice";
import voucherReducer from "./voucher";
import subsidiBansosReducer from "./subsidiBansos";
import penerimaReducer from "./penerimaBantuan";
import voucherBansosReducer from "./voucherBansos";
import kartuSubsidiReducer from "./kartuSubsidi";
import penerbitReducer from "./penerbit";
import penggunaReducer from "./managementPengguna";
import authReducer from "./auth";
import redeemSubsidiReducer from "./redeemSubsidi";
import inventoryReducer from "./inventory";
import reportReducer from "./report";

export default configureStore({
  reducer: {
    global: globalReducer,
    profile: profileReducer,
    ...penerimaReducer,
    ...subsidiBansosReducer,
    ...voucherBansosReducer,
    ...voucherReducer,
    ...kartuSubsidiReducer,
    ...penerbitReducer,
    ...penggunaReducer,
    ...authReducer,
    ...redeemSubsidiReducer,
    ...inventoryReducer,
    ...reportReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
});
