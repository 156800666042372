import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import MainTable from "components/Table/MainTable";
import {
  changeFilterName,
  changeLimit,
  changePage,
  changeFilter,
  deleteFilter,
  addFilter,
} from "redux/report/Stocks/slice";
import AktifStatus from "components/Status/AktifStatus";
import MainCard from "components/Paper/MainCard";
import MainPagination from "components/Pagination";
import useFetchData from "hook/useFetchData";
import ExportHelper from "helper/ExportHelper";
import moment from "moment";
import MainCardHeader3 from "components/Paper/MainCardHeader3";
import useSelect from "hook/useSelect";
import useAutoFetch from "hook/useAutoFetch";
import Page from "components/Page";
import { getListStok, getStok } from "redux/report/action";
import DialogStock from "./Dialog";

const headers = [
  {
    name: "Kode Barang",
    key: "mpCode",
    width: 30,
  },
  {
    name: "Nama Barang",
    key: "mpName",
    width: 30,
  },
  {
    name: "Stok Awal",
    key: "firstStock",
    width: 30,
  },
  {
    name: "Stok Akhir",
    key: "lastStock",
    width: 30,
  },
  {
    name: "Tanggal Dibuat",
    custom: (item) => {
      return moment(item.createdAt).isValid()
        ? moment(item.createdAt).format("DD/MM/YYYY")
        : "-";
    },
    width: 30,
  },
  {
    name: "Dibuat",
    key: "createdByName",
    width: 30,
  },
  {
    name: "Tanggal Diubah",
    custom: (item) => {
      return moment(item.updatedAt).isValid()
        ? moment(item.updatedAt).format("DD/MM/YYYY")
        : "-";
    },
    width: 30,
  },
  {
    name: "Diubah oleh",
    key: "updatedByName",
    width: 30,
  },
  {
    name: "Status",
    custom: (item) => {
      return (
        <AktifStatus
          customText={["Ada", "Habis"]}
          value={item.mpActive}
          minWidth="120px"
          maxWidth="120px"
        />
      );
    },
    width: 30,
  },
];

const filter = [
  {
    label: "Kode Barang",
    value: "productCode",
  },
  {
    label: "Nama Barang",
    value: "productName",
  },
  {
    label: "Tanggal dibuat",
    value: "createdAt",
    type: "date",
  },
  {
    label: "Dibuat",
    value: "createdBy",
  },
  {
    label: "Tanggal diubah",
    value: "updatedAt",
    type: "date",
  },
];

export const card_tipe = [
  {
    label: "Ada",
    value: 1,
  },
  {
    label: "Habis",
    value: 0,
  },
];

const StockReport = () => {
  const { list, loading, params, totalItems, currentPage, filterName } =
    useSelect((state) => state.reportStock);
  const data = useRef(null);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const fetchData = async () => {
    await dispatch(getListStok(params));
  };

  useAutoFetch(fetchData, currentPage, filterName, params);

  const filterData = [
    {
      label: "Status",
      key: "statusStock",
      type: "switch",
      options: [
        {
          label: "Ada",
          activecolor: "#129350",

          value: 1,
        },
        {
          label: "Habis",
          activecolor: "#cc1d15",
          value: 0,
        },
      ],
    },
    {
      label: "Tanggal",
      startDate: "startDate",
      endDate: "endDate",
      type: "daterange",
    },
  ];

  const exportData = useFetchData({
    action: getStok,
    snackbar: false,
    onSuccess: (data, type) => {
      ExportHelper({
        type,
        data: data.list,
        filename: "report-stock",
      });
    },
  });

  const handleExport = (data) => {
    exportData.fetch(null, null, data);
  };

  return (
    <>
      <Page title="Stock Report" desc />
      <MainCard title="Stock Report" borderColor="#C8C8C8" addButton={false}>
        <MainCardHeader3
          filterName={filterName}
          changeFilterName={changeFilterName}
          deleteFilter={deleteFilter}
          changeFilter={changeFilter}
          params={params}
          addFilter={addFilter}
          onChangeExport={(type) => handleExport(type)}
          filter={filter}
          title="Filter"
          filterData={filterData}
          downloadText="Ekspor"
          fetchData={fetchData}
        />
        <MainTable
          headers={headers}
          loading={loading}
          data={list}
          summaryKey="mpName"
          detail={[
            {
              type: "button",
              tooltip: "Lihat Detail",
              variant: "icon:eye",
              onClick: (_, item) => {
                data.current = item;
                setOpen({
                  ...open,
                  dialog: true,
                });
              },
            },
          ]}
          increment={params?.offset + 1}
        />
        <MainPagination
          params={params}
          changeLimit={changeLimit}
          changePage={changePage}
          totalItems={totalItems}
          currentPage={currentPage}
        />
      </MainCard>

      <DialogStock
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        data={data?.current}
      />
    </>
  );
};

export default StockReport;
