import React from "react";

const BookmarkIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1950_71307)">
        <path
          d="M20 9.3335V26.6268L14.3867 24.2135L13.3333 23.7602L12.28 24.2135L6.66667 26.6268V9.3335H20ZM25.3333 1.3335H11.9867C10.52 1.3335 9.33333 2.5335 9.33333 4.00016H22.6667C24.1333 4.00016 25.3333 5.20016 25.3333 6.66683V24.0002L28 25.3335V4.00016C28 2.5335 26.8 1.3335 25.3333 1.3335ZM20 6.66683H6.66667C5.2 6.66683 4 7.86683 4 9.3335V30.6668L13.3333 26.6668L22.6667 30.6668V9.3335C22.6667 7.86683 21.4667 6.66683 20 6.66683Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1950_71307">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BookmarkIcon;
