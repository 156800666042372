import { postLogin, postLoginInit, getIP, put } from "../../api";
import { Auth } from "../../api/endpoints";

export const initialization = async (body) => {
  const response = await postLogin(Auth.initialization, body);
  return response;
};

export const LoginAction = async (body, appid) => {
  const response = await postLoginInit(Auth.login, body, appid);
  return response;
};

export const RegisterAction = async (data) => {
  const response = await postLoginInit(Auth.register, data);
  return response;
};

export const verifMerchant = async (data, id) => {
  const withId = `${Auth.verifMerchant}/${id}`;

  const response = await put(withId, data);
  return response;
};

export const getIpAddress = async () => {
  const response = await getIP();
  return response.data.data;
};
