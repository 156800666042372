import PropTypes from "prop-types";
import {
  Box,
  CircularProgress,
  Fade,
  LinearProgress,
  Typography,
} from "@mui/material";
import React from "react";

const MainLoading = ({
  loading,
  children,
  loader,
  height,
  customEmptyData,
  ...props
}) => {
  return (
    <Box
      sx={{
        position: "relative",
        minHeight: height,
        height: "100%",
        ...props.sx,
      }}
    >
      <Fade in={!loading}>
        <Box
          sx={{
            height: "100%",
          }}
        >
          {!loading && children}
          {customEmptyData && (
            <Typography
              variant="labelMedium2"
              component="div"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "200px",
              }}
            >
              Data Tidak Ditemukan
            </Typography>
          )}
        </Box>
      </Fade>
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: 0,
            right: 0,
            bottom: "50%",
            margin: "auto",
            zIndex: 9999,
            // marginTop: "10%",
            width: "300px",
          }}
        >
          {loader && (
            <LinearProgress
              sx={{
                ".MuiLinearProgress-bar": {
                  backgroundColor: "#3F4144",
                },
                backgroundColor: "#f6f6f6",
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

MainLoading.propTypes = {
  loading: PropTypes.bool,
  loader: PropTypes.bool,
  children: PropTypes.any,
  height: PropTypes.any,
  customEmptyData: PropTypes.node,
  sx: PropTypes.object,
};

MainLoading.defaultProps = {
  loading: false,
  children: null,
  height: 200,
  loader: true,
  customEmptyData: null,
  sx: null,
};

export default MainLoading;
