import React from "react";

const TicketIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3334 2.5H1.66669V7.11937L2.21814 7.47714C2.43333 7.62836 2.63493 7.84456 2.78544 8.09541C3.37708 9.08147 3.05734 10.3605 2.07127 10.9521L1.66669 11.1948V17.5H18.3334V11.214L17.7819 10.8562C17.5667 10.705 17.3651 10.4888 17.2146 10.2379C16.623 9.25186 16.9427 7.97288 17.9288 7.38124L18.3334 7.13849V2.5ZM16.6667 4.16667V6.2325L16.6172 6.27196C15.2048 7.43163 14.8171 9.48145 15.7854 11.0954L15.9071 11.2851C16.0767 11.5327 16.2753 11.7587 16.4982 11.9586L16.6667 12.1008V12.5176H15.8334V14.1843H16.6667V15.8333H3.33335V14.1843H4.16669V12.5176H3.33335V12.0992L3.38288 12.0614C4.79524 10.9017 5.18298 8.85189 4.2146 7.23792L4.0929 7.04825C3.92331 6.80065 3.72479 6.57459 3.5018 6.37477L3.33335 6.23167V4.16667H16.6667ZM7.50002 14.1843H5.83335V12.5176H7.50002V14.1843ZM9.16669 14.1843H10.8334V12.5176H9.16669V14.1843ZM14.1667 14.1843H12.5V12.5176H14.1667V14.1843Z"
        fill="white"
      />
      <mask
        id="mask0_1375_17346"
        maskUnits="userSpaceOnUse"
        x="1"
        y="2"
        width="18"
        height="16"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3334 2.5H1.66669V7.11937L2.21814 7.47714C2.43333 7.62836 2.63493 7.84456 2.78544 8.09541C3.37708 9.08147 3.05734 10.3605 2.07127 10.9521L1.66669 11.1948V17.5H18.3334V11.214L17.7819 10.8562C17.5667 10.705 17.3651 10.4888 17.2146 10.2379C16.623 9.25186 16.9427 7.97288 17.9288 7.38124L18.3334 7.13849V2.5ZM16.6667 4.16667V6.2325L16.6172 6.27196C15.2048 7.43163 14.8171 9.48145 15.7854 11.0954L15.9071 11.2851C16.0767 11.5327 16.2753 11.7587 16.4982 11.9586L16.6667 12.1008V12.5176H15.8334V14.1843H16.6667V15.8333H3.33335V14.1843H4.16669V12.5176H3.33335V12.0992L3.38288 12.0614C4.79524 10.9017 5.18298 8.85189 4.2146 7.23792L4.0929 7.04825C3.92331 6.80065 3.72479 6.57459 3.5018 6.37477L3.33335 6.23167V4.16667H16.6667ZM7.50002 14.1843H5.83335V12.5176H7.50002V14.1843ZM9.16669 14.1843H10.8334V12.5176H9.16669V14.1843ZM14.1667 14.1843H12.5V12.5176H14.1667V14.1843Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1375_17346)">
        <rect width="20" height="20" fill="currentColor" />
      </g>
    </svg>
  );
};

export default TicketIcon;
