import PageTitle from "components/Page/PageTitle";
import MainCard from "components/Paper/MainCard";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import React, { useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import DasborCard from "./components/DasborCard";
import PPOB from "./icon/PPOB";
import TukarVoucher from "./icon/tukarVoucher";
import TukarKartuSubsidi from "./icon/tukarKartuSubsidi";
import Inventory from "./icon/inventory";
import { KeyboardArrowDown } from "@mui/icons-material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "#fff";
    tooltipEl.style.color = "#111";
    tooltipEl.style.border = "1px solid #f7f7f7";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.boxShadow = "0px 28px 28px -20px rgba(0, 0, 0, 0.25)";
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    // tooltipEl.style.transform = "translate(50%, 0)";
    tooltipEl.style.transition = "all 0.1s ease";

    const box = document.createElement("div");
    box.style.display = "flex";

    box.className = "myChart";
    box.style.flexDirection = "column";

    tooltipEl.appendChild(box);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;

  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement("div");

    tableHead.style.background = "#EBECED";
    // tableHead.style.borderTopLeftRadius = "6px";
    // tableHead.style.borderTopRightRadius = "6px";
    titleLines.forEach((title) => {
      const tr = document.createElement("div");
      tr.style.width = "100%";
      tr.style.fontSize = "14px";
      tr.style.fontWeight = "700";
      tr.style.padding = "5px 14px";

      const text = document.createTextNode(title);

      tr.appendChild(text);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement("div");
    tableBody.style.display = "flex";
    tableBody.style.flexDirection = "column";
    tableBody.style.paddingBottom = "5px";
    tableBody.style.paddingTop = "5px";

    tooltip.labelColors[0].borderColor = "#cc1d15";
    tooltip.labelColors[0].backgroundColor = "#cc1d15";
    tooltip.labelColors[1].borderColor = "#0C6CE2";
    tooltip.labelColors[1].backgroundColor = "#0C6CE2";

    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i];

      const listBox = document.createElement("div");
      listBox.style.display = "flex";
      listBox.style.alignItems = "center";
      listBox.style.gap = "3px";
      listBox.style.padding = "6px 11px";

      const span = document.createElement("span");
      span.style.background = colors.backgroundColor;
      span.style.borderColor = colors.borderColor;
      span.style.borderRadius = "30px";
      span.style.borderWidth = "2px";
      span.style.marginRight = "10px";
      span.style.height = "10px";
      span.style.width = "10px";
      span.style.display = "inline-block";

      const tr = document.createElement("div");
      tr.style.display = "flex";
      tr.style.alignItems = "center";

      const newText = body[0];

      const split = newText.split(":");

      // console.log(split)
      const span1 = document.createElement("span");
      const span2 = document.createElement("span");

      span1.style.fontWeight = "500";
      span1.style.fontSize = "12px";
      span2.style.fontSize = "12px";
      span2.style.fontWeight = "700";

      const span1Text = document.createTextNode(split[0] + ":");
      const span2Text = document.createTextNode(split[1]);

      span1.appendChild(span1Text);
      span2.appendChild(span2Text);

      tr.appendChild(span1);
      tr.appendChild(span2);

      listBox.appendChild(span);
      listBox.appendChild(tr);
      tableBody.appendChild(listBox);
    });

    const tableRoot = tooltipEl.querySelector(".myChart");

    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.x + "px";
  tooltipEl.style.top = positionY + tooltip.y + "px";
  tooltipEl.style.font = "Manrope";
};

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Mei",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const data = {
  labels,
  datasets: [
    {
      label: "Internet & TV Kabel",
      data: [0, 200, 100, 150, 60, 30, 200, 340, 180, 240, 180, 380],
      tension: 0.3,
      borderWidth: 1.5,
      borderColor: "#CC1D15",
      backgroundColor: "#CC1D15",
      pointBorderColor: "rgba(0, 0, 0, 0)",
      pointBackgroundColor: "rgba(0, 0, 0, 0)",
      pointHoverBackgroundColor: "#CC1D15",
      pointHoverBorderColor: "#CC1D15",
    },
    {
      label: "PDAM",
      data: [50, 100, 120, 190, 260, 160, 130, 110, 200, 360, 290, 190],
      tension: 0.3,
      borderWidth: 1.5,
      borderColor: "#0C6CE2",
      backgroundColor: "#0C6CE2",
      pointBorderColor: "rgba(0, 0, 0, 0)",
      pointBackgroundColor: "rgba(0, 0, 0, 0)",
      pointHoverBackgroundColor: "#0C6CE2",
      pointHoverBorderColor: "#0C6CE2",
      order: 1,
    },
  ],
};

const ButtonChart = () => {
  return (
    <Button
      // onClick={handleClick}
      variant="secondary-outlined"
      endIcon={
        <KeyboardArrowDown
          sx={{
            position: "absolute",
            right: 7,
            top: 0,
            bottom: 0,
            margin: "auto 0",
            // marginTop: 0.5,
          }}
        />
      }
      sx={{
        "&:after": {
          position: "absolute",
          content: '""',
          top: "0",
          left: "80px",
          backgroundColor: "#323232",
          width: "1px",
          height: "100%",
        },
        minWidth: "120px",
        position: "relative",
        justifyContent: "start",
        fontWeight: "bold",
        "&:hover": {
          "&:after": {
            backgroundColor: "#fff !important",
          },
        },
        height: "35px",
        paddingLeft: "10px",
      }}
    >
      Perbulan
    </Button>
  );
};
const ChartDasbor = () => {
  const [indexActive, setIndexActive] = useState(null);

  const {
    profile: { Fullname },
  } = useSelector((state) => state.profile);

  // useEffect(() => {
  //   console.log(indexActive);
  // }, [indexActive]);

  const options = {
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: "index",
    },
    onHover: (data) => {
      const index = data.chart._active;
      if (!index.length) {
        return;
      }
      setIndexActive(index[0]?.index);
    },
    plugins: {
      legend: {
        position: "bottom",
        align: "center",
        maxHeight: 100,
        labels: {
          padding: 20,
          boxWidth: 10,
          boxHeight: 10,
          useBorderRadius: true,
          borderRadius: 5,
          font: {
            size: 14,
            family: "Manrope",
            weight: "600",
          },
          color: "#000",
        },
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: externalTooltipHandler,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 200,
          font: {
            family: "Manrope",
            weight: "500",
          },
          color: "#4C4D4F",
        },
        border: {
          display: false,
        },
        beginAtZero: true,
      },
      x: {
        grid: {
          color: (data) => {
            if (data?.index === indexActive) {
              return "#202021";
            } else {
              return "#878889";
            }
          },
        },
        border: {
          display: false,
          dash: [5, 2],
        },
        ticks: {
          font: (data) => {
            if (data?.index === indexActive) {
              return {
                family: "Manrope",
                weight: "700",
              };
            } else {
              return {
                family: "Manrope",
                weight: "500",
              };
            }
          },
          color: "#4C4D4F",
        },
      },
    },
  };

  return (
    <>
      <PageTitle
        title={`Hallo ${Fullname || "-"}`}
        text={"Selamat Datang Kembali!"}
        backBtn={false}
      />

      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={3}>
          <DasborCard
            desc="Total Transaksi PPOB Kartu Subsidi"
            title="5.130"
            iconBackgroundColor="#cc1d15"
            icon={<PPOB />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DasborCard
            desc="Aktivitas Penukaran Voucer"
            title="125"
            iconBackgroundColor="#615CEA"
            icon={<TukarVoucher />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DasborCard
            desc="Aktivitas Penukaran Kartu Subsidi"
            title="1023"
            iconBackgroundColor="#129350"
            icon={<TukarKartuSubsidi />}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DasborCard
            desc="Inventory barang stok daily"
            title="3489"
            iconBackgroundColor="#0C6CE2"
            icon={<Inventory />}
          />
        </Grid>
      </Grid>

      <MainCard
        title="Transaksi PPOB Kartu Subsidi"
        addButton={false}
        custom={<ButtonChart />}
      >
        <Box p={2} height="304px">
          <Line
            options={options}
            data={data}
            onMouseLeave={() => setIndexActive(null)}
          />
        </Box>
      </MainCard>
    </>
  );
};

export default ChartDasbor;
