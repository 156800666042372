import React from "react";
import PropTypes from "prop-types";
import StatusChip from "./StatusChip";

const color = [
  {
    label: "Diajukan",
    background: "#f7efd9",
    color: "#ff6f05",
    value: 0,
  },
  {
    label: "Disetujui",
    background: "#e9f2e9",
    color: "#2c8424",
    value: 1,
  },
  {
    label: "Dipublikasikan",
    background: "#e6f0fc",
    color: "#0c6ce2",
    value: 2,
  },
  {
    label: "Dibatalkan",
    background: "#f5e3e3",
    color: "#eb5151",
    value: 3,
  },
  {
    label: "Dikeluarkan",
    background: "#ececec",
    color: "#4e5d78",
    value: 4,
  },
  {
    label: "Ditunda",
    background: "#ececec",
    color: "#4e5d78",
    value: 5,
  },
  {
    label: "Diakhiri",
    background: "#ececec",
    color: "#4e5d78",
    value: 6,
  },
];

const find = (value, text) => {
  return color.find((i) => i.value === value)?.[text] || "-";
};

const StatusKartuSubsidi = ({ value }) => (
  <StatusChip
    label={find(value, "label")}
    color={find(value, "color")}
    background={find(value, "background")}
  />
);

StatusKartuSubsidi.defaultProps = {
  value: 0,
  arr: [],
  type: 1,
};

StatusKartuSubsidi.propTypes = {
  value: PropTypes.number,
  arr: PropTypes.array,
  type: PropTypes.number,
};

export default StatusKartuSubsidi;
