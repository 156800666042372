import React from "react";

const OfflineTransaksi = () => {
  return (
    <svg
      width="36"
      height="40"
      viewBox="0 0 36 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5342 10.6999V32.5666C31.5342 33.2499 31.3262 33.8166 30.925 34.2833C30.5239 34.7666 30.0187 34.9999 29.4393 34.9999H19.0539C18.4745 34.9999 17.9693 34.7666 17.5681 34.2833C17.167 33.7999 16.959 33.2499 16.959 32.5666V10.6999C16.959 10.0166 17.167 9.44993 17.5681 8.98327C17.9693 8.49993 18.4745 8.2666 19.0539 8.2666H29.4393C30.0187 8.2666 30.5239 8.49993 30.925 8.98327C31.3262 9.4666 31.5342 10.0166 31.5342 10.6999Z"
        fill="#F7B500"
      />
      <mask
        id="mask0_2578_95275"
        maskUnits="userSpaceOnUse"
        x="16"
        y="8"
        width="16"
        height="27"
      >
        <path
          d="M31.5342 10.6999V32.5666C31.5342 33.2499 31.3262 33.8166 30.925 34.2833C30.5239 34.7666 30.0187 34.9999 29.4393 34.9999H19.0539C18.4745 34.9999 17.9693 34.7666 17.5681 34.2833C17.167 33.7999 16.959 33.2499 16.959 32.5666V10.6999C16.959 10.0166 17.167 9.44993 17.5681 8.98327C17.9693 8.49993 18.4745 8.2666 19.0539 8.2666H29.4393C30.0187 8.2666 30.5239 8.49993 30.925 8.98327C31.3262 9.4666 31.5342 10.0166 31.5342 10.6999Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2578_95275)">
        <rect
          x="15.8442"
          y="32.6166"
          width="23.772"
          height="3.33333"
          fill="#DFA513"
        />
      </g>
      <rect
        x="19.3066"
        y="14.2832"
        width="9.90996"
        height="14.7"
        fill="#EB4A42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4648 20.0832V19.1832H25.249V20.0832H20.4648ZM20.4648 20.9666V21.8666H25.249V20.9666H20.4648ZM20.4648 22.7499V23.6499H23.6592V22.7499H20.4648ZM24.3724 23.4166L25.7839 24.9999H25.7987L28.0571 22.4666L27.4925 21.8166L25.7987 23.7166L24.937 22.7832L24.3724 23.4166Z"
        fill="white"
      />
      <path
        d="M19.3211 7.43333V29.3C19.3211 29.9833 19.1131 30.55 18.7119 31.0167C18.3108 31.5 17.8056 31.7333 17.2262 31.7333H6.85565C6.27621 31.7333 5.77105 31.5 5.3699 31.0167C4.96875 30.5333 4.76074 29.9833 4.76074 29.3V7.43333C4.76074 6.75 4.96875 6.18333 5.3699 5.71667C5.77105 5.23333 6.27621 5 6.85565 5H17.2411C17.8205 5 18.3256 5.23333 18.7268 5.71667C19.128 6.2 19.336 6.75 19.336 7.43333H19.3211Z"
        fill="#EB4A42"
      />
      <mask
        id="mask1_2578_95275"
        maskUnits="userSpaceOnUse"
        x="4"
        y="5"
        width="16"
        height="27"
      >
        <path
          d="M19.3211 7.43333V29.3C19.3211 29.9833 19.1131 30.55 18.7119 31.0167C18.3108 31.5 17.8056 31.7333 17.2262 31.7333H6.85565C6.27621 31.7333 5.77105 31.5 5.3699 31.0167C4.96875 30.5333 4.76074 29.9833 4.76074 29.3V7.43333C4.76074 6.75 4.96875 6.18333 5.3699 5.71667C5.77105 5.23333 6.27621 5 6.85565 5H17.2411C17.8205 5 18.3256 5.23333 18.7268 5.71667C19.128 6.2 19.336 6.75 19.336 7.43333H19.3211Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_2578_95275)">
        <rect
          width="1.48575"
          height="28.3333"
          transform="matrix(-1 0 0 1 19.9756 4.05005)"
          fill="#D63129"
        />
      </g>
      <rect
        x="7.09375"
        y="11.0166"
        width="9.90996"
        height="14.7"
        fill="#FCC039"
      />
      <path
        d="M10.0948 16.1834H10.3919V16.5167H10.0948V16.1834Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.78711 14.7167V17.9667H11.6843V14.7167H8.78711ZM9.51523 17.15V15.5333H10.9564V17.15H9.51523Z"
        fill="white"
      />
      <path
        d="M12.3978 18.7667H13.4824V19.5833H12.3978V18.7667Z"
        fill="white"
      />
      <path
        d="M13.4821 19.5834H14.2101V18.7667H15.2947V19.9834H14.2101V20.3834H14.5667V20.7834H15.2947V22.0001H14.5667V21.1834H13.8386V22.0001H12.3975V20.3834H13.1255V21.2001H13.4821V19.5834Z"
        fill="white"
      />
      <path
        d="M13.9876 16.1834H13.6905V16.5167H13.9876V16.1834Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3975 17.9667V14.7167H15.2947V17.9667H12.3975ZM14.5664 17.15V15.5333H13.1253V17.15H14.5664Z"
        fill="white"
      />
      <path
        d="M10.0948 20.2333H10.3919V20.5667H10.0948V20.2333Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.78711 18.7667V22.0167H11.6843V18.7667H8.78711ZM9.51523 21.2001V19.5834H10.9564V21.2001H9.51523Z"
        fill="white"
      />
    </svg>
  );
};

export default OfflineTransaksi;
