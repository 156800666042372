import PropTypes from "prop-types";
import { Typography, Grow, Box } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";

const TableDate = ({ days }) => {
  const labels = [];
  for (let i = 0; i <= 6; i++) {
    labels.push(
      <Typography variant="gridLabel">
        {moment().day(i).format("dd")}
      </Typography>
    );
  }


  return (
    <Box
      component={"table"}
      sx={{
        tableLayout: "fixed",
        marginTop: "10px",
      }}
    >
      <thead>
        <tr>
          {labels.map((label, i) => (
            <Box component="td" key={i} textAlign="center">
              {label}
            </Box>
          ))}
        </tr>
        <tr>
          <Box
            component="td"
            sx={{
              padding: "8px 5px !important",
              backgroundColor: "#fff",
            }}
            colSpan={7}
          ></Box>
        </tr>
      </thead>
      {/* <Grow in={!loading} timeout={300}> */}
      <Box component="tbody">
        {days.map((tr, i) => (
          <Fragment key={i}>
            <tr>
              {tr.map((td, inde) => (
                <Fragment key={inde}>{td}</Fragment>
              ))}
            </tr>
            <Box
              component="tr"
              sx={{
                "&:last-child": {
                  display: "none",
                },
              }}
            >
              <Box
                component="td"
                sx={{
                  padding: "8px 5px !important",
                  backgroundColor: "#fff",
                }}
                colSpan={7}
              ></Box>
            </Box>
          </Fragment>
        ))}
      </Box>
      {/* </Grow> */}
    </Box>
  );
};

TableDate.propTypes = {
  days: PropTypes.any,
  loading: PropTypes.bool,
};

TableDate.defaultProps = {
  days: [],
  loading: false,
};

export default TableDate;
