import MainDialog from "components/Dialog/MainDialog";
import React from "react";
import PropTypes from "prop-types";
import MainGrid from "components/Grid/MainGrid";

const DetailTransaksi = ({ open, onClose, detail }) => {
  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
      }}
      title="Detail Transaksi"
      width="sm"
      action={false}
    >
      {detail.map((detail, i) => (
        <MainGrid
          key={i}
          label={detail.name}
          value={detail.value}
          labelMd={5}
          valueMd={7}
        />
      ))}
    </MainDialog>
  );
};

DetailTransaksi.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  detail: PropTypes.any,
};

DetailTransaksi.defaultProps = {
  onClose: () => {},
  open: false,
  detail: [],
};

export default DetailTransaksi;
