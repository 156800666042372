import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const select = (state) => state.voucherBansosPenerima || initialState;

export const List = createSelector(select, (p) => p.list);
export const Loading = createSelector(select, (p) => p.loading);
export const Params = createSelector(select, (p) => p.params);
export const TotalItems = createSelector(select, (p) => p.totalItems);
export const CurrentPage = createSelector(select, (p) => p.currentPage);
export const FilterName = createSelector(select, (p) => p.filterName);

export const Fail = createSelector(select, (p) => p.fail);
export const Semua = createSelector(select, (p) => p.semua);
export const TidakDitemukan = createSelector(select, (p) => p.tidakDitemukan);
export const TidakSama = createSelector(select, (p) => p.tidakSama);
export const Unverif = createSelector(select, (p) => p.unverif);
export const Verif = createSelector(select, (p) => p.verif);
export const NamaProgram = createSelector(select, (p) => p.namaProgram);
