import PropTypes from "prop-types";
import { Box, Button, Checkbox, Divider, Typography } from "@mui/material";
import React from "react";

const CardProduct = ({ title, desc, onChange }) => {
  return (
    <Box sx={{ border: "1px solid #C8C8C8", borderRadius: "6px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          minHeight: "150px",
        }}
      >
        <Typography
          variant="cardTitle3"
          p={2}
          borderBottom="1px solid #c8c8c8"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {title}
          <Checkbox sx={{ padding: "0px" }} />
        </Typography>
        <Typography variant="cardText2" p={2} minHeight="130px">
          {desc}
        </Typography>
      </Box>
      
      <Button
        variant="text"
        sx={{
          display: "flex",
          width: "auto",
          marginLeft: "auto",
          mb: 2,
          mr: 2,
          color: "#cc1d15",
        }}
        onClick={onChange}
      >
        Pelajari Lebih Lanjut
      </Button>
    </Box>
  );
};

CardProduct.propTypes = {
  desc: PropTypes.any,
  title: PropTypes.any,
  onChange: PropTypes.func,
};

CardProduct.defaultProps = {
  desc: PropTypes.string,
  title: PropTypes.string,
  onChange: null,
};

export default CardProduct;
