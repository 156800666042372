import { get, getParams, post } from "api";
import { Merchant } from "api/endpoints";

export const registerMerchant = async (data) => {
  const response = await post(Merchant.registerMerchant, data);

  return response;
};

export const detailMerchant = async (data) => {
  const response = await get(Merchant.detailUserMerchant, data);

  return response;
};

export const getMerchantList = async (params) => {
  const response = await getParams(Merchant.list, params);
  return response;
};
