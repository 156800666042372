import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from "@mui/material";
import MainDialog from "components/Dialog/MainDialog";
import React from "react";
import ImgVoucher from "../ImageVoucher";
import PropTypes from "prop-types";
import { ExpandMore } from "@mui/icons-material";

const DetailVoucher = ({
  open,
  onClose,
  dataVoucher,
  QRClick,
  handleRedeem,
}) => {
  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
      }}
      title="Detail Voucer"
      width="sm"
      action={false}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 3,
        }}
      >
        <Box
          sx={{
            width: "376px",
            height: "224px",
          }}
        >
          <ImgVoucher dataVoucher={dataVoucher} />
        </Box>
      </Box>
      <Accordion disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="gridLabel">Deskripsi Voucer</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="gridValue"
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: dataVoucher?.VoucherDesc }}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="gridLabel">Syarat & Ketentuan</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="gridValue"
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: dataVoucher?.VoucherTC }} />
          </Typography>
        </AccordionDetails>
      </Accordion>

      {dataVoucher?.Status === 1 && (
        <Button
          variant="primary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleRedeem}
        >
          Tukar Voucer
        </Button>
      )}
      <Button
        variant="secondary-outlined"
        fullWidth
        sx={{ mt: 2 }}
        onClick={QRClick}
      >
        atau pindai &nbsp;
        <Box
          component="span"
          sx={{
            color: "#cc1d15",
            fontFamily: "inherit !important",
            fontWeight: "bold",
            transition: "all 0.3s",
            "&:hover": {
              color: "#fff !important",
            },
          }}
        >
          QR Code
        </Box>
      </Button>
    </MainDialog>
  );
};

DetailVoucher.propTypes = {
  onClose: PropTypes.func,
  QRClick: PropTypes.func,
  open: PropTypes.bool,
  dataVoucher: PropTypes.any,
  handleRedeem: PropTypes.func,
};

DetailVoucher.defaultProps = {
  onClose: () => {},
  QRClick: () => {},
  open: false,
  dataVoucher: {},
  handleRedeem: () => {},
};

export default DetailVoucher;
