import React from "react";
import Providers from "components/RouteRoleProviders";
import Voucher from "pages/MasterData/MasterBantuan/Voucher";
import DetailBansosVoucher from "pages/MasterData/MasterBantuan/Voucher/DetailBansosVoucher";

const voucherRoutes = [
  {
    path: "/master-data/master-bantuan/voucer",
    element: (
      <Providers permission="Web:MasterData:MasterBantuan:Voucer:Get" >
        <Voucher />
      </Providers>
    ),
  },
  {
    path: "/master-data/master-bantuan/voucer/:id",
    element: (
      <Providers permission="Web:MasterData:MasterBantuan:Voucer:Get">
        <DetailBansosVoucher />
      </Providers>
    ),
  },
];

export default voucherRoutes;
