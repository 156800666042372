import PropTypes from "prop-types";
import {
  Check,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { Box, Typography, Collapse } from "@mui/material";
import React, { forwardRef } from "react";

const MainTemplate = forwardRef(
  ({ onChange, chooseTemplate, selected, list, onClick }, ref) => {
    return (
      <>
        <Box
          ref={ref}
          sx={{
            display: "flex",
          }}
        >
          <Typography variant="label">atau pilih &nbsp;</Typography>
          <Typography
            component={"span"}
            variant="label"
            sx={{
              color: "#cc1d15",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() => onClick(list[0])}
          >
            template
            {chooseTemplate ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
          </Typography>
        </Box>

        <Collapse in={chooseTemplate}>
          {chooseTemplate && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "30px",
              }}
            >
              <Box
                component="img"
                src={selected.value && selected?.image}
                sx={{
                  height: "100%",
                  minWidth: "200px",
                  maxWidth: "260px",
                  width: "100%",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  my: "auto",
                }}
              >
                <Typography variant="label">Palet Warna</Typography>
                <Typography variant="desc">
                  Pilih latar warna untuk kartu subsidi
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "8px",
                    marginTop: "15px",
                  }}
                >
                  {list.map((item, index) => (
                    <Box
                      sx={{
                        width: "32px",
                        borderRadius: "50%",
                        height: "32px",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        backgroundColor: item.color,
                      }}
                      //   onClick={() => SvgToFile(item)}
                      onClick={() => onChange(item)}
                      key={index}
                    >
                      {selected?.value === item.value && <Check />}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          )}
        </Collapse>
      </>
    );
  }
);

MainTemplate.displayName = "mt";

MainTemplate.propTypes = {
  chooseTemplate: PropTypes.any,
  list: PropTypes.any,
  onChange: PropTypes.any,
  onClick: PropTypes.func,
  selected: PropTypes.shape({
    image: PropTypes.any,
    value: PropTypes.any,
  }),
};

MainTemplate.defaultProps = {
  chooseTemplate: null,
  list: [],
  onChange: () => {},
  selected: null,
  onClick: null,
};

export default MainTemplate;
