import PropTypes from "prop-types";
import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";

const CardList = ({ title, desc, buttonText, onChange }) => {
  return (
    <Box sx={{ border: "1px solid #C8C8C8", borderRadius: "6px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          minHeight: "150px",
        }}
      >
        <Typography variant="cardTitle3" p={2} borderBottom="1px solid #c8c8c8">
          {title}
        </Typography>
        <Typography variant="cardText2" p={2} minHeight="130px">
          {desc}
        </Typography>
      </Box>

      {onChange && (
        <Button
          variant="primary"
          sx={{
            display: "flex",
            width: "auto",
            marginLeft: "auto",
            mb: 2,
            mr: 2,
          }}
          onClick={onChange}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
};

CardList.propTypes = {
  buttonText: PropTypes.string,
  desc: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string,
};

CardList.defaultProps = {
  buttonText: "",
  desc: "",
  onChange: null,
  title: "",
};

export default CardList;
