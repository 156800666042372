import { Box, Grid, Typography } from "@mui/material";
import MainAutocomplete from "components/AutoComplete/MainAutocomplete";
import MainController from "components/Controller/MainController";
import VerticalGrid from "components/Grid/VerticalGrid";
import TextFieldv3 from "components/TextField/TextFieldv3";
import { KewarganegaraanData } from "pages/Dasbor/variable";
import React from "react";
import PropTypes from "prop-types";

const Form1 = ({ controller: Controller, control, errors, clearErrors }) => {
  return (
    <Grid container spacing={3} mt={1}>
      <VerticalGrid label={"Alamat Pemilik"} md={12}>
        <MainController
          controller={Controller}
          name="BizAddress"
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          rules={{ required: "Alamat Pemilik wajib diisi" }}
          render={({ field }) => (
            <TextFieldv3
              {...field}
              placeholder="Masukkan alamat pemilik bisnis"
            />
          )}
        />
      </VerticalGrid>
      <VerticalGrid label={"Kewarganegaraan"}>
        <MainController
          controller={Controller}
          name="BizRegion"
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          rules={{ required: "Kewarganegaraan wajib diisi" }}
          render={({ field }) => (
            <MainAutocomplete
              {...field}
              typefield={1}
              options={KewarganegaraanData}
              value={
                KewarganegaraanData.find(
                  (item) => item.value === field.value
                ) || null
              }
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onChange={(_, nv) => {
                if (nv == null) {
                  field.onChange(null);
                }
                field.onChange(nv.value);
              }}
              placeholder="Pilih kewarganegaraan"
            />
          )}
        />
      </VerticalGrid>
      <VerticalGrid label={"Kode Pos"}>
        <MainController
          controller={Controller}
          name="BizPostCode"
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          rules={{
            required: "Kode Pos wajib diisi",
            maxLength: {
              message: "Kode Pos tidak boleh melebihi 5 karakter",
              value: 5,
            },
          }}
          render={({ field }) => (
            <TextFieldv3
              {...field}
              name="phone"
              placeholder="Masukkan kode pos"
            />
          )}
        />
      </VerticalGrid>
      <VerticalGrid label={"Nomor Ponsel Pemilik"}>
        <Box display="flex" gap={1}>
          <Box
            sx={{
              backgroundColor: "#EBECED",
              alignItems: "center",
              display: "flex",
              alignItems: "center",
              width: "50px",
              height: "45px",
              borderRadius: "4px",
              justifyContent: "center",
            }}
          >
            <Typography variant="labelMedium2">+62</Typography>
          </Box>
          <Box display="flex" flexDirection="column" flex={1}>
            <MainController
              controller={Controller}
              name="BizPhone"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              rules={{
                required: "Nomor Ponsel Pemilik wajib diisi",
              }}
              render={({ field }) => (
                <TextFieldv3
                  {...field}
                  name="phone"
                  type="number"
                  placeholder="Masukkan nomor ponsel"
                />
              )}
            />
          </Box>
        </Box>
      </VerticalGrid>
    </Grid>
  );
};

Form1.propTypes = {
  clearErrors: PropTypes.any,
  control: PropTypes.any,
  controller: PropTypes.any,
  errors: PropTypes.any,
};

Form1.defaultProps = {
  setValue: null,
  clearErrors: null,
  control: null,
  controller: null,
  errors: null,
};

export default Form1;
