import { createAsyncThunk } from "@reduxjs/toolkit";
import { getParams, post, get, put } from "api";
import { Voucher } from "api/endpoints";

export const getListVoucher = createAsyncThunk(
  "voucher/getVoucher",
  async (params) => {
    const response = await getParams(Voucher.list, params);
    return response.data.data;
  }
);

export const getVoucher = async (params) => {
  const response = await getParams(Voucher.list, params);

  return response;
};

export const voucherNew = async (data) => {
  const response = await post(Voucher.action, data);

  return response;
};

export const voucherUpdate = async (data, id) => {
  const withId = `${Voucher.action}/${id}`;
  const response = await put(withId, data);
  return response;
};

export const getDetailVoucerBansos = async (id) => {
  const withId = `${Voucher.action}/${id}`;

  const response = await get(withId);

  return response;
};

export const validasiImport = async (data) => {
  const response = await post(Voucher.validasiImport, data);
  return response;
};

export const multiVoucer = async (data) => {
  const response = await post(Voucher.multiVoucher, data);
  return response;
};
