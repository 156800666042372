import {
  Avatar,
  Box,
  Button,
  Drawer,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCookie, removeCookie } from "helper/cokkie";
import { AppBar } from "./style";
import Logo from "assets/layout/logo";
import { getPhoto, getProfile } from "redux/profile/action";
import { getIpAddress } from "redux/auth/actions";
import { alert } from "redux/global/slice";

const isLogin = getCookie("token-ntt-webadmin");
const PrivateRoutev2 = () => {
  const dispatch = useDispatch();
  const [openRight, setOpenRight] = useState(false);
  const navigate = useNavigate();
  const {
    photo,
    profile: { Fullname, role, Email },
  } = useSelector((state) => state.profile);

  if (!isLogin) {
    return window.location.replace("/auth/login");
  }

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getPhoto());
    getIpAddress();
    let isRegistered = JSON.parse(localStorage.getItem("profile-ntt"));

    if (isRegistered.bussinesRegistered != 1) {
      return navigate("/");
    }

    let token = localStorage.getItem("token-ntt-webadmin");
    let account;
    if (token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      account = JSON.parse(jsonPayload);

      localStorage.setItem(
        "permission-ntt",
        JSON.stringify(account.Permission)
      );
    }
  }, []);

  const Logout = () => {
    removeCookie("token-ntt-webadmin");
    localStorage.clear();
    // navigate("/auth/login");
    dispatch(
      alert({
        open: true,
        message: "Logout Berhasil",
      })
    );
    navigate(`/auth/login`);
  };

  return (
    <>
      <Box display="flex">
        <AppBar position="fixed" open={false}>
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              minHeight: {
                lg: "75px",
              },
              zIndex: "200",
            }}
          >
            <Box display="flex" gap={1} alignItems="center">
              <Logo />
              <Typography variant="sidebarTitle" fontSize="20px">
                Web Merchant
              </Typography>
            </Box>

            <Button
              variant="text"
              sx={{
                textTransform: "none",
                display: "flex",
                justifyContent: "space-evenly",
                gap: "20px",
                minWidth: "200px",
              }}
              onClick={() => setOpenRight(true)}
            >
              <Avatar alt="profile-photo" src={photo} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                <Typography variant="textMedium">{Fullname || "-"}</Typography>
                <Typography variant="textMediumV2">{role || "-"}</Typography>
              </Box>
              <KeyboardArrowDownIcon sx={{ color: "#3F4144" }} />
            </Button>
          </Toolbar>
        </AppBar>

        <Box
          component="main"
          sx={{
            flexGrow: "1",
            paddingTop: "100px",
            backgroundColor: "#f6f6f6",
            // minHeight: "100vh",
            minHeight: "100vh",
            paddingBottom: "30px",
          }}
        >
          <Box
            sx={{
              // minHeight: "calc(100vh - 190px)",
              height: "100%",
              paddingLeft: "70px",
              paddingRight: "70px",
            }}
          >
            <Outlet />
          </Box>
          <Box
            sx={{
              display: "flex",
              height: "50px",
              backgroundColor: "#fff",
              marginTop: "30px",
              justifyContent: "space-between",
              px: 3,
              paddingLeft: "70px",
              paddingRight: "70px",
              alignItems: "center",
            }}
          >
            <Typography variant="textMedium">
              V{process.env.REACT_APP_APP_VERSION}
            </Typography>
            <Typography variant="textMedium">
              2022 © &nbsp;
              <a
                href="https://www.inisa.id/"
                target="_blank"
                style={{ color: "#cc1d15", fontFamily: "inherit" }}
                rel="noreferrer"
              >
                INISA
              </a>
              &nbsp;| PT Digital Pemerintahan Indonesia
            </Typography>
          </Box>
        </Box>
      </Box>

      <Drawer
        anchor={"right"}
        open={openRight}
        onClose={() => setOpenRight(false)}
        PaperProps={{ sx: { width: "300px", zIndex: "200" } }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "30px",
            }}
          >
            <Avatar
              alt="profile-photo"
              src={photo}
              sx={{ margin: "auto", width: 56, height: 56 }}
            />
            <Typography
              variant="textMedium"
              sx={{
                fontSize: "16px",
                fontWeight: "800",
                color: "#3F4144",
                textAlign: "center",
              }}
            >
              {Fullname}
            </Typography>
            <Typography
              variant="textMediumV2"
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "center",
                color: "#3F4144",
              }}
            >
              {Email}
            </Typography>
          </Box>
        </Box>

        <Button
          variant="primary"
          type="button"
          sx={{ margin: "auto 15px 50px 15px", padding: "15px" }}
          onClick={Logout}
        >
          Logout
        </Button>
      </Drawer>
    </>
  );
};

export default PrivateRoutev2;
