const FormatRupiah = (angka, prefix = ".") => {
  let number_string = String(angka)
      .replace(/[^,\d]/g, "")
      .toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  if (ribuan) {
    var separator = sisa ? prefix : "";
    rupiah += separator + ribuan.join(prefix);
  }

  rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;

  return parseInt(angka) <= 0 ? `-${rupiah}` : rupiah;
};

export default FormatRupiah;
