import PropTypes from "prop-types";
import React from "react";
import { getCookie } from "helper/cokkie";
import { Box, Grid, Typography } from "@mui/material";
import InisaLogo from "assets/login/new-inisa-logo.png";

const isLogin = getCookie("token-ntt-webadmin");
const PublicRoute = ({ ilustration, title, desc, children }) => {
  if (isLogin) {
    return window.location.replace("/");
  }

  const styles = {
    height: {
      lg: "554px",
      xl: "700px",
    },
    marginLeft: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: "auto",
    objectFit: "contain",
  };

  return (
    <Grid container sx={{ height: "100vh", width: "100%" }}>
      <Grid
        item
        sx={{
          display: {
            xs: "none",
            md: "block",
          },
          // backgroundColor: "#F5F8F9",
        }}
        md={6}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: "1.5em",
            mt: "1.2em",
            position: "relative ",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              component={"img"}
              sx={{
                width: "62px",
                objectFit: "contain",
              }}
              src={InisaLogo}
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="loginTitle">Web Merchant</Typography>
            </Box>
          </Box>
        </Box>
        <Box display="flex" height="calc(100% - 110px)">
          <Box component={"img"} src={ilustration} sx={styles} />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyContent: "center",
            marginTop: "80px",
            height: "calc(100% - 110px)",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // maxWidth: "530px",
              width: "70%",
            }}
          >
            <Typography variant="loginFormTitle" id="halo">
              {title}
            </Typography>
            <Typography variant="text2">{desc}</Typography>

            {children}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

PublicRoute.propTypes = {
  children: PropTypes.node,
  ilustration: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
};

PublicRoute.defaultProps = {
  children: null,
  ilustration: null,
  title: "",
  desc: "",
};

export default PublicRoute;
