import React from "react";

const WarningIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_766_58821)">
        <path
          d="M36.875 53.25C36.875 51.5241 38.2741 50.125 40 50.125C41.7259 50.125 43.125 51.5241 43.125 53.25V53.75C43.125 55.4759 41.7259 56.875 40 56.875C38.2741 56.875 36.875 55.4759 36.875 53.75V53.25ZM36.875 26.25C36.875 24.5241 38.2741 23.125 40 23.125C41.7259 23.125 43.125 24.5241 43.125 26.25V40.25C43.125 41.9759 41.7259 43.375 40 43.375C38.2741 43.375 36.875 41.9759 36.875 40.25V26.25Z"
          fill="#F5B419"
        />
        <circle cx="40" cy="40" r="38" stroke="#F5B419" strokeWidth="4" />
      </g>
      <defs>
        <clipPath id="clip0_766_58821">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WarningIcon;
