import React from "react";

const NoImageIcon = () => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1940_7820)">
        <path
          d="M64 117C93.2711 117 117 93.2711 117 64C117 34.7289 93.2711 11 64 11C34.7289 11 11 34.7289 11 64C11 93.2711 34.7289 117 64 117Z"
          fill="#EEEEEE"
        />
        <path
          d="M85.36 41.3501H42.65C40.7888 41.3501 39.28 42.8589 39.28 44.7201V83.0901C39.28 84.9513 40.7888 86.4601 42.65 86.4601H85.36C87.2212 86.4601 88.73 84.9513 88.73 83.0901V44.7201C88.73 42.8589 87.2212 41.3501 85.36 41.3501Z"
          fill="#DADADA"
        />
        <path
          d="M70.4201 58.1699L58.5601 75.2799L51.9301 65.7099L39.3601 83.8499C39.7101 85.3399 41.0401 86.4599 42.6401 86.4599H85.3501C86.8001 86.4599 88.0201 85.5399 88.5001 84.2599L70.4201 58.1699Z"
          fill="#D0D0D0"
        />
        <path
          d="M59.0801 63.0499C61.6869 63.0499 63.8001 60.9366 63.8001 58.3299C63.8001 55.7231 61.6869 53.6099 59.0801 53.6099C56.4733 53.6099 54.3601 55.7231 54.3601 58.3299C54.3601 60.9366 56.4733 63.0499 59.0801 63.0499Z"
          fill="#D0D0D0"
        />
        <path
          d="M34.16 50.2598H7.37001C6.20469 50.2598 5.26001 51.2044 5.26001 52.3698V76.4398C5.26001 77.6051 6.20469 78.5498 7.37001 78.5498H34.16C35.3253 78.5498 36.27 77.6051 36.27 76.4398V52.3698C36.27 51.2044 35.3253 50.2598 34.16 50.2598Z"
          fill="#DADADA"
        />
        <path
          d="M24.8001 60.8101L17.3601 71.5401L13.2001 65.5401L5.32007 76.9201C5.54007 77.8601 6.37007 78.5601 7.38007 78.5601H34.1601C35.0701 78.5601 35.8401 77.9801 36.1401 77.1801L24.8001 60.8201V60.8101Z"
          fill="#D0D0D0"
        />
        <path
          d="M17.68 63.8702C19.3147 63.8702 20.64 62.545 20.64 60.9102C20.64 59.2754 19.3147 57.9502 17.68 57.9502C16.0452 57.9502 14.72 59.2754 14.72 60.9102C14.72 62.545 16.0452 63.8702 17.68 63.8702Z"
          fill="#D0D0D0"
        />
        <path
          d="M120.62 50.2598H93.83C92.6647 50.2598 91.72 51.2044 91.72 52.3698V76.4398C91.72 77.6051 92.6647 78.5498 93.83 78.5498H120.62C121.785 78.5498 122.73 77.6051 122.73 76.4398V52.3698C122.73 51.2044 121.785 50.2598 120.62 50.2598Z"
          fill="#DADADA"
        />
        <path
          d="M111.26 60.8101L103.82 71.5401L99.66 65.5401L91.78 76.9201C92 77.8601 92.83 78.5601 93.84 78.5601H120.62C121.53 78.5601 122.3 77.9801 122.6 77.1801L111.26 60.8201V60.8101Z"
          fill="#D0D0D0"
        />
        <path
          d="M104.14 63.8702C105.775 63.8702 107.1 62.545 107.1 60.9102C107.1 59.2754 105.775 57.9502 104.14 57.9502C102.505 57.9502 101.18 59.2754 101.18 60.9102C101.18 62.545 102.505 63.8702 104.14 63.8702Z"
          fill="#D0D0D0"
        />
        <path
          d="M64 100.45C71.3178 100.45 77.25 94.518 77.25 87.2002C77.25 79.8824 71.3178 73.9502 64 73.9502C56.6822 73.9502 50.75 79.8824 50.75 87.2002C50.75 94.518 56.6822 100.45 64 100.45Z"
          fill="#969696"
        />
        <path
          d="M65.24 85.8999H69.1901V88.2099H65.24V92.7199H62.78V88.2099H58.8V85.8999H62.78V81.6899H65.24V85.8999Z"
          fill="#DADADA"
        />
        <path
          d="M84.51 52.8599C84.51 49.2999 81.76 46.3899 78.26 46.1299C78.09 46.1099 77.93 46.1099 77.76 46.1099C74.03 46.1099 71.01 49.1299 71.01 52.8599C71.01 52.9699 71.01 53.0799 71.01 53.1899C71.01 56.6999 73.98 59.6699 77.77 63.4099C77.94 63.2399 78.11 63.0799 78.27 62.9099C81.93 59.2899 84.7 56.3599 84.52 52.8599H84.51ZM78.26 56.5599C78.1 56.5799 77.93 56.5999 77.76 56.5999C75.76 56.5999 74.14 54.9799 74.14 52.9799C74.14 50.9799 75.76 49.3599 77.76 49.3599C77.93 49.3599 78.1 49.3699 78.26 49.3999C80.02 49.6399 81.37 51.1599 81.37 52.9799C81.37 54.7999 80.01 56.3199 78.26 56.5599Z"
          fill="#969696"
        />
      </g>
      <defs>
        <clipPath id="clip0_1940_7820">
          <rect width="128" height="128" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NoImageIcon;
