import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React, { useRef, useState } from "react";
import { Box, Grid } from "@mui/material";
import QRScanner from "components/Camera/QRScanner";
import AlertDialog from "components/Dialog/AlertDialog";
import ScanGagal from "assets/redeem/ScanGagal";
import { useNavigate } from "react-router-dom";
import useFetchData from "hook/useFetchData";
import PembayaranBerhasil from "assets/redeem/PembayaranBerhasil";
import { redeemSubsidiBansos } from "redux/redeemSubsidi/action";
import FormatRupiah from "helper/FormatRupiah";

const KonfirmasiManual = ({ open, onClose }) => {
  const navigate = useNavigate();

  const refScanner = useRef(null);
  const kartuSubsidiName = useRef("");
  const totalTagihan = useRef("");

  const [openDialog, setOpenDialog] = useState({
    success: false,
    fail: false,
    notFound: false,
  });

  const action = useFetchData({
    action: redeemSubsidiBansos,
    message: "Berhasil redeem subsidi",
    onSuccess: () => {
      onClose();
      setOpenDialog({
        ...openDialog,
        success: true,
      });
    },
    customMessageError: (res) => res?.data?.message,
    onError: () => {
      setOpenDialog({
        ...openDialog,
        fail: true,
      });
    },
  });

  return (
    <>
      <MainDialog
        open={open}
        onClose={() => {
          onClose();
          refScanner.current.stop();
        }}
        title={"Konfirmasi Manual"}
        loading={false}
        valid={true}
        onReset={() => {
          reset();
        }}
        action={false}
        customWidth="500px"
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ position: "relative" }}>
              <QRScanner
                noScan={openDialog.success}
                onSuccess={({ data }, qrScanner) => {
                  if (data) {
                    refScanner.current = qrScanner;
                    const { Id } = JSON.parse(
                      localStorage.getItem("profile-ntt")
                    );
                    const split = data.split("-");

                    kartuSubsidiName.current = split[3];
                    totalTagihan.current = split[1];

                    if (split[0] == Id) {
                      const formData = new FormData();
                      formData.append("qr", split[2]);
                      formData.append("amount", split[1]);
                      formData.append("fee", "0");
                      action.fetch(formData);
                    } else {
                      setOpenDialog({
                        ...openDialog,
                        notFound: true,
                      });
                    }

                    qrScanner.stop();
                  }
                  setOpenDialog({
                    ...openDialog,
                    success: true,
                  });
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </MainDialog>

      <AlertDialog
        buttonSX={{
          minWidth: "100%",
          minHeight: "50px",
        }}
        customWidth="329px"
        onClose={() => {
          setOpenDialog({
            ...openDialog,
            success: false,
          });
          refScanner.current.start();
        }}
        open={openDialog.success}
        alertTitle="Pembayaran Dikonfirmasi"
        alertDesc={`Pembayaran sejumlah Rp ${FormatRupiah(
          totalTagihan.current
        )} dari ${kartuSubsidiName.current}`}
        submitText="Lihat Detail Transaksi"
        onSubmit={() => {
          setOpenDialog({
            ...openDialog,
            success: false,
          });
          navigate(`/redeem-subsidi/subsidi-bansos/${action.response.TrxId}`);
        }}
        customIcon={<PembayaranBerhasil />}
      />

      <AlertDialog
        buttonSX={{
          minWidth: "100%",
          minHeight: "50px",
        }}
        customWidth="329px"
        open={openDialog.fail}
        alertTitle="Scan Gagal"
        submitText="Scan Ulang"
        onSubmit={() => {
          setOpenDialog({
            ...openDialog,
            fail: false,
          });
        }}
        onClose={() => {
          setOpenDialog({
            ...openDialog,
            fail: false,
          });
        }}
        customIcon={<ScanGagal />}
      />

      <AlertDialog
        buttonSX={{
          minWidth: "100%",
          minHeight: "50px",
        }}
        customWidth="329px"
        open={openDialog.notFound}
        alertTitle="ID kartu subsidi tidak ditemukan"
        submitText="Scan Ulang"
        onSubmit={() => {
          setOpenDialog({
            ...openDialog,
            notFound: false,
          });
        }}
        onClose={() => {
          setOpenDialog({
            ...openDialog,
            notFound: false,
          });
        }}
        customIcon={<ScanGagal />}
      />
    </>
  );
};

KonfirmasiManual.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

KonfirmasiManual.defaultProps = {
  onClose: () => {},
  open: false,
};
export default KonfirmasiManual;
