import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PeriodeFormat from "components/Format/PeriodeFormat";
import MainStatus from "components/Status/MainStatus";
import { statusBansos } from "../ProgramBantuan/SubsidiBansos/variables";
import AktifStatus from "components/Status/AktifStatus";
import MainTable from "components/Table/MainTable";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ mt: 2 }}>{value === index && children}</Box>
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

TabPanel.defaultProps = {
  children: null,
  index: 0,
  value: 0,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TabList = ({ voucher, subsidi, loading }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const headers = [
    {
      name: "Penyelenggara",
      key: "penyelenggara",
    },
    {
      name: "Nama Program",
      key: "nama_program",
    },
    {
      name: "Periode Bantuan",
      custom: (item) => {
        return (
          <PeriodeFormat
            StartDate={item?.tanggal_mulai}
            EndDate={item?.tanggal_selesai}
          />
        );
      },
    },
    {
      name: "Status",
      custom: (item) => {
        return <AktifStatus value={item?.StatusBansos} />;
      },
    },
  ];

  const headers2 = [
    {
      name: "Penyelenggara",
      key: "penyelenggara",
      width: 40,
    },
    {
      name: "Nama Program",
      key: "nama_program",
      width: 40,
    },
    {
      name: "Status",
      custom: (item) => {
        return <AktifStatus value={item?.StatusBansos}  />;
      },
      width: 20,
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs value={value} onChange={handleChange} textColor="inherit">
        <Tab label="Kartu Subsidi" {...a11yProps(0)} />
        <Tab label="Voucer Bansos" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <MainTable
          headers={headers2}
          loading={loading}
          data={subsidi}
          align="center"
          summaryKey="penyelenggara"
          detail={[
            {
              type: "link",
              variant: "icon:eye",
              url_key:
                "csr-bansos/penerima-bantuan/kartu-subsidi:m_subsidi_id:reff_id_user",
            },
          ]}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MainTable
          headers={headers}
          loading={loading}
          data={voucher}
          align="center"
          summaryKey="penyelenggara"
          detail={[
            {
              type: "link",
              variant: "icon:eye",
              url_key:
                "csr-bansos/penerima-bantuan/voucher:m_bansos_id:reff_id_user",
            },
          ]}
        />
      </TabPanel>
    </Box>
  );
};

TabList.propTypes = {
  subsidi: PropTypes.any,
  voucher: PropTypes.any,
  loading: PropTypes.bool,
};

TabList.defaultProps = {
  subsidi: [],
  voucher: [],
  loading: false,
};

export default TabList;
