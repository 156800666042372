import React from "react";

const MasterDataIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1228_12581)">
        <path
          d="M12 1C7.18 1 2 2.25 2 5V19C2 21.75 7.18 23 12 23C16.82 23 22 21.75 22 19V5C22 2.25 16.82 1 12 1ZM20 12C20 12.47 17.51 14 12 14C6.49 14 4 12.47 4 12V7.52C5.95 8.52 9.04 9 12 9C14.96 9 18.05 8.53 20 7.52V12ZM12 3C17.25 3 20 4.49 20 5C20 5.51 17.25 7 12 7C6.75 7 4 5.51 4 5C4 4.49 6.75 3 12 3ZM12 21C6.49 21 4 19.47 4 19V14.52C5.95 15.52 9.04 16 12 16C14.96 16 18.05 15.53 20 14.52V19C20 19.47 17.51 21 12 21Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1228_12581">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MasterDataIcon;
