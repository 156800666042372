import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import FormError from "components/Alert";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { alert } from "redux/global/slice";
import { getIpAddress, initialization, LoginAction } from "redux/auth/actions";
import { Link, useSearchParams } from "react-router-dom";
import { setCookie } from "helper/cokkie";
import MainController from "components/Controller/MainController";
import VerticalGrid from "components/Grid/VerticalGrid";
import moment from "moment";
import TextFieldv3 from "components/TextField/TextFieldv3";
import VisibilityOff from "assets/paper/v2/VisibilityOff";
import VisibilityOn from "assets/paper/v2/VisibilityOn";
import PublicRoute from "components/PublicRoute";
import InisaIlu from "assets/login/forgot-password-ilu.png";

const defaultValues = {
  Email: "",
  Password: "",
  Source: "web",
};

const ForgotPasswordConfirm = () => {
  const Appid = useRef(null);
  const dispatch = useDispatch();
  const [sparams] = useSearchParams();
  const [response, setResponse] = useState({
    loading: false,
    toast: false,
    message: "",
  });
  const [retry, setRetry] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
  } = useForm({
    defaultValues: { ...defaultValues },
  });

  useEffect(() => {
    (async () => {
      const data = new FormData();
      data.append("AppName", "qoin-apps");
      const response = await initialization(data);
      if (response) {
        Appid.current = response.data.data.AppID;
      } else {
        Appid.current = "hello world";
      }
    })();
    getIpAddress();

    if (sparams.get("w")) {
      dispatch(
        alert({
          open: true,
          message: "Expired token atau token tidak sesuai",
        })
      );
    }
  }, []);

  useEffect(() => {
    if (retry > 0) {
      const intervalId = setInterval(() => {
        setRetry(retry - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    } else {
      setResponse({
        ...response,
        message: "",
      });
    }
  }, [retry]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    setResponse({
      ...response,
      loading: true,
    });
    formData.append("Email", data.Email);
    formData.append("Password", data.Password);
    formData.append("Source", "web");

    const io = await LoginAction(formData, Appid.current);

    if (io?.data?.code === 202) {
      setResponse({
        ...response,
        message: "",
      });
      dispatch(
        alert({
          open: true,
          message: "Login Berhasil",
        })
      );
      setCookie("token-ntt-webadmin", "expired-token");

      localStorage.setItem("merchant-id-ntt", io.data.data.ClientId);
      localStorage.setItem("token-ntt-webadmin", io.data.data.Token);
      localStorage.setItem("profile-ntt", JSON.stringify(io.data.data));
      let token = io.data.data.Token;
      let account;
      if (token) {
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        account = JSON.parse(jsonPayload);

        localStorage.setItem(
          "permission-ntt",
          JSON.stringify(account.Permission)
        );
      }
      setTimeout(() => {
        setResponse({
          ...response,
          loading: false,
          message: "",
        });
      }, 1500);
      // if (sparams.get("navigateTo")) {
      //   if (sparams.get("navigateTo") == "/") {
      //     window.location.replace("/master-data/master-bantuan/penerbit");
      //   }
      //   setTimeout(() => {
      //     window.location.replace(sparams.get("navigateTo"));
      //   }, 1500);
      // } else {
      //   setTimeout(() => {
      //     window.location.replace("/master-data/master-bantuan/penerbit");
      //   }, 1500);
      // }
    } else if (
      io?.data?.message === "Too Many Request" ||
      io?.data.code == 429
    ) {
      setResponse({
        ...response,
        loading: false,
        message: io?.data?.message,
      });
      setRetry(io.data.data.RetryAfter);
    } else {
      dispatch(
        alert({
          open: true,
          message: "Maaf terjadi kesalahan",
        })
      );
      setResponse({
        ...response,
        loading: false,
        message: io?.data?.message,
      });
    }
  };

  const PasswordIcon = useMemo(
    () => (showPassword.password ? VisibilityOn : VisibilityOff),
    [showPassword.password]
  );

  const ConfPasswordIcon = useMemo(
    () => (showPassword.conPassword ? VisibilityOn : VisibilityOff),
    [showPassword.conPassword]
  );

  return (
    <PublicRoute
      ilustration={InisaIlu}
      title="Lupa Kata Sandi"
      desc="Masukkan email terdaftar untuk atur ulang kata sandi"
    >
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "100%",
          marginTop: "30px",
        }}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <FormError
          message={`${response.message} ${
            retry > 0 ? `| ${moment.utc(retry * 1000).format("mm:ss")}` : ""
          }`}
          open={response.message ? 0 : 1}
          onClose={() => {
            if (retry) {
              return;
            }
            setResponse({
              ...response,
              message: "",
            });
          }}
        />
        <Grid container spacing={3}>
          <VerticalGrid label="Kata Sandi" md={12}>
            <MainController
              controller={Controller}
              name="password"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              alertType={1}
              rules={{
                required: "Kata Sandi wajib diisi",
                minLength: {
                  message: "Kata Sandi tidak boleh kurang dari 6 karakter",
                  value: 6,
                },
                // pattern: {
                //   message: "Kata Sandi karakter harus terdapat lowercase , uppercase & number",
                //   value: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{6,}$/,
                // },
              }}
              render={({ field }) => (
                <TextFieldv3
                  {...field}
                  name="Password"
                  type={showPassword.password ? "text" : "password"}
                  errors={errors}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder="Masukkan kata sandi"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{
                          color: "#4C4D4F",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            password: !showPassword?.password,
                          })
                        }
                      >
                        <PasswordIcon />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </VerticalGrid>

          <VerticalGrid label="Konfirmasi Kata Sandi" md={12}>
            <MainController
              controller={Controller}
              name="passwordconfirmation"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              rules={{
                required: "Konfirmasi Kata Sandi wajib diisi",
                minLength: {
                  message:
                    "Konfirmasi Kata Sandi tidak boleh kurang dari 6 karakter",
                  value: 6,
                },
                validate: (value) =>
                  value === watch("password") || "Kata Sandi tidak sama",
              }}
              alertType={1}
              render={({ field }) => (
                <TextFieldv3
                  {...field}
                  name="passwordconfirmation"
                  type={showPassword.conPassword ? "text" : "password"}
                  errors={errors}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder="Masukkan ulang kata sandi"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{
                          color: "#4C4D4F",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            conPassword: !showPassword?.conPassword,
                          })
                        }
                      >
                        <ConfPasswordIcon />
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
          </VerticalGrid>
        </Grid>

        <Button
          variant="primary"
          disabled={retry || response.loading ? true : false}
          type="submit"
          sx={{ margin: "10px 0px", padding: "13px", borderRadius: "8px" }}
        >
          Konfirmasi
        </Button>
        <Typography variant="textCommon" sx={{ textAlign: "center" }}>
          <Link to="/auth/login" style={{ textDecoration: "none" }}>
            <span style={{ color: "#cc1d15" }}>Kembali Kehalaman Masuk</span>
          </Link>
        </Typography>
      </Box>
    </PublicRoute>
  );
};

export default ForgotPasswordConfirm;
