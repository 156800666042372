import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState, useMemo, useRef, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  getIpAddress,
  initialization,
  RegisterAction,
} from "redux/auth/actions";
import VerticalGrid from "components/Grid/VerticalGrid";
import MainController from "components/Controller/MainController";
import useFetchData from "hook/useFetchData";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import InisaIlu from "assets/login/new-ilustrasi.png";
import PublicRoute from "components/PublicRoute";
import TextFieldv3 from "components/TextField/TextFieldv3";
import VisibilityOn from "assets/paper/v2/VisibilityOn";
import VisibilityOff from "assets/paper/v2/VisibilityOff";
import { addName } from "redux/auth/slice";
import FormatNumber from "helper/FormatNumber";

const Register = () => {
  const defaultValues = {
    clientname: "",
    clientwebsite: "",
    clientinstagram: "",
    fullname: "",
    phone: "",
    email: "",
    password: "",
    passwordconfirmation: "",
  };

  const Appid = useRef(null);
  const [agree, setAgree] = useState(false);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState({
    password: false,
    conPassword: false,
  });

  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    clearErrors,
    watch,
    reset,
    trigger,
  } = useForm({
    defaultValues: { ...defaultValues },
  });

  useEffect(() => {
    (async () => {
      const data = new FormData();
      data.append("AppName", "qoin-apps");
      const response = await initialization(data);
      if (response) {
        Appid.current = response.data.data.AppID;
      } else {
        Appid.current = "hello world";
      }
    })();
    getIpAddress();
  }, []);

  const [response, setResponse] = useState({
    loading: false,
    toast: false,
    message: "",
  });

  const action = useFetchData({
    action: RegisterAction,
    message: "Berhasil register",
    onSuccess: (data) => {
      navigate("/notif-verifikasi-email");
      dispatch(addName(data?.Email));
      reset();
    },
  });

  //   dispatch(
  //     alert({
  //       open: true,
  //       message: "Login...",
  //     })
  //   );
  //   const formData = new FormData();
  //   formData.append("Email", data.Email);
  //   formData.append("Password", getValues("password"));
  //   formData.append("Source", "web");

  //   const io = await LoginAction(formData, Appid.current);

  //   if (io?.data?.code === 202) {
  //     setResponse({
  //       ...response,
  //       message: "",
  //     });
  //     dispatch(
  //       alert({
  //         open: true,
  //         message: "Login Berhasil",
  //       })
  //     );
  //     setCookie("token-ntt-webadmin", "expired-token");

  //     localStorage.setItem("merchant-id-ntt", io.data.data.ClientId);
  //     localStorage.setItem("token-ntt-webadmin", io.data.data.Token);
  //     localStorage.setItem("profile-ntt", JSON.stringify(io.data.data));
  //     let token = io.data.data.Token;
  //     let account;
  //     if (token) {
  //       var base64Url = token.split(".")[1];
  //       var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  //       var jsonPayload = decodeURIComponent(
  //         atob(base64)
  //           .split("")
  //           .map(function (c) {
  //             return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  //           })
  //           .join("")
  //       );

  //       account = JSON.parse(jsonPayload);

  //       localStorage.setItem(
  //         "permission-ntt",
  //         JSON.stringify(account.Permission)
  //       );
  //     }
  //     setResponse({
  //       ...response,
  //       loading: false,
  //       message: "",
  //     });

  //     if (io?.data?.data?.bussinesRegistered == 1) {
  //       setTimeout(() => {
  //         return window.location.replace("/bussines-registration");
  //       }, 1500);
  //     } else {
  //       if (sparams.get("navigateTo")) {
  //         if (sparams.get("navigateTo") == "/") {
  //           setTimeout(() => {
  //             return window.location.replace(
  //               "/master-data/master-bantuan/penerbit"
  //             );
  //           }, 1500);
  //         }
  //         setTimeout(() => {
  //           return window.location.replace(sparams.get("navigateTo"));
  //         }, 1500);
  //       } else {
  //         setTimeout(() => {
  //           return window.location.replace(
  //             "/master-data/master-bantuan/penerbit"
  //           );
  //         }, 1500);
  //       }
  //     }
  //   } else if (
  //     io?.data?.message === "Too Many Request" ||
  //     io?.data.code == 429
  //   ) {
  //     setResponse({
  //       ...response,
  //       loading: false,
  //       message: io?.data?.message,
  //     });
  //     setRetry(io.data.data.RetryAfter);
  //   } else {
  //     dispatch(
  //       alert({
  //         open: true,
  //         message: "Maaf terjadi kesalahan",
  //       })
  //     );
  //     setResponse({
  //       ...response,
  //       loading: false,
  //       message: io?.data?.message,
  //     });
  //   }
  // };

  const onSubmit = async (data) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    await action.fetch(formData);
  };

  const convertToBase64 = async (file, handler = null) => {
    if(file == null){
      return handler(null)
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const arr = reader.result.split(",");
      if (handler != null) {
        handler(arr[1])
      }
    }
  }

  const PasswordIcon = useMemo(
    () => (showPassword.password ? VisibilityOn : VisibilityOff),
    [showPassword.password]
  );

  const ConfPasswordIcon = useMemo(
    () => (showPassword.conPassword ? VisibilityOn : VisibilityOff),
    [showPassword.conPassword]
  );

  return (
    <PublicRoute
      ilustration={InisaIlu}
      title="Daftar Akun"
      desc="Lengkapi data dan informasi yang dibutuhkan."
    >
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          width: "100%",
          marginTop: "20px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <VerticalGrid label={"Nama Bisnis"} md={12}>
            <MainController
              controller={Controller}
              name="clientname"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              rules={{
                required: "Businnes Brand wajib diisi",
                maxLength: {
                  message: "Email tidak boleh melebihi 255 karakter",
                  value: 255,
                },
              }}
              alertType={1}
              render={({ field }) => (
                <TextFieldv3
                  {...field}
                  name="clientname"
                  errors={errors}
                  type="text"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder="Masukkan nama bisnis"
                />
              )}
            />
          </VerticalGrid>
          <VerticalGrid label={"Nama Pemilik Bisnis"} md={12}>
            <MainController
              controller={Controller}
              name="fullname"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              rules={{
                required: "Full Name wajib diisi",
                maxLength: {
                  message: "Full Name tidak boleh melebihi 255 karakter",
                  value: 255,
                },
              }}
              alertType={1}
              render={({ field }) => (
                <TextFieldv3
                  {...field}
                  name="fullname"
                  errors={errors}
                  type="text"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder="Masukkan nama pemilik bisnis"
                />
              )}
            />
          </VerticalGrid>

          <VerticalGrid label={"Email"} md={12}>
            <MainController
              controller={Controller}
              name="email"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              rules={{
                required: "Email wajib diisi",
                maxLength: {
                  message: "Email tidak boleh melebihi 255 karakter",
                  value: 255,
                },
                pattern: {
                  value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                  message: "Format Email salah",
                },
              }}
              alertType={1}
              render={({ field }) => (
                <TextFieldv3
                  {...field}
                  name="Email"
                  errors={errors}
                  type="text"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder="Masukkan email"
                />
              )}
            />
          </VerticalGrid>

          <VerticalGrid label={"Website (opsional)"}>
            <MainController
              controller={Controller}
              name="clientwebsite"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              rules={{
                maxLength: {
                  message: "Website tidak boleh melebihi 255 karakter",
                  value: 255,
                },
              }}
              alertType={1}
              render={({ field }) => (
                <TextFieldv3
                  {...field}
                  type="text"
                  name="clientwebsite"
                  errors={errors}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder="Cth: www.usahamu.id"
                />
              )}
            />
          </VerticalGrid>

          <VerticalGrid label={"Instagram (opsional)"}>
            <MainController
              controller={Controller}
              name="clientinstagram"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              alertType={1}
              rules={{
                maxLength: {
                  message: "Instagram tidak boleh melebihi 255 karakter",
                  value: 255,
                },
              }}
              render={({ field }) => (
                <TextFieldv3
                  {...field}
                  type="text"
                  name="clientinstagram"
                  errors={errors}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder="Masukkan instagram"
                />
              )}
            />
          </VerticalGrid>

          <VerticalGrid label={"Nomor Ponsel"} md={12}>
            <MainController
              controller={Controller}
              name="phone"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              alertType={1}
              rules={{
                required: "Nomor Ponsel wajib diisi",
                maxLength: {
                  message: "Nomor Ponsel tidak boleh melebihi 13 karakter",
                  value: 13,
                },
                minLength: {
                  message: "Nomor Ponsel tidak boleh kurang dari 10 karakter",
                  value: 10,
                },
              }}
              render={({ field }) => (
                <Box display="flex" gap={1}>
                  <Box
                    sx={{
                      backgroundColor: "#EBECED",
                      alignItems: "center",
                      display: "flex",
                      alignItems: "center",
                      width: "60px",
                      borderRadius: "4px",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="labelMedium2">+62</Typography>
                  </Box>
                  <TextFieldv3
                    {...field}
                    name="phone"
                    errors={errors}
                    type="text"
                    value={field.value}
                    onChange={(e) => {
                      trigger("phone");
                      let v = FormatNumber(e.target.value);
                      field.onChange(v);
                    }}
                    placeholder="Masukkan nomor ponsel"
                  />
                </Box>
              )}
            />
          </VerticalGrid>

          <VerticalGrid label={"Kata Sandi"}>
            <MainController
              controller={Controller}
              name="password"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              alertType={1}
              rules={{
                required: "Kata Sandi wajib diisi",
                minLength: {
                  message: "Kata Sandi tidak boleh kurang dari 6 karakter",
                  value: 6,
                },
                // pattern: {
                //   message: "Kata Sandi karakter harus terdapat lowercase , uppercase & number",
                //   value: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{6,}$/,
                // },
              }}
              desc="Minimal 6 karakter"
              render={({ field }) => (
                <TextFieldv3
                  {...field}
                  name="Password"
                  type={showPassword.password ? "text" : "password"}
                  errors={errors}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder="Masukkan kata sandi"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{
                          color: "#4C4D4F",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            password: !showPassword?.password,
                          })
                        }
                      >
                        <PasswordIcon />
                      </IconButton>
                    ),
                    autoComplete: "new-password",
                  }}
                />
              )}
            />
          </VerticalGrid>

          <VerticalGrid label={"Konfirmasi Kata Sandi"}>
            <MainController
              controller={Controller}
              name="passwordconfirmation"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              rules={{
                required: "Konfirmasi Kata Sandi wajib diisi",
                minLength: {
                  message:
                    "Konfirmasi Kata Sandi tidak boleh kurang dari 6 karakter",
                  value: 6,
                },
                validate: (value) =>
                  value === watch("password") || "Kata Sandi tidak sama",
              }}
              alertType={1}
              render={({ field }) => (
                <TextFieldv3
                  {...field}
                  name="passwordconfirmation"
                  type={showPassword.conPassword ? "text" : "password"}
                  errors={errors}
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  placeholder="Masukkan ulang kata sandi"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        sx={{
                          color: "#4C4D4F",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() =>
                          setShowPassword({
                            ...showPassword,
                            conPassword: !showPassword?.conPassword,
                          })
                        }
                      >
                        <ConfPasswordIcon />
                      </IconButton>
                    ),
                    autoComplete: "new-password",
                  }}
                />
              )}
            />
          </VerticalGrid>
        </Grid>

        <Box display="flex" alignItems="flex-start" gap={1}>
          <Checkbox
            sx={{ padding: "1px", color: "#323232" }}
            checked={agree}
            onChange={() => setAgree(!agree)}
          />
          <Typography variant="desc3" fontWeight="600">
            Dengan melanjutkan, Anda memahami dan menyetujui penggunaan kami
            atas informasi yang Anda sampaikan sesuai dengan ketentuan{" "}
            <span
              style={{
                color: "#cc1d15",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              Kebijakan Privasi
            </span>{" "}
            INISA Merchant.
          </Typography>
        </Box>
        <Button
          variant="primary"
          disabled={!isValid || !agree || action.loading}
          type="submit"
          sx={{ margin: "10px 0px", padding: "13px", borderRadius: "8px" }}
        >
          Daftar
        </Button>
        <Typography variant="textCommon" sx={{ textAlign: "center", pb: 3 }}>
          Sudah Punya Akun?{"  "}
          <Link to="/auth/login" style={{ textDecoration: "none" }}>
            <span style={{ color: "#cc1d15", fontWeight: "bold" }}>Login</span>
          </Link>
        </Typography>
      </Box>
    </PublicRoute>
  );
};

export default Register;
