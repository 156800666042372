import React from "react";
import Providers from "components/RouteRoleProviders";
import DetailPengguna from "pages/ManagementPengguna/Penggunav2/Detail";
import Pengguna from "pages/ManagementPengguna/Penggunav2";
import Peran from "pages/ManagementPengguna/Peranv2";
import Detail from "pages/ManagementPengguna/Peranv2/Detail";
import LogActivity from "pages/ManagementPengguna/LogActivity";

const penggunaRoutes = [
  {
    path: "/management-pengguna/pengguna",
    element: (
      <Providers permission="Web:Pengguna:Get">
        <Pengguna />
      </Providers>
    ),
  },
  {
    path: "/management-pengguna/pengguna/:id",
    element: (
      <Providers permission="Web:Pengguna:Get">
        <DetailPengguna />
      </Providers>
    ),
  },
  {
    path: "/management-pengguna/peran",
    element: (
      <Providers permission="Web:Peran:Get">
        <Peran />
      </Providers>
    ),
  },
  {
    path: "/management-pengguna/peran/:id",
    element: (
      <Providers permission="Web:Peran:Get">
        <Detail />
      </Providers>
    ),
  },
  {
    path: "/management-pengguna/log-aktivitas",
    element: (
      <Providers permission="Web:Peran:Get">
        <LogActivity />
      </Providers>
    ),
  },
  
];

export default penggunaRoutes;
