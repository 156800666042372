import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import Popover from "@mui/material/Popover";
import { Box, TextField } from "@mui/material";
import { ExpandMore, Search } from "@mui/icons-material";

export default function MainPopperCustom({
  value,
  onClick,
  options,
  onChange,
  children,
  onChangeFilter,
  ...props
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const tf = useRef();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <TextField
        onClick={(event) => {
          onClick && onClick();
          setAnchorEl(event.currentTarget);
        }}
        value={value || ""}
        ref={tf}
        {...props}
        InputProps={{
          endAdornment: (
            <>
              <ExpandMore
                sx={{
                  position: "absolute",
                  right: "13px",
                  color: "#4E4751",
                }}
              />
            </>
          ),
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ top: "5px" }}
      >
        <Box sx={{ width: tf?.current?.offsetWidth, padding: "10px" }}>
          <TextField
            placeholder="Cari"
            InputProps={{
              endAdornment: <Search />,
            }}
            onChange={(e) => {
              onChangeFilter(e.target.value);
            }}
          />
          <Box
            sx={{
              height: "250px",
              overflowY: "auto",
              "&::-webkit-scrollbar": {
                boxShadow: "inset 0 0 0px rgba(0, 0, 0, 0)",
                width: "12px",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                backgroundColor: "#c6defd",
              },
              marginTop: "10px",
            }}
          >
            {children}
          </Box>
        </Box>
      </Popover>
    </>
  );
}

MainPopperCustom.propTypes = {
  props: PropTypes.any,
  onChange: PropTypes.any,
  onClick: PropTypes.any,
  options: PropTypes.any,
  value: PropTypes.any,
  onChangeFilter: PropTypes.any,
  children: PropTypes.any,
};

MainPopperCustom.defaultProps = {
  props: null,
  onChange: null,
  onClick: null,
  options: [],
  value: null,
  children: null,
  onChangeFilter: null,
};
