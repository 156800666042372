import CSRIcon from "assets/layout/sidebar/CSRIcon";
import MerchantIcon from "assets/layout/sidebar/MerchantIcon";
import BookmarkIcon from "assets/paper/BookmarkIcon";

export const ProdukList = [
  {
    id: 1,
    icon: CSRIcon,
    title: "CSR Bantuan Sosial",
    desc: "Produk yang membantu program pemberian bantuan sosial.",
    dataTitle: "Issuer includes",
    data: [
      "Master Penerbit",
      "Master Kartu Subsidi",
      "Master Voucher",
      "Program Bantuan Kartu Subsidi",
      "Program Bantuan Voucher Bansos",
      "Pengaturan",
    ],
  },
  {
    id: 2,
    icon: MerchantIcon,
    title: "Merchant",
    desc: "Produk yang efisien atur pencatatan bisnismu dengan mudah. ",
    dataTitle: "Merchant includes",
    data: ["Inventory", "Reporting", "Kasir", "Pengaturan"],
  },
  {
    id: 3,
    icon: BookmarkIcon,
    title: "Power Merchant",
    desc: "Produk gabungan solusi  atur bantuan sosial dan pencatatan bisnismu.",
    dataTitle: "Merchant includes",
    data: [
      "Master Penerbit",
      "Master Kartu Subsidi",
      "Master Voucher",
      "Program Bantuan Kartu Subsidi",
      "Program Bantuan Voucher Bansos",
      "Inventory",
      "Reporting",
      "Kasir",
      "Pengaturan",
    ],
  },
];

export const TipeBisnis = [
    {
      label: "Perorangan",
      value: 1,
    },
    {
      label: "Badan Usaha",
      value: 2,
    },
  ];

export const TipeEntitasBisnis = [
  {
    label: "Goverment Service",
    value: 1,
  },
  {
    label: "General Trading",
    value: 2,
  },
];

export const kategoriBisnis1 = [
  {
    label: "Goverment Healty Service",
    value: 1,
  },
  {
    label: "Goverment Service",
    value: 2,
  },
];

export const kategoriBisnis2 = [
  {
    label: "E-Commerce",
    value: 3,
  },
  {
    label: "General Trading",
    value: 4,
  },
];

export const KewarganegaraanData = [
  {
    label: "WNI",
    value: "wni",
  },
  {
    label: "WNA",
    value: "wna",
  },
];
