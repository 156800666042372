import Cookies from "js-cookie";

export const setCookie = (key, value) => {
  const inSixHours = new Date(new Date().getTime() + 360 * 60 * 1000);
  Cookies.set(key, value, {
    expires: inSixHours,
    path: "/",
  });
};

export const removeCookie = (key) => {
  Cookies.remove(key, {
    expires: 1,
  });
};

export const getCookie = (key) => {
  return Cookies.get(key);
};

// export const getCookie = (key, req) => {
//   return process.browser
//     ? getCookieFromBrowser(key)
//     : getCookieFromServer(key, req);
// };


// const getCookieFromServer = (key, req) => {
//   if (!req.headers.Cookies) {
//     return undefined;
//   }
//   const rawCookie = req.headers.Cookies.split(";").find((c) =>
//     c.trim().startsWith(`${key}=`)
//   );
//   if (!rawCookie) {
//     return undefined;
//   }
//   return rawCookie.split("=")[1];
// };
