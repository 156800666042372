export const defaultValues = {
  IssuerId: null,
  MerchantCode: "",
  VoucherName: "",
  VoucherType: 1,
  Status: 0,
  VoucherDesc: "",
  VoucherTC: "",
  AmountValue: "",
  RewardAmount: "",
  PrefixCode: "",
  PercentageValue: "",
  MinTrx: "",
  MaxTrx: "",
  Budget: "",
  Distributed: "",
  StartDate: null,
  EndDate: null,
  Start: "",
  End: "",
  Image1: "",
  Image2: "",
};

export const tipe_voucher = [
  {
    label: "Nilai Tetap",
    value: 1,
  },
  {
    label: "Nilai Tetap dengan minimum transaksi",
    value: 2,
  },
  {
    label: "Persentase tetap",
    value: 3,
  },
  {
    label: "Persentase tetap dengan minimum transaksi",
    value: 4,
  },
  {
    label: "Persentase tetap dengan maksimum transaksi",
    value: 5,
  },
  {
    label: "Persentase tetap dengan minimum transaksi dan maksimum nilai",
    value: 6,
  },
  {
    label: "Rujukan nilai tetap dan dapatkan nilai hadiah",
    value: 7,
  },
];

export const status_voucher = [
  {
    label: "Diajukan",
    value: 0,
  },
  {
    label: "Disetujui",
    value: 1,
  },
  {
    label: "Dipublikasikan",
    value: 2,
  },
  {
    label: "Dibatalkan",
    value: 3,
  },
  {
    label: "Dikeluarkan",
    value: 4,
  },
  {
    label: "Ditunda",
    value: 5,
  },
  {
    label: "Diakhiri",
    value: 6,
  },
];

export const status_redeem = [
  {
    label: "Belum Aktif",
    value: 0,
  },
  {
    label: "Aktif",
    value: 1,
  },
  {
    label: "Kadaluarsa",
    value: 2,
  },
  {
    label: "Ditukarkan",
    value: 3,
  },
  {
    label: "Diblokir",
    value: 4,
  },
  {
    label: "Diklaim",
    value: 5,
  },
  {
    label: "Ditutup",
    value: 6,
  },
  {
    label: "Digunakan",
    value: 7,
  },
];
