import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import React, { forwardRef } from "react";

const TextFieldv3 = forwardRef((props, ref) => {
  return (
    <TextField
      ref={ref}
      {...props}
      error={Boolean(props.error)}
      InputProps={{
        ...props.InputProps,
        sx: {
          backgroundColor: "#EBECED !important",
          transition: "all 0.2s",
          "&.Mui-focused": {
            backgroundColor: "#fff !important",
          },
          ...props?.InputProps?.sx,
        },
      }}
    />
  );
});

TextFieldv3.displayName = "tf3";

TextFieldv3.propTypes = {
  InputProps: PropTypes.any,
  error: PropTypes.bool,
};

TextFieldv3.defaultProps = {
  InputProps: null,
  error: false,
};

export default TextFieldv3;
