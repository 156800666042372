import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, getParams, put } from "api";
import { PenerimaBantuan } from "api/endpoints";

export const getListPenerimaBantuan = createAsyncThunk(
  "penerimaBantuan/getPenerimaBantuan",
  async (params) => {
    const response = await getParams(PenerimaBantuan.list, params);
    return response?.data?.data;
  }
);

export const getPenerimaBantuan = async (params) => {
  const response = await getParams(PenerimaBantuan.list, params);
  return response;
};

export const detailBansosPenerima = async (id) => {
  const wid = `${PenerimaBantuan.bansosPenerima}/${id}`;
  const response = await get(wid);
  return response;
};

export const getDetailBansosVoucherSubsidi = createAsyncThunk(
  "penerimaBantuan/getDetailBansosVoucherSubsidi",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const wid = `${PenerimaBantuan.bansosPenerimaVoucherSubsidi}/${id}`;

      const response = await getParams(wid, params);
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const bansosSubsidiProgramUser = createAsyncThunk(
  "penerimaBantuan/getBansosSubsidiProgramUser",
  async ({ subsidi, user, params }, { rejectWithValue }) => {
    try {
      const wid = `${PenerimaBantuan.bansosSubsidiProgramUser}/${subsidi}/${user}`;

      const response = await getParams(wid, params);
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const bansosVoucherProgramUser = createAsyncThunk(
  "penerimaBantuan/getbansosVoucherProgramUser",
  async ({ voucher, user, params }, { rejectWithValue }) => {
    try {
      const wid = `${PenerimaBantuan.bansosVoucherProgramUser}/${voucher}/${user}`;
      const response = await getParams(wid, params);
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const bansosDetailUserSubsidi = async (id) => {
  const wid = `${PenerimaBantuan.bansosDetailUserSubsidi}/${id}`;
  const response = await get(wid);
  return response;
};

export const bansosRiwayatTransaksi = createAsyncThunk(
  "penerimaBantuan/getBansosRiwayatTransaksi",
  async ({ id, params }, { rejectWithValue }) => {
    try {
      const wid = `${PenerimaBantuan.bansosRiwayatTransaksi}/${id}`;
      const response = await getParams(wid, params);
      return response.data.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const bansosVoucherRedeem = async (data) => {
  const response = await put(PenerimaBantuan.bansosVoucherRedeem, data);
  return response;
};
