import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import React from "react";
import CardList from "../components/CardList";
import MainController from "components/Controller/MainController";

const StepView0 = ({
  controller: Controller,
  control,
  errors,
  setOpenDialog,
  clearErrors,
}) => {
  return (
    <Grid container spacing={5} mt={1}>
      <Grid item xs={12} md={6}>
        <MainController
          controller={Controller}
          name="BizType"
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          rules={{ required: "Tipe Bisnis wajib diisi" }}
          render={({ field }) => (
            <CardList
              buttonText="Daftar Sebagai Perorangan"
              title="Perseorangan"
              desc="Bisnis yang kepemilikannya atas nama perorangan atau individu"
              onChange={() => {
                field.onChange(1);
                setOpenDialog();
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MainController
          controller={Controller}
          name="BizType"
          control={control}
          errors={errors}
          clearErrors={clearErrors}
          rules={{ required: "Tipe Bisnis wajib diisi" }}
          render={({ field }) => (
            <CardList
              buttonText="Daftar Sebagai Badan Usaha"
              title="Badan Usaha"
              desc="Bisnis yang kepemilikannya atas nama perusahaan, dalam bentuk PT, Yayasan, CV, UD, lembaga pendidikan, atau asosiasi"
              onChange={() => {
                field.onChange(2);
                setOpenDialog();
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

StepView0.propTypes = {
  clearErrors: PropTypes.any,
  control: PropTypes.any,
  controller: PropTypes.any,
  errors: PropTypes.any,
  setActiveStep: PropTypes.any,
  setValue: PropTypes.any,
  setOpenDialog: PropTypes.func,
};

StepView0.defaultProps = {
  setActiveStep: null,
  setValue: null,
  clearErrors: null,
  control: null,
  controller: null,
  errors: null,
  setOpenDialog: null,
};

export default StepView0;
