import { createSlice } from "@reduxjs/toolkit";
import { bansosSubsidiProgramUser } from "../action";

export const initialState = {
  list: [],
  namaProgram: "",
  params: {},
  message: "",
  loading: false,
};

export const bansosSubsidiProgramUserSlice = createSlice({
  name: "kartusubsidipenerimaBantuan",
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.params = {
        ...state.params,
        search: action.payload,
      };
    },
    deleteFilter: (state) => {
      delete state.params?.filter;
    },
  },
  extraReducers: {
    [bansosSubsidiProgramUser.pending]: (state) => {
      state.loading = true;
    },
    [bansosSubsidiProgramUser.rejected]: (state, action) => {
      state.loading = false;
      state.message = action.error.message;
    },
    [bansosSubsidiProgramUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload?.list;
      state.namaProgram = action.payload?.nama_program;
    },
  },
});

export const { changeFilter, deleteFilter } =
  bansosSubsidiProgramUserSlice.actions;

export default bansosSubsidiProgramUserSlice.reducer;
