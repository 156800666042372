import PropTypes from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import React from "react";
import { useNavigate } from "react-router-dom";

const PageTitle = ({
  title,
  desc,
  text,
  lastText,
  backBtn,
  variantTitle,
  justifyContent,
  custom,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: justifyContent,
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 0.3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: justifyContent,
            }}
          >
            {backBtn && (
              <IconButton
                onClick={() => navigate(-1)}
                sx={{
                  p: 0,
                }}
              >
                <ChevronLeftIcon
                  sx={{
                    fontSize: {
                      xs: "40px",
                      md: "50px",
                    },
                    color: "#111",
                    fontWeight: "bold",
                  }}
                />
              </IconButton>
            )}
          </Box>
          <Box display="flex" flexDirection="column" >
            <Typography variant={variantTitle}>
              {title} {desc && desc}
            </Typography>
            {Boolean(text) && (
              <Typography
                variant="desc"
                sx={{
                  display: {
                    xs: "none",
                    md: "block",
                  },
                  textTransform: "none",
                }}
              >
                {text}
                <span style={{ color: "#cc1d15" }}>{lastText}</span>
              </Typography>
            )}
          </Box>
        </Box>
        {custom && custom}
      </Box>
    </>
  );
};

PageTitle.propTypes = {
  title: PropTypes.any,
  desc: PropTypes.any,
  to: PropTypes.any,
  text: PropTypes.string,
  lastText: PropTypes.string,
  backBtn: PropTypes.bool,
  custom: PropTypes.node,
  variantTitle: PropTypes.string,
  justifyContent: PropTypes.string,
};

PageTitle.defaultProps = {
  title: "",
  desc: "",
  to: "",
  text: "",
  lastText: "",
  backBtn: true,
  custom: null,
  variantTitle: "h1",
  justifyContent: "space-between",
};

export default PageTitle;
