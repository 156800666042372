import WarningIcon from "assets/paper/WarningIcon";
import MainDialog from "components/Dialog/MainDialog";
import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Typography } from "@mui/material";

const DeleteDialog = ({ open, onClose, onSubmit }) => {
  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
      }}
      customWidth="372px"
      action={false}
      closeButton={false}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
          pt: 1,
        }}
      >
        <WarningIcon />
        <Typography variant="cardTitle">Yakin Hapus Data</Typography>
        <Box
          sx={{
            display: "flex",
            gap: 3,
          }}
        >
          <Button
            variant="secondary-outlined"
            sx={{
              width: "150px",
              height: "50px",
            }}
            onClick={onClose}
          >
            Tidak
          </Button>
          <Button
            variant="primary"
            sx={{
              width: "150px",
              height: "50px",
            }}
            onClick={onSubmit}
          >
            Ya
          </Button>
        </Box>
      </Box>
    </MainDialog>
  );
};

DeleteDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

DeleteDialog.defaultProps = {
  open: false,
  onClose: null,
  onSubmit: null,
};

export default DeleteDialog;
