const color2 = (value) => {
  let color0 = "#cc1d15";
  let color1 = "#129350";
  let color2 = "#0C6CE2";
  let color5 = "#7300F7";

  switch (value) {
    case 0:
      return color0;
    case 1:
      return color1;
    case 2:
      return color0;
    case 3:
      return color5;
    case 4:
      return color0;
    case 5:
      return color1;
    case 6:
      return color0;
    case 7:
      return color0;
    default:
      return color2;
  }
};

export default color2;
