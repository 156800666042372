import React, { forwardRef } from "react";
const { NavLink } = require("react-router-dom");
import PropTypes from "prop-types";

const CustomNavLink = forwardRef((props, ref) => (
  <NavLink
    ref={ref}
    {...props}
    className={({ isActive }) =>
      isActive ? props.className + " Mui-selected" : props.className
    }
  />
));

CustomNavLink.displayName = "Navlink";

CustomNavLink.propTypes = {
  className: PropTypes.string,
};

CustomNavLink.defaultProps = {
  className: "",
};
export default CustomNavLink;
