import Dasbor from "assets/layout/sidebar/Dasbor";
const permissionList = ['Web:Dashboard:Get']
const dashboard = {
  id: "dasbor",
  title: "Dasbor",
  url: "/",
  type: "item",
  icon: Dasbor,
  permission: permissionList,
};

export default dashboard;
