import PropTypes from "prop-types";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import React from "react";
import Close from "@mui/icons-material/Close";
import { TransitionGroup } from "react-transition-group";

const ListTitle = ({ data, listKey, onDelete }) => {
  return (
    <Box display="flex" flexDirection="column">
      <TransitionGroup>
        {data.map((go, i) => (
          <Collapse key={i}>
            <Box
              key={i}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              py={1.5}
              px={1}
              borderBottom="1px solid #EBECED"
              borderTop="1px solid #EBECED"
            >
              <Typography variant="gridLabel">{go.IssuerName}</Typography>
            </Box>
            <TransitionGroup>
              {go.list.map((item, j) => (
                <Collapse key={j}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    py={1}
                    px={1}
                    // borderBottom="1px solid #EBECED"
                  >
                    <Typography variant="label2" fontWeight={500}>
                      {item?.[listKey] || "-"}
                    </Typography>
                    <IconButton
                      sx={{
                        color: "#323232",
                        cursor: "pointer",
                        marginTop: "auto",
                        marginBottom: "auto",
                        padding: "4px",
                      }}
                      onClick={() => onDelete(go, item)}
                    >
                      <Close sx={{ fontSize: "20px" }} />
                    </IconButton>
                  </Box>
                </Collapse>
              ))}
            </TransitionGroup>
          </Collapse>
        ))}
      </TransitionGroup>
    </Box>
  );
};

ListTitle.propTypes = {
  data: PropTypes.any,
  listKey: PropTypes.any,
  title: PropTypes.string,
  onDelete: PropTypes.func,
};

ListTitle.defaultProps = {
  data: [],
  listKey: null,
  title: "",
  onDelete: () => {},
};

export default ListTitle;
