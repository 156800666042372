import React from "react";

const ScanBerhasil = () => {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="128" height="128" rx="64" fill="#F5F6F8" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.5 63.5C106.5 65.688 106.341 67.8454 106.023 69.9396C104.612 79.2352 100.234 87.545 93.8885 93.8908C86.1152 101.678 75.359 106.5 63.5077 106.5C39.7915 106.5 20.5 87.2119 20.5 63.5C20.5 43.9592 33.5882 27.4315 51.4667 22.2286C55.2902 21.103 59.3357 20.5 63.5077 20.5C87.2085 20.5 106.5 39.7881 106.5 63.5Z"
        fill="#F5B419"
      />
      <path
        d="M112.5 63.5C112.5 65.9933 112.319 68.4517 111.957 70.8381C110.349 81.4308 105.359 90.9001 98.1288 98.1313C89.2709 107.005 77.0138 112.5 63.5087 112.5C36.4833 112.5 14.5 90.5206 14.5 63.5C14.5 41.2326 29.4145 22.3987 49.7877 16.4698C54.1446 15.1871 58.7547 14.5 63.5087 14.5C90.5167 14.5 112.5 36.4794 112.5 63.5"
        stroke="#F7B500"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.0384 80.883C57.0097 80.883 56.9829 80.883 56.9542 80.881C56.0861 80.8577 55.2638 80.4734 54.6825 79.8174L43.2701 66.933C42.0998 65.6112 42.205 63.579 43.5072 62.3911C44.8095 61.2032 46.8116 61.3119 47.9819 62.6318L57.1608 72.9948L77.3026 52.5483C78.5379 51.2924 80.5477 51.2924 81.783 52.5483C83.0221 53.806 83.0221 55.8421 81.783 57.098L59.2795 79.9397C58.6848 80.5452 57.8779 80.883 57.0384 80.883Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117 113C114.788 113 112.791 113.897 111.35 115.338C109.897 116.792 109 118.788 109 121C109 118.788 108.102 116.792 106.662 115.338C105.208 113.897 103.212 113 101 113C103.212 113 105.208 112.102 106.662 110.65C108.102 109.208 109 107.212 109 105C109 109.411 112.589 113 117 113Z"
        fill="#F5B419"
      />
      <mask
        id="mask0_2316_86358"
        maskUnits="userSpaceOnUse"
        x="108"
        y="8"
        width="10"
        height="10"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M108 8H118V18H108V8Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2316_86358)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118 12.9997C116.618 12.9997 115.37 13.5607 114.469 14.4615C113.561 15.3695 113 16.6175 113 18C113 16.6175 112.439 15.3695 111.539 14.4615C110.63 13.5607 109.382 12.9997 108 12.9997C109.382 12.9997 110.63 12.4388 111.539 11.5307C112.439 10.6299 113 9.38196 113 7.99948C113 10.7561 115.244 12.9997 118 12.9997Z"
          fill="#F5B419"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 110.001C21.8939 110.001 20.8961 110.449 20.1757 111.169C19.4489 111.896 18.9995 112.895 18.9995 114C18.9995 112.895 18.5511 111.896 17.8307 111.169C17.1039 110.449 16.1061 110.001 15 110.001C16.1061 110.001 17.1039 109.552 17.8307 108.825C18.5511 108.105 18.9995 107.106 18.9995 106C18.9995 108.206 20.7941 110.001 23 110.001Z"
        fill="#F5B419"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61 7C60.1703 7 59.4213 7.33636 58.8814 7.87614C58.3359 8.42159 57.9994 9.17045 57.9994 10C57.9994 9.17045 57.663 8.42159 57.1231 7.87614C56.5776 7.33636 55.8297 7 55 7C55.8297 7 56.5776 6.66364 57.1231 6.11818C57.663 5.57841 57.9994 4.82955 57.9994 4C57.9994 5.65455 59.3463 7 61 7Z"
        fill="#F5B419"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126 63C125.17 63 124.421 63.3364 123.881 63.8761C123.336 64.4216 122.999 65.1705 122.999 66C122.999 65.1705 122.663 64.4216 122.123 63.8761C121.578 63.3364 120.83 63 120 63C120.83 63 121.578 62.6636 122.123 62.1182C122.663 61.5784 122.999 60.8295 122.999 60C122.999 61.6545 124.346 63 126 63Z"
        fill="#F5B419"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 52C9.1703 52 8.42129 52.3364 7.88142 52.8761C7.33586 53.4216 6.99943 54.1705 6.99943 55C6.99943 54.1705 6.663 53.4216 6.12313 52.8761C5.57757 52.3364 4.8297 52 4 52C4.8297 52 5.57757 51.6636 6.12313 51.1182C6.663 50.5784 6.99943 49.8295 6.99943 49C6.99943 50.6545 8.34628 52 10 52Z"
        fill="#F5B419"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M87 118C85.8939 118 84.8953 118.448 84.1756 119.169C83.4481 119.896 82.9995 120.894 82.9995 122C82.9995 120.894 82.551 119.896 81.8303 119.169C81.1038 118.448 80.1051 118 79 118C80.1051 118 81.1038 117.552 81.8303 116.825C82.551 116.104 82.9995 115.106 82.9995 114C82.9995 116.205 84.7947 118 87 118Z"
        fill="#F5B419"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 14.5C21.203 14.5 19.5806 15.2291 18.41 16.3997C17.2291 17.5806 16.5 19.203 16.5 21C16.5 19.203 15.7709 17.5806 14.6003 16.3997C13.4194 15.2291 11.797 14.5 10 14.5C11.797 14.5 13.4194 13.7709 14.6003 12.59C15.7709 11.4194 16.5 9.797 16.5 8C16.5 11.5837 19.4163 14.5 23 14.5Z"
        fill="#F5B419"
      />
    </svg>
  );
};

export default ScanBerhasil;
