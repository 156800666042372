import InventoryIcon from "assets/layout/sidebar/Inventory";

const permissionList = ["Web:Pengguna:Get", "Web:Peran:Get"];

const Report = {
  id: "report",
  type: "collapse",
  title: "Report",
  icon: InventoryIcon,
  permission: permissionList,
  children: [
    {
      id: "daily-report",
      title: "Daily Report",
      type: "item",
      url: "/report/daily-report",
      permission: permissionList[0],
    },
    {
      id: "stock-report",
      title: "Stock Report",
      type: "item",
      url: "/report/stock-report",
      permission: permissionList[1],
    },
  ],
};

export default Report;
