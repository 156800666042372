import React from "react";

const VoucerBansosIlu = () => {
  return (
    <svg
      width="106"
      height="116"
      viewBox="0 0 106 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.0535 6.42192C84.4883 5.94604 85.1489 4.33037 85.6863 2.175C86.2869 4.19492 87.0041 5.73333 87.6333 6.42192C88.2631 7.11119 89.6711 7.8969 91.509 8.5532C89.5445 9.14072 88.0681 9.86358 87.6333 10.3395C87.1984 10.8153 86.5379 12.431 86.0005 14.5864C85.4013 12.5695 84.6833 11.0287 84.0535 10.3395C83.4242 9.65087 82.0184 8.86602 80.1831 8.21011C82.1422 7.62067 83.6186 6.8978 84.0535 6.42192Z"
        fill="#F7B500"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9499 100.672C22.3848 100.196 23.0454 98.5804 23.5828 96.425C24.1834 98.4449 24.9005 99.9833 25.5298 100.672C26.1596 101.361 27.5675 102.147 29.4055 102.803C27.441 103.391 25.9646 104.114 25.5298 104.589C25.0949 105.065 24.4344 106.681 23.897 108.836C23.2978 106.819 22.5798 105.279 21.9499 104.589C21.3207 103.901 19.9149 103.116 18.0796 102.46C20.0387 101.871 21.5151 101.148 21.9499 100.672Z"
        fill="#F7B500"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M97.3102 12.682C97.6001 12.3648 98.0405 11.2877 98.3988 9.85075C98.7992 11.1974 99.2773 12.223 99.6968 12.682C100.117 13.1415 101.055 13.6653 102.281 14.1029C100.971 14.4946 99.9867 14.9765 99.6968 15.2937C99.4069 15.611 98.9665 16.6881 98.6082 18.125C98.2088 16.7804 97.7301 15.7532 97.3102 15.2937C96.8907 14.8347 95.9535 14.3114 94.73 13.8742C96.0361 13.4812 97.0203 12.9993 97.3102 12.682Z"
        fill="#F7B500"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.88835 106.932C10.1782 106.615 10.6186 105.538 10.9769 104.101C11.3773 105.447 11.8554 106.473 12.2749 106.932C12.6948 107.392 13.6334 107.915 14.8587 108.353C13.5491 108.745 12.5648 109.226 12.2749 109.544C11.985 109.861 11.5446 110.938 11.1864 112.375C10.7869 111.03 10.3082 110.003 9.88835 109.544C9.46886 109.085 8.53167 108.561 7.30811 108.124C8.61418 107.731 9.59844 107.249 9.88835 106.932Z"
        fill="#F7B500"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.3634 102.582C93.6533 102.265 94.0937 101.188 94.452 99.7508C94.8524 101.097 95.3305 102.123 95.75 102.582C96.1699 103.042 97.1085 103.565 98.3338 104.003C97.0242 104.395 96.0399 104.876 95.75 105.194C95.4601 105.511 95.0197 106.588 94.6615 108.025C94.262 106.68 93.7833 105.653 93.3634 105.194C92.944 104.735 92.0068 104.211 90.7832 103.774C92.0893 103.381 93.0735 102.899 93.3634 102.582Z"
        fill="#F7B500"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.56339 12.682C8.8533 12.3648 9.29366 11.2877 9.65193 9.85075C10.0523 11.1974 10.5305 12.223 10.9499 12.682C11.3698 13.1415 12.3085 13.6653 13.5338 14.1029C12.2241 14.4946 11.2398 14.9765 10.9499 15.2937C10.66 15.611 10.2197 16.6881 9.86141 18.125C9.46194 16.7804 8.98329 15.7532 8.56339 15.2937C8.14391 14.8347 7.20672 14.3114 5.98315 13.8742C7.28923 13.4812 8.27349 12.9993 8.56339 12.682Z"
        fill="#F7B500"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7474 46.1009C11.7372 46.5476 11.2284 47.7497 11.6109 48.7859L25.5077 86.4264C25.8903 87.4626 27.0193 87.9405 28.0296 87.4939L95.0405 57.8655C96.0508 57.4189 96.5596 56.2168 96.177 55.1805L90.7492 40.4788C90.1661 40.7465 89.5258 40.8944 88.8539 40.8944C86.1463 40.8944 83.9514 38.4924 83.9514 35.5294C83.9514 33.2529 85.2471 31.3075 87.0755 30.5283L82.2803 17.54C81.8977 16.5038 80.7686 16.0259 79.7584 16.4725L12.7474 46.1009Z"
        fill="#920B07"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3289 48.789C11.3289 47.6844 12.2243 46.789 13.3289 46.789L90.2201 46.789C91.3247 46.789 92.2201 47.6844 92.2201 48.789V65.5665C89.3379 65.5665 87.0013 67.9685 87.0013 70.9315C87.0013 73.8945 89.3379 76.2965 92.2201 76.2965L92.2201 93.074C92.2201 94.1785 91.3247 95.074 90.2201 95.074L13.3289 95.074C12.2243 95.074 11.3289 94.1785 11.3289 93.074L11.3289 76.2965C14.2111 76.2965 16.5476 73.8945 16.5476 70.9315C16.5476 67.9685 14.2111 65.5665 11.3289 65.5665L11.3289 48.789Z"
        fill="#EB5050"
      />
      <ellipse
        cx="51.7744"
        cy="72.2727"
        rx="8.57937"
        ry="9.38875"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4876 46.789H26.0364L26.0364 54.8365H28.4876V46.789ZM77.5126 46.789H75.0614V54.8365H77.5126V46.789ZM75.0614 60.2015H77.5126V68.249H75.0614V60.2015ZM28.4876 60.2015H26.0364V68.249H28.4876V60.2015ZM75.0614 73.614H77.5126V81.6615H75.0614V73.614ZM28.4876 73.614H26.0364V81.6615H28.4876V73.614ZM75.0614 87.0265H77.5126L77.5126 95.074H75.0614L75.0614 87.0265ZM28.4876 87.0265H26.0364V95.074H28.4876L28.4876 87.0265Z"
        fill="white"
      />
    </svg>
  );
};

export default VoucerBansosIlu;
