import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  Tab,
  Tabs,
} from "@mui/material";
import PageTitle from "components/Page/PageTitle";
import React, { useState, useEffect } from "react";
import Profile from "../components/Profile";
import PropTypes from "prop-types";
import MainTable from "components/Table/MainTable";
import AktifStatus from "components/Status/AktifStatus";
import PenyelenggaraBantuan from "../components/PenyelenggaraBantuan";
import TextFieldv3 from "components/TextField/TextFieldv3";
import SearchIcon from "assets/paper/SearchIcon";
import PencilIconv2 from "assets/paper/v2/PencilIconv2";
import Dialog from "../Dialog";
import { getUserDetail } from "redux/managementPengguna/action";
import {
  User,
  Loading,
  LogActivity,
  Params,
  Task,
} from "redux/managementPengguna/Pengguna/Detail/selectors";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { changeFilter } from "redux/managementPengguna/Pengguna/Detail/slice";

const headers2 = [
  {
    name: "Tanggal dan Waktu ",
    key: "tanggalwaktu",
    width: 30,
  },
  {
    name: "Aktifitas",
    key: "aktifitas",
    width: 30,
  },
  {
    name: "Status",
    custom: (item) => (
      <AktifStatus
        customText={["Berhasil", "Tidak berhasil"]}
        value={item.status}
        minWidth="140px"
      />
    ),
    width: 30,
  },
];
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box sx={{ mt: 2 }}>{value === index && children}</Box>
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

TabPanel.defaultProps = {
  children: null,
  index: 0,
  value: 0,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const PenggunaDetail = () => {
  const [open, setOpen] = useState({
    open: false,
    import: false,
  });
  const [dialogData, setDialogData] = useState(null);

  const { id } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(User);
  const loading = useSelector(Loading);
  const params = useSelector(Params);
  const logActivity = useSelector(LogActivity);
  const task = useSelector(Task);

  const [value, setValue] = React.useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const fetchData = async () => {
    await dispatch(getUserDetail({ params, id }));
  };

  useEffect(() => {
    fetchData();
  }, [params]);

  const handleOpenDialog = () => {
    const obj = { ...user, id };
    if (task.length > 0) {
      obj.Vouchers = task;
    }
    setDialogData(obj);
    setOpen({
      ...open,
      open: true,
    });
  };

  return (
    <>
      <PageTitle
        title={`Detail Pengguna`}
        text="Dashboard / Management Pengguna / Pengguna / "
        lastText="Detail Pengguna "
        backBtn={false}
      />
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={4}>
          <Profile user={user} onClick={() => handleOpenDialog()} />
        </Grid>
        <Grid item xs={8}>
          <Paper
            sx={{
              borderRadius: "8px",
            }}
          >
            <Box display="flex">
              <Box sx={{ flex: "auto" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  sx={{
                    ".MuiTabs-indicator": {
                      backgroundColor: "#202021",
                    },
                  }}
                  textColor="inherit"
                >
                  <Tab label="Log Aktifitas" {...a11yProps(0)} />
                  <Tab label="Penyelenggara dan Bantuan" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <Box sx={{ display: "flex", flex: "auto", mt: 3, mr: 2, gap: 1 }}>
                <TextFieldv3
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    sx: {
                      minWidth: "100px",
                    },
                  }}
                  onChange={(e) => {
                    if (value == 0) {
                      dispatch(
                        changeFilter({
                          type: "activity",
                          value: e.target.value,
                        })
                      );
                    } else {
                      dispatch(
                        changeFilter({
                          type: "voucher",
                          value: e.target.value,
                        })
                      );
                    }
                  }}
                  placeholder="Placeholder..."
                />
                {value == 1 && (
                  <Button
                    variant="secondary-outlined"
                    onClick={() => handleOpenDialog()}
                    sx={{
                      minWidth: "45px !important",
                      p: 0,
                    }}
                  >
                    <PencilIconv2 />
                  </Button>
                )}
              </Box>
            </Box>
            <TabPanel value={value} index={0}>
              <MainTable
                headers={headers2}
                loading={loading}
                data={logActivity}
                align="center"
                summaryKey="penyelenggara"
                no={false}
                action=""
                sx={{
                  borderBottomLeftRadius: "8px",
                  borderBottomRightRadius: "8px",
                }}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <PenyelenggaraBantuan data={task} />
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={open.open}
        onClose={() =>
          setOpen({
            ...open,
            open: false,
          })
        }
        refresh={fetchData}
        data={dialogData}
      />
    </>
  );
};

export default PenggunaDetail;
