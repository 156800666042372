import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React from "react";
import DataKartu from "../DataKartu";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const DetailKartuSubsidi = ({ open, response, onClose }) => {
  return (
    <MainDialog
      open={open}
      onClose={() => {
        onClose();
      }}
      title="Detail Kartu Subsidi"
      width="sm"
      action={false}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "376px",
            height: "224px",
          }}
        >
          <DataKartu response={response} />
        </Box>
      </Box>

      <Accordion
        disableGutters
        elevation={0}
        sx={{
          marginTop: "30px",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="gridLabel">Deskripsi Voucher</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="gridValue"
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            {response?.data_kartu?.deskripsi_kartu || "-"}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="gridLabel">Cara Penukaran</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="gridValue"
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            {response?.data_kartu?.cara_penukaran || "-"}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="gridLabel">Syarat dan Ketentuan</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="gridValue"
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: response?.data_kartu?.syarat_ketentuan,
              }}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>
    </MainDialog>
  );
};

DetailKartuSubsidi.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  response: PropTypes.any,
};

DetailKartuSubsidi.defaultProps = {
  onClose: () => {},
  open: false,
  response: [],
};

export default DetailKartuSubsidi;
