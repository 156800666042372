import { Box } from "@mui/material";
import React from "react";

const StartAdorment = (txt) => {
  return {
    startAdornment: (
      <Box
        sx={{
          fontSize: "14px",
          fontWeight: "700",
          backgroundColor: "#D4D5D5",
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          borderTopLeftRadius: "4px",
          borderBottomLeftRadius: "4px",
          display: "flex",
          textSlign: "center",
          alignItems: "center",
          padding: "9px 15px",
          color: "#111",
        }}
      >
        {txt}
      </Box>
    ),
    sx: {
      input: {
        marginLeft: "50px",
      },
    },
  };
};

export default StartAdorment;
