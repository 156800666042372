import { createAsyncThunk } from "@reduxjs/toolkit";
import { getParams, post, put, get } from "api";
import { Inventory } from "api/endpoints";

export const getListMasterProduk = createAsyncThunk(
  "inventory/getListMasterProduk",
  async (params) => {
    const response = await getParams(Inventory.masterProduk, params);
    return response.data.data;
  }
);

export const getMasterProduk = async (params) => {
  const response = await getParams(Inventory.masterProduk, params);
  return response;
};

export const getMerchantProduk = async (params, id) => {
  const response = await getParams(
    `${Inventory.masterMerchantProduk}/${id}`,
    params
  );
  return response;
};

export const createMasterProduk = async (data) => {
  const response = await post(Inventory.masterProduk, data);

  return response;
};

export const updateMasterProduk = async (data, id) => {
  const withId = `${Inventory.masterProduk}/${id}`;
  const response = await put(withId, data);
  return response;
};

export const getListMasterDistribusi = createAsyncThunk(
  "inventory/getListMasterDistribusi",
  async (params) => {
    const response = await getParams(Inventory.masterDistribusi, params);
    return response.data.data;
  }
);

export const getMasterDistribusi = async (params) => {
  const response = await getParams(Inventory.masterDistribusi, params);
  return response;
};

export const createMasterDistribusi = async (data) => {
  const response = await post(Inventory.masterDistribusi, data);

  return response;
};

export const updateMasterDistribusi = async (data, id) => {
  const withId = `${Inventory.masterDistribusi}/${id}`;
  const response = await put(withId, data);
  return response;
};
