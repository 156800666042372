import Dashboard from "./dashboard";
import MasterData from "./master-data";
import ManagementPengguna from "./management-pengguna";
import CSRBansos from "./csr-bansos";
import Inventory from "./inventory";
import RedeemSubsidi from "./redeem-subsidi";
import Report from "./report";

const menuItems = [
  Dashboard,
  MasterData,
  CSRBansos,
  ManagementPengguna,
  Inventory,
  Report,
  RedeemSubsidi,
];

export default menuItems;
