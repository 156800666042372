import React from "react";

const InisaLogo = () => {
  return (
    <svg
      width="24"
      height="30"
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 12.0067C24 5.3757 18.6275 0 12.0001 0C5.37266 0 0 5.3757 0 12.0067C0 16.7418 2.74024 20.835 6.71975 22.7897L6.72052 22.7882C6.97556 22.923 7.26578 23 7.57442 23C8.58615 23 9.4064 22.1793 9.4064 21.167C9.4064 20.4389 8.98112 19.8117 8.36641 19.5161L8.36832 19.5122C5.58588 18.1619 3.6673 15.3087 3.6673 12.0067C3.6673 7.40222 7.39804 3.66937 12.0001 3.66937C16.6021 3.66937 20.3327 7.40222 20.3327 12.0067C20.3327 14.7722 18.9856 17.2216 16.9137 18.7383C15.309 19.8949 13.7648 18.4373 13.7648 16.8381V12.0533H13.7625V10.8122C13.7625 10.448 13.4673 10.1526 13.1032 10.1526H10.738C10.374 10.1526 10.0788 10.448 10.0788 10.8122V12.0533V12.1751V17.6949H10.0791C10.0824 19.0939 11.4503 21.9608 14.0719 22.3235C17.5488 22.7786 19.2189 21.6065 19.6727 21.2366L19.6731 21.2371C19.6772 21.2338 19.6809 21.2301 19.6849 21.2267C19.7002 21.2142 19.7143 21.2024 19.7264 21.1921C19.7264 21.1921 19.7255 21.1913 19.7254 21.1912C22.3381 18.9887 24 15.6927 24 12.0067Z"
        fill="#D8232A"
      />
      <path
        d="M11.9178 9.35056C12.9365 9.35056 13.7623 8.52421 13.7623 7.50487C13.7623 6.48553 12.9365 5.65918 11.9178 5.65918C10.899 5.65918 10.0732 6.48553 10.0732 7.50487C10.0732 8.52421 10.899 9.35056 11.9178 9.35056Z"
        fill="#AB2430"
      />
      <mask
        id="mask0_965_16797"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="23"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 12.0066C0 16.7416 2.74013 20.8349 6.71966 22.7896L6.72043 22.7882C6.9756 22.923 7.26582 23 7.57433 23C8.58619 23 9.40633 22.1792 9.40633 21.1668C9.40633 20.4388 8.98104 19.8117 8.36632 19.5161L8.36824 19.5122C5.58578 18.1618 3.66732 15.3085 3.66732 12.0066C3.66732 7.40209 7.39795 3.66924 12 3.66924C16.6019 3.66924 20.3328 7.40209 20.3328 12.0066C20.3328 14.7722 18.9857 17.2216 16.9138 18.7382C15.3089 19.8948 13.7646 18.4372 13.7646 16.8381V12.0533H13.7624V10.8121C13.7624 10.4478 13.4672 10.1525 13.1032 10.1525H10.738C10.3739 10.1525 10.0787 10.4478 10.0787 10.8121V12.0533V12.1751V17.6949H10.079C10.0823 19.0937 11.4503 21.9608 14.0718 22.3235C17.5489 22.7785 19.2189 21.6063 19.6727 21.2366L19.6731 21.237C19.6772 21.2337 19.6809 21.2299 19.6848 21.2266C19.7002 21.2142 19.7143 21.2024 19.7264 21.1919C19.7264 21.1919 19.7255 21.1912 19.7254 21.191C22.3381 18.9885 24 15.6926 24 12.0066C24 5.37557 18.6274 0 12 0C5.37243 0 0 5.37557 0 12.0066Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_965_16797)">
        <path
          d="M13.7622 9.02441C13.7622 12.4254 13.7645 16.1122 13.7645 16.8386C13.7645 17.8816 15.1422 23.1343 20.5779 21.048C17.4505 25.9654 11.7167 22.8381 10.8232 21.5333C9.92964 20.2658 8.77554 13.1664 9.48286 10.1157C10.6371 9.63141 13.7622 9.02441 13.7622 9.02441Z"
          fill="#AB2430"
        />
      </g>
      <path d="M2 29.9999H3.0143V24H2V29.9999Z" fill="#D8232A" />
      <path
        d="M8.98191 26.4091C8.95079 26.211 8.89183 26.0145 8.80985 25.8332C8.66055 25.4689 8.45894 25.1505 8.20543 24.8816C8.0743 24.7509 7.93532 24.629 7.79202 24.52C7.64154 24.4052 7.47563 24.3103 7.30409 24.2391C6.94455 24.0804 6.57912 24 6.21736 24C5.84906 24 5.47997 24.0804 5.12553 24.2367C4.94902 24.3101 4.78311 24.4052 4.6321 24.52C4.55274 24.5806 4.47456 24.6448 4.39794 24.7132L4.40422 24H3.3877V29.9999H4.402L4.40174 26.9584C4.40762 26.8349 4.42318 26.7129 4.44776 26.5953C4.47168 26.4795 4.50554 26.3647 4.54751 26.256C4.64413 26.012 4.77448 25.805 4.9417 25.6335C5.09585 25.4627 5.29039 25.324 5.52311 25.2196C5.7242 25.1251 5.95783 25.0771 6.21736 25.0771C6.45819 25.0771 6.68999 25.1258 6.90611 25.2215C7.12249 25.3177 7.31782 25.4574 7.48648 25.637C7.57054 25.7265 7.64454 25.8233 7.70717 25.9247C7.77228 26.0302 7.83007 26.1434 7.87714 26.2558C7.97375 26.5009 8.02291 26.7513 8.02291 27V29.9999H9.03708L9.03695 26.9786C9.0295 26.7854 9.01107 26.5935 8.98191 26.4091Z"
        fill="#D8232A"
      />
      <path d="M9.375 29.9999H10.3892V24H9.375V29.9999Z" fill="#D8232A" />
      <path
        d="M11.6933 26.2671C11.7418 26.1446 11.7986 26.0326 11.8613 25.9337C11.9249 25.8344 11.9998 25.7385 12.0849 25.6481C12.26 25.4621 12.4549 25.3226 12.6676 25.2322C12.8979 25.1293 13.1336 25.0771 13.3682 25.0771C13.6026 25.0771 13.8382 25.1293 14.0681 25.2319C14.1844 25.2841 14.2901 25.3443 14.3822 25.4106C14.4758 25.4781 14.5664 25.5581 14.6513 25.6481L14.8214 25.8289L15.5576 25.0681L15.3737 24.8826C15.1005 24.6062 14.7997 24.3885 14.4727 24.2317C14.1207 24.0779 13.7489 24 13.3682 24C12.9796 24 12.6108 24.0769 12.2717 24.2286C11.9344 24.38 11.6303 24.5979 11.3684 24.876C11.1063 25.1544 10.901 25.4774 10.7587 25.8355C10.6159 26.1947 10.5435 26.5866 10.5435 26.9998V27.5438H15.1123C15.0929 27.6102 15.0694 27.6769 15.0414 27.7444C14.9579 27.9647 14.8268 28.1701 14.6518 28.3548C14.566 28.4454 14.4757 28.5246 14.3826 28.5909C14.2899 28.6573 14.1845 28.717 14.0688 28.7684C13.8388 28.8707 13.603 28.9227 13.3682 28.9227C13.1336 28.9227 12.8979 28.8705 12.6637 28.7659C12.4545 28.6768 12.2598 28.5378 12.0849 28.3517L11.9125 28.1683L11.1966 28.9289L11.3678 29.1121C11.6297 29.3923 11.9334 29.6125 12.2703 29.7663C12.6091 29.9213 12.9785 29.9999 13.3682 29.9999C13.7489 29.9999 14.1199 29.9238 14.4782 29.7702C14.7982 29.6204 15.0992 29.4057 15.3779 29.1268C15.5061 28.9911 15.6211 28.841 15.7194 28.6806C15.816 28.5239 15.9023 28.3529 15.9774 28.1705C16.1204 27.8118 16.1928 27.4214 16.1928 27.0099V26.4668H11.6272C11.6461 26.3973 11.6684 26.3303 11.6933 26.2671Z"
        fill="#D8232A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.175 24.876C21.4299 25.1472 21.6331 25.4677 21.7789 25.8291C21.9257 26.1927 22 26.5866 22 26.9998V29.9999H20.9858V29.2919C20.8266 29.4346 20.6516 29.5603 20.462 29.6678C20.0734 29.8881 19.6405 29.9999 19.1753 29.9999C18.7857 29.9999 18.4146 29.9209 18.0726 29.7651C17.732 29.6101 17.4304 29.3944 17.1756 29.1236C16.9204 28.8527 16.7172 28.5322 16.5715 28.1708C16.425 27.8078 16.3506 27.4139 16.3506 26.9998C16.3506 26.5862 16.425 26.1922 16.5715 25.8291C16.7173 25.4674 16.9206 25.1469 17.1756 24.876C17.4304 24.6053 17.732 24.3896 18.0726 24.2345C18.4146 24.0789 18.7857 24 19.1753 24C19.5649 24 19.9358 24.0789 20.2777 24.2345C20.6179 24.3892 20.9196 24.605 21.175 24.876ZM20.9504 27.4153C20.9738 27.3024 20.9858 27.1848 20.9858 27.0656V26.9998C20.9858 26.7418 20.9394 26.4862 20.848 26.2397C20.8022 26.1311 20.7442 26.0182 20.68 25.9142C20.6204 25.8179 20.5504 25.7329 20.4722 25.6618L20.4615 25.6521L20.4521 25.6411C20.3755 25.5535 20.2886 25.4737 20.1931 25.4041C20.0964 25.3336 19.9911 25.2711 19.8813 25.2189C19.659 25.1264 19.4183 25.0771 19.1753 25.0771C18.9261 25.0771 18.6859 25.126 18.4617 25.2226C18.2392 25.3284 18.047 25.4706 17.8986 25.641L17.8889 25.6518L17.8782 25.6618C17.8 25.7329 17.7301 25.8179 17.6707 25.914C17.6057 26.0194 17.5478 26.1321 17.4986 26.2497C17.4113 26.4856 17.3649 26.7414 17.3649 26.9998C17.3649 27.2652 17.4109 27.52 17.5018 27.7575C17.6018 27.9885 17.7345 28.1955 17.892 28.3627C18.0496 28.5301 18.2445 28.6712 18.4719 28.7819C18.5779 28.8295 18.6941 28.8663 18.8102 28.8884C18.9296 28.9111 19.0523 28.9227 19.1753 28.9227C19.2982 28.9227 19.4208 28.9111 19.5397 28.8884C19.6563 28.8663 19.7725 28.8295 19.8856 28.7787C19.9926 28.7283 20.0991 28.6666 20.1979 28.5979C20.291 28.5329 20.3767 28.4561 20.452 28.3698C20.6159 28.1955 20.7488 27.9885 20.8532 27.7468C20.8919 27.6469 20.9258 27.5319 20.9504 27.4153Z"
        fill="#D8232A"
      />
    </svg>
  );
};

export default InisaLogo;
