import PropTypes from "prop-types";
import { Box, Grid, styled, Typography } from "@mui/material";

const SGrid = styled(Grid)(({ borderRadius, minHeight, striped }) => ({
  padding: "16px",
  display: "flex",
  minHeight: minHeight,
  "&:nth-of-type(even)": {
    backgroundColor: striped == "even" ? "#F6F6F6" : "#fff",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: striped == "odd" ? "#F6F6F6" : "#fff",
  },
  "&:last-child": {
    borderBottomLeftRadius: borderRadius ? "8px" : "0px",
    borderBottomRightRadius: borderRadius ? "8px" : "0px",
  },
  marginLeft: "0px",
  marginTop: "0px",
}));

import React from "react";

const MainGrid = ({
  label,
  value,
  labelMd,
  valueMd,
  semicolon,
  variant,
  striped,
  minHeight,
  borderRadius,
  center,
  ...props
}) => {
  return (
    <SGrid
      borderRadius={borderRadius}
      minHeight={minHeight}
      striped={striped}
      container
      {...props}
    >
      <Grid item xs={12} sm={labelMd} md={labelMd}>
        <Typography
          variant={variant[0]}
          display="flex"
          sx={{
            alignItems: center ? "center" : "normal",
            height: "100%",
            width: "100%",
          }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={labelMd}
        md={valueMd}
        sx={{
          display: "flex",
          alignItems: center ? "center" : "normal",
          width: "100%",
          marginTop: {
            xs: "20px",
            sm: "0px",
          },
          paddingBottom: {
            xs: "20px",
            sm: "0px",
          },
        }}
      >
        <Typography
          variant={variant[1]}
          display="flex"
          gap="10px"
          sx={{
            alignItems: center ? "center" : "normal",
            width: "100%",
          }}
        >
          {semicolon && (
            <Box
              component="span"
              sx={{
                display: {
                  xs: "none",
                  sm: "block",
                },
                width: "10px",
              }}
            >
              :
            </Box>
          )}
          {value}
        </Typography>
      </Grid>
    </SGrid>
  );
};

MainGrid.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  labelMd: PropTypes.number,
  valueMd: PropTypes.number,
  semicolon: PropTypes.bool,
  variant: PropTypes.any,
  striped: PropTypes.string,
  minHeight: PropTypes.string,
  borderRadius: PropTypes.bool,
  center: PropTypes.bool,
};

MainGrid.defaultProps = {
  label: "-",
  value: "-",
  labelMd: 2,
  valueMd: 8,
  semicolon: true,
  variant: ["gridLabel", "gridValue"],
  striped: "even",
  minHeight: "54px",
  borderRadius: true,
  center: false,
};

export default MainGrid;
