import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const select = (state) => state.penggunaDetail || initialState;

export const User = createSelector(select, (p) => p.user);
export const Loading = createSelector(select, (p) => p.loading);
export const Params = createSelector(select, (p) => p.params);
export const LogActivity = createSelector(select, (p) => p.logActivity);
export const Task = createSelector(select, (p) => p.tasks);


