import { Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const Page = ({ title, desc }) => {
  return (
    <>
      <Typography variant="h1">{title}</Typography>
      {desc && (
        <Typography
          variant="desc"
          sx={{
            mt: 1,
          }}
        >
          Dasbor{" "}
          {window.location.pathname
            .replaceAll("-", " ")
            .replaceAll(/[0-9]/g, "")
            .split("/")
            .map((path, i, arr) => {
              if (i == arr.length - 1)
                return (
                  <span style={{ color: "#cc1d15", fontWeight: 700 }} key={i}>
                    {path}
                  </span>
                );
              else {
                return path == "csr bansos" ? (
                  <span style={{ textTransform: "none" }} key={i}>
                    {" "}
                    CSR Bansos /{" "}
                  </span>
                ) : (
                  `${path} / `
                );
              }
            })}
        </Typography>
      )}
    </>
  );
};

Page.propTypes = {
  desc: PropTypes.bool,
  title: PropTypes.string,
};

Page.defaultProps = {
  title: "",
  desc: false,
};

export default Page;
