import React from "react";
import menuItems from "menu-items";
import NavItem from "./NavItem";
import NavCollapse from "./NavCollapse";

const MenuList = ({ openSidebar }) => {
  const navItems = menuItems.map((item, i) => {
    switch (item.type) {
      case "item":
        return <NavItem item={item} key={i} openSidebar={openSidebar} />;
      case "collapse":
        return <NavCollapse item={item} key={i} openSidebar={openSidebar} />;
      default:
        return "-";
    }
  });

  return navItems;
};

export default MenuList;
