import { Alert, Box, Button, Grid } from "@mui/material";
import React from "react";
import VerticalGrid from "components/Grid/VerticalGrid";
import WarningIconSmall from "assets/paper/WarningIconSmall";
import TextFieldv3 from "components/TextField/TextFieldv3";
import PropTypes from "prop-types";
import MainController from "components/Controller/MainController";

const StepView3 = ({
  handleBack,
  controller: Controller,
  control,
  errors,
  clearErrors,
}) => {
  return (
    <Box
      sx={{
        mt: 1.5,
      }}
      display="flex"
      flexDirection="column"
    >
      <Alert icon={<WarningIconSmall />} variant="warning">
        Mohoh pastikan nama pemilik rekening sesuai dengan nama KTP atau KITAS
        yang telah didaftarkan. Gunakan akun bank perusahaan yang sesuai dengan
        NPWP yang telah didaftarkan.
      </Alert>

      <Grid
        container
        spacing={3}
        sx={{
          py: 2,
        }}
      >
        <VerticalGrid label={"Nama Bank"} md={12}>
          <MainController
            controller={Controller}
            name="BankName"
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            rules={{ required: "Nama Bank wajib diisi" }}
            render={({ field }) => (
              <TextFieldv3 {...field} placeholder="Masukkan nama bank" />
            )}
          />
        </VerticalGrid>
        <VerticalGrid label={"Nomor Rekening"} md={12}>
          <MainController
            controller={Controller}
            name="BankRekeningNo"
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            rules={{ required: "Nomor Rekening wajib diisi" }}
            render={({ field }) => (
              <TextFieldv3 {...field} placeholder="Masukkan nomor rekening" />
            )}
          />
        </VerticalGrid>
        <VerticalGrid label={"Nama Pemilik Rekening"} md={12}>
          <MainController
            controller={Controller}
            name="BankRekeningName"
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            rules={{ required: "Nama Pemilik Rekening wajib diisi" }}
            render={({ field }) => (
              <TextFieldv3
                {...field}
                placeholder="Masukkan nama pemilik rekening"
              />
            )}
          />
        </VerticalGrid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          pt: 2,
          gap: 2,
        }}
      >
        <Button
          color="inherit"
          onClick={handleBack}
          variant="gray"
          sx={{ mr: 1 }}
        >
          Kembali
        </Button>

        <Button type="submit" variant="primary" sx={{ width: "200px" }}>
          {"Selanjutnya"}
        </Button>
      </Box>
    </Box>
  );
};

StepView3.propTypes = {
  handleBack: PropTypes.func,
  clearErrors: PropTypes.any,
  control: PropTypes.any,
  controller: PropTypes.any,
  errors: PropTypes.any,
};

StepView3.defaultProps = {
  handleBack: null,
  clearErrors: null,
  control: null,
  controller: null,
  errors: null,
};

export default StepView3;
