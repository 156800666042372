import React from "react";

const PlusCircleIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3583_129830)">
        <path
          d="M9.99984 1.66675C5.39984 1.66675 1.6665 5.40008 1.6665 10.0001C1.6665 14.6001 5.39984 18.3334 9.99984 18.3334C14.5998 18.3334 18.3332 14.6001 18.3332 10.0001C18.3332 5.40008 14.5998 1.66675 9.99984 1.66675ZM14.1665 10.8334H10.8332V14.1667H9.1665V10.8334H5.83317V9.16675H9.1665V5.83341H10.8332V9.16675H14.1665V10.8334Z"
          fill="#CC1D15"
        />
      </g>
      <defs>
        <clipPath id="clip0_3583_129830">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlusCircleIcon;
