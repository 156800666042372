import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React from "react";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const MainDatePicker = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker
        {...props}
        inputFormat="DD/MM/YYYY"
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              placeholder: props.placeholder,
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

MainDatePicker.propTypes = {
  inputFormat: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
};

MainDatePicker.defaultProps = {
  inputFormat: "",
  value: null,
  placeholder: "",
};
export default MainDatePicker;
