import PropTypes from "prop-types";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Divider,
  StepConnector,
  stepConnectorClasses,
  StepIcon,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckIcon from "assets/paper/CheckIcon";
import { KeyboardArrowRight } from "@mui/icons-material";

function QontoStepIcon(props) {
  const { active, completed, icon } = props;

  return (
    <>
      {completed ? (
        <Box
          sx={{
            width: "32px",
            height: "32px",
            backgroundColor: "#cc1d15",
            border: "2px solid #CC1D15",
            color: "#fff",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: "all 0.4s",
          }}
        >
          {icon}
        </Box>
      ) : active ? (
        <Box
          sx={{
            width: "32px",
            height: "32px",
            backgroundColor: "#cc1d15",
            color: "#fff",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: "all 0.4s",
            fontWeight: "700",
          }}
        >
          {icon}
        </Box>
      ) : (
        <Box
          sx={{
            width: "32px",
            height: "32px",
            backgroundColor: "#E2E8F0",
            color: "#94A3B8",
            border: "2px solid #94A3B8",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            transition: "all 0.4s",
            fontWeight: "700",
          }}
        >
          {icon}
        </Box>
      )}
    </>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.any,
  className: PropTypes.any,
  completed: PropTypes.any,
  icon: PropTypes.any,
};

QontoStepIcon.defaultProps = {
  active: null,
  className: null,
  completed: null,
  icon: null,
};

const ArrowIcon = () => {
  return (
    <StepLabel
      sx={{
        "& .MuiStepLabel-label": {
          display: "flex",
          marginTop: "auto",
        },
        "& .MuiStepLabel-label.Mui-active": {
          color: "#cc1d15", // color icon blue (when active)
        },
        "& .MuiStepLabel-label.Mui-completed": {
          color: "#cc1d15", // color icon blue (when active)
        },
        m: "auto",
      }}
      icon={<span />}
    >
      <KeyboardArrowRight />
    </StepLabel>
  );
};

const MainStepper = ({
  steps,
  withAction,
  activeStep,
  children,
  handleNext,
  handleBack,
  handleSubmit,
}) => {
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Stepper
          activeStep={activeStep}
          // alternativeLabel
          sx={{
            py: 2,
            "&.Mui-completed": {
              color: "#cc1d15",
            },
          }}
          connector={<ArrowIcon />}
        >
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <Box
                    sx={{
                      // width: "120px",
                      whiteSpace: "wrap",
                      textAlign: "center",
                      // margin: "auto",
                      marginLeft: "2px",
                      fontWeight: 700,
                      color: activeStep >= index ? "#202021" : "#878889",
                    }}
                  >
                    {label}
                  </Box>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {children}
      </Box>
    </>
  );
};

MainStepper.propTypes = {
  activeStep: PropTypes.number,
  children: PropTypes.node,
  setActiveStep: PropTypes.func,
  steps: PropTypes.array,
  withAction: PropTypes.bool,
  handleNext: PropTypes.func,
  handleBack: PropTypes.func,
  handleSubmit: PropTypes.func,
};

MainStepper.defaultProps = {
  steps: null,
  withAction: true,
  children: null,
  activeStep: 0,
  setActiveStep: () => {},
  handleNext: () => {},
  handleBack: () => {},
  handleSubmit: null,
};

export default MainStepper;
