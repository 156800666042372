import { Box } from "@mui/material";
import PropTypes from "prop-types";
import QrScanner from "qr-scanner";
import React, { useEffect } from "react";

const QRScanner = ({ noScan, onError, onSuccess }) => {
  useEffect(() => {
    if (noScan) {
      return;
    }
    const qrScanner = new QrScanner(
      document.getElementById("qr-video"),
      (data) => onSuccess(data, qrScanner),
      {
        onDecodeError: onError,
        maxScansPerSecond: 5000,
        highlightScanRegion: true,
        highlightCodeOutline: true,
      }
    );

    qrScanner.start();

    return () => {
      qrScanner.stop();
    };
  }, []);

  return (
    <Box className="video-container">
      <Box
        sx={{
          width: "100%",
          height: "500px",
          objectFit: "cover",
        }}
        component="video"
        id="qr-video"
      ></Box>
    </Box>
  );
};

QRScanner.propTypes = {
  onError: PropTypes.any,
  onSuccess: PropTypes.any,
  noScan: PropTypes.bool,
};

QRScanner.defaultProps = {
  onError: null,
  onSuccess: null,
  noScan: false,
};

export default QRScanner;
