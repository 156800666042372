import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";

const Days = ({ date, startDate, endDate, onClick, past }) => {
  const isPast = moment().subtract(1, "day").isAfter(date);

  let className = [];

  if (moment().isSame(new Date(date), "day")) {
    className.push("active");
  }

  if (date.isSame(new Date(startDate), "day")) {
    className.push("start");
  }

  if (date.isSame(new Date(startDate), "day") && endDate == null) {
    className.push("start end");
  }

  if (date.isBetween(new Date(startDate), new Date(endDate), "day")) {
    className.push("between");
  }

  if (date.isSame(new Date(endDate), "day")) {
    className.push("end");
  }

  if (past && isPast) {
    className.push("muted");
  }

  return (
    <Box
      component={"td"}
      onClick={() => onClick(date)}
      sx={{
        "&:has(.between)": {
          backgroundColor: "#F9E8E7",
        },
        "&:has(.start):has(.end)": {
          background: "linear-gradient(90deg, #fff 50%, #fff 30%)",
        },
        "&:has(.start)": {
          background: "linear-gradient(90deg, #fff 50%, #F9E8E7 30%)",
        },
        "&:has(.end)": {
          background: "linear-gradient(90deg, #F9E8E7 50%, #fff 50%)",
        },
        "&:has(.between):last-child": {
          background: "linear-gradient(90deg, #F9E8E7 90%, #fff 50%)",
        },
        "&:has(.between):first-of-type": {
          background: "linear-gradient(270deg, #F9E8E7 90%, #fff 50%)",
        },
        "&:has(.start):last-child": {
          background: "none",
        },
        "&:has(.end):first-of-type": {
          background: "none",
        },
        ".start": {
          backgroundColor: "#cc1d15",
          color: "#fff",
        },
        ".end": {
          backgroundColor: "#cc1d15",
          color: "#fff",
        },
        cursor: "pointer",
        textAlign: "center",
      }}
    >
      <Typography
        component="div"
        variant="labelMedium2"
        className={className.join(" ")}
        sx={{
          width: "30px",
          margin: "auto",
        }}
      >
        {date.date() || ""}
      </Typography>
    </Box>
  );
};

Days.propTypes = {
  date: PropTypes.shape({
    date: PropTypes.func,
    isBetween: PropTypes.func,
    isSame: PropTypes.func,
  }),
  endDate: PropTypes.any,
  onClick: PropTypes.func,
  past: PropTypes.any,
  startDate: PropTypes.any,
};

Days.defaultProps = {
  date: null,
  endDate: null,
  onClick: () => {},
  past: null,
  startDate: null,
};

export default Days;
