import React from "react";

const XCircleIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3583_129191)">
        <path
          d="M5.83342 9.16675V10.8334H14.1667V9.16675H5.83342ZM10.0001 1.66675C5.40008 1.66675 1.66675 5.40008 1.66675 10.0001C1.66675 14.6001 5.40008 18.3334 10.0001 18.3334C14.6001 18.3334 18.3334 14.6001 18.3334 10.0001C18.3334 5.40008 14.6001 1.66675 10.0001 1.66675ZM10.0001 16.6667C6.32508 16.6667 3.33341 13.6751 3.33341 10.0001C3.33341 6.32508 6.32508 3.33341 10.0001 3.33341C13.6751 3.33341 16.6667 6.32508 16.6667 10.0001C16.6667 13.6751 13.6751 16.6667 10.0001 16.6667Z"
          fill="#CC1D15"
        />
      </g>
      <defs>
        <clipPath id="clip0_3583_129191">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default XCircleIcon;
