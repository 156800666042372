import React from "react";

const CalendarIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_960_10883)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5556 2.66659H16.4816C17.5001 2.66659 18.3334 3.49159 18.3334 4.49992V17.3333C18.3334 18.3416 17.5001 19.1666 16.4816 19.1666H3.5186C3.02746 19.1666 2.55643 18.9734 2.20915 18.6296C1.86185 18.2858 1.66675 17.8195 1.66675 17.3333L1.67601 4.49992C1.67601 3.49159 2.49082 2.66659 3.5186 2.66659H4.44453V0.833252H6.29638V2.66659H13.7038V0.833252H15.5556V2.66659ZM3.51856 17.3331H16.4815V8.16642H3.51856V17.3331ZM7.22222 11.8333H5.37037V9.99992H7.22222V11.8333ZM9.0742 11.8333H10.926V9.99992H9.0742V11.8333ZM14.6297 11.8333H12.7778V9.99992H14.6297V11.8333ZM5.37037 15.4998H7.22222V13.6665H5.37037V15.4998ZM10.926 15.4998H9.0742V13.6665H10.926V15.4998ZM12.7778 15.4998H14.6297V13.6665H12.7778V15.4998Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_960_10883">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalendarIcon;
