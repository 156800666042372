import React from "react";

const FileDownload = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5087 7.99168L14.708 9.16668L10.4552 13.3333L6.2023 9.16668L7.40161 7.99168L9.6046 10.1417V3.33334H11.3057V10.1417L13.5087 7.99168ZM15.5586 15V12.5H17.2598V15C17.2598 15.9167 16.4943 16.6667 15.5586 16.6667H5.35172C4.41609 16.6667 3.65057 15.9167 3.65057 15V12.5H5.35172V15H15.5586Z"
        fill="black"
      />
      <mask
        id="mask0_1323_4079"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="15"
        height="14"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5087 7.99168L14.708 9.16668L10.4552 13.3333L6.2023 9.16668L7.40161 7.99168L9.6046 10.1417V3.33334H11.3057V10.1417L13.5087 7.99168ZM15.5586 15V12.5H17.2598V15C17.2598 15.9167 16.4943 16.6667 15.5586 16.6667H5.35172C4.41609 16.6667 3.65057 15.9167 3.65057 15V12.5H5.35172V15H15.5586Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1323_4079)">
        <rect x="0.248291" width="20.4138" height="20" fill="currentColor" />
      </g>
    </svg>
  );
};

export default FileDownload;
