import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import MainGrid from "components/Grid/MainGrid";
import {
  KewarganegaraanData,
  ProdukList,
  TipeBisnis,
  TipeEntitasBisnis,
} from "../variable";

const StepView4 = ({ kategoriBisnisData, getValues, handleBack }) => {
  const detailBisnis = TipeBisnis.find(
    (i) => i.value == getValues("BizType")
  )?.label;
  const produk = getValues("BizProduct");
  const produkData = ProdukList.find((i) => i.id == produk)?.title;

  const informasiBisnis = useMemo(() => {
    return [
      {
        label: "Tipe Entitas Bisnis",
        value: TipeEntitasBisnis.find(
          (i) => i.value == getValues("BizEntitasType")
        )?.label,
      },
      {
        label: "Kategori Bisnis",
        value: kategoriBisnisData.find(
          (i) => i.value == getValues("BizCategory")
        )?.label,
      },
      {
        label: "URL Bisnis",
        value: getValues("BizUrl"),
      },
      {
        label: "Email PIC Finance",
        value: getValues("BizEmailPicFinance"),
      },
      {
        label: "No. Ponsel PIC Finance",
        value: getValues("BizHpPicFinance"),
      },
      {
        label: "Email PIC Teknis",
        value: getValues("BizEmailPicTech"),
      },
      {
        label: "No. Ponsel PIC Teknis",
        value: getValues("BizHpPicTech"),
      },
    ];
  }, []);

  const informasiPemilik = useMemo(() => {
    return [
      {
        label: "Alamat Pemilik",
        value: getValues("BizAddress"),
      },
      {
        label: "Kewarganegaraan",
        value: KewarganegaraanData.find(
          (i) => i.value == getValues("BizRegion")
        )?.label,
      },
      {
        label: "Kode Pos",
        value: getValues("BizPostCode"),
      },
      {
        label: "No. Ponsel Pemilik",
        value: getValues("BizPhone"),
      },
    ];
  }, []);

  const Dokumen = useMemo(() => {
    const data = [
      {
        label: "KTP Direktur",
        value: getValues("BizKtpImg"),
        type: "image",
      },
      {
        label: "Nama Sesuai KTP",
        value: getValues("BizKtpName"),
      },
      {
        label: "Nomor KTP",
        value: getValues("BizKtpNo"),
      },
      {
        label: "NPWP Pemilik Bisnis",
        value: getValues("BizNpwpImg"),
        type: "image",
      },
      {
        label: "Nama Sesuai NPWP",
        value: getValues("BizNpwpName"),
      },
      {
        label: "Nomor NPWP",
        value: getValues("BizNpwpNo"),
      },
      {
        label: "Alamat Sesuai NPWP",
        value: getValues("BizNpwpAlamat"),
      },
    ];

    return data.map((item, i) => {
      if (item.type == "image") {
        return {
          ...item,
          value: (
            <Box
              key={i}
              component="img"
              src={
                item.value
                  ? typeof item.value === "object"
                    ? URL.createObjectURL(item.value)
                    : item.value
                  : ""
              }
              sx={{
                width: "292px",
                height: "174px",
                objectFit: "contain",
              }}
            />
          ),
        };
      } else {
        return item;
      }
    });
  }, []);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexDirection="column" mt={3} gap={2}>
        <Typography variant="cardTitle">Tipe Bisnis</Typography>

        <MainGrid
          label="Tipe Bisnis"
          value={detailBisnis || "-"}
          striped="odd"
        />
        <MainGrid label="Produk" value={produkData || "-"} striped="odd" />
      </Box>

      <Box display="flex" flexDirection="column" mt={3} gap={2}>
        <Typography variant="cardTitle">Informasi Bisnis</Typography>
        <Box display="flex" flexDirection="column">
          {informasiBisnis.map(({ label, value }, i) => (
            <MainGrid
              borderRadius={false}
              key={i}
              label={label}
              value={value ? value : "-"}
              striped="odd"
            />
          ))}
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" mt={3} gap={2}>
        <Typography variant="cardTitle">Informasi Pemilik</Typography>
        <Box display="flex" flexDirection="column">
          {informasiPemilik.map(({ label, value }, i) => (
            <MainGrid
              borderRadius={false}
              key={i}
              label={label}
              value={value ? value : "-"}
              striped="odd"
            />
          ))}
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" mt={3} gap={2}>
        <Typography variant="cardTitle">Dokumen</Typography>
        <Box display="flex" flexDirection="column">
          {Dokumen.map(({ label, value }, i) => (
            <MainGrid
              borderRadius={false}
              key={i}
              label={label}
              value={value ? value : "-"}
              striped="odd"
            />
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          pt: 2,
          gap: 2,
        }}
      >
        <Button
          color="inherit"
          onClick={handleBack}
          variant="gray"
          sx={{ mr: 1 }}
        >
          Kembali
        </Button>

        <Button type="submit" variant="primary" sx={{ width: "200px" }}>
          {"Selanjutnya"}
        </Button>
      </Box>
    </Box>
  );
};

StepView4.propTypes = {
  defaultValues: PropTypes.any,
  getValues: PropTypes.func,
  handleBack: PropTypes.func,
  kategoriBisnisData: PropTypes.array,
};

StepView4.defaultProps = {
  defaultValues: {},
  getValues: null,
  handleBack: null,
  kategoriBisnisData: [],
};

export default StepView4;
