import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Fade,
  useTheme,
  useMediaQuery,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Collapse,
  TableContainer,
  LinearProgress,
} from "@mui/material";
import React, { useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import TooltipButton from "components/Button/MainDetailButton";
import { Box } from "@mui/system";
import { ExpandMore } from "@mui/icons-material";
import MainCheckbox from "components/Checkbox/MainCheckbox";

const MainTable = ({
  data,
  headers,
  action,
  no,
  increment,
  loading,
  width,
  detail,
  align,
  summaryKey,
  filter,
  is_duplicate,
  checkbox,
  handleClickCheckBox,
  checkBoxData,
  checkBoxValue,
  handleCheckBoxAll,
  ...props
}) => {
  const body = useRef();
  const theme = useTheme();
  const [accordVal, setAccordVal] = useState("");
  const goFilter = data?.filter((item) =>
    Boolean(filter) ? filter(item) : item
  );
  const matchs = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <PerfectScrollbar>
      {matchs ? (
        <Fade in={!loading} ref={body}>
          <Table
            {...props}
            sx={{
              minWidth: 650,
              margin: "0px 0px",
              p: 2,
              pr: 3,
              whiteSpace: "nowrap",
              ...props.sx,
            }}
          >
            <TableHead
              sx={{
                backgroundColor: "#F3F4F6",
              }}
            >
              <TableRow>
                {no && <TableCell align="center">No</TableCell>}
                {checkbox && (
                  <TableCell
                    align="center"
                    sx={{
                      padding: "0 10px !important",
                    }}
                  >
                    <MainCheckbox
                      checked={
                        goFilter?.length
                          ? goFilter.every((i) =>
                              checkBoxData.includes(i[checkBoxValue])
                            )
                          : false
                      }
                      onClick={() => handleCheckBoxAll(goFilter)}
                    />
                  </TableCell>
                )}
                {headers?.map((header, i) => {
                  return (
                    <TableCell
                      key={i}
                      width={header?.width + "%"}
                      align={header?.align || "left"}
                    >
                      {header?.name}
                    </TableCell>
                  );
                })}
                {Boolean(action) && (
                  <TableCell align={align || "left"}>{action}</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {!loading &&
                goFilter?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={
                      is_duplicate
                        ? {
                            backgroundColor: "#ff6c3e !important",
                          }
                        : {}
                    }
                  >
                    {no && (
                      <TableCell align="center">{index + increment}</TableCell>
                    )}
                    {checkbox && (
                      <TableCell
                        align="center"
                        sx={{
                          padding: "0 10px !important",
                        }}
                      >
                        <MainCheckbox
                          onClick={() => handleClickCheckBox(row)}
                          checked={checkBoxData.some(
                            (i) => i == row[checkBoxValue]
                          )}
                        />
                      </TableCell>
                    )}
                    {headers &&
                      headers?.map((header, hi) => {
                        return (
                          <TableCell align={header?.align || "left"} key={hi}>
                            {header.key
                              ? row?.[header.key] || "-"
                              : header.custom
                              ? header?.custom(row)
                              : "-"}
                          </TableCell>
                        );
                      })}
                    {Boolean(action) && (
                      <TableCell align="left">
                        <Stack
                          direction={"row"}
                          spacing={1}
                          justifyContent={"flex-start"}
                        >
                          {detail?.length !== 0 &&
                            detail?.map((det, j) => {
                              return (
                                <TooltipButton key={j} {...det} root={row} />
                              );
                            })}
                        </Stack>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Fade>
      ) : (
        <Box style={{ p: 2 }}>
          {!loading && (
            <>
              {goFilter?.map((item, index) => (
                <Accordion
                  expanded={accordVal === index}
                  key={index}
                  onChange={(e) => {
                    let newAccordVal = accordVal === index ? "" : index;
                    setAccordVal(newAccordVal);
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls={index + "-content"}
                    id={index + "-header"}
                    sx={{
                      background: "#fff !important",
                      borderBottom: "1px solid #EBECED",
                    }}
                  >
                    <Typography variant="textCommon">
                      {typeof summaryKey == "function"
                        ? summaryKey(item)
                        : item[summaryKey] || "-"}
                      {/* {item[summaryKey] || "-"} */}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {Boolean(checkbox) && (
                      <Grid
                        container
                        sx={{
                          paddingBottom: 1,
                        }}
                        spacing={3}
                      >
                        <Grid item xs={5}>
                          <Typography variant="textCommon">Check</Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Stack
                            direction={"row"}
                            spacing={1}
                            justifyContent={"flex-start"}
                          >
                            <MainCheckbox
                              onClick={() => handleClickCheckBox(item)}
                              checked={checkBoxData.some(
                                (i) => i == item[checkBoxValue]
                              )}
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    )}

                    {headers?.map((header, i) => {
                      return (
                        <Grid
                          container
                          sx={{
                            margin: "10px 0px",
                          }}
                          key={i}
                        >
                          <Grid item xs={12} md={5}>
                            <Typography variant="gridLabel">
                              {header.name}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={7}
                            sx={{
                              paddingTop: {
                                // xs: "10px",
                                md: "0px",
                              },
                              paddingBottom: {
                                xs: "10px",
                                md: "0px",
                              },
                            }}
                          >
                            <Typography variant="gridValue">
                              {header.key
                                ? item?.[header.key] || "-"
                                : header.custom
                                ? header?.custom(item)
                                : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                    {Boolean(action) && (
                      <Grid
                        container
                        sx={{
                          paddingBottom: 1,
                        }}
                        spacing={3}
                      >
                        <Grid item xs={5}>
                          <Typography variant="textCommon">{action}</Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Stack
                            direction={"row"}
                            spacing={1}
                            justifyContent={"flex-start"}
                          >
                            {detail?.length !== 0 &&
                              detail?.map((det, j) => {
                                return (
                                  <TooltipButton key={j} {...det} root={item} />
                                );
                              })}
                          </Stack>
                        </Grid>
                      </Grid>
                    )}
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          )}
        </Box>
      )}
      {loading && (
        <Box
          align="center"
          sx={{
            display: "flex",
            height: `${body?.current?.offsetHeight || 200}px !important`,
            // minHeight: "200px",
            my: 2,
            width: "100%",
            // alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "300px" }}>
            <LinearProgress
              sx={{
                ".MuiLinearProgress-bar": {
                  backgroundColor: "#3F4144",
                },
                backgroundColor: "#E8E8E8",
              }}
            />
          </Box>
        </Box>
      )}

      {!loading && (data?.length <= 0 || !data) ? (
        <Box
          align="center"
          sx={{
            display: "flex",
            height: `${body?.current?.offsetHeight || 400}px !important`,
            width: "100%",
            my: 5,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="textCommon">Data tidak ditemukan</Typography>
        </Box>
      ) : (
        ""
      )}
    </PerfectScrollbar>
  );
};

MainTable.propTypes = {
  data: PropTypes.array,
  headers: PropTypes.array,
  action: PropTypes.string,
  no: PropTypes.bool,
  increment: PropTypes.number,
  loading: PropTypes.bool,
  width: PropTypes.string,
  detail: PropTypes.array,
  align: PropTypes.string,
  summaryKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  sx: PropTypes.object,
  filter: PropTypes.any,
  is_duplicate: PropTypes.bool,
  checkbox: PropTypes.bool,
  handleClickCheckBox: PropTypes.func,
  checkBoxData: PropTypes.array,
  checkBoxValue: PropTypes.string,
  handleCheckBoxAll: PropTypes.func,
};

MainTable.defaultProps = {
  data: [],
  headers: [],
  action: "Aksi",
  no: true,
  increment: 1,
  loading: false,
  width: "100%",
  detail: [],
  align: "left",
  summaryKey: "",
  sx: {},
  filter: null,
  is_duplicate: false,
  checkbox: false,
  handleClickCheckBox: () => {},
  checkBoxData: [],
  checkBoxValue: "id",
  handleCheckBoxAll: () => {},
};

export default MainTable;
