import React from "react";

const CopyIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5526 3H6.07895C5.21053 3 4.5 3.73636 4.5 4.63636V16.0909H6.07895V4.63636H15.5526V3ZM17.9211 6.27273H9.23684C8.36842 6.27273 7.65789 7.00909 7.65789 7.90909V19.3636C7.65789 20.2636 8.36842 21 9.23684 21H17.9211C18.7895 21 19.5 20.2636 19.5 19.3636V7.90909C19.5 7.00909 18.7895 6.27273 17.9211 6.27273ZM9.23684 19.3636H17.9211V7.90909H9.23684V19.3636Z"
        fill="black"
      />
      <mask
        id="mask0_1879_3956"
        // style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="4"
        y="3"
        width="16"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5526 3H6.07895C5.21053 3 4.5 3.73636 4.5 4.63636V16.0909H6.07895V4.63636H15.5526V3ZM17.9211 6.27273H9.23684C8.36842 6.27273 7.65789 7.00909 7.65789 7.90909V19.3636C7.65789 20.2636 8.36842 21 9.23684 21H17.9211C18.7895 21 19.5 20.2636 19.5 19.3636V7.90909C19.5 7.00909 18.7895 6.27273 17.9211 6.27273ZM9.23684 19.3636H17.9211V7.90909H9.23684V19.3636Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_1879_3956)">
        <rect x="2.5" y="2" width="20" height="20" fill="currentColor" />
      </g>
    </svg>
  );
};

export default CopyIcon;
