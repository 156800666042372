import React from "react";

const EyeIconv2 = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_867_1734)">
        <path
          d="M10.0002 5.00004C13.1585 5.00004 15.9752 6.77504 17.3502 9.58337C15.9752 12.3917 13.1585 14.1667 10.0002 14.1667C6.84183 14.1667 4.02516 12.3917 2.65016 9.58337C4.02516 6.77504 6.84183 5.00004 10.0002 5.00004ZM10.0002 3.33337C5.8335 3.33337 2.27516 5.92504 0.833496 9.58337C2.27516 13.2417 5.8335 15.8334 10.0002 15.8334C14.1668 15.8334 17.7252 13.2417 19.1668 9.58337C17.7252 5.92504 14.1668 3.33337 10.0002 3.33337ZM10.0002 7.50004C11.1502 7.50004 12.0835 8.43337 12.0835 9.58337C12.0835 10.7334 11.1502 11.6667 10.0002 11.6667C8.85016 11.6667 7.91683 10.7334 7.91683 9.58337C7.91683 8.43337 8.85016 7.50004 10.0002 7.50004ZM10.0002 5.83337C7.9335 5.83337 6.25016 7.51671 6.25016 9.58337C6.25016 11.65 7.9335 13.3334 10.0002 13.3334C12.0668 13.3334 13.7502 11.65 13.7502 9.58337C13.7502 7.51671 12.0668 5.83337 10.0002 5.83337Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_867_1734">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyeIconv2;
