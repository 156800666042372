import React from "react";
import Providers from "components/RouteRoleProviders";
import RedeemSubsidi from "pages/Redeem/Subsidi";
import DetailPenukaran from "pages/Redeem/Subsidi/Detail/OnlineTransaksi/Penukaran";
import DetailOfflineTransaksi from "pages/Redeem/Subsidi/Detail/OfflineTransaksi/Penukaran";

const redeemRoutes = [
  {
    path: "/redeem-subsidi",
    element: (
      <Providers permission="Web:Pengguna:Get">
        <RedeemSubsidi />
      </Providers>
    ),
  },
  {
    path: "/redeem-subsidi/voucer-bansos/:id",
    element: (
      <Providers permission="Web:Pengguna:Get">
        <DetailPenukaran />
      </Providers>
    ),
  },
  {
    path: "/redeem-subsidi/subsidi-bansos/:id",
    element: (
      <Providers permission="Web:Pengguna:Get">
        <DetailOfflineTransaksi />
      </Providers>
    ),
  },
];

export default redeemRoutes;
