import masterProdukSlice from "redux/inventory/MasterProduk/slice";
import masterDistribusiSlice from "redux/inventory/MasterDistribusi/KirimProduk/slice";
import terimaProdukSlice from "./MasterDistribusi/TerimaProduk/slice";

const inventoryReducer = {
  masterProduk: masterProdukSlice,
  masterDistribusi: masterDistribusiSlice,
  terimaProduk: terimaProdukSlice,
};

export default inventoryReducer;
