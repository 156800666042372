import { createSlice } from "@reduxjs/toolkit";
import { pagination } from "redux/pagination";
import { getPenerimaBansos } from "../action";

export const initialState = {
  list: [],
  currentPage: 1,
  params: {
    limit: 10,
    offset: 0,
    tipe: 0,
  },
  filterName: "search",
  totalItems: 0,
  message: "",
  fail: 0,
  semua: 0,
  tidakDitemukan: 0,
  tidakSama: 0,
  unverif: 0,
  verif: 0,
  namaProgram: "",
  loading: false,
};

export const subsidiSlice = createSlice({
  name: "subsidiBansos",
  initialState,
  reducers: {
    ...pagination,
    changeTipe: (state, action) => {
      state.params = {
        ...state.params,
        tipe: action.payload,
      };
    },
    changeSearch: (state, action) => {
      state.params = {
        ...state.params,
        search: action.payload,
      };
    },
  },
  extraReducers: {
    [getPenerimaBansos.pending]: (state) => {
      state.loading = true;
    },
    [getPenerimaBansos.rejected]: (state, action) => {
      state.loading = false;
      console.log(action);
      state.message = action.error.message;
    },
    [getPenerimaBansos.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload.list;
      state.totalItems = action.payload.total_items;
      state.fail = action.payload.fail;
      state.semua = action.payload.semua;
      state.tidakDitemukan = action.payload.tidakditemukan;
      state.tidakSama = action.payload.tidaksama;
      state.unverif = action.payload.unverif;
      state.verif = action.payload.verif;
      state.namaProgram = action.payload.nama_program;
    },
  },
});

export const {
  changePage,
  changeLimit,
  addFilter,
  changeTipe,
  changeSearch,
} = subsidiSlice.actions;

export default subsidiSlice.reducer;
