import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import React from "react";
import { Grid } from "@mui/material";
import TextFieldv3 from "components/TextField/TextFieldv3";
import VerticalGrid from "components/Grid/VerticalGrid";
import MainController from "components/Controller/MainController";

const ManualValidasi = ({
  handleSubmit,
  isValid,
  errors,
  control,
  Controller,
  open,
  onClose,
  onSubmit,
  action,
}) => {
  const goSubmit = (data) => {
    action.fetch(data.ManualValidasi.Id);
  };

  const getProps = (name) => {
    return {
      controller: Controller,
      name: name,
      control: control,
      errors: errors,
    };
  };

  return (
    <>
      <MainDialog
        open={open}
        onClose={() => {
          onClose();
        }}
        title={"Redeem Subsidi - Manual Validasi"}
        loading={action.loading}
        valid={isValid}
        handleSubmit={handleSubmit}
        onSubmit={goSubmit}
        submitText={"Selanjutnya"}
        customWidth="1000px"
      >
        <Grid container spacing={3}>
          <VerticalGrid
            label="Informasi ID Pengguna (NIK / No.KK / NIP)"
            md={12}
          >
            <MainController
              {...getProps("ManualValidasi.Id")}
              rules={{
                required: true,
              }}
              defaultValue=""
              // shouldUnregister={true}
              render={({ field }) => (
                <TextFieldv3
                  {...field}
                  errors={errors}
                  placeholder="Masukkan Nomor ID"
                />
              )}
            />
          </VerticalGrid>
        </Grid>
      </MainDialog>
    </>
  );
};

ManualValidasi.propTypes = {
  Controller: PropTypes.any,
  control: PropTypes.any,
  data: PropTypes.object,
  errors: PropTypes.any,
  handleSubmit: PropTypes.any,
  id: PropTypes.any,
  isEdit: PropTypes.bool,
  isValid: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  setRes: PropTypes.func,
  onSubmit: PropTypes.func,
  watch: PropTypes.any,
  register: PropTypes.any,
  unregister: PropTypes.any,
  setValue: PropTypes.any,
  action: PropTypes.any,
};

ManualValidasi.defaultProps = {
  isEdit: false,
  onClose: () => {},
  open: false,
  refresh: () => {},
  onSubmit: () => {},
  data: null,
  id: null,
  setRes: null,
  handleSubmit: null,
  isValid: null,
  errors: null,
  control: null,
  Controller: null,
  watch: null,
  register: null,
  unregister: null,
  setValue: null,
  action: null,
};
export default ManualValidasi;
