import React from "react";

const WarningIconSmall = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16699 12.5003H10.8337V14.167H9.16699V12.5003ZM9.16699 5.83366H10.8337V10.8337H9.16699V5.83366ZM9.99199 1.66699C5.39199 1.66699 1.66699 5.40033 1.66699 10.0003C1.66699 14.6003 5.39199 18.3337 9.99199 18.3337C14.6003 18.3337 18.3337 14.6003 18.3337 10.0003C18.3337 5.40033 14.6003 1.66699 9.99199 1.66699ZM10.0003 16.667C6.31699 16.667 3.33366 13.6837 3.33366 10.0003C3.33366 6.31699 6.31699 3.33366 10.0003 3.33366C13.6837 3.33366 16.667 6.31699 16.667 10.0003C16.667 13.6837 13.6837 16.667 10.0003 16.667Z"
        fill="#F5B419"
      />
    </svg>
  );
};

export default WarningIconSmall;
