import React from "react";

const KartuSubsidiIlu = () => {
  return (
    <svg
      width="106"
      height="82"
      viewBox="0 0 106 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2599_7950)">
        <path
          d="M90.991 17.8H4.40901C1.97398 17.8 0 19.8876 0 22.4629V76.9371C0 79.5124 1.97398 81.6 4.40901 81.6H90.991C93.426 81.6 95.4 79.5124 95.4 76.9371V22.4629C95.4 19.8876 93.426 17.8 90.991 17.8Z"
          fill="url(#paint0_linear_2599_7950)"
        />
        <rect y="60.5002" width="95.5826" height="8.80258" fill="white" />
        <path
          d="M30.1066 37.6841V43.4231C30.1066 45.2165 28.7344 46.6677 27.0386 46.6677H15.4723C13.7804 46.6677 12.4043 45.2165 12.4043 43.4231V37.6841C12.4043 35.8907 13.7804 34.4354 15.4723 34.4354H27.0386C28.7344 34.4354 30.1066 35.8907 30.1066 37.6841Z"
          fill="white"
        />
        <path
          d="M19.5772 34.4354V35.8701C19.5772 37.5852 18.2557 38.9828 16.6301 38.9828H12.4043V38.1583H16.6301C17.8269 38.1583 18.7976 37.1317 18.7976 35.8701V34.4354H19.5772Z"
          fill="#666666"
        />
        <path
          d="M30.2002 40.364V41.1885H24.5632C23.1988 41.1885 22.0839 42.3676 22.0839 43.8106V46.6677H21.3042V43.8106C21.3042 41.91 22.7661 40.364 24.5632 40.364H30.2002Z"
          fill="#666666"
        />
      </g>
      <g clipPath="url(#clip1_2599_7950)">
        <path
          d="M96.3347 17.0411L95.877 17.4769L91.5021 19.3618L96.2239 21.3006L98.1319 26.0985L100.131 21.3054L104.863 19.3618L100.136 17.3251L98.1319 12.5222L96.3347 17.0411ZM106 27.6603L106 27.6799L105.976 27.6799L106 27.6603Z"
          fill="#F7B500"
        />
        <path
          d="M80.483 11.9518L79.8904 12.5197L74.2001 14.9726L80.3385 17.494L82.8198 23.7313L85.4216 17.4989L91.5745 14.9726L85.4313 12.3239L82.8246 6.08163L80.4878 11.9567L80.483 11.9518ZM93.0488 25.7582L93.0488 25.7827L93.0151 25.7827L93.0488 25.7582Z"
          fill="#F7B500"
        />
        <path
          d="M87.05 28.8109L86.7754 29.0703L84.1447 30.2013L86.9778 31.3665L88.1245 34.2453L89.3242 31.3665L92.167 30.2013L89.329 28.9773L88.1245 26.0936L87.0452 28.806L87.05 28.8109ZM92.856 35.1902L92.856 35.2L92.8415 35.2L92.856 35.1902Z"
          fill="#F7B500"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2599_7950"
          x1="60.6874"
          y1="115.796"
          x2="46.3601"
          y2="3.94698"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#11998E" />
          <stop offset="1" stopColor="#38EF7D" />
        </linearGradient>
        <clipPath id="clip0_2599_7950">
          <rect
            width="95.4"
            height="63.8"
            fill="white"
            transform="translate(0 17.8)"
          />
        </clipPath>
        <clipPath id="clip1_2599_7950">
          <rect
            width="31.8"
            height="34.8"
            fill="white"
            transform="translate(106 35.2) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default KartuSubsidiIlu;
