import React from "react";
import { Alert as A, Typography } from "@mui/material";
import PropTypes from "prop-types";
import AlertIcon from "assets/paper/AlertIcon";

const FormError = ({ type, ...props }) => {
  if (props.open ? props.open : !props.message) {
    return;
  }

  if (type === 1) {
    return (
      <Typography variant="inputDesc" sx={{ color: "#cc1d15" }}>
        * {props.message}
      </Typography>
    );
  }

  return (
    <A {...props} icon={<AlertIcon />} variant="primary" onClose={props.onClose}>
      {props.message}
    </A>
  );
};

FormError.defaultProps = {
  message: "",
  open: "",
  onClose: () => {},
  type: 0,
};

FormError.propTypes = {
  message: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.any,
  type: PropTypes.number,
};

export default FormError;
