import { styled } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";

export const AppBar = styled(MuiAppBar)(({ theme, open }) => ({
  zIndex: 500,
  backgroundColor: "#fff",
  boxShadow: "rgb(100 116 139 / 12%) 1px 1px 4px",
  width: "100%",
  paddingLeft: "40px",
  paddingRight: "40px",
}));
