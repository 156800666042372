import React, { useMemo } from "react";
import PropTypes from "prop-types";
import MainDialog from "components/Dialog/MainDialog";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Typography } from "@mui/material";
import Validasi from "components/Status/Validasi";
import MainGrid from "components/Grid/MainGrid";
import moment from "moment";
import MainLoading from "components/Loading/MainLoading";

const DetailVoucerBansos = ({ data, loading, open, onClose }) => {
  const RincianPenukaran = [
    {
      label: "Status",
      value: (
        <Validasi
          textTrue="Berhasil"
          textFalse="Dibatalkan"
          value={data?.status}
        />
      ),
    },
    {
      label: "Waktu Penukaran",
      value: moment(data?.date).format("DD/MM/YYYY HH:ss"),
    },
    {
      label: "Nama Merchant",
      value: data?.merchant,
    },
    {
      label: "Nama Petugas",
      value: data?.officer,
    },
  ];

  const RincianKartuSubsidi = [
    {
      label: "Nama Kartu",
      value: data?.voucher,
    },
    {
      label: "Kode Kartu",
      value: data?.code,
    },
    {
      label: "Nama Penerima",
      value: data?.claimed_by,
    },
  ];

  return (
    <>
      <MainDialog
        open={open}
        onClose={() => {
          onClose();
        }}
        loading={false}
        action={false}
        customWidth="610px"
        closeButton={false}
      >
        <MainLoading loading={loading}>
          <Box display="flex" pb={2} justifyContent="space-between">
            <Typography variant="h2">Rincian Transaksi</Typography>
            <IconButton
              aria-label="close"
              sx={{ padding: 0 }}
              disabled={false}
              onClick={onClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          {RincianPenukaran.map((item, i) => (
            <MainGrid key={i} {...item} labelMd={4} valueMd={5} center />
          ))}
          <Box display="flex" py={2} justifyContent="space-between">
            <Typography variant="h2">Rincian Voucer Bansos</Typography>
            <IconButton
              aria-label="close"
              sx={{ padding: 0 }}
              disabled={false}
              onClick={onClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          {RincianKartuSubsidi.map((item, i) => (
            <MainGrid
              key={i}
              {...item}
              labelMd={4}
              valueMd={5}
              striped="odd"
              center
            />
          ))}
        </MainLoading>
      </MainDialog>
    </>
  );
};

DetailVoucerBansos.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  data: PropTypes.object,
  loading: PropTypes.bool,
};

DetailVoucerBansos.defaultProps = {
  onClose: () => {},
  open: false,
  data: {},
  loading: false,
};
export default DetailVoucerBansos;
