import { createAsyncThunk } from "@reduxjs/toolkit";
import { getParams, get, post } from "api";
import { VoucherBansos } from "api/endpoints";

export const getListVoucherBansos = createAsyncThunk(
  "voucherBansos/getVoucherBansos",
  async (params) => {
    const response = await getParams(VoucherBansos.list, params);
    return response.data.data;
  }
);

export const voucherBansosGet = async (params) => {
  const response = await getParams(VoucherBansos.list, params);
  return response;
};

export const getPenerimaBansos = createAsyncThunk(
  "voucherBansos/getPenerimaBansos",
  async ({ params, id }) => {
    const withId = `${VoucherBansos.listPenerima}/${id}`;

    const response = await getParams(withId, params);
    return response.data.data;
  }
);

export const getVoucherBansos = async (data, id) => {
  const withId = `${VoucherBansos.voucherPenerbit}/${id}`;

  const response = await getParams(withId, data);

  return response;
};

export const getDetailVoucher = async (id) => {
  const withId = `${VoucherBansos.detailVoucher}/${id}`;

  const response = await get(withId);

  return response;
};

export const newVoucherBansos = async (data) => {
  const response = await post(VoucherBansos.createBansosMulti, data);

  return response;
};

export const exportUser = async (id) => {
  const withId = `${VoucherBansos.exportUser}/${id}`;

  const response = await get(withId);

  return response;
};
