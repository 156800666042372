import { createSlice } from "@reduxjs/toolkit";
// import { pagination } from "redux/pagination";
import { getListPengguna } from "../action";

export const initialState = {
  list: [],
  currentPage: 1,
  params: {
    limit: 10,
    page: 1,
  },
  totalItems: 0,
  message: "",
  filterName: "search",
  loading: false,
};

export const penggunaSlice = createSlice({
  name: "pengguna",
  initialState,
  reducers: {
    changeLimit: (state, action) => {
      let limit = action.payload.limit;
      state.params.limit = limit;
    },
    changeFilterName: (state, action) => {
      //   console.log(action);
      let filterName = action.payload.name;
      state.filterName = filterName;
    },
    changeFilter: (state, action) => {
      state.params = {
        ...state.params,
        limit: 10,
        page: 1,
        [state.filterName]: action.payload,
      };
    },
    changePage: (state, action) => {
      let limit = action.payload.limit;
      //   let offset = (action.payload.page - 1) * action.payload.limit;
      state.params.limit = limit;
      state.params.page = action.payload.page;
    },
    deleteFilter: (state) => {
      delete state.params?.[state.filterName];
    },
    addFilter: (state, action) => {
      if (!action.payload.action) {
        state.params = {
          ...state.params,
          ...action.payload.list,
        };
      } else {
        for (let i in action.payload.tempList) {
          delete state.params[i];
        }
      }
    },
  },
  extraReducers: {
    [getListPengguna.pending]: (state) => {
      state.loading = true;
    },
    [getListPengguna.rejected]: (state, action) => {
      state.loading = false;
      state.message = action.error.message;
    },
    [getListPengguna.fulfilled]: (state, action) => {
      state.loading = false;
      state.list = action.payload.records;
      state.totalItems = action.payload.total_record;
    },
  },
});

export const {
  changePage,
  changeLimit,
  changeFilterName,
  changeFilter,
  deleteFilter,
  addFilter,
} = penggunaSlice.actions;

export default penggunaSlice.reducer;
