import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import SidebarOpen from "assets/layout/sidebarOpen";
import SidebarClose from "assets/layout/sidebarClose";
import { AppBar } from "./style";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import { getCookie, removeCookie } from "helper/cokkie";
import { useDispatch, useSelector } from "react-redux";
import { getPhoto, getProfile } from "redux/profile/action";
import { Avatar, Drawer, Typography, Button } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useMediaQuery, useTheme } from "@mui/material";
import { alert } from "redux/global/slice";
import { ScrollRestoration } from "react-router-dom";
import { getIpAddress } from "redux/auth/actions";

const isLogin = getCookie("token-ntt-webadmin");
const Layout = () => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.between("xs", "md"));

  const [open, setOpen] = useState(true);
  const [openRight, setOpenRight] = useState(false);
  const {
    photo,
    profile: { Fullname, role, Email },
  } = useSelector((state) => state.profile);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (!isLogin) {
    return window.location.replace("/auth/login");
  }

  useEffect(() => {
    if (match) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [match]);

  useEffect(() => {
    let str = JSON.parse(localStorage.getItem("sidebar"));
    if (str == null) {
      localStorage.setItem("sidebar", true);
      setOpen(true);
    } else {
      setOpen(str);
    }
  }, []);

  useEffect(() => {
    let isRegistered = JSON.parse(localStorage.getItem("profile-ntt"));
    if (isRegistered?.bussinesRegistered == 1) {
      return navigate("/bussines-registration");
    }

    dispatch(getProfile());
    dispatch(getPhoto());
    getIpAddress();

    let token = localStorage.getItem("token-ntt-webadmin");
    let account;
    if (token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      account = JSON.parse(jsonPayload);

      localStorage.setItem(
        "permission-ntt",
        JSON.stringify(account.Permission)
      );
    }
  }, []);

  const Logout = () => {
    removeCookie("token-ntt-webadmin");
    localStorage.clear();
    // navigate("/auth/login");
    dispatch(
      alert({
        open: true,
        message: "Logout Berhasil",
      })
    );
    return window.location.replace("/auth/login");
  };

  const handleOpenSidebar = () => {
    setOpen(!open);
    localStorage.setItem("sidebar", !open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar position="fixed" open={open} match={match ? 1 : 0}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            minHeight: {
              lg: "75px",
            },
            zIndex: "200",
          }}
        >
          <IconButton
            color="inherit"
            edge="start"
            onClick={() => handleOpenSidebar()}
          >
            {open ? <SidebarOpen /> : <SidebarClose />}
          </IconButton>
          <Button
            variant="text"
            sx={{
              textTransform: "none",
              display: "flex",
              justifyContent: "space-evenly",
              gap: "20px",
              minWidth: "200px",
            }}
            onClick={() => setOpenRight(true)}
          >
            <Avatar alt="profile-photo" src={photo} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <Typography variant="textMedium">{Fullname || "-"}</Typography>
              <Typography variant="textMediumV2">{role || "-"}</Typography>
            </Box>
            <KeyboardArrowDownIcon sx={{ color: "#3F4144" }} />
          </Button>
        </Toolbar>
      </AppBar>
      <Sidebar open={open} setOpen={setOpen} />
      <Drawer
        anchor={"right"}
        open={openRight}
        onClose={() => setOpenRight(false)}
        PaperProps={{ sx: { width: "300px", zIndex: "200" } }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "30px",
            }}
          >
            <Avatar
              alt="profile-photo"
              src={photo}
              sx={{ margin: "auto", width: 56, height: 56 }}
            />
            <Typography
              variant="textMedium"
              sx={{
                fontSize: "16px",
                fontWeight: "800",
                color: "#3F4144",
                textAlign: "center",
              }}
            >
              {Fullname}
            </Typography>
            <Typography
              variant="textMediumV2"
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "center",
                color: "#3F4144",
              }}
            >
              {Email}
            </Typography>
          </Box>
        </Box>

        <Button
          variant="primary"
          type="button"
          sx={{ margin: "auto 15px 50px 15px", padding: "15px" }}
          onClick={Logout}
        >
          Logout
        </Button>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: "1",
          paddingTop: {
            xs: "70px",
            md: "104px",
          },
          backgroundColor: "#ebeced",
          overflow: "hidden",
          // minHeight: "100vh",
          // height: "100vh",
        }}
      >
        <Box
          sx={{
            minHeight: "calc(100vh - 190px)",
            paddingLeft: match ? "13px" : "32px",
            paddingRight: match ? "13px" : "32px",
          }}
        >
          <Outlet />
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "50px",
            backgroundColor: "#fff",
            marginTop: "30px",
            justifyContent: "space-between",
            px: 3,
            alignItems: "center",
          }}
        >
          <Typography variant="textMedium">
            V{process.env.REACT_APP_APP_VERSION}
          </Typography>
          <Typography variant="textMedium">
            2022 © &nbsp;
            <a
              href="https://www.inisa.id/"
              target="_blank"
              style={{ color: "#cc1d15", fontFamily: "inherit" }}
              rel="noreferrer"
            >
              INISA
            </a>
            &nbsp;| PT Digital Pemerintahan Indonesia
          </Typography>
        </Box>
      </Box>
      <ScrollRestoration />
    </Box>
  );
};

export default Layout;
