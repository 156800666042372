import React from "react";
import { Paper } from "@mui/material";
import PropTypes from "prop-types";
import PageTitle from "components/Page/PageTitle";

const StepView = ({ activeStep, view, children, paper, page }) => {
  if (activeStep == view && !paper) {
    return children;
  }

  if (activeStep == view && paper) {
    return (
      <Paper
        sx={{
          p: 5,
          mt: 3,
          borderRadius: "16px",
          boxShadow: "0px 4px 11px -10px #c8c8c8",
        }}
      >
        <PageTitle {...page} variantTitle="title4" backBtn={false} />
        {children}
      </Paper>
    );
  }
};

StepView.propTypes = {
  activeStep: PropTypes.any,
  children: PropTypes.node,
  page: PropTypes.object,
  view: PropTypes.any,
  paper: PropTypes.bool,
};

StepView.defaultProps = {
  activeStep: null,
  children: null,
  view: null,
  page: null,
  paper: true,
};

export default StepView;
