import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const color = {
  4: {
    label: "Officer",
    color: "#0C6CE2",
    background: "#E7F0FC",
  },
  1: {
    label: "Super Admin",
    color: "#CC1D15",
    background: "#FAE8E8",
  },
  2: {
    label: "Super Visor",
    color: "#7300F7",
    background: "#F1E6FE",
  },
  3: {
    label: "Operator",
    color: "#129350",
    background: "#E7F0FC",
  },
  5: {
    color: "#4e5d78",
    background: "#ececec",
  },
};

const find = (value, text) => {
  return color?.[value]?.[text] || "-";
};

const RoleUser = ({ value, string }) => (
  <Box
    sx={{
      width: "100px",
      height: "24px",
      backgroundColor: find(value, "background") || "#FAE8E8",
      color: find(value, "color") || "#CC1D15",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    {string}
  </Box>
);

RoleUser.defaultProps = {
  value: 0,
  string: "",
};

RoleUser.propTypes = {
  value: PropTypes.any,
  string: PropTypes.string,
};

export default RoleUser;
