import Close from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import React, { forwardRef } from "react";
import FotoProfile from "assets/pengguna/profile-tmp.png";
import PropTypes from "prop-types";

const FileDropComponent = forwardRef(
  ({ width, height, onChange, value, onClose }, ref) => {
    return (
      <Box
        ref={ref}
        sx={{
          cursor: "pointer",
          backgroundColor: "#ebeced",
          borderRadius: "4px",
          width: width,
          maxWidth: "270px",
          height: height,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          gap: 1,
        }}
        onClick={onChange}
      >
        {!Boolean(value) ? (
          <>
            <Box component="img" src={FotoProfile} />
          </>
        ) : (
          <>
            {Boolean(onClose) && (
              <Box
                sx={{
                  position: "absolute",
                  top: "0px",
                  right: "4px",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  onClose();
                }}
              >
                <Close
                  sx={{
                    fontSize: "15px",
                  }}
                />
              </Box>
            )}
            <Box
              component="img"
              src={
                value
                  ? typeof value === "object"
                    ? URL.createObjectURL(value)
                    : value
                  : ""
              }
              sx={{
                // objectFit: "contain",
                borderRadius: "4px",
                width: "100%",
                height: "100%",
              }}
            ></Box>
          </>
        )}
      </Box>
    );
  }
);

FileDropComponent.displayName = "fdc";

FileDropComponent.propTypes = {
  onChange: PropTypes.func,
  setError: PropTypes.func,
  value: PropTypes.any,
  onClose: PropTypes.any,
  name: PropTypes.any,
  accept: PropTypes.string,
  width: PropTypes.any,
  height: PropTypes.any,
};

FileDropComponent.defaultProps = {
  onChange: () => {},
  setError: () => {},
  value: null,
  onClose: null,
  accept: "image/png,image/jpg,image/jpeg",
  name: "",
  width: "182px",
  height: "182px",
};

export default FileDropComponent;
