import React from "react";

const TukarVoucher = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 7.60002V4.80002C16 4.02302 15.37 3.40002 14.6 3.40002H3.4C2.63 3.40002 2.007 4.02302 2.007 4.80002V7.60002C2.777 7.60002 3.4 8.23002 3.4 9.00002C3.4 9.77002 2.777 10.4 2 10.4V13.2C2 13.97 2.63 14.6 3.4 14.6H14.6C15.37 14.6 16 13.97 16 13.2V10.4C15.23 10.4 14.6 9.77002 14.6 9.00002C14.6 8.23002 15.23 7.60002 16 7.60002ZM14.6 6.57802C13.767 7.06102 13.2 7.97102 13.2 9.00002C13.2 10.029 13.767 10.939 14.6 11.422V13.2H3.4V11.422C4.233 10.939 4.8 10.029 4.8 9.00002C4.8 7.96402 4.24 7.06102 3.407 6.57802L3.4 4.80002H14.6V6.57802ZM8.3 11.1H9.7V12.5H8.3V11.1ZM8.3 8.30002H9.7V9.70002H8.3V8.30002ZM8.3 5.50002H9.7V6.90002H8.3V5.50002Z"
        fill="white"
      />
    </svg>
  );
};

export default TukarVoucher;
